// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import "../../assets/styles/media/media-selector-modal.scss";

// Import Components
import {Modal} from "antd";
import {MediaFileCard} from "./mediaFileCard";
import {MediaFolderCard} from "./mediaFolderCard";
import {LinkButton} from "../buttons/buttons";
import {InputGroup} from "../uiElements/inputGroup";

// Import utils
import {GetGallery} from "../../redux/actions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {getFolderData} from "../../containers/media/utils/helperFunctions";
import {LIMIT_GET_MEDIA} from "../../redux/constants";



class MediaSelectorModal extends Component {
    constructor() {
        super();
        this.state = {
            selectedImages: [],
            searchValue: '',
            folderList: [],
        };
        this.selectImage = this.selectImage.bind(this);
        this.insertWithDoubleClick = this.insertWithDoubleClick.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getSearchValue = this.getSearchValue.bind(this);
        this.getSearchData = this.getSearchData.bind(this);
        this.loadMoreImages = this.loadMoreImages.bind(this);
        this.insertMedia = this.insertMedia.bind(this);
        this.openFolder = this.openFolder.bind(this);
    }

    componentDidMount() {
        const {galleryImages} = this.props;
        !galleryImages?.itemsList?.length && this.props.GetGallery();
    }

    selectImage(item, isSelected) {
        this.setState({
            selectedImages: isSelected ? [] : [item]
        })
    }

    getSearchValue({value}) {
        this.setState({
            searchValue: value
        });
        !value && this.getSearchData('');
    }

    getSearchData(text) {
        //  this.props.GetGallery(0, LIMIT_GET_MEDIA, true, text);
    }

    onSearchKeyDown(e) {
        e && e.key === 'Enter' &&
        this.getSearchData(this.state.searchValue);
    }

    loadMoreImages() {
        const {galleryImages} = this.props;
        if (galleryImages && galleryImages.hasMore) {
            const offset = galleryImages.itemsList && galleryImages.itemsList.length;
            this.props.GetGallery(offset, LIMIT_GET_MEDIA, false, galleryImages.text);
        }
    }

    insertMedia() {
        const {selectedImages} = this.state;
        this.props.getMedia(selectedImages);
        this.closeModal();
    }

    insertWithDoubleClick(item) {
        this.props.getMedia([item]);
        this.closeModal();
    }

    closeModal() {
        this.setState({
            selectedImages: []
        });
        this.props.closeModal();
    }

    openFolder(folderData) {
        this.setState({
            folderList: [...this.state.folderList, folderData]
        })
    }

    changeFolder = (id) => {
        const {folderList} = this.state;
        if (id === null) {
            this.setState({
                folderList: []
            })
        } else {
            const newList = [];
            folderList.some(f => {
                newList.push(f);
                return f.id === id
            });
            this.setState({
                folderList: newList
            })
        }
    };

    render() {
        const {isOpen, galleryImages, acceptTypes} = this.props;
        const {selectedImages, searchValue, folderList} = this.state;
        let itemsList = galleryImages ? galleryImages.itemsList : [];

        let currentFolder = null;
        let pathList = [];

        if (searchValue) {
            itemsList = itemsList.filter((item => item.name.includes(searchValue)))
        }
        if (folderList && folderList.length) {
            currentFolder = folderList[folderList.length - 1];
            pathList = currentFolder && currentFolder.path && currentFolder.path.split('/').slice(1);
            itemsList = getFolderData(itemsList, pathList);
        }
        if (acceptTypes && acceptTypes.length) {
            itemsList = itemsList.filter((item => item.type === 'FOLDER' || acceptTypes.some(type => type === item.extension)))
        }
        return <Modal
            visible={isOpen}
            onCancel={this.closeModal}
            wrapClassName={"custom-modal"}
        >
            <div className="media-selector-modal">
                <div className="media-selector-modal-inner">
                    <div className="selector-modal-header">
                        <div className="selector-modal-title">
                            <h1>
                            <span className={'path-item'} onClick={() => this.changeFolder(null)}>
                                Ֆայլեր
                            </span>
                                {
                                    folderList && !!folderList.length && folderList.map((folder) => {
                                        return <>
                                            <span> / </span>
                                            <span className={'path-item'}
                                                  onClick={() => this.changeFolder(folder.id)}>
                                        {folder.name}
                                    </span>
                                        </>
                                    })
                                }
                            </h1>
                        </div>
                        {!currentFolder && <InputGroup
                            inputType={"input"}
                            type={'text'}
                            value={searchValue}
                            placeholder={'Փնտրել...'}
                            onKeyDown={this.onSearchKeyDown.bind(this)}
                            onChange={this.getSearchValue}
                        >
                            <div className={'search-icon'}
                                 onClick={() => this.getSearchData(searchValue)}>
                                <img src={require('../../assets/images/ic_search.svg')} alt=""/>
                            </div>
                        </InputGroup>}
                        <button className="selector-modal-closing-btn"
                                onClick={this.closeModal}>
                            <img src={require('../../assets/images/ic_clear.svg')}
                                 className="close_icon" alt=""/>
                        </button>
                    </div>

                    <div className={'selector-modal-content'}>
                        {itemsList && !!itemsList.length && itemsList.map((item, index) => {
                            const isSelected = selectedImages.find(v => v.id === item.id);
                            if (item.type === "FOLDER") {
                                return <MediaFolderCard key={item.id}
                                                        deleteMedia={(id) => this.openAlertModal("deleteFolder", id)}
                                                        openFolder={this.openFolder}
                                                        withEdit={false}
                                                        item={item}/>
                            }
                            return <MediaFileCard key={item.id}
                                                  selectImage={this.selectImage}
                                                  selectable={true}
                                                  withCopy={true}
                                                  onDoubleClick={this.insertWithDoubleClick}
                                                  isSelected={isSelected}
                                                  item={item}/>
                        })}
                    </div>
                    <div className="action-buttons">
                        <LinkButton title={'Ընտրել'}
                                    disabled={!selectedImages.length}
                                    cb={this.insertMedia}/>
                    </div>
                </div>
            </div>
        </Modal>
    }
}

const mapDispatchToProps = {
    GetGallery
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaSelectorModal);
