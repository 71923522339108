// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles

// Import components
import ItemsList from "../../components/ItemsList";
import swal from "sweetalert";
import moment from "moment";
import {Select} from "antd";
import {InputGroup} from "../../components/uiElements/inputGroup";
import DownloadStationsExcel from "../../components/excel/DownloadStationsExcel";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetStations,
    DeleteStation,
    GetNetworks,
    GetRegionResidences,
    ClearRegionResidences
} from "../../redux/actions";
import {STATION_TYPES} from "../../constants/constTypes";
import {getActivationColor} from "../../utils/dateHelper";


class Stations extends Component {

    constructor() {
        super();
        this.state = {
            networkId: undefined,
            type: undefined,
            residenceId: undefined,
            regionId: undefined,
            networkOptions: [],
        };
        this.stationTypeOptions = Object.keys(STATION_TYPES).map(key => ({
            id: key,
            name: STATION_TYPES[key]
        }));
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
    }

    async componentDidMount() {
        this.props.GetStations();
        this.props.ClearRegionResidences();
        !this.props.networks?.length && await this.props.GetNetworks();
        const {networks, mainLanguage} = this.props;
        const networkOptions = networks.map(network => {
            const mainTranslations = network?.translations?.find(item => item?.language === mainLanguage);
            const {title = '-',} = mainTranslations || {};
            return {
                id: network.id,
                name: title
            }
        });

        this.setState({networkOptions})
    }

    changeFilter({name, value}) {
        const {stations} = this.props;
        const {networkId, regionId, residenceId, type,} = this.state;
        // console.log(name, value)
        const newFilter = {
            networkId,
            regionId,
            residenceId,
            type,
            text: stations?.text,
            [name]: value,
        }
        if (name === 'regionId') {
            newFilter.residenceId = undefined;
            value ? this.props.GetRegionResidences(value) :
                this.props.ClearRegionResidences();
        }
        this.props.GetStations(newFilter).then(() => {
            this.setState({
                ...newFilter,
            });
        });
    }

    deleteStation = id => {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել լցակայանը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteStation(id);
            }
        });
    };

    loadMoreItems() {
        const {stations} = this.props;
        const {networkId, regionId, residenceId, type,} = this.state;
        const {text, hasMore} = stations;
        stations.hasMore && this.props.GetStations({
            reset: false,
            offset: stations.itemsList.length,
            text,
            networkId,
            regionId,
            residenceId,
            type
        });
    };

    render() {
        let {stations, mainLanguage, requestLoading, regions, regionResidences} = this.props;
        const {networkOptions, networkId, regionId, residenceId, type} = this.state;
        let itemListHeaderInfo = {
            generalInfo: ["Անվանում", "Ցանցի անվանում", "Գործ․ հասցե", "Պատասխանատու", "Հեռախոս", "Տեսակ", "Վերջին գործ․", "Գույն"],
            largeItems: [0, 2, 3],
            status: true,
            minWidth: 1400,
            actions: ["edit", "delete"],
        };

        let itemListInfo = stations?.itemsList?.map((station) => {
            const {stationNetwork, translations, lastUpdatedAt, phoneNumber, residence, type} = station;
            const mainTranslations = translations?.find(item => item.language === mainLanguage);
            const networkTranslations = stationNetwork?.translations?.find(item => item.language === mainLanguage);
            const {title, supervisor} = mainTranslations || {};
            const address = residence?.name;
            return {
                id: station.id,
                values: [
                    title ?? '-',
                    stationNetwork?.title ?? '-',
                    address ?? "-",
                    supervisor ?? "-",
                    phoneNumber ?? "-",
                    type ? STATION_TYPES[type] : '-',
                    lastUpdatedAt ? moment(lastUpdatedAt).format("DD.MM.YY / HH:mm") : "-",
                    <div className="station-color"
                         style={{backgroundColor: getActivationColor(lastUpdatedAt)}}/>
                ],
                status: !station.isHidden,
                deleteCb: this.deleteStation.bind(this, station.id)
            }
        });
        return <div>


            <div className="station-filters-wrapper">
                <div className={'wrapper'}>
                    <InputGroup inputType="selectCustom"
                                placeholder="Ընտրել ցանց"
                                name="networkId"
                                showSearch={false}
                                value={networkId}
                                onChange={this.changeFilter}
                                options={networkOptions}>
                        {networkId && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                    </InputGroup>
                    <InputGroup inputType="selectCustom"
                                placeholder="Մարզ/Քաղաք"
                                name="regionId"
                                showSearch={false}
                                value={regionId}
                                onChange={this.changeFilter}
                                options={regions}>
                        {regionId && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                    </InputGroup>
                    <InputGroup inputType="selectCustom"
                                placeholder="Քաղաք/Համայնք"
                                name="residenceId"
                                showSearch={false}
                                value={residenceId}
                                onChange={this.changeFilter}
                                options={regionResidences}>
                        {residenceId && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                    </InputGroup>
                    <InputGroup inputType="selectCustom"
                                placeholder="Տեսակ"
                                name="type"
                                showSearch={false}
                                value={type}
                                onChange={this.changeFilter}
                                options={this.stationTypeOptions}>
                        {type && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                    </InputGroup>
                </div>
                <DownloadStationsExcel mainLanguage={mainLanguage}
                                       stations={stations}
                                       GetStations={this.props.GetStations}/>
            </div>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       loading={requestLoading}
                       editLink={"/station/edit"}
                       detailsLink={"station"}
                       infiniteScroll={true}
                       hasMore={stations.hasMore}
                       loadMoreItems={this.loadMoreItems}/>
        </div>

    }

}


const mapDispatchToProps = {
    GetStations,
    DeleteStation,
    GetNetworks,
    GetRegionResidences,
    ClearRegionResidences
};

export default connect(mapStateToProps, mapDispatchToProps)(Stations)
