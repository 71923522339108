import {request, _urlCategories} from "../api";
import {CATEGORY_CONSTS} from "../constants";
import swal from "sweetalert";
import {history} from "../../configs/history";

export const GetCategories = () => {
    const requestData = {
        url: _urlCategories,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: CATEGORY_CONSTS.GET_CATEGORIES,
                    payload: data
                });
            })
    }
};

export const GetCategoryById = (id) => {
    const requestData = {
        url: `${_urlCategories}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: CATEGORY_CONSTS.GET_CATEGORY_BY_ID,
                    payload: res.data
                })
            })
            .catch(() => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ որ բան այն չէ, Խնդրում ենք  փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const DeleteCategoryById = id => {
    const requestData = {
        url:  `${_urlCategories}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: CATEGORY_CONSTS.DELETE_CATEGORY_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ որ բան այն չէ, Խնդրում ենք  փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const CreateCategory = data => {
    const requestData = {
        url: _urlCategories,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: CATEGORY_CONSTS.CREATE_CATEGORY,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Բաժինը հաջողությամբ ստեղծվեց",
                    icon: "success",
                    button: "Լավ",
                }).then(()=>{
                    history.push('/categories')
                })
            })
            .catch(() => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ որ բան այն չէ, Խնդրում ենք  փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const UpdateCategoryById = (id, data) => {
    const requestData = {
        url: `${_urlCategories}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: CATEGORY_CONSTS.UPDATE_CATEGORY_BY_ID,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Բաժինը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ",
                }).then(()=>{
                    history.push('/categories')
                })
            })
            .catch(() => {
               // console.log('error');
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ որ բան այն չէ, Խնդրում ենք  փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const UpdateCategoriesPositions = items => {
    const requestData = {
        url: _urlCategories,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: CATEGORY_CONSTS.UPDATE_CATEGORIES_POSITIONS
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Բաժինը հաջողությամբ թարմացվեց!",
                    icon: "success",
                    button: "Լավ"
                })
            })
            .catch(() => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ որ բան այն չէ, Խնդրում ենք  փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};
