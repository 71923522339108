// Import packages
import React, {useState, useCallback, useEffect} from 'react'
import Cropper from 'react-easy-crop';

// Import utils
import getCroppedImg from "../../utils/cropImage";

// Import components
import {Slider} from "antd";


export function ImageCropper(props) {
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [cropping, setCropping] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    useEffect(() => {
        if (props.isEditing) {
            // setCroppedAreaPixels(props.editingImage.croppedAreaPixels);
            setCrop(props.editingImage.crop);
            setZoom(props.editingImage.zoom);
        }
    }, [props.isEditing])


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);


    const showCroppedImage = useCallback(async () => {
        setCropping(true);
        try {
            const croppedImage = await getCroppedImg(
                props.imageUrl,
                croppedAreaPixels,
            )
            //return as dataUrl

            // props.getCropImage({
            //     croppedImage:croppedImage,
            //     croppedImageFile:new File([dataURLtoBlob1(croppedImage)], "cropped-image", {type: "image/jpeg"}),
            //     imageUrl:props.imageUrl,
            //     zoom:zoom,
            //     croppedAreaPixels,
            // });

            //return as blob
            const url = URL.createObjectURL(croppedImage);
            // console.log(url);
            props.getCropImage({
                path: url,
                croppedImageFile: new File([croppedImage], "cropped.png", {type: "image/jpeg"}),
                imageUrl: props.imageUrl,
                zoom,
                crop,
            });
            URL.revokeObjectURL(croppedImage)
            setZoom(1);
        } catch (e) {
        //    console.error(e)
        }
        setCropping(false);
    }, [croppedAreaPixels]);

    const onClose = useCallback(() => {
        props.closeCrop('');
    }, []);

    return (
        <div className={'crop-wrapper'}>
            <div className={`cropping ${cropping ? 'show' : ''}`}>Cropping...</div>
            {!!props.imageUrl && <>
                <button
                    className={'crop-btn confirm-btn'}
                    onClick={showCroppedImage}>
                    <img src={require('../../assets/images/ic_crop_img.svg')}  alt="" className={'crop-icon'}/>
                </button>
                <button
                    className={'crop-btn close-btn'}
                    onClick={onClose}>
                    <img src={require("../../assets/images/ic_close_crop.svg")} className={'crop-icon'}/>
                </button>

                <Cropper
                    image={props.imageUrl}
                    crop={crop}
                    zoom={zoom}
                    maxZoom={2}
                    showGrid={false}
                    aspect={(props.aspect?.x / props.aspect?.y) || 4 / 3}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    classes={{
                        containerClassName: 'cropper-component',
                        cropAreaClassName: 'cropArea'
                    }}
                />

                <div className="zoom-slider">
                    <button className='zoom-btn zoom-up' onClick={() => setZoom(zoom + 1)}>+</button>
                    <Slider
                        vertical
                        max={2}
                        min={1}
                        step={0.01}
                        value={zoom}
                        onChange={setZoom}
                    />
                    <button className='zoom-btn zoom-down' onClick={() => setZoom(zoom > 1 ? zoom - 1 : zoom)}>-
                    </button>
                </div>
            </>
            }
        </div>
    )
}

function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
}
