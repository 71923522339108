// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";

// Import styles
import "../../../assets/styles/containerStyles/tire-details.scss";

// Import components
import PageHeader from "../../../components/pageContentViews/pageHeader";
import PageWrapper from "../../../components/pageContentViews/pageWrapper";

// Import utils
import {mapStateToProps} from "../../../redux/mapStateToProps";
import {SetOffersAsSeen} from "../../../redux/actions";
import {LinkButton} from "../../../components/buttons/buttons";
import {OFFERS_TYPE} from "../../../constants/constTypes";



class TireDetails extends Component {
    constructor(props) {
        super(props);
        this.tyreData = props.tyreChangeList?.itemsList?.find(tyre => tyre.id === props.id);
    }

    componentDidMount() {
        const {id} = this.props;
        this.tyreData && !this.tyreData.seen && this.props.SetOffersAsSeen(id, OFFERS_TYPE.TYRE_CHANGE);
    }

    render() {
        return <PageWrapper>
            <section className="tire-details">
                <PageHeader pageTitle={'Անվադողի փոխում'}/>
                <div className="tire-details-wrapper">
                    <div className="left-part">
                        <h1 className="data-title">Հայտի տվյալներ</h1>
                        <table className="data-view">
                            <tbody>
                            {this.tyreData?.createdAt &&
                            <tr>
                                <td className="text-bold">Ուղարկման ամսաթիվ։</td>
                                <td className="text-grey">{moment(this.tyreData?.createdAt).format('DD.MM.YYYY')}</td>
                            </tr>
                            }
                            {this.tyreData?.carMake?.name &&
                            <tr>
                                <td className="text-bold">Մակնիշ։</td>
                                <td className="text-grey">{this.tyreData?.carMake?.name}</td>
                            </tr>
                            }
                            {this.tyreData?.carModel?.name &&
                            <tr>
                                <td className="text-bold">Մոդել։</td>
                                <td className="text-grey">{this.tyreData?.carModel?.name}</td>
                            </tr>
                            }
                            {this.tyreData?.tyreSize?.name &&
                            <tr>
                                <td className="text-bold">Չափս։</td>
                                <td className="text-grey">{this.tyreData?.tyreSize?.name}</td>
                            </tr>
                            }
                            {this.tyreData?.quantity &&
                            <tr>
                                <td className="text-bold">Քանակ։</td>
                                <td className="text-grey">{this.tyreData?.quantity}</td>
                            </tr>
                            }
                            {this.tyreData?.tyreSize?.price &&
                            <tr>
                                <td className="text-bold">Արժեք։</td>
                                <td className="text-grey">{this.tyreData?.tyreSize?.price}</td>
                            </tr>
                            }
                            </tbody>
                        </table>
                        <div className="separator"/>
                        <table className="data-view">
                            <tbody>
                            {this.tyreData?.address &&
                            <tr>
                                <td className="text-bold">Նախընտրելի համայնք։</td>
                                <td className="text-grey">{this.tyreData?.address}</td>
                            </tr>
                            }
                            {this.tyreData?.date &&
                            <tr>
                                <td className="text-bold">Օր։</td>
                                <td className="text-grey">{moment(this.tyreData?.date).format('DD.MM.YYYY')}</td>
                            </tr>
                            }
                            {this.tyreData?.hour &&
                            <tr>
                                <td className="text-bold">Ժամ։</td>
                                <td className="text-grey">{this.tyreData?.hour}</td>
                            </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="right-part">
                        <h1 className="data-title">Հայտատուի տվյալներ</h1>
                        <table className="data-view">
                            <tbody>
                            {this.tyreData?.user?.firstName &&
                            <tr>
                                <td className="text-bold">Անվանում։ </td>
                                <td className="text-grey">{this.tyreData?.user?.firstName + " " + this.tyreData?.user?.lastName}</td>
                            </tr>
                            }
                            {this.tyreData?.user?.username &&
                            <tr>
                                <td className="text-bold">Հեռախոս։</td>
                                <td className="text-grey">{this.tyreData?.user?.username}</td>
                            </tr>
                            }
                            {this.tyreData?.user?.email &&
                            <tr>
                                <td className="text-bold">Էլ․ հասցե։</td>
                                <td className="text-grey">{this.tyreData?.user?.email}</td>
                            </tr>
                            }
                            </tbody>
                        </table>

                    </div>
                </div>
                {/*<div className="separator"/>*/}
                {/*<div>*/}
                {/*    <span className="amount-title">Գումար:</span>*/}
                {/*    <span className="amount">{tireData?.price} Դ</span>*/}
                {/*</div>*/}
                {/*<div className="offer-buttons">*/}
                {/*    <LinkButton title={"Հաստատել"} className="bg-green"/>*/}
                {/*    <LinkButton title={"Մերժել"} className="bg-red"/>*/}
                {/*</div>*/}
            </section>

        </PageWrapper>;

    }
}

const mapDispatchToProps = {SetOffersAsSeen};

export default connect(mapStateToProps, mapDispatchToProps)(TireDetails)
