// Import packages
import React, {Component} from "react";
import {Link} from "react-router-dom";

// Import Styles
import '../../assets/styles/cards/list-card.scss';

// Import components
import {LinkButton} from "../buttons/buttons";
import {MaterialIcon} from "../utils/Icon";
import {Checkbox, Icon, Dropdown} from "antd";
import {STATUS_LIST} from "../../constants/constTypes";


class ListItemCard extends Component {
    render() {

        const {
            data, status, actions, columnsWidth, width, detailsLink, editLink, selectingActive
        } = this.props;

        return <div className={`list-item-wrapper ${data?.newItem ? 'selected' : ''} ${data?.isEdited ? 'isEdited' : ''}`} onClick={data?.onClick}
                    style={{width}}>
            {selectingActive && <span className={'selecting-checkbox'}>
                <Checkbox checked={data?.selected} onChange={data?.handleSelect}/>
            </span>}
            <div className="general-info-content">
                {data?.values && !!data.values.length && data.values.map((item, index) => {
                    const width = columnsWidth[index] + 'px';
                    return detailsLink ?
                        <Link className="general-info-item" style={{width: width}} key={index}
                              to={{
                                  pathname: `/${detailsLink}/${data.id}`
                              }}>
                            {item}
                        </Link>
                        :
                        <span className="general-info-item" style={{width: width}}
                              key={index}>
                            {item}
                        </span>
                })}
            </div>
            <div className="item-actions-part">
                {status && <>
                    {
                        typeof data?.status === 'boolean' ?
                            <div className="status">{data?.status ? 'Ակտիվ' : 'Ոչ ակտիվ'}</div>
                            : <span className={`status-badge`}
                                    style={{backgroundColor: STATUS_LIST[data?.status]?.color}}>
                    {STATUS_LIST[data?.status]?.title}</span>
                    }
                </>
                }
                {actions && <div className="actions">
                    {actions && !!actions && actions.map((item) => {
                        switch (item) {
                            case 'edit':
                                return <LinkButton link={{
                                    pathname: `${editLink}/${data.id}`
                                }} key={item} title={<MaterialIcon icon="edit"/>}
                                                   className="bg-orange"/>
                            case 'delete':
                                return <LinkButton key={item}
                                                   title={<MaterialIcon icon="delete"/>}
                                                   className="bg-red"
                                                   cb={data.deleteCb}/>
                            case 'details':
                                return <LinkButton link={{pathname: `/${detailsLink}/${data.id}`}} key={item}
                                                   title={<MaterialIcon
                                                       icon="keyboard_arrow_right"/>}/>
                            case 'update':
                                return <Dropdown overlay={data?.updatedMenu}
                                                 placement={"bottomRight"}>
                                    <Icon className="more" type="more"/>
                                </Dropdown>

                        }
                        // return item === "edit" ?
                        //     <LinkButton link={{
                        //         pathname: `${editLink}/${data.id}`
                        //     }} key={item} title={<MaterialIcon icon="edit"/>}
                        //                 className="bg-orange"/>
                        //
                        //     : (item === "delete" ?
                        //         <LinkButton key={item}
                        //                     title={<MaterialIcon icon="delete"/>}
                        //                     className="bg-red"
                        //                     cb={data.deleteCb}/>
                        //         : (item === "details" ?
                        //             <LinkButton link={{
                        //                 pathname: `/${detailsLink}/${data.id}`
                        //             }} key={item} title={<MaterialIcon
                        //                 icon="keyboard_arrow_right"/>}/> : ""))
                    })}
                </div>}
                {/*{update && <div className="update-action">*/}
                {/*    <Dropdown overlay={data?.updatedMenu}*/}
                {/*              placement={"bottomRight"}>*/}
                {/*        <Icon className="more" type="more"/>*/}
                {/*    </Dropdown>*/}
                {/*</div>}*/}
            </div>
        </div>
    }
}

export default ListItemCard
