// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import swal from 'sweetalert';
//import assets
import '../../assets/styles/dataDisplay/notification-modal.scss';

// Import components
import {Modal} from "antd";
import {LanguageTabs} from "./Tabs";
import {InputGroup} from "./inputGroup";
import {LinkButton} from "../buttons/buttons";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {changeStateField, initTranslations} from "../../utils/helperFunctions";

import {SendNotification} from "../../redux/actions";

class ModalSendNotification extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = ['title', 'description'];
        this.state = {
            translations: initTranslations(this.translationsFields, props.activeLanguages),
            languageTab: props.mainLanguage,
            notificationSend: false,
            loading: false
        };
        this.errorFields = new Set();
        this.errorsTabs = new Set();
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = this.getInputValues.bind(this);
        this.sendNotification = this.sendNotification.bind(this);
    }

    getInputValues({name, value,}) {
        const {translations, languageTab,notificationSend} = this.state;
        if(notificationSend) {
            return;
        }
        this.setState({
            translations: {
                ...translations,
                [languageTab]: {
                    ...translations[languageTab],
                    [name]: value
                }
            }
        });
    }

    validateFields(notificationData) {
        this.errorFields.clear();
        this.errorsTabs.clear();
        Object.keys(notificationData).forEach(key => {
            const trData = notificationData[key] || {};
            let tabHasError = false;
            this.translationsFields.forEach(item => {
                if (!trData || !trData[item] || trData[item] === "") {
                    this.errorFields.add(item + '_' + key);
                    tabHasError = true;
                }
            });
            tabHasError && this.errorsTabs.add(key);
        });
        return !this.errorFields.size;
    }

    async sendNotification() {
        const {translations} = this.state;
        const validationResult = this.validateFields(translations);
        const {userIds, sendToAllUsers} = this.props;
        //console.log(userIds)
        if (validationResult) {
            await this.setState({loading: true});
            const Tr_Data = [];
            Object.keys(translations).forEach(key => {
                const trData = translations[key] || {};
                Tr_Data.push({
                    language: key,
                    title: trData.title,
                    description: trData.description
                })
            });

            const reqData = {
                translations: Tr_Data
            };
            !sendToAllUsers && (reqData.receiverIds = userIds.join(','));
            await this.props.SendNotification(reqData).then(() => {
                this.setState({
                    notificationSend: true
                });
            }).catch(() => {
            });
            this.setState({loading: false});
        } else {
            this.forceUpdate();
        }

    }

    closeModal = () => {
        this.props.closeModal(this.state.notificationSend);
        this.setState({
            translations: initTranslations(this.translationsFields, this.props.activeLanguages),
            notificationSend: false
        })
    }

    render() {
        const {visible, userIds} = this.props;
        const {languageTab, translations, notificationSend, loading} = this.state;
        const trData = translations[languageTab] || {};
        return <Modal
            visible={visible}
            wrapClassName={'custom-modal'}
            onCancel={this.closeModal}
        >
            <div className={'send-notification-modal'}>
                <span className={'close-icon'} onClick={this.closeModal}>
                    <img src={require('../../assets/images/ic_clear.svg')}
                         className="close_icon" alt=""/>
                </span>
                <h2 className="title">Ծանուցում</h2>
                <section className="send-notification">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  activeTab={languageTab}
                                  errorsTabs={this.errorsTabs}
                    />
                    <div className="info-adding-fields">

                        <InputGroup inputType={"input"}
                                    type={"text"}
                                    label={"Վերնագիր"}
                                    placeholder={"Վերնագիր"}
                                    name={"title"}
                                    maxLength={50}
                                    error={this.errorFields.has('title_' + languageTab)}
                                    value={trData?.title}
                                    onChange={this.getInputValues}/>
                        <InputGroup inputType={"textarea"}
                                    label={"Նկարագրություն"}
                                    placeholder={"Նկարագրություն"}
                                    name={"description"}
                                    maxLength={300}
                                    error={this.errorFields.has('description_' + languageTab)}
                                    value={trData?.description}
                                    onChange={this.getInputValues}/>
                    </div>
                    <div className={'message'}>{notificationSend && 'Գործողությունը հաջողությամբ կատարվեց'}</div>
                    <div className="btn-wrapper">
                        <LinkButton title="Ուղարկել Ծանուցում"
                                    loading={loading}
                                    disabled={notificationSend}
                                    loadingWithTitle={true}
                                    cb={this.sendNotification}/>
                    </div>
                </section>
            </div>
        </Modal>
    }
}


const mapDispatchToProps = {
    SendNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSendNotification)
