// Import packages
import React, {useState, useEffect} from "react";


//import assets
import '../../assets/styles/media/preview-modal.scss';

// Import components
import {Modal} from "antd";

// Import utils
import {generateFileMediaUrl} from "../../utils/generateMemberMediaUrl";
import {IMAGES_TYPES_PATTERN} from "../../constants/acceptedTypes";

const googleDocViewerExtension = ['docx'];

function PreviewModal(props) {
    const {visible, data} = props;
    const [type, toggleType] = useState('');
    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                const isImage = new RegExp(IMAGES_TYPES_PATTERN, 'i').test(data?.extension);
                if (isImage) {
                    toggleType('image');
                } else {
                    toggleType('iframe');
                }
            }, 250);
        } else {
            toggleType('')
        }
    }, [visible]);

    function closeModal() {
        props.closeModal()
    }

    let url = data?.path && generateFileMediaUrl(data?.path, true);
    const isDocViewer = googleDocViewerExtension.includes(data?.extension);
    if (isDocViewer) {
        url = `https://docs.google.com/viewer?url=${url}&embedded=true`;
    }
    return <Modal
        visible={visible}
        wrapClassName={'custom-modal'}
        onCancel={closeModal}>

        <div className={'preview-modal'}>
                <span className={'close-icon'} onClick={closeModal}>
                    <img src={require('../../assets/images/ic_clear.svg')}
                         className="close_icon" alt=""/>
                </span>
            <div className="header">
                <h2>{data?.name}</h2>
            </div>
            <section className={'content-wrapper'}>
                {url && type && <>
                    {type === 'image' && <img className={'preview-img'} src={url} alt=""/>}
                    {type === 'iframe' && <iframe src={url} frameBorder="0" allowFullScreen title={data?.id}/>}
                </>}
            </section>
        </div>
    </Modal>

}


export default PreviewModal;
