// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/partners.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetCompanies, DeleteCompany} from "../../redux/actions";
import swal from "sweetalert";
import {getCompanyListData} from "./utils/actionFunctions";
import {truncNumber} from "../../utils/helperFunctions";
import {BALANCE_TYPES, MEASUREMENT_TYPES} from "../../constants/constTypes";
import DownloadNetworksExcel from "../../components/excel/DownloadNetworksExcel";
import DownloadCompaniesExcel from "../../components/excel/DownloadCompaniesExcel";

class Companies extends Component {
    constructor() {
        super();
        this.state = {
            searchValue: ''
        };
        this.getSearchValue = this.getSearchValue.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.getCompanyListData = getCompanyListData.bind(this);
    }

    componentDidMount() {
        this.props.GetCompanies();
    }

    deleteCompany = id => {
        //console.log('id', id)
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել ընկերությունը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteCompany(id);
            }
        });
    };

    getSearchValue(searchValue) {
        this.setState({
            searchValue
        }, () => !searchValue && this.searchItems())
    }

    searchItems() {
        const {searchValue} = this.state;
        this.props.GetCompanies({
            text: searchValue
        });
    }


    render() {
        const {companies, mainLanguage, requestLoading, fuelList} = this.props;
        const {balancesSum, userBalancesSum} = companies
        const {searchValue} = this.state;
        let itemListHeaderInfo = {
            generalInfo: ["Անվանում", "Պատասխանատու", "Հեռ. Համար", 'Էլ․ հասցե',
                "Աշխ. քանակ", "Մեք. քանակ", "Գր․ ամսաթիվ", "Հաշիվ"],
            status: true,
            minWidth: 2000,
            actions: ["edit", "delete"],
        };
        fuelList && fuelList.forEach(fuel => {
            const mainTr = fuel?.translations?.find(item => item.language === mainLanguage);
            mainTr && itemListHeaderInfo.generalInfo.push(mainTr.title);
        });
        let itemListInfo = this.getCompanyListData(companies?.itemsList, true);

        return <PageWrapper withActions={true}>
            <section className="partners">
                <PageHeader pageTitle={'Ընկերություններ'}
                            linkTitle={"Ավելացնել"}
                            addingLink={"/companies/add"}
                            renderingItemsTitleWithCount={`Ընկերությունների քանակ ${companies?.itemsList?.length || 0}`}
                            getSearchValue={this.getSearchValue}
                            searchItems={this.searchItems}
                            searchValue={searchValue}
                            search={true}
                            renderingCustomFuelsListOrExcel={
                                <DownloadCompaniesExcel mainLanguage={mainLanguage}
                                                        fuelList={fuelList}
                                                        companies={companies}
                                                        GetCompanies={this.props.GetCompanies}/>
                            }
                />
                <div className="sum-result">
                    <div className={'fuels-sum-wrapper'}>
                        {fuelList?.map((item) => {
                            const balance = balancesSum?.find(balance => balance?.fuel === item?.id);
                            const userBalance = userBalancesSum && Array.isArray(userBalancesSum) && userBalancesSum.find(item => item?.fuel === balance?.fuel)
                            const balanceTitle = item?.translations?.find(tr => tr.language === mainLanguage)?.shortName ?? '-'
                            const amountSum = (balance?.amount || 0) + (userBalance?.amount || 0)
                            const amount = truncNumber(amountSum);
                            return <div className="fuel-item" key={item?.id}>
                                <span>{balanceTitle}</span>
                                <span className={'sum'}>
                                    {amount ?? 0} {MEASUREMENT_TYPES[item?.measurementUnit]}
                                </span>
                            </div>
                        })}
                    </div>
                    {<span
                        className={"amount"}>{Number(balancesSum && Array.isArray(balancesSum) && balancesSum.find(item => item.type === BALANCE_TYPES.MONEY)?.amount) || 0} դր․</span>}
                </div>
                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={itemListInfo}
                           loading={requestLoading}
                           detailsLink={"companies/company"}
                           editLink={"/companies/edit"}/>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetCompanies,
    DeleteCompany
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies)
