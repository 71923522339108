import {request, _urlStations, _urlNetworks} from "../api";
import {STATION_CONSTS, UTIL_CONSTS} from "../constants";
import swal from "sweetalert";
import {LIMIT_GET_STATIONS} from "../../constants/constLimitCounts";
import {history} from "../../configs/history";
import {concatDate} from "../../utils/concatTime";


export const GetStations = ({
                                reset = true,
                                offset = 0,
                                text = '',
                                regionId = null,
                                residenceId = null,
                                type = null,
                                networkId = null,
                                limit = LIMIT_GET_STATIONS,
                                getResponse = false
                            } = {}) => {
    let url = `${_urlStations}?offset=${offset}&limit=${limit}`;
    text && (url += `&text=${encodeURIComponent(text)}`);
    regionId && (url += `&regionIds=${regionId}`);
    residenceId && (url += `&residenceIds=${residenceId}`);
    type && (url += `&types=${type}`);
    networkId && (url += `&stationNetworkIds=${networkId}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && !getResponse && dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                data && !getResponse && dispatch({
                    type: STATION_CONSTS.GET_STATIONS,
                    payload: {
                        data: data.stations,
                        count: data.stationsQuantity,
                        text,
                        regionId,
                        residenceId,
                        type,
                        reset,
                        networkId,
                        hasMore: data.stations.length === limit
                    }
                });
                if (getResponse) {
                    return data.stations
                }
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};


export const GetStationById = (id) => {
    const requestData = {
        url: `${_urlStations}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: STATION_CONSTS.GET_STATION_BY_ID,
                    payload: data
                });
                //console.log("station", data);
            })
    }
};

export const AddStation = (data) => {
    const requestData = {
        url: _urlStations,
        token: true,
        method: "POST",
        data: data
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                swal({
                    title: "Հաջողվեց!",
                    text: "Բենզալցակայանը հաջողությամբ ստեղծվեց",
                    icon: "success",
                    button: "Լավ",
                });
                history.push('/dashboard')
            }).catch((error) => {
                if (error && error.response && error.response.status === 409) {
                    swal({
                        title: "Զգուշացում!",
                        text: "Այս էլ․ փոստով Բենզալցակայան արդեն գոյություն ունի",
                        icon: "warning",
                        button: "Լավ",
                    })
                }
            })
    }
};

export const EditStation = (id, data) => {
    const requestData = {
        url: `${_urlStations}/${id}`,
        token: true,
        method: "PATCH",
        data: data
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {

                swal({
                    title: "Հաջողվեց!",
                    text: "Բենզալցակայանը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ",
                });
                history.push('/dashboard')
            })
    }
};

export const DeleteStation = (id) => {
    const requestData = {
        url: `${_urlStations}/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: STATION_CONSTS.DELETE_STATION,
                    payload: id
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Բենզալցակայանը հաջողությամբ ջնջվեց",
                    icon: "success",
                    button: "Լավ",
                });
            })
    }
};


//========================================Networks==================================================


export const GetNetworks = ({type = null} = {}) => {
    let url = _urlNetworks;
    type && (url += `?types=${type}`);
    const requestData = {
        url: url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then(async ({data}) => {
                data && await dispatch({
                    type: STATION_CONSTS.GET_STATION_NETWORKS,
                    payload: data
                });
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};


export const GetNetworksById = (id) => {
    const requestData = {
        url: `${_urlNetworks}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: STATION_CONSTS.GET_STATION_NETWORK_BY_ID,
                    payload: data
                });
                //console.log("networks", data);
            })
    }
};

export const AddNetwork = (data) => {
    const requestData = {
        url: _urlNetworks,
        token: true,
        method: "POST",
        data: data
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: STATION_CONSTS.ADD_STATION_NETWORK,
                    payload: data
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Ցանցը հաջողությամբ ստեղծվեց",
                    icon: "success",
                    button: "Լավ",
                });
                history.push('/networks')
            }).catch((error) => {
                if (error && error.response && error.response.status === 409) {
                    swal({
                        title: "Զգուշացում!",
                        text: "Այս էլ․ փոստով Ցանց արդեն գոյություն ունի",
                        icon: "warning",
                        button: "Լավ",
                    })
                }
            })
    }
};

export const EditNetwork = (id, data) => {
    const requestData = {
        url: `${_urlNetworks}/${id}`,
        token: true,
        method: "PATCH",
        data: data
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: STATION_CONSTS.EDIT_STATION_NETWORK,
                    payload: data,
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Ցանցը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ",
                });
                history.push('/networks')
            })
    }
};

export const DeleteNetwork = (id) => {
    const requestData = {
        url: `${_urlNetworks}/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: STATION_CONSTS.DELETE_STATION_NETWORK,
                    payload: id
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Ցանցը հաջողությամբ ջնջվեց",
                    icon: "success",
                    button: "Լավ",
                });
            })
    }
};

export const GetRemainingFuels = ({
                                      requestStatus = "ACCEPTED",
                                      bidStatus = "COMPLETED",
                                  } = {}) => {
    let url = `${_urlNetworks}/remaining-fuels?requestStatus=${requestStatus}&bidStatus=${bidStatus}`;

    const requestData = {
        url: url,
        token: true,
        method: "GET",
        acceptLanguage: true
    };

    return dispatch => {
        return request(requestData)
            .then(async ({data}) => {
                data && await dispatch({
                    type: STATION_CONSTS.GET_REMAINING_FUELS,
                    payload: data
                });
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};
export const GetRemainingFuelsByNetworkId = (id, {
                                                 requestStatus = "ACCEPTED",
                                                 bidStatus = "COMPLETED",
                                             } = {}) => {
    let url = `${_urlNetworks}/${id}/remaining-fuels?requestStatus=${requestStatus}&bidStatus=${bidStatus}`;

    const requestData = {
        url: url,
        token: true,
        method: "GET",
        acceptLanguage: true
    };

    return dispatch => {
        return request(requestData)
            .then(async ({data}) => {
                console.log("data", data)
                return data
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};


//========================================Networks supervisor==================================================


export const GetNetworkSupervisor = (id) => {

    const requestData = {
        url: `${_urlNetworks}/${id}/station-supervisors`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then(async ({data}) => {
                data && await dispatch({
                    type: STATION_CONSTS.GET_STATION_NETWORK_SUPERVISORS,
                    payload: data
                });
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const AddNetworkSupervisor = (id, data) => {
    const requestData = {
        url: `${_urlNetworks}/${id}/station-supervisors`,
        token: true,
        method: "POST",
        data: data
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: STATION_CONSTS.ADD_STATION_NETWORK_SUPERVISOR,
                    payload: data
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Պատասխանատուն հաջողությամբ ավելացվեց",
                    icon: "success",
                    button: "Լավ",
                });
                history.push(`/networks/edit/${id}`)
            }).catch((error) => {
                if (error && error.response && error.response.status === 409) {
                    swal({
                        title: "Զգուշացում!",
                        text: "Այս էլ․ փոստով վերահսկիչ արդեն գոյություն ունի",
                        icon: "warning",
                        button: "Լավ",
                    })
                }
            })
    }
};

export const DeleteNetworkSupervisor = (networkId, id) => {
    const requestData = {
        url: `${_urlNetworks}/${networkId}/station-supervisors/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: STATION_CONSTS.DELETE_STATION_NETWORK_SUPERVISOR,
                    payload: id
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Պատասխանատուն հաջողությամբ ջնջվեց",
                    icon: "success",
                    button: "Լավ",
                });
            })
    }
};

export const EditNetworkSupervisor = (networkId, id, data) => {
    const requestData = {
        url: `${_urlNetworks}/${networkId}/station-supervisors/${id}`,
        token: true,
        method: "PATCH",
        data: data
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: STATION_CONSTS.EDIT_STATION_NETWORK_SUPERVISOR,
                    payload: data,
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Պատասխանատուն հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ",
                });
                history.push(`/networks/edit/${networkId}`)
            })
    }
};


//========================================Networks transfers==================================================

export const GetTransferRequests = ({
                                        reset = true,
                                        offset = 0,
                                        limit = LIMIT_GET_STATIONS,
                                        getResponse = false,
                                        text = '',
                                        status = null,
                                        type = null,
                                        startDate = null,
                                        endDate = null,
                                        stationNetworkId = null,
                                        transferType = 'sale-fuel',
                                        key = 'saleRequestsList'
                                    } = {}) => {
    let url = `${_urlNetworks}/${transferType}-requests?offset=${offset}&limit=${limit}`

    text && (url += `&text=${text}`);
    status && (url += `&status=${status}`);
    type && (url += `&fuelIds=${type}`);
    stationNetworkId && (url += `&stationNetworkId=${stationNetworkId}`);

    const _startDate = concatDate(startDate, "", "start");
    const _endDate = concatDate(endDate,"", "end");

    _startDate && (url += `&startDate=${_startDate}`);
    _endDate && (url += `&endDate=${_endDate}`);

    const requestData = {
        url,
        token: true,
        method: "GET",
        acceptLanguage: true
    };

    return dispatch => {
        reset && !getResponse && dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then(async ({data}) => {
                data && await dispatch({
                    type: STATION_CONSTS.GET_TRANSFERS_REQUEST,
                    payload: {
                        data: data.requests,
                        operationsSum: data.operationsSum,
                        operationsQuantity: data.operationsQuantity,
                        fuelsSum: data.fuelsSum,
                        hasMore: data?.requests?.length === limit,
                        reset,
                        text,
                        status,
                        stationNetworkId,
                        startDate,
                        endDate,
                        type,
                        key,
                    }
                });
                if (getResponse) {
                    return data
                }
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const UpdateSaleFuel = (id, data) => {
    const requestData = {
        url: `${_urlNetworks}/sale-fuel-requests/${id}`,
        method: "PUT",
        token: true,
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: STATION_CONSTS.UPDATE_SALE_FUEL,
                    payload: {
                        id,
                        status: data.status
                    }
                });
            })
    }
};

export const AddPaidAmount = (data) => {
    const requestData = {
        url: `${_urlNetworks}/paid-amount-requests`,
        token: true,
        method: "POST",
        acceptLanguage: true,
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(async ({data}) => {
                data && await dispatch({
                    type: STATION_CONSTS.ADD_PAID_AMOUNT,
                    payload: data
                });
            })

    }
};
