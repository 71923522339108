export const dateHelper = startingDate => {
    let firstDate = new Date(startingDate);
    let lastDate = new Date();
    let diff = lastDate.getTime() - firstDate.getTime();
    let oneDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs(diff / (oneDay)));
};

export const getActivationColor = activationDate => {
    let lastActivation = new Date(activationDate);
    let currentDate = new Date();
    let diff = currentDate.getTime() - lastActivation.getTime();
    let oneDay = 24 * 60 * 60 * 1000;
    let dayCount = Math.ceil(diff / oneDay);
    switch (dayCount) {
        case 1:
            return '#1aad40';
        case 2:
            return '#ffeb3b';
        default :
            return '#ff0d20'
    }
};
