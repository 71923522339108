// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import '../../assets/styles/containerStyles/service.scss';

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import PageHeader from "../../components/pageContentViews/pageHeader";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import ServiceCard from "../../components/cards/ServiceCard";
import {ServiceData} from "../../constants/fakeData";


class Services extends Component {

    constructor() {
        super();
        this.state = {

        };
    }

    componentDidMount() {
    }

    render() {
        return <PageWrapper>
            <section className="services">
                <PageHeader pageTitle={'Ծառայություններ'}
                            linkTitle={"Ավելացնել"}
                            addingLink={"/services/add"}
                            search={true} />
                <div className="services-list">
                    {ServiceData && !!ServiceData.length && ServiceData.map((item, index) => {
                        return <ServiceCard key={index}
                                            data={item}/>
                    })}
                </div>
            </section>

        </PageWrapper>

    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Services)