import moment from "moment";


export function getReviewDetailsHeader(reviewData, mainLanguage) {
    const {id, createdAt} = reviewData || {};
    return {
        id: id,
        name: reviewData?.author?.firstName + " " + reviewData?.author?.lastName,
        station: reviewData?.station,
        phone: reviewData?.author.username || '-',
        date: createdAt ?  moment(createdAt).format('DD.MM.YYYY / HH:mm') :  moment(new Date()).format('DD.MM.YYYY'),
    };
}
