// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";

// Import utils
import {mapStateToProps} from "../../../redux/mapStateToProps";
import {GetOffers} from "../../../redux/actions";
import ItemsList from "../../../components/ItemsList";
import {OFFERS_TYPE} from "../../../constants/constTypes";

class Repairing extends Component {

    constructor() {
        super();
        this.fetching=false;
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    async loadMoreItems() {
        if (!this.fetching) {
            this.fetching=true;
            const {data} = this.props;
            data.hasMore && await this.props.GetOffers({
                reset: false,
                offset: data.itemsList.length,
                type: OFFERS_TYPE.REPAIRING
            });
            this.fetching=false
        }
    };

    render() {
        const {data, requestLoading} = this.props;
        const itemListHeaderInfo = {
            generalInfo: ["Անվանում", "Մակնիշ", "Մոդել", "VIN Համար", "Տարեթիվ", "Ցանկալի գումար", "Էվակուատոր"],
            largeItems: [0,1,2,3,4,5,6],
            status: true,
        };
        let itemListInfo = [];
        data.itemsList &&  data.itemsList.forEach(item => {
            itemListInfo.push({
                id: item.id,
                status: item.status,
                newItem: !(item?.seen ?? true),
                values: [
                    item?.user?.firstName + " " + item?.user?.lastName,
                    item?.carMake?.name || '-',
                    item?.carModel?.name || '-',
                    item?.vinCode || '-',
                    item?.createdAt ? moment(item.createdAt).format('DD.MM.YYYY') : '',
                    item?.wantedPrice + 'Դրամ' || '-',
                    item?.evacuatorIncluded ?'Պետք է' : 'Պետք չէ',
                ],
            });
        });
        return <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                          itemListInfo={itemListInfo}
                          detailsLink={"offers/repairing"}
                          loading={requestLoading}
                          infiniteScroll={true}
                          hasMore={data.hasMore}
                          loadMoreItems={this.loadMoreItems}
        />

    }
}

const mapDispatchToProps = {GetOffers};

export default connect(mapStateToProps, mapDispatchToProps)(Repairing)
