// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import '../../assets/styles/containerStyles/product/product.scss';

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import ProductCard from "../../components/cards/ProductCard";


// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetProducts} from "../../redux/actions";
import InfiniteScroll from "react-infinite-scroller";


class Products extends Component {
    constructor() {
        super();

        this.state = {
            searchValue: '',
        };
        this.getSearchValue = this.getSearchValue.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        this.props.GetProducts()
    }

    loadMoreItems() {
        const {products} = this.props;
        const {filterValueId, categoryId} = this.state;
        const {text, hasMore} = products;
        products.hasMore && this.props.GetProducts({
            reset: false,
            offset: products.itemsList.length,
            text,
            filterValueId,
            categoryId,
        });
    };

    getSearchValue(searchValue) {
        this.setState({
            searchValue
        }, () => !searchValue && this.searchItems())
    }

    searchItems() {
        const {searchValue} = this.state;
        searchValue?.length !== 1 && this.props.GetProducts({
            text: searchValue
        });
    }

    render() {
        const {searchValue} = this.state;
        let {products} = this.props;
        return <PageWrapper>
            <section className="products">
                <PageHeader pageTitle={'Ապրանքներ'}
                            linkTitle={"Ավելացնել"}
                            addingLink={"/products/add"}
                            getSearchValue={this.getSearchValue}
                            searchItems={this.searchItems}
                            searchValue={searchValue}
                            search={true}/>
                <div className="products-list">
                    <InfiniteScroll
                        hasMore={products.hasMore}
                        loadMore={this.loadMoreItems}
                        className={'products-list'}
                        pageStart={0}
                        useWindow={true}
                        initialLoad={false}>
                        {products && !!products.itemsList.length && products.itemsList.map((item, index) => {
                            return <ProductCard key={index}
                                                data={item}/>
                        })}
                    </InfiniteScroll>
                </div>


            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
