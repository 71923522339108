// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import "../../../assets/styles/containerStyles/tire-details.scss";

// Import components
import PageHeader from "../../../components/pageContentViews/pageHeader";
import PageWrapper from "../../../components/pageContentViews/pageWrapper";

// Import utils
import {mapStateToProps} from "../../../redux/mapStateToProps";
import {SetOffersAsSeen} from "../../../redux/actions";
import moment from "moment";
import {OFFERS_TYPE} from "../../../constants/constTypes";



class SparesDetails extends Component {

    constructor(props) {
        super(props);
        this.repairingData = props.repairingList?.itemsList?.find(repairing => repairing.id === props.id);
    }

    componentDidMount() {
        const {id} = this.props;
        this.repairingData && !this.repairingData.seen && this.props.SetOffersAsSeen(id, OFFERS_TYPE.REPAIRING);
    }
    render() {
        return <PageWrapper>
            <section className="tire-details">
                <PageHeader pageTitle={"Ավտոպահեստամաս"}/>
                <div className="tire-details-wrapper">
                    <div className="left-part">
                        <h1 className="data-title">Հայտի տվյալներ</h1>
                        <table className="data-view">
                            <tbody>
                            {this.repairingData?.createdAt && <tr>
                                <td className="text-bold">Ուղարկման ամսաթիվ։</td>
                                <td className="text-grey">{moment(this.repairingData?.createdAt).format('DD.MM.YYYY')}</td>
                            </tr>}
                            {this.repairingData?.carMake?.name && <tr>
                                <td className="text-bold">Մակնիշ։</td>
                                <td className="text-grey">{this.repairingData?.carMake?.name}</td>
                            </tr>}
                            {this.repairingData?.carModel?.name && <tr>
                                <td className="text-bold">Մոդել։</td>
                                <td className="text-grey">{this.repairingData?.carModel?.name}</td>
                            </tr>}
                            {this.repairingData?.year && <tr>
                                <td className="text-bold">Տարեթիվ։</td>
                                <td className="text-grey">{this.repairingData?.year}</td>
                            </tr>}
                            {this.repairingData?.vinCode && <tr>
                                <td className="text-bold">VIN համար։</td>
                                <td className="text-grey">{this.repairingData?.vinCode}</td>
                            </tr>}
                            {this.repairingData?.description && <tr>
                                <td className="text-bold">Պահանջվող աշխատանք։</td>
                                <td className="text-grey">{this.repairingData?.description}</td>
                            </tr>}
                            {this.repairingData?.wantedPrice && <tr>
                                <td className="text-bold">Ցանկալի գումար:</td>
                                <td className="text-grey">{this.repairingData?.wantedPrice+ 'Դրամ'}</td>
                            </tr>}
                            <tr>
                                <td className="text-bold">Էվակուատոր:</td>
                                <td className="text-grey">{this.repairingData?.evacuatorIncluded ?'Պետք է' : 'Պետք չէ'}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="separator"/>
                        <table className="data-view">
                            <tbody>
                            {this.repairingData?.address &&
                            <tr>
                                <td className="text-bold">Նախընտրելի համայնք։</td>
                                <td className="text-grey">{this.repairingData?.address}</td>
                            </tr>
                            }
                            {this.repairingData?.date &&
                            <tr>
                                <td className="text-bold">Օր։</td>
                                <td className="text-grey">{moment(this.repairingData?.date).format('DD.MM.YYYY')}</td>
                            </tr>
                            }
                            {this.repairingData?.hour &&
                            <tr>
                                <td className="text-bold">Ժամ։</td>
                                <td className="text-grey">{this.repairingData?.hour}</td>
                            </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="right-part">
                        <h1 className="data-title">Հայտատուի տվյալներ</h1>
                        <table className="data-view">
                            <tbody>
                            {this.repairingData?.user?.firstName &&
                            <tr>
                                <td className="text-bold">Անվանում։ </td>
                                <td className="text-grey">{this.repairingData?.user?.firstName + " " + this.repairingData?.user?.lastName}</td>
                            </tr>
                            }
                            {this.repairingData?.user?.username &&
                            <tr>
                                <td className="text-bold">Հեռախոս։</td>
                                <td className="text-grey">{this.repairingData?.user?.username}</td>
                            </tr>
                            }
                            {this.repairingData?.user?.email &&
                            <tr>
                                <td className="text-bold">Էլ․ հասցե։</td>
                                <td className="text-grey">{this.repairingData?.user?.email}</td>
                            </tr>
                            }
                            </tbody>
                        </table>

                    </div>
                </div>
                {/*<div className="offer-buttons">*/}
                {/*    <LinkButton title={"Հաստատել"} className="bg-green"/>*/}
                {/*    <LinkButton title={"Մերժել"} className="bg-red"/>*/}
                {/*</div>*/}
            </section>

        </PageWrapper>;

    }
}

const mapDispatchToProps = {
    SetOffersAsSeen
};

export default connect(mapStateToProps, mapDispatchToProps)(SparesDetails)
