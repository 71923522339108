// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import DetailsHeader from "../../components/cards/DetailsHeader";

import PageWrapper from "../../components/pageContentViews/pageWrapper";
import {getReviewDetailsHeader} from "./utils/actionFunctions";

class ReviewDetails extends Component {
    constructor() {
        super();
        this.state = {
            userData: null
        };
    }

    componentDidMount() {
        const {reviews, id} = this.props;
        const reviewData = reviews?.itemsList?.find(review => review.id === id);

        reviewData && this.setState({
            reviewData
        })
    }

    render() {
        const {reviewData} = this.state;
        const {mainLanguage} = this.props;
        const reviewDataHeader = getReviewDetailsHeader(reviewData, mainLanguage);
        return <PageWrapper withActions={true}>
            <section className="user-details">
                <DetailsHeader data={reviewDataHeader}
                               headerDate
                               mainLanguage={mainLanguage}/>

                <div className="separator"/>
                <h1 className="history-title">Մեկնաբանություն</h1>
                <p>{reviewData?.text}</p>

            </section>
        </PageWrapper>

    }
}

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewDetails)
