export const headerWidth = {wch: 23};
export const headerFuelItemWidth = {wch: 12};
export const headerStyle = {
    font: {sz: "13",},
    alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: false
    },
};

export const oddRowStyle = {
    fill: {patternType: "solid", fgColor: {rgb: "f2f7fb"}},
    border: {
        left: {style: 'thin', color: {rgb: "CCCCCC"}},
        right: {style: 'thin', color: {rgb: "CCCCCC"}},
        top: {style: 'thin', color: {rgb: "CCCCCC"}},
        bottom: {style: 'thin', color: {rgb: "CCCCCC"}},
    }
};

