import React from "react";
import {history} from "../../configs/history";

//import assets
import "../../assets/styles/media/mediaCard.scss";

//import utils
import {LOCKED_MEDIA_FOLDERS} from "../../constants/acceptedTypes";
import {MaterialIcon} from "../utils/Icon";

export class MediaFolderCard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.locked = LOCKED_MEDIA_FOLDERS.includes(props?.item?.id);
    }

    render() {
        let {item, withEdit, folderList, draggable} = this.props;
        draggable = !this.locked && draggable;
        return <div className={`media-card folder`}
                    draggable={draggable}
                    onDragOver={(e) => draggable && e.preventDefault()}
                    onDragEnd={() => draggable && this.props.dragFile(null)}
                    onDragStart={() => draggable && this.props.dragFile(item)}
                    onDrop={() => draggable && this.props.onDrop(item)}
                    key={item.id}>

            <div className={'image-wrapper'} onDoubleClick={() => this.props.openFolder(item)}>
                {!this.locked && <div className="action-buttons">
                    {withEdit &&
                    <button onClick={() => history.push({
                        pathname: `media/edit-file/${item.id}`,
                        state: {
                            data: item,
                            folderList
                        }
                    })}>
                        <img src={require("../../assets/images/ic_edit.svg")}
                             alt="edit"/>
                    </button>}

                    <button onClick={() => this.props.deleteMedia(item.id)}>
                        <img src={require("../../assets/images/icon-trash-colored.svg")}
                             alt="delete"/>
                    </button>
                </div>}
                {this.locked && <div className={'folder-locked'}>
                    <MaterialIcon icon={'lock'}/>
                </div>}
                <div className={'card-image'}>
                    <img className={'image'} src={require('../../assets/images/file-icons/folder.png')} alt="file"/>
                </div>
            </div>
            <span className={'img-name'}>{item.name}</span>
        </div>
    }
}


