// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/networks.scss';

// Import components

import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetNetworks, DeleteNetwork, GetRemainingFuels} from "../../redux/actions";
import swal from "sweetalert";
import {NETWORK_STATION_TYPES, STATION_TYPES} from "../../constants/constTypes";
import {Select} from "antd";
import {InputGroup} from "../../components/uiElements/inputGroup";
import DownloadStationsExcel from "../../components/excel/DownloadStationsExcel";
import DownloadNetworksExcel from "../../components/excel/DownloadNetworksExcel";

class Networks extends Component {

    constructor() {
        super();
        this.state = {
            type: undefined,
        };
        this.stationTypeOptions = Object.keys(NETWORK_STATION_TYPES).map(key => ({
            id: key,
            name: NETWORK_STATION_TYPES[key]
        }));
        this.changeFilter = this.changeFilter.bind(this);
    }

    componentDidMount() {
        this.props.GetNetworks();
        this.props.GetRemainingFuels();
    }

    deleteNetwork = id => {
        //console.log('id', id)
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել ցանցը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteNetwork(id);
            }
        });
    };

    changeFilter({name, value}) {
        const {type} = this.state;
        // console.log(name, value)
        const newFilter = {
            type,
            [name]: value,
        }
        this.props.GetNetworks(newFilter).then(() => {
            this.setState({
                ...newFilter,
            });
        });
    }


    render() {
        const {networks, mainLanguage, requestLoading, remainingFuels} = this.props;
        const {type} = this.state;

        let itemListHeaderInfo = {
            generalInfo: ["Անվանում", "Տեսակ", 'Պատասխանատու', 'Հեռախոս'],
            largeItems: [3, 4],
            status: true,
            actions: ["edit", "delete"],
        };


        let itemListInfo = networks?.map((network) => {
            const mainTranslations = network?.translations?.find(item => item.language === mainLanguage);
            const {title = '-', supervisor = '-',} = mainTranslations || {};
            //console.log(network.id);
            return {
                id: network.id,
                values: [title, NETWORK_STATION_TYPES[network?.type] ?? '-', supervisor, network.phoneNumber ?? '-'],
                status: !network.isHidden,
                deleteCb: this.deleteNetwork.bind(this, network.id)
            }
        });
        const remainingFuelsList = [];
        this.props?.fuelList && this.props.fuelList.forEach(fuel => {
            const soldAmount = remainingFuels.soldFuels &&
                remainingFuels.soldFuels.find(item => item.fuelId === fuel.id)?.sumAmounts || 0;
            const refuelAmount = remainingFuels.refueledFuels &&
                remainingFuels.refueledFuels.find(item => item.fuelId === fuel.id)?.sumAmounts || 0;
            const resultSum = soldAmount - refuelAmount;
            remainingFuelsList.push({
                id: fuel.id,
                sumAmounts: resultSum
            })
        });
        return <PageWrapper withActions={true}>
            <section className="networks">
                <PageHeader pageTitle={'Ցանցեր'}
                            linkTitle={"Ավելացնել"}
                            addingLink={"/networks/add"}
                            renderingItemsTitleWithCount={`Ցանցերի քանակ ${networks?.length ?? 0}`}
                            rightTopProps={
                                <InputGroup inputType="selectCustom"
                                            placeholder="Տեսակ"
                                            name="type"
                                            showSearch={false}
                                            value={type}
                                            onChange={this.changeFilter}
                                            options={this.stationTypeOptions}>
                                    {type && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                                </InputGroup>
                            }
                            renderingCustomFuelsListOrExcel={
                                <>
                                    <div className={'fuels-sum-wrapper'}>
                                        {this.props?.fuelList && this.props?.fuelList.map((fuel, index) => {
                                            let item = remainingFuelsList.find(item => item.id === fuel.id);
                                            return <div className={'fuel-item'} key={index}>
                                                <span>{fuel?.translations?.find(tr => tr.language === mainLanguage)?.title} </span>
                                                <span className={'sum'}>
                                            {item?.sumAmounts ? item?.sumAmounts : 0}
                                    </span>
                                            </div>
                                        })}
                                    </div>
                                    <DownloadNetworksExcel mainLanguage={mainLanguage}
                                                           networks={networks}
                                                           GetNetworks={this.props.GetNetworks}/></>
                            }
                />
                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={itemListInfo}
                           loading={requestLoading}
                           detailsLink={"networks/edit"}
                           editLink={"/networks/edit"}/>
            </section>


        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetNetworks,
    DeleteNetwork,
    GetRemainingFuels
};

export default connect(mapStateToProps, mapDispatchToProps)(Networks)
