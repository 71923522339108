// Import packages
import React, {Component} from "react";

//import components
import ReactExport from "react-data-export";
import {LinkButton} from "../buttons/buttons";

//import utils
import {BALANCE_TYPES} from "../../constants/constTypes";
import moment from "moment";

//import excel styles
import {headerWidth,headerFuelItemWidth, headerStyle, oddRowStyle} from "../../constants/excelConstStyles";
import {truncNumber} from "../../utils/helperFunctions";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


class DownloadUsersExcel extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            loading: false,
        }
        this.excelButton = React.createRef();
    }

    downloadExcel = async () => {
        const {usersList} = this.props;
        await this.setState({loading: true})
        try {
            const exportData = await this.props.GetUsers({
                limit: usersList?.count,
                getResponse: true,
            });
            await this.setState({data: exportData})
            this.excelButton.current.click();
        } catch {
        }
        this.setState({loading: false, data: []})
    }

    render() {
        const {mainLanguage, usersList, fuelList} = this.props;
        const {data, loading} = this.state;


        const emptyRow = [{value: ''}, {value: ''}, {value: ''}]
        const downloadedData = !!data?.length && [{
            columns: [
                {title: "Անուն", width: headerWidth, style: headerStyle},
                {title: "Հեռախոս", width: headerWidth, style: headerStyle},
                {title: "Գրանցման ամսաթիվ", width: headerWidth, style: headerStyle},
                {title: "Հաշիվ", width: headerFuelItemWidth, style: headerStyle},
            ],
            data: [emptyRow, ...data?.map((user, index) => {
                const {username, firstName, lastName, isBlocked,createdAt, balances} = user;
                const name = `${firstName || ''} ${lastName || ''}`
                const money = balances?.find(b => b.type === BALANCE_TYPES.MONEY)?.amount ?? 0;
                const bgStyle = index % 2 === 0 ? oddRowStyle : {};
                const cellStyle = {font: {sz: "11"}, ...bgStyle};

                const cellData= [
                    {value: name ?? '-', style: cellStyle},
                    {value: username ?? '-', style: cellStyle},
                    {
                        value: createdAt ? moment(createdAt).format("DD.MM.YY / HH:mm") : "-",
                        style: cellStyle
                    },
                    {value: truncNumber(money) ?? 0, style: cellStyle},
                ];
                fuelList && fuelList.forEach(fuel => {
                    const currBalance = balances && balances.find(balance => balance?.fuel?.id === fuel.id);
                    cellData.push({value:truncNumber(currBalance?.amount) || 0, style: cellStyle});
                });

                return cellData;
            })],
        }];
        if (Array.isArray(downloadedData)) {
            fuelList && fuelList.forEach(fuel => {
                const mainTr = fuel?.translations?.find(item => item.language === mainLanguage);
                downloadedData[0].columns.push({
                    title: mainTr?.title || '-',
                    width: headerFuelItemWidth,
                    style: headerStyle
                });
                emptyRow.push({value: ''});
            });
        }

        return <div>
            {!!data?.length && <ExcelFile element={<span ref={this.excelButton} style={{visibility: 'hidden'}}/>}
                                          filename={'Petrol Users ' + moment().format('DD-MM-YYYY')}>
                <ExcelSheet dataSet={downloadedData}
                            name={'Users'}/>
            </ExcelFile>}
            <LinkButton title={'Excel'}
                        cb={this.downloadExcel}
                        loadingWithTitle
                        disabled={!usersList?.itemsList?.length}
                        loading={loading}/>
        </div>
    }
}

export default DownloadUsersExcel
