// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/dataDisplay/notification-dropdown.scss';

// Import components
import InfiniteScroll from 'react-infinite-scroller';
import moment from "moment";

// Import utils
import {
    TRANSFERS_NOTIFICATION_TYPES,
    UPDATED_FUELS_TYPES
} from "../../constants/constTypes";
import {SetAllNotificationsAsOpened} from "../../socket/emitters";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetNotifications, UpdateSaleFuel} from "../../redux/actions";
import {DeleteAllNotifications, DeleteNotification} from "../../socket/emitters";
import {LinkButton} from "../buttons/buttons";

class NotificationDropdown extends Component {
    constructor() {
        super();
        this.state = {};

        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        SetAllNotificationsAsOpened();
    }

    loadMoreItems() {
        const {notificationsList, notificationHasMore} = this.props;

        notificationHasMore && this.props.GetNotifications({
            reset: false,
            offset: notificationsList.length,
        });
    };

    render() {
        let {notificationsList, notificationHasMore} = this.props;

        return <div className="notification-dropdown" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
        }}>
            <div className="notifications-header">Ծանուցումներ</div>
            {!!notificationsList?.length ? <InfiniteScroll
                    hasMore={notificationHasMore}
                    loadMore={this.loadMoreItems}
                    className={'notifications-content'}
                    pageStart={0}
                    useWindow={true}
                    initialLoad={false}>
                    {
                        notificationsList.map(item => {
                            return <div className="notification-item" key={item.id}>
                            <span className={'close-icon'} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                DeleteNotification(item.id)
                            }}>
                                <img src={require('../../assets/images/ic_clear.svg')}
                                     className="close_icon" alt=""/>
                            </span>
                                <div className="notification-title">{item.title}</div>
                                <pre className="notification-description">{item.description}</pre>
                                {item.type === TRANSFERS_NOTIFICATION_TYPES.FUEL_SALE_TO_ADMIN.type &&
                                <div className="action-wrapper">{
                                    item?.saleFuelRequest?.status === 'ACTIVE' ? <>
                                        <LinkButton title={UPDATED_FUELS_TYPES.ACCEPTED}
                                                    cb={() => this.props.UpdateSaleFuel(item?.saleFuelRequest?.id, {
                                                        status: "ACCEPTED",
                                                        stationNetworkId: item.stationNetwork
                                                    })}
                                        />
                                        <LinkButton title={UPDATED_FUELS_TYPES.REJECTED}
                                                    cb={() => this.props.UpdateSaleFuel(item?.saleFuelRequest?.id, {
                                                        status: "REJECTED",
                                                        stationNetworkId: item.stationNetwork
                                                    })}
                                        />
                                    </> : TRANSFERS_NOTIFICATION_TYPES.FUEL_SALE_TO_ADMIN.message[item?.saleFuelRequest?.status]
                                }
                                </div>}
                                {item?.createdAt &&
                                <div
                                    className="notification-date">{moment(item?.createdAt).format("DD.MM.YY / HH:mm")}</div>}
                            </div>
                        })
                    }
                </InfiniteScroll>
                : <span className="empty-notification">Ծանուցումներ չկան</span>}

            <div className="notifications-footer">
                <button className="clear-all"
                        disabled={!notificationsList?.length}
                        onClick={this.props.DeleteAllNotifications}
                >Ջնջել բոլորը
                </button>
            </div>
        </div>
    }
}

const mapDispatchToProps = {
    GetNotifications,
    UpdateSaleFuel,
    DeleteAllNotifications,
    DeleteNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDropdown)
