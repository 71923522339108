// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from 'moment';

// Import Styles
import '../../assets/styles/containerStyles/reviews.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetReviews, GetNotSeenReviewsCount, SetReviewAsSeen} from "../../redux/actions";


class Reviews extends Component {
    constructor() {
        super();
        this.state = {
            searchValue: ''
        };
        this.getSearchValue = this.getSearchValue.bind(this);
        this.searchItems = this.searchItems.bind(this);
    }

    componentDidMount() {
        this.props.GetReviews();
        this.props.GetNotSeenReviewsCount();
    }

    getSearchValue(searchValue) {
        this.setState({
            searchValue
        }, () => !searchValue && this.searchItems())

    }

    searchItems() {
        const {searchValue} = this.state;
        this.props.GetReviews({
            text: searchValue
        });
    }

    setReviewAsSeen(id) {
        const {reviews} = this.props;
       // console.log('set-review as seen', id);
        const review = reviews.itemsList.find(review => review.id === id);
        review && (review.seen === false) && this.props.SetReviewAsSeen(id);
    }

    render() {
        const {searchValue} = this.state;
        const {reviews, mainLanguage, requestLoading} = this.props;

        const count = reviews.itemsList.length;
        let itemListHeaderInfo = {
            generalInfo: ["Անուն", "Հեռախոս", "Լցակայան", "Հասցե", "Ամսաթիվ", "Մեկնաբանություն"],
            largeItems: [0, 5],
        };

        let itemListInfo = reviews?.itemsList && Array.isArray(reviews?.itemsList) && reviews.itemsList.map((review) => {
            const mainTranslations = review?.station?.translations?.find(item => item.language === mainLanguage);
            const name = mainTranslations?.title || '';
            const stationTranslations = review?.station?.translations?.find(item => item.language === mainLanguage);
            const {addressCity, addressRegion, address} = stationTranslations || {};
            const date = moment(review?.createdAt).format("HH:MM:DD / HH:mm");
            return {
                id: review.id,
                newItem: !(review?.seen ?? true),
                onClick: this.setReviewAsSeen.bind(this, review.id),
                values: [
                    review?.author?.firstName + " " + review?.author?.lastName,
                    review?.author?.username,
                    name,
                    `${addressCity ?? ''} ${addressRegion ?? ''} ${address ?? ''}`,
                    date,
                    review?.text
                ],
            }
        });


        return <PageWrapper withActions={true}>
            <section className="reviews">

                <PageHeader pageTitle={'Մեկնաբանություններ'}
                            renderingItemsTitleWithCount={`Մեկնաբանությունների քանակ ${count}`}
                            search={true}
                            getSearchValue={this.getSearchValue}
                            searchItems={this.searchItems}
                            searchValue={searchValue}/>
                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={itemListInfo}
                           loading={requestLoading}
                           detailsLink={"reviews/review"}/>
            </section>

        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetReviews,
    SetReviewAsSeen,
    GetNotSeenReviewsCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reviews)
