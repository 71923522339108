import isEmail from "validator/lib/isEmail";
import moment from "moment";

export function validateFields(errors, validationData) {

    const {activeLanguages} = this.props;
    this.errorsSet.clear();
    this.errorsTabs.clear();
    const phoneRegExp = /^[+]?[0-9]{9,20}$/;
    errors.requiredFields && errors.requiredFields.forEach(key => {

        if (!validationData[key] || validationData[key] === "") {
            this.errorsSet.add(key);
            return false;
        }
        if ((key === 'username' || key === 'password' || key === 'taxCode') && validationData?.[key]?.length < 8) {
            this.errorsSet.add(key);
            return false;
        }
        if (key === 'repeatPassword' && validationData.password !== validationData.repeatPassword) {
            this.errorsSet.add(key);
            return false;
        }
        if ((key === 'username' || key === 'email') && !isEmail(validationData?.[key])) {
            this.errorsSet.add(key);
            return false;
        }
        if (validationData?.bankAccount?.length && validationData?.bankAccount?.length < 16) {
            this.errorsSet.add('bankAccount');
        }
        if (validationData?.phoneNumber && !phoneRegExp.test(validationData?.phoneNumber)) {
            this.errorsSet.add('phoneNumber');
        }
        if (validationData?.stations?.length === 0) {
            this.errorsSet.add('stations');
            return false;
        }
        return true;
    });


    activeLanguages && activeLanguages.forEach(lg => {
        const trData = validationData?.translations?.[lg.id] || {};
        let tabHasError = false;
        errors.translations && errors.translations.forEach(key => {
            if (!trData || !trData[key] || trData[key] === "") {
                // console.groupCollapsed(` %c Translation`, "color:red");
                // console.log( trData);
                // console.log( key);
                // console.log( lg);
                // console.groupEnd();
                this.errorsSet.add(key + '_' + lg.id);
                tabHasError = true;
            }
        });
        tabHasError && this.errorsTabs.add(lg.id);
    });
    return !this.errorsSet.size;
}


export function parseDetailsData(companyById, mainLanguage) {
    const mainTr = companyById?.translations?.find(tr => tr.language === mainLanguage);
    const {addressHeadAddress, addressHeadRegion, addressHeadCity} = mainTr || {};

    return {
        id: companyById?.id,
        name: mainTr?.name,
        address: `${addressHeadAddress ? addressHeadAddress + ', ' : ''}${addressHeadRegion ? addressHeadRegion + ', ' : ''}${addressHeadCity ? addressHeadCity + ', ' : ''}` || '-',
        phone: companyById?.phoneNumber1 ?? '-',
        date: companyById?.lastUpdatedAt ? moment(companyById?.lastUpdatedAt).format('DD.MM.YYYY') : '-',
        isHidden: !companyById?.isHidden,
    };
}

