// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetStaticTexts, UpdateStaticTexts} from "../../redux/actions/staticTextActions";
import {StaticTextFields} from "../../constants/staticTextFields";

// Import styles
import "../../assets/styles/containerStyles/staticTexts.scss";

// Import components
import {StaticTextRow} from "../../components/uiElements/StaticTextRow";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {Editor as DescriptionEditor} from "../../components/uiElements/Editor";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {Icon, Tabs} from "antd";

const {TabPane} = Tabs;

const areaStaticTexts = [];

const languages = [
    {
        "id": "hy",
        "code": "hy",
        "name": "Հայ",
        "isMain": true,
    },
    {
        "id": "en",
        "code": "en",
        "name": "Eng",
        "isMain": false,
    },
    {
        "id": "ru",
        "code": "ru",
        "name": "Ру",
        "isMain": false,
    }
];

class StaticTexts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            translations: {},
            editingField: null,
            editingFieldIndex: null,
            temporaryInfo: "",
            selectedPage: undefined,
            mounted: false,
            languageTab: props.mainLanguage,
            languageCode: props?.languages?.find(lg => lg.id === props?.mainLanguage)?.code,
        };
        this.errorsTabs = new Set();

        this.changeLanguageTab = this.changeLanguageTab.bind(this);
        this.getTempValue = this.getTempValue.bind(this);
        this.saveStaticTexts = this.saveStaticTexts.bind(this);
        this.cancelEditing = this.cancelEditing.bind(this);
        this.startEditing = this.startEditing.bind(this);
        this.getSelectedPage = this.getSelectedPage.bind(this);
        this.checkError = this.checkError.bind(this);
        this.getEditorValue = this.getEditorValue.bind(this);

    }

    async componentDidMount() {
        await this.props.GetStaticTexts();
        const {languageCode} = this.state;
        this.setState({
            fields: this.props.staticTextsList && this.props.staticTextsList[languageCode || 'en'],
            mounted: true
        })

    }

    changeLanguageTab(activeTab) {
        const languageCode = languages?.find(lg => lg.id === activeTab)?.code;
        this.setState({
            languageTab: activeTab,
            fields: this.props.staticTextsList && this.props.staticTextsList[languageCode],
            languageCode
        })
    }

    startEditing(name) {
        this.setState({
            editingField: name,
            temporaryInfo: this.state.fields?.[name]
        })
    }

    getTempValue({value}) {
        this.setState({
            temporaryInfo: value
        })
    }
    getEditorValue({value, name}) {
        this.setState({
            editingField: name,
            temporaryInfo: value
        })
    }

    getSelectedPage({value}) {
        this.setState({
            selectedPage: value ?? undefined
        });
    }

    saveStaticTexts() {
        const {fields, editingField, temporaryInfo, languageCode} = this.state;
        const updatingData = {
            value: temporaryInfo
        };

        languageCode && this.props.UpdateStaticTexts(updatingData, languageCode, editingField).then(() => {
            this.setState({
                fields: {
                    ...fields,
                    [editingField]: temporaryInfo
                },
                editingField: null,
                temporaryInfo: ""
            })
        });

    }

    cancelEditing() {
        this.setState({
            editingField: null,
            temporaryInfo: ""
        })
    }

    checkError(language) {
        let hasError = false;
        this.errorsTabs && this.errorsTabs.forEach((tab) => {
            tab.includes(language.id) && (hasError = true)
        });
        return hasError;
    }

    render() {
        const {fields, editingField, temporaryInfo, languageTab, selectedPage, mounted} = this.state;
        // console.log(selectedPage, 'selectedPage');
        let staticList = [];
        if (selectedPage){
            staticList=StaticTextFields[selectedPage]?.list
        }else{
            Object.keys(StaticTextFields).forEach(page => {
                staticList = staticList?.concat(StaticTextFields[page]?.list);
            })
        }
        const selectOptions = Object.keys(StaticTextFields).map(page => ({
            id: page,
            name: StaticTextFields[page]?.pageName
        }));
        return <PageWrapper pageTitle={'Static texts'}>
            <section className="main-content admin-static">
                <div className="translation-select-wrapper">
                    <InputGroup
                        inputType={"selectCustom"}
                        value={selectedPage}
                        placeholder={'Sections...'}
                        onChange={this.getSelectedPage}
                        withClear={'Բոլորը'}
                        options={selectOptions}
                    />
                </div>
                <div className="translation-tabs">

                    {/*<LanguageTabs changeLanguageTab={this.changeLanguageTab}*/}
                    {/*              activeTab={languageTab}*/}
                    {/*/>*/}

                    <Tabs className="language-tabs"
                          type="card"
                          activeKey={languageTab}
                          onChange={this.changeLanguageTab}>
                        {languages?.map((language) => {
                            this.checkError(language);
                            return <TabPane tab={<span
                                className={this.checkError(language) ? 'haveError' : ''}>{language.name}</span>}
                                            key={language.id}/>
                        })}
                    </Tabs>

                    {selectedPage !== 'sliderDescription' ? staticList.map((row, index) => {
                            const inputType = areaStaticTexts.includes(row.key) ? 'textarea' : 'input';
                            return (row.key !== 'privacy-policy-text' && row.key !== 'slider_1_item_description')
                                && <StaticTextRow key={index}
                                                  inputType={inputType}
                                                  editingField={editingField}
                                                  value={(fields && fields[row.key]) || ""}
                                                  name={row.key}
                                                  label={row.label}
                                                  tempValue={temporaryInfo}
                                                  getTempValue={this.getTempValue}
                                                  startEditing={this.startEditing}
                                                  saveStaticTexts={this.saveStaticTexts}
                                                  cancelEditing={this.cancelEditing}/>
                        })
                        :  <div className="editor-wrapper">
                            {mounted && <DescriptionEditor
                                value={fields && fields['slider_1_item_description']}
                                name={'slider_1_item_description'}
                                tempValue={temporaryInfo}
                                onChange={this.getEditorValue}
                                startEditing={this.startEditing}
                                saveStaticTexts={this.saveStaticTexts}
                                cancelEditing={this.cancelEditing}
                            />}
                                <button className="saving-btn"
                                        onClick={this.saveStaticTexts}>
                                    <Icon type="save"/>
                                </button>

                        </div>
                    }



                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetStaticTexts,
    UpdateStaticTexts,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaticTexts);
