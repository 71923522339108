import {HISTORY_TYPES} from "../../../constants/constTypes";

export const stationsHeaderSaleInfo = {
    generalInfo: [
        "Ամսաթիվ",
        "Ցանց",
        "Տեսակ",
        "Քանակ",
        "Գումար",
        'Կարգավիճակ',
    ],
    largeItems: [],
    status: false,
    actions: ["update"]
};

export const stationsHeaderTransferInfo = {
    generalInfo: [
        "Ամսաթիվ",
        "Ցանց",
        "Գումար",
        'Կարգավիճակ',
    ],
    largeItems: [],
};
export const tabs = [
    {
        name: "Վաճառքներ",
        key: "saleRequestsList",
        transferType: 'sale-fuel',
        type: HISTORY_TYPES.GET_SALE_FUEL_REQUEST,
    },
    {
        name: "Փոխանցումներ",
        key: "paidAmountList",
        transferType: 'paid-amount',
        type: HISTORY_TYPES.GET_PAID_AMOUNT_REQUEST,
    },
];
