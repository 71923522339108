import {request, _urlProducts} from "../api";
import {PRODUCT_CONSTS, UTIL_CONSTS} from "../constants";
import swal from "sweetalert";
import {LIMIT_GET_PRODUCTS} from "../../constants/constLimitCounts";
import {history} from "../../configs/history";

export const GetProducts = ({
                                reset = true,
                                offset = 0,
                                text = '',
                                filterValueId = null,
                                categoryId = null,
                                limit = LIMIT_GET_PRODUCTS,
                                getResponse = false
                            } = {}) => {
    let url = `${_urlProducts}?offset=${offset}&limit=${limit}`;
    text && (url += `&text=${encodeURIComponent(text)}`);
    categoryId && (url += `&categoryIds=${categoryId}`);
    filterValueId && (url += `&filterValueIds=${filterValueId}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && !getResponse && dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                data && !getResponse && dispatch({
                    type: PRODUCT_CONSTS.GET_PRODUCTS,
                    payload: {
                        data: data,
                        text,
                        filterValueId,
                        categoryId,
                        reset,
                        hasMore: data.length === limit
                    }
                });
                if (getResponse) {
                    return data
                }
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const GetProductById = (id) => {
    const requestData = {
        url: `${_urlProducts}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: PRODUCT_CONSTS.GET_PRODUCT_BY_ID,
                    payload: res.data
                })
            })
            .catch(() => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ որ բան այն չէ, Խնդրում ենք  փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const AddProduct = (data) => {
    const requestData = {
        url: _urlProducts,
        token: true,
        method: "POST",
        customHeaders: {
            "Content-Type": "multipart/form-data",
        },
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                swal({
                    title: "Հաջողվեց!",
                    text: "Ապրանքը հաջողությամբ ստեղծվեց",
                    icon: "success",
                    button: "Լավ",
                });
                history.push('/products')
            }).catch((error) => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ-որ բան այն չէ",
                    icon: "warning",
                    button: "Լավ",
                })
            })
    }
};

export const UpdateProduct = (id, data) => {
    const requestData = {
        url: `${_urlProducts}/${id}`,
        method: "PATCH",
        token: true,
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                swal({
                    title: "Հաջողվեց!",
                    text: "Ապրանքը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ",
                });
                history.push('/products')
            }).catch((error) => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ-որ բան այն չէ",
                    icon: "warning",
                    button: "Լավ",
                })
            })
    }
};

export const ToggleProductVisibility = (id, data) => {
    const requestData = {
        url: `${_urlProducts}/${id}`,
        method: "PATCH",
        token: true,
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PRODUCT_CONSTS.TOGGLE_PRODUCT_VISIBILITY,
                    payload: {
                        id,
                        isHidden: data.isHidden
                    }
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Ապրանքը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ",
                });
            }).catch((error) => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ-որ բան այն չէ",
                    icon: "warning",
                    button: "Լավ",
                })
            })
    }
};

export const DeleteProduct = (id) => {
    const requestData = {
        url: `${_urlProducts}/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: PRODUCT_CONSTS.DELETE_PRODUCT,
                    payload: id
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Ապրանքը հաջողությամբ ջնջվեց",
                    icon: "success",
                    button: "Լավ",
                });
            })
    }
};
