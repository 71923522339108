import React from "react";
import "../../assets/styles/dataDisplay/nestableItem.scss";
import {Link} from "react-router-dom";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {MaterialIcon} from "../utils/Icon";


export const NestableItem = ({item}) => {
    const {withImage, largeItem, path, description, title, link, deleteCb} = item;

    return <div className={`nestable-list-item ${largeItem ? ' largeItem ' : ''}`}>
        <div className="nestable-item-inner">
            <div className="moving-icon">
                <img src={require("../../assets/images/nestable_icon.svg")} alt="menu"/>
            </div>
            {withImage &&
            <div className="item-image">
                <img src={generateImageMediaUrl(path)} alt="item"/>
            </div>}
            {title && description ? <div className="info-wrapper-flex">
                <div className="item-title">
                    <span>{title}</span>
                </div>
                <div className="item-description">
                    <span>{description}</span>
                </div>
            </div> : <>
                {title &&
                <div className="item-title">
                    <span>{title}</span>
                </div>}
                {description &&
                <div className="item-description">
                    <span>{description}</span>
                </div>}

            </>}
            <div className="action-buttons">
                {link && <div className="action-button edit">
                    <Link to={link}>
                        <MaterialIcon icon="edit"/>
                    </Link>
                </div>}
                <div className="action-button remove" onClick={deleteCb}>
                    <MaterialIcon icon="delete"/>
                </div>
            </div>

        </div>

    </div>
};
