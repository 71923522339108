// Import packages
import React, {Component} from "react";

//import components
import ReactExport from "react-data-export";
import {LinkButton} from "../buttons/buttons";

//import utils
import {HISTORY_TYPES, STATION_HISTORY_TYPES, STATION_NETWORK_SALES_TYPES} from "../../constants/constTypes";
import moment from "moment";

//import excel styles
import {headerStyle, headerWidth, oddRowStyle} from "../../constants/excelConstStyles";
import {truncNumber} from "../../utils/helperFunctions";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


class DownloadHistoryExcel extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            loading: false,
        }
        this.excelButton = React.createRef();
    }

    downloadExcel = async () => {
        const {historyList, activeTab, limit} = this.props;
        const {text, stationId, startDate, endDate, types, stationNetworkId, status, fuelId} = historyList;
        // console.log("historyList", historyList)
        await this.setState({loading: true});
        try {
            const exportData = await this.props.GetHistory({
                limit,
                key: activeTab,
                text,
                status,
                stationId,
                stationNetworkId,
                fuelId,
                startDate,
                endDate,
                type: types,
            });
            await this.setState({data: exportData.histories});
            this.excelButton.current.click();

        } catch (err) {
            console.log('ERROE', err);
        }
        this.setState({loading: false, data: []});
    }

    render() {
        const {historyList, activeTab, mainLanguage} = this.props;
        const {data, loading} = this.state;
        let columns = []
        switch (activeTab) {
            case "charging":
                columns = [
                    {title: "Լցակայան", width: headerWidth, style: headerStyle},
                    {title: "Համար", width: headerWidth, style: headerStyle},
                    {title: "Օգտատեր", width: headerWidth, style: headerStyle},
                    {title: "Հեռ․", width: headerWidth, style: headerStyle},
                    {title: "Պետ․ գրանցված", width: headerWidth, style: headerStyle},
                    {title: "Պետ․ լրացված", width: headerWidth, style: headerStyle},
                    {title: "Տեսակ", width: headerWidth, style: headerStyle},
                    {title: "Քանակ", width: headerWidth, style: headerStyle},
                    {title: "Գումար", width: headerWidth, style: headerStyle},
                    {title: "Կարգավիճակ", width: headerWidth, style: headerStyle},
                    {title: "Ամսաթիվ", width: headerWidth, style: headerStyle},
                ]
                break;
            case "transfers":
                columns = [
                    {title: "Փոխանցող", width: headerWidth, style: headerStyle},
                    {title: "Համար", width: headerWidth, style: headerStyle},
                    {title: "Ստացող", width: headerWidth, style: headerStyle},
                    {title: "Հեռ համար", width: headerWidth, style: headerStyle},
                    {title: "Տեսակ", width: headerWidth, style: headerStyle},
                    {title: "Քանակ", width: headerWidth, style: headerStyle},
                    {title: "Ամսաթիվ", width: headerWidth, style: headerStyle},
                ]
                break;
            case "purchasedFuel":
                columns = [
                    {title: "Ստացող", width: headerWidth, style: headerStyle},
                    {title: "Հեռ համար", width: headerWidth, style: headerStyle},
                    {title: "Համար", width: headerWidth, style: headerStyle},
                    {title: "Միջոց", width: headerWidth, style: headerStyle},
                    {title: "Տեսակ", width: headerWidth, style: headerStyle},
                    {title: "Բալանս", width: headerWidth, style: headerStyle},
                    {title: "Գումար", width: headerWidth, style: headerStyle},
                    {title: "Քանակ", width: headerWidth, style: headerStyle},
                    {title: "Ամսաթիվ", width: headerWidth, style: headerStyle},
                ]
                break;
            case "accountReplenishment":
                columns = [
                    {title: "Ստացող", width: headerWidth, style: headerStyle},
                    {title: "Հեռ համար", width: headerWidth, style: headerStyle},
                    {title: "Համար", width: headerWidth, style: headerStyle},
                    {title: "Միջոց", width: headerWidth, style: headerStyle},
                    {title: "Բալանս", width: headerWidth, style: headerStyle},
                    {title: "Գումար", width: headerWidth, style: headerStyle},
                    {title: "Ամսաթիվ", width: headerWidth, style: headerStyle},
                ]
        }
        const emptyRow = []
        columns.map(() => {
            emptyRow.push({value: ''})
        })
        const downloadedData = !!data?.length && [{
            columns: columns,
            data: [emptyRow, ...data?.map((history, index) => {
                const {station, transactionNumber, sender, amount, sum, fuel, date, bid, company, receiver, subCompany, type} = history || {};
                const bgStyle = index % 2 === 0 ? oddRowStyle : {};
                const cellStyle = {font: {sz: "11"}, ...bgStyle};

                switch (activeTab) {
                    case "charging":
                        return [
                            {
                                value: station?.translations?.find(item => item?.language === mainLanguage)?.title || '-',
                                style: cellStyle
                            },
                            {value: transactionNumber || '-', style: cellStyle},
                            {
                                value: sender && sender.firstName && `${sender?.firstName} ${sender?.lastName}` || '-',
                                style: cellStyle
                            },
                            {value: sender && sender.username && sender.username || '-', style: cellStyle},
                            {value: bid?.registeredCarNumber || '-', style: cellStyle},
                            {value: bid?.filledCarNumber || '-', style: cellStyle},
                            {value: fuel?.translations?.find(lg => lg.language === mainLanguage)?.title || '-', style: cellStyle},
                            {value: truncNumber(amount) || '-', style: cellStyle},
                            {value: sum || '-', style: cellStyle},
                            {value: bid?.status && STATION_HISTORY_TYPES[bid?.status] || '-', style: cellStyle},
                            {value: moment(date).format("DD.MM.YY / HH:mm"), style: cellStyle},
                        ]
                    case "transfers":
                        let _sender = '-';
                        if (sender) {
                            _sender = sender?.firstName ? `${sender?.firstName} ${sender?.lastName}` : '-'
                        } else if (subCompany) {
                            _sender = subCompany?.translations?.find(lg => lg.language === mainLanguage)?.name || '-'

                        } else if (company) {
                            _sender = company?.translations?.find(lg => lg.language === mainLanguage)?.name || '-'
                        }
                        return [
                            {
                                value: _sender || '-',
                                style: cellStyle
                            },
                            {value: transactionNumber || '-', style: cellStyle},
                            {
                                value: receiver?.firstName && `${receiver?.firstName} ${receiver?.lastName}` || '-',
                                style: cellStyle
                            },
                            {value: receiver?.username || company?.phoneNumber1 || company?.phoneNumber2 || '-', style: cellStyle},
                            {value: fuel?.translations?.find(lg => lg.language === mainLanguage)?.title || '-', style: cellStyle},
                            {value: truncNumber(amount) || '-', style: cellStyle},
                            {value: moment(date).format("DD.MM.YY / HH:mm"), style: cellStyle},
                        ]
                    case "purchasedFuel":
                        const getter = receiver?.firstName ? `${receiver?.firstName} ${receiver?.lastName || ''}`
                            : (company?.translations?.find(lg => lg.language === mainLanguage)?.name || '-');
                        const phoneNumber = receiver ? receiver?.username : (company?.phoneNumber1 || company?.phoneNumber2);
                        const section = receiver ? 'Օգտատեր' : 'Ընկերություն';
                        const method = type === HISTORY_TYPES.GET_FUEL_VIA_CARD ? 'Կցված քարտ' : (type === HISTORY_TYPES.ADMIN_TO_COMPANY || type === HISTORY_TYPES.ADMIN_TO_USER ? 'Ադմին' : 'Petrol.am հաշիվ');

                        return [
                            {
                                value: getter || '-',
                                style: cellStyle
                            },
                            {value: phoneNumber || '-', style: cellStyle},
                            {value: transactionNumber || '-', style: cellStyle},
                            {value: method || '-', style: cellStyle},
                            {value: fuel?.translations?.find(lg => lg.language === mainLanguage)?.title || '-', style: cellStyle},
                            {value: section || '-', style: cellStyle},
                            {value: sum || "-", style: cellStyle},
                            {value: truncNumber(amount) || '-', style: cellStyle},
                            {value: moment(date).format("DD.MM.YY / HH:mm"), style: cellStyle},
                        ]
                    case "accountReplenishment":
                        const getterAccountReplenishment = receiver?.firstName ? `${receiver?.firstName} ${receiver?.lastName || ''}`
                            : (company?.translations?.find(lg => lg.language === mainLanguage)?.name || '-');
                        const phoneNumberAccountReplenishment = receiver ? receiver?.username : (company?.phoneNumber1 || company?.phoneNumber2);
                        const sectionAccountReplenishment = receiver ? 'Օգտատեր' : 'Ընկերություն';
                        return [
                            {
                                value: getterAccountReplenishment || '-',
                                style: cellStyle
                            },
                            {value: phoneNumberAccountReplenishment || '-', style: cellStyle},
                            {value: transactionNumber || '-', style: cellStyle},
                            {value: type === HISTORY_TYPES.FILLED_ACCOUNT_FROM_CARD ? 'Կցված քարտ' : 'Սուպեր Ադմին', style: cellStyle},
                            {value: sectionAccountReplenishment || '-', style: cellStyle},
                            {value: truncNumber(amount) || '-', style: cellStyle},
                            {value: moment(date).format("DD.MM.YY / HH:mm"), style: cellStyle},
                        ]
                }
            })],
        }];

        return <div>
            {!!data?.length && <ExcelFile element={<span ref={this.excelButton} style={{visibility: 'hidden'}}/>}
                                          filename={'History_' + moment().format('DD-MM-YYYY')}>
                <ExcelSheet dataSet={downloadedData}
                            name={'History'}/>
            </ExcelFile>}
            <LinkButton title={'Excel'}
                        cb={this.downloadExcel}
                        loadingWithTitle
                        disabled={!historyList?.itemsList?.length}
                        loading={loading}/>
        </div>
    }
}

export default DownloadHistoryExcel
