// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../assets/styles/containerStyles/dashboard.scss';

// Import components
import PageWrapper from '../components/pageContentViews/pageWrapper';
import PageHeader from "../components/pageContentViews/pageHeader";
import FuelCard from "../components/cards/FuelCard";
import Stations from "./stations/Stations";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {GetStations} from "../redux/actions";
import {ACCESS_PAGES} from "../constants/accessPages";

class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            searchValue: ''
        };
        this.getSearchValue = this.getSearchValue.bind(this);
        this.searchItems = this.searchItems.bind(this);
    }

    getSearchValue(searchValue) {
        this.setState({
            searchValue
        }, () => !searchValue && this.searchItems())
    }

    searchItems() {
        const {searchValue} = this.state;
        const {stations} = this.props;
        const {networkId, regionId, residenceId, type} = stations;
        this.props.GetStations({
            networkId,
            regionId,
            residenceId,
            type,
            text: searchValue
        });
    }


    render() {
        let {fuelList, stations, mainLanguage, accessPages, isAdmin, location, fuelsLastUpdatedDate} = this.props;
        const {searchValue} = this.state;
        const reGetStationData = location?.state?.reGetData;

        const haveStationAccess = isAdmin || accessPages?.includes(ACCESS_PAGES.station?.key);
        const haveFuelsAccess = isAdmin || accessPages?.includes(ACCESS_PAGES.fuels?.key);
        return <PageWrapper withActions={true}>
            <section className="dashboard">
                <PageHeader pageTitle={'Գնացուցակ'}
                            updateTime={fuelsLastUpdatedDate}
                            linkTitle={haveStationAccess && "Ավելացնել"}
                            addingLink={haveStationAccess && "/station/add"}
                            renderingItemsTitleWithCount={haveStationAccess && `Լցակայանների քանակ ${stations.count}`}
                            getSearchValue={this.getSearchValue}
                            searchItems={this.searchItems}
                            searchValue={searchValue}
                            search={haveStationAccess}
                            stationFilters>
                    <div className={"fuel-list-section"}>
                        {fuelList && !!fuelList.length && fuelList.map((item, index) => {
                            return <FuelCard key={index}
                                             mainLanguage={mainLanguage}
                                             data={item}/>
                        })}
                        {haveFuelsAccess && fuelList && !!fuelList.length &&
                        <FuelCard isEdited={true}/>}
                    </div>
                </PageHeader>
                {haveStationAccess && <Stations reGetData={reGetStationData}/>}
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetStations
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
