// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../assets/styles/containerStyles/fuels-edit.scss';

// Import components
import PageHeader from "../components/pageContentViews/pageHeader";
import PageWrapper from "../components/pageContentViews/pageWrapper";
import {LanguageTabs} from "../components/uiElements/Tabs";
import TitleRow from "../components/cards/TitleRow";
import FuelRow from "../components/cards/FuelRow";


// Import utils
import {mapStateToProps} from "../redux/mapStateToProps.js";
import {UpdateFuel} from "../redux/actions";


class FuelsEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            languageTab: props.mainLanguage,
        };
        this.errorsTabs = new Set();
        this.changeLanguageTab = this.changeLanguageTab.bind(this);
        this.handleTabError = this.handleTabError.bind(this);
    }

    changeLanguageTab(activeTab) {
        const {languages} = this.props;
        const languageCode = languages?.find(lg => lg.id === activeTab)?.code;
        this.setState({
            languageTab: activeTab,
            languageCode
        })
    }

    handleTabError() {
        this.forceUpdate();
    }

    render() {
        const {languageTab} = this.state;
        let {fuelList, activeLanguages} = this.props;
        let titleRowContent = {
            generalInfo: ["Նկար", "Անվանում", "Կարճ անուն", "Շուկայական գին", "Վաճառքի գին", "կազմ. համար", "Կարգավիճակ"],
            actions: ["save"]
        };

        return <PageWrapper>
            <section className="fuels-edit-card">
                <PageHeader pageTitle={' Փոփոխել'}
                            updateTime={new Date()}/>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={this.errorsTabs}
                              activeTab={languageTab}
                />
                <table>
                    <TitleRow titleRowContent={titleRowContent}/>
                    {fuelList && !!fuelList.length && fuelList.map((item, index) => {
                        return <FuelRow key={index}
                                        data={item}
                                        errorsTabs={this.errorsTabs}
                                        handleTabError={this.handleTabError}
                                        languageTab={languageTab}
                                        activeLanguages={activeLanguages}
                                        UpdateFuel={this.props.UpdateFuel}/>
                    })}
                </table>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    UpdateFuel
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelsEdit);
