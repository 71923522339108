// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/company-details.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {CustomTabs} from "../../components/uiElements/Tabs";
import BalanceEditingCard from "../../components/cards/BalanceEditingCard";
import BalanceCard from "../../components/cards/BalanceCard";
import DetailsHeader from "../../components/cards/DetailsHeader";
import ItemsList from "../../components/ItemsList";
import {LinkButton} from "../../components/buttons/buttons";
import {MaterialIcon} from "../../components/utils/Icon";
import swal from "sweetalert";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetCompanyById,
    EditCompanyBalance,
    ClearSingleCompanyData,
    ClearByIdHistory,
    GetHistoryByCompanyId,
    DeleteCompany,
    GetCompanyUsers,
    ClearCompanyUsers,

} from "../../redux/actions";
import {tabs} from "./utils/constants";
import {companiesHistoryHeaderInfo} from "../history/utils/constants";
import {parseCompanyHistoryData} from "../history/utils/actionFunctionsForDetails";
import {parseDetailsData, getCompanyListData} from "./utils/actionFunctions";
import {changeStateField, truncNumber} from "../../utils/helperFunctions";

import {BALANCE_TYPES} from "../../constants/constTypes";


class CompanyDetails extends Component {
    constructor() {
        super();
        this.state = {
            activeTab: tabs[0].key,
        };
        this.changeTab = changeStateField.bind(this, "activeTab");
        this.getCompanyListData = getCompanyListData.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.editBalance = this.editBalance.bind(this);
        this.deleteCompany = this.deleteCompany.bind(this);
    }

    async componentDidMount() {
        const id = this.props?.id;
        await this.props.GetCompanyById(id);
        this.props.GetHistoryByCompanyId({id,});
        this.props.GetCompanyUsers(id);
    }

    componentWillUnmount() {
        this.props.ClearSingleCompanyData();
        this.props.ClearByIdHistory();
        this.props.ClearCompanyUsers();
    }

    deleteCompany = id => {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել ընկերությունը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteCompany(id);
            }
        });
    };

    getInputValues(e) {
        const {name, value, checked} = e.target;
        const isSwitch = name === "isEnabled";
        this.setState({
            ...this.state,
            [name]: isSwitch ? !checked : value
        });
    }

    loadMoreItems() {
        const {historyById, id} = this.props;
        historyById?.hasMore && this.props.GetHistoryByCompanyId({
            id,
            reset: false,
            offset: historyById?.itemsList?.length,
            startDate: historyById?.startDate,
            endDate: historyById?.endDate
        });
    };

    editBalance(companyId, fuelId, amount) {
        const reqData={
            amount,
        }
        fuelId && (reqData.fuelId=fuelId);
        return this.props.EditCompanyBalance(companyId, reqData).then(() => {
            this.props.GetHistoryByCompanyId({id: companyId});
        });
    }

    render() {
        const {companyById, fuelList, mainLanguage, historyById, companyUsers} = this.props;
        const {activeTab} = this.state;

        const workersHeaderInfo = {
            generalInfo: ["Անուն", "Հեռախոս", "Հաշիվ",],
            largeItems: [0, 1],
            status: true,
            actions: ["details"],
        };
        const subCompanyHeaderInfo = {
            generalInfo: ["Անվանում", "Պատասխանատու", "Հեռ. Համար", 'Էլ․ հասցե',
                "Աշխ. քանակ", "Մեք. քանակ", "Գր․ ամսաթիվ",],
            largeItems: [],
            status: true,
            actions: ["edit", "delete"],
        };

        fuelList && fuelList.forEach(fuel => {
            const mainTr = fuel?.translations?.find(item => item.language === mainLanguage);
            if (mainTr) {
                workersHeaderInfo.generalInfo.push(mainTr.title);
                //subCompanyHeaderInfo.generalInfo.push(mainTr.title);
            }
        });

        let workersInfo = companyUsers?.map((user) => {
            const {username, balances, fullName, isBlocked} = user;
            const money = balances?.find(b => b.type === BALANCE_TYPES.MONEY)?.amount ?? 0;
            //console.log(company.id);

            const userData = {
                id: user.id,
                values: [
                    fullName ?? '-',
                    username ?? '-',
                    truncNumber(money) ?? '-',
                ],
                status: !isBlocked,
            };
            fuelList && fuelList.forEach(fuel => {
                const currBalance = balances && balances.find(balance => balance?.fuel?.id === fuel.id);
                userData.values.push(truncNumber(currBalance?.amount) ?? 0);
            });
            return userData;
        });
        const historyInfo = parseCompanyHistoryData(historyById, mainLanguage);
        let companyInfo = this.getCompanyListData(companyById?.subCompanies);

        const itemListHeaderInfo = activeTab === "workers"
            ? workersHeaderInfo
            : activeTab === 'subCompany'
                ? subCompanyHeaderInfo : companiesHistoryHeaderInfo;
        const itemListInfo = activeTab === "workers" ? workersInfo :
            activeTab === 'subCompany' ? companyInfo : historyInfo;

        return <PageWrapper withActions={true}>
            <section className="partner-details">
                <DetailsHeader data={parseDetailsData(companyById, mainLanguage)}
                               withEditAction={<LinkButton
                                   link={`/companies/edit/${companyById?.id}`}
                                   title={<MaterialIcon icon="edit"/>}
                                   className="bg-orange"/>}
                />
                <div className="separator"/>
                <div className="balance-part">
                    {fuelList && <BalanceEditingCard fuelList={fuelList}
                                                     id={companyById?.id}
                                                     data={companyById?.balances}
                                                     mainLanguage={mainLanguage}
                                                     inCompanyDetails={true}
                                                     getData={this.props.GetCompanyById}
                                                     editBalance={this.editBalance}/>}
                    <BalanceCard data={companyById?.balances}
                                 fuelList={fuelList}
                                 withMoney={true}
                                 mainLanguage={mainLanguage}/>

                </div>
                <div className="separator"/>
                {activeTab === "workers" &&
                <div className="workers-count">Աշխատակիցների քանակ։ {companyUsers?.length ?? 0}</div>}
                <CustomTabs changeTab={this.changeTab} activeTab={activeTab} tabsList={tabs}/>
                {/*
                {activeTab === "workers" &&
                <LinkButton
                    link={`/companies/edit/${companyById?.id}`}
                    title={"Ավելացնել"}
                    className="add-company-worker"/>
                }*/}
                <ItemsList
                    id={activeTab}
                    className={activeTab === "workers"
                        ? "workers-list" : (activeTab === "history"
                            ? "history-list" : (activeTab === "subCompany"
                                ? "subCompany-list" : ""))}
                    itemListHeaderInfo={itemListHeaderInfo}
                    itemListInfo={itemListInfo}
                    detailsLink={activeTab === "workers" && "users/user"}
                    editLink={activeTab === "subCompany" && "/companies/edit"}
                    infiniteScroll={true}
                    hasMore={historyById.hasMore}
                    loadMoreItems={this.loadMoreItems}/>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetCompanyById,
    EditCompanyBalance,
    ClearSingleCompanyData,
    ClearByIdHistory,
    GetHistoryByCompanyId,
    DeleteCompany,
    GetCompanyUsers,
    ClearCompanyUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails)
