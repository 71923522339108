// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetOrders, SetOrderAsSeen, GetNotSeenOrdersCount} from "../../redux/actions";

// Import Styles
import '../../assets/styles/containerStyles/orders.scss';

// Import components
import {InputGroup} from "../../components/uiElements/inputGroup";
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";
import {StatusList} from "../../constants/constTypes";


class Orders extends Component {
    constructor() {
        super();
        this.state = {
            orderStatus: 'ALL',
        };
        this.getInputValues = this.getInputValues.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        this.props.GetOrders();
        this.props.GetNotSeenOrdersCount();
    }

    async getInputValues({name, value}) {
        await this.setState({
            [name]: value,
        });
        this.props.GetOrders({status: this.state.orderStatus === 'ALL' ? null : this.state.orderStatus})
    }

    async loadMoreItems() {
        if (!this.fetching) {
            this.fetching=true;
            const {ordersList} = this.props;
            ordersList.hasMore && await this.props.GetOrders({
                reset: false,
                offset: ordersList.itemsList.length,
            });
            this.fetching=false
        }
    };

    setOrderAsSeen(id) {
        const {ordersList} = this.props;
        const order = ordersList.itemsList.find(order => order.id === id);
        order && (order.seen === false) && this.props.SetOrderAsSeen(id);
    }

    render() {
        const {ordersList, requestLoading} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անվանում", "Հեռախոս", "Գին", "Ամսաթիվ"],
            status: true,
            largeItems: [0, 1, 2, 3],
            // actions: ["delete"],
        };
        let itemListInfo = [];
        ordersList.itemsList.forEach(item => {
            itemListInfo.push({
                id: item.id,
                status: item.status,
                newItem: !(item?.seen ?? true),
                onClick: this.setOrderAsSeen.bind(this, item.id),
                values: [
                    item?.user?.firstName + " " + item?.user?.lastName,
                    item?.user?.username,
                    item.sum + " Դրամ",
                    moment(item.createdAt).format('DD.MM.YYYY'),
                ],
            });
        });

        const statusList = StatusList;
        const select = <div className="right-side">
            <InputGroup
                inputType="selectCustom"
                value={this.state.orderStatus}
                options={statusList}
                placeholder="Ընտրել"
                name="orderStatus"
                style={{width: 200}}
                onChange={this.getInputValues}
            />
        </div>

        return <PageWrapper withActions={true}>
            <section className="orders">
                <PageHeader pageTitle={'Պատվերներ'}
                            rightTopStatusProp={select}
                            search={false}/>
                <ItemsList itemListInfo={itemListInfo}
                           itemListHeaderInfo={itemListHeaderInfo}
                           detailsLink={"orders/order"}
                           editLink={"orders/order"}
                           loading={requestLoading}
                           infiniteScroll={true}
                           hasMore={ordersList.hasMore}
                           loadMoreItems={this.loadMoreItems}
                />
            </section>

        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetOrders,
    SetOrderAsSeen,
    GetNotSeenOrdersCount
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
