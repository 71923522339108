import React, {Component} from "react";
//import styles
import '../../assets/styles/components/filteringRow.scss';

//import components
import {DatePicker, Select} from 'antd';
import {InputGroup} from "./inputGroup";
import {
    GetHistory, GetStations, GetCompanies
} from "../../redux/actions";
import {connect} from "react-redux";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {SPENT_TYPES, STATION_HISTORY_TYPES, TRANSFER_TYPES} from "../../constants/constTypes";
import {tabs} from "../../containers/history/utils/constants";
import {parseAccountReplenishmentHistoryData} from "../../containers/history/utils/actionFunctions";
import {disabledEndDate, disabledStartDate} from "../../utils/helperFunctions";
import types from "less/lib/less/functions/types";
import moment from "moment";

const dateFormat = 'DD.MM.YYYY';

class FilteringRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: moment(),
            endDate: undefined,
            stationId: undefined,
            companyId: undefined,
            fuelId: undefined,
            status: undefined,
            purchasedFuels: undefined,
            purchasedBalance: undefined,
            purchasedMethod: undefined,
            replenishmentMethod: undefined,
            replenishmentBalance: undefined,
            balanceType: undefined,
        };
        this.fuelOptions = this.props?.fuelList.filter(item => !item.isHidden).map(fuel => ({
            id: fuel.id,
            name: fuel.translations.find(lg => lg.language === this.props?.mainLanguage).title
        }));

        this.statusOptions = Object.keys(STATION_HISTORY_TYPES).map(key => ({
            id: key,
            name: STATION_HISTORY_TYPES[key]
        }));

        this.transferTypesOptions = Object.keys(TRANSFER_TYPES).map(key => ({
            id: key,
            name: TRANSFER_TYPES[key]
        }))
        this.spentTypesOptions = Object.keys(SPENT_TYPES).map(key => ({
            id: key,
            name: SPENT_TYPES[key]
        }))
        this.changeFilter = this.changeFilter.bind(this);
    }

    async componentDidMount() {
        !this.props.stations.itemsList?.length && await this.props.GetStations()
        !this.props.companies?.itemsList?.length && await this.props.GetCompanies()
        this.stationsOptions = this.props.stations.itemsList?.map(station => ({
            id: station.id,
            name: station?.translations?.find(tr => tr?.language === this.props.mainLanguage)?.title
        }))
        this.companiesOptions = this.props.companies.itemsList.length && this.props.companies.itemsList?.map(company => ({
            id: company.id,
            name: company?.translations?.find(tr => tr?.language === this.props.mainLanguage)?.name
        }))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {historyType} = this.props;
        if (prevProps.historyType !== this.props.historyType) {
            let filterData = {
                key: historyType,
                startDate: moment(),
                endDate: undefined,
                stationId: undefined,
                companyId: undefined,
                fuelId: undefined,
                status: undefined,
                purchasedFuels: undefined,
                purchasedBalance: undefined,
                purchasedMethod: undefined,
                replenishmentBalance: undefined,
                replenishmentMethod: undefined,
                balanceType: undefined,
                type: undefined,
            };

            this.props.GetHistory(filterData).then(() => {
                this.setState({
                    ...filterData
                });
            });
        }
    }

    handleChangeData(name, value) {
        const {startDate, endDate, stationId, companyId, status, fuelId, type} = this.state;
        const {historyType} = this.props;
        let filterData = {
            key: historyType,
            stationId,
            companyId,
            fuelId,
            status,
            startDate,
            endDate,
            [name]: value,
            type

        };
        this.props.searchItems(filterData)
        this.setState({
            ...filterData
        })
    }

    mergeTypes(arr1, arr2) {
        const result = [];
        if (!arr1.length) {
            return arr2
        }
        arr1.forEach(x => {
            if (!arr2.length || arr2.includes(x)) {
                result.push(x)
            }
        })
        return result
    }

    changeFilter({name, value}) {

        const {historyType, searchValue} = this.props;
        const {stationId, companyId, status, fuelId, startDate, endDate, type, balanceType} = this.state;

        let filterData = {
            key: historyType,
            stationId,
            companyId,
            fuelId,
            status,
            startDate,
            endDate,
            type,
            [name]: value,
            text: searchValue,
        };


        if (name === 'balanceType') {
            filterData['type'] = value
        }

        if (name === "purchasedMethod" || name === "purchasedBalance") {
            const _purchasedChanged = value ? value.split(",") : []
            const _purchasedConstName = name === "purchasedMethod" ? 'purchasedBalance' : 'purchasedMethod';
            const _purchasedConst = this.state[_purchasedConstName] ? this.state[_purchasedConstName].split(",") : []
            const mergedTypes = this.mergeTypes(_purchasedChanged, _purchasedConst)
            filterData['type'] = mergedTypes.join(",")
        }
        if (name === "replenishmentMethod" || name === "replenishmentBalance") {
            const _replenishmentChanged = value ? value.split(",") : []
            const _replenishmentConstName = name === "replenishmentMethod" ? 'replenishmentBalance' : 'replenishmentMethod';
            const _replenishmentConst = this.state[_replenishmentConstName] ? this.state[_replenishmentConstName].split(",") : []
            const mergedTypes = this.mergeTypes(_replenishmentChanged, _replenishmentConst)
            filterData['type'] = mergedTypes.join(",")
        }

        this.props.GetHistory(filterData).then(() => {
            this.setState({
                ...filterData
            });
        });
    }

    render() {
        const {stationId, companyId, fuelId, status, type, balanceType, purchasedBalance, purchasedMethod, replenishmentMethod, replenishmentBalance, startDate, endDate} = this.state;
        const {historyType} = this.props;
        // Tab 3
        const purchasedMethodOptions = [
            {
                id: "COMPANY_GET_FUEL_VIA_ACCOUNT,GET_FUEL_VIA_ACCOUNT",
                name: "Petrol.am հաշիվ"
            },
            {
                id: "GET_FUEL_VIA_CARD",
                name: "Քարտ",
                disabled: purchasedBalance === 'COMPANY_GET_FUEL_VIA_ACCOUNT'
            }

        ]
        const purchasedBalanceOptions = [
            {
                id: "COMPANY_GET_FUEL_VIA_ACCOUNT",
                name: "Կազմակերպություն",
                disabled: purchasedMethod === 'GET_FUEL_VIA_CARD'
            },
            {
                id: "GET_FUEL_VIA_ACCOUNT,GET_FUEL_VIA_CARD",
                name: "Ֆիզիկական անձ",
            }

        ]
        // Tab 4
        this.replenishmentBalanceOptions = [
            {
                id: "ADMIN_TO_COMPANY",
                name: "Կազմակերպություն",
                disabled: replenishmentMethod === "FILLED_ACCOUNT_FROM_CARD"
            },
            {
                id: "FILLED_ACCOUNT_FROM_CARD",
                name: "Ֆիզիկական անձ",
                disabled: replenishmentMethod === "ADMIN_TO_COMPANY"
            }

        ]
        this.replenishmentMethodOptions = [
            {
                id: "ADMIN_TO_COMPANY",
                name: "Սուպեր Ադմին",
                disabled: replenishmentBalance === "FILLED_ACCOUNT_FROM_CARD"
            },
            {
                id: "FILLED_ACCOUNT_FROM_CARD",
                name: "Քարտ",
                disabled: replenishmentBalance === "ADMIN_TO_COMPANY"
            }

        ]

        return <div className="filtering-row">
            <div className="form-group-items">
                <InputGroup inputType={"wrapper"}>
                    <DatePicker value={startDate}
                                format={dateFormat}
                                showToday={false}
                                allowClear={true}
                                disabledDate={(date) => disabledStartDate(date, endDate)}
                                placeholder="Սկիզբ"
                                className={`date-picker`}
                                onChange={(value) =>
                                    this.handleChangeData('startDate', value)}>
                    </DatePicker>
                </InputGroup>
                <InputGroup inputType={"wrapper"}>
                    <DatePicker value={endDate}
                                format={dateFormat}
                                showToday={false}
                                allowClear={true}
                                disabledDate={(date) => disabledEndDate(date, startDate)}
                                placeholder="Ավարտ"
                                className={`date-picker`}
                                onChange={(value) =>
                                    this.handleChangeData('endDate', value)}>
                    </DatePicker>
                </InputGroup>
            </div>
            {historyType === tabs[0].key &&
            <div className="form-group-items">
                <InputGroup inputType="selectCustom"
                            placeholder="Ընտրել լցակայան"
                            name="stationId"
                            showSearch={false}
                            value={stationId}
                            onChange={this.changeFilter}
                            options={this.stationsOptions}>
                    {stationId && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                </InputGroup>
                <InputGroup inputType="selectCustom"
                            placeholder="Տեսակ"
                            name="fuelId"
                            showSearch={false}
                            value={fuelId}
                            onChange={this.changeFilter}
                            options={this.fuelOptions}>
                    {fuelId && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                </InputGroup>
                <InputGroup inputType="selectCustom"
                            placeholder="Կարգավիճակ"
                            name="status"
                            showSearch={false}
                            value={status}
                            onChange={this.changeFilter}
                            options={this.statusOptions}>
                    {status && <Select.Option value={undefined}>{'Բոլորը'}</Select.Option>}
                </InputGroup>
                <InputGroup inputType="selectCustom"
                            placeholder="Բալանս"
                            name="balanceType"
                            showSearch={false}
                            value={balanceType}
                            onChange={this.changeFilter}
                            options={this.spentTypesOptions}>
                    {balanceType && <Select.Option value={undefined}>{'Բոլորը'}</Select.Option>}
                </InputGroup>
            </div>
            }
            {historyType === tabs[1].key &&
            <div className="form-group-items">
                <InputGroup inputType="selectCustom"
                            placeholder="Փոխանցող ընկերություն"
                            name="companyId"
                            showSearch={false}
                            value={companyId}
                            onChange={this.changeFilter}
                            options={this.companiesOptions}>
                    {companyId && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                </InputGroup>
                <InputGroup inputType="selectCustom"
                            placeholder="Տեսակ"
                            name="fuelId"
                            showSearch={false}
                            value={fuelId}
                            onChange={this.changeFilter}
                            options={this.fuelOptions}>
                    {fuelId && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                </InputGroup>
                <InputGroup inputType="selectCustom"
                            placeholder="Փոխանցման տեսակ"
                            name="balanceType"
                            showSearch={false}
                            value={balanceType}
                            onChange={this.changeFilter}
                            options={this.transferTypesOptions}>
                    {balanceType && <Select.Option value={undefined}>{'Բոլորը'}</Select.Option>}
                </InputGroup>
            </div>
            }
            {historyType === tabs[2].key &&
            <div className="form-group-items">
                <InputGroup inputType="selectCustom"
                            placeholder="Միջոց"
                            name="purchasedMethod"
                            showSearch={false}
                            value={purchasedMethod}
                            onChange={this.changeFilter}
                            options={purchasedMethodOptions}>
                    {purchasedMethod && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                </InputGroup>
                <InputGroup inputType="selectCustom"
                            placeholder="Տեսակ"
                            name="fuelId"
                            showSearch={false}
                            value={fuelId}
                            onChange={this.changeFilter}
                            options={this.fuelOptions}>
                    {fuelId && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                </InputGroup>
                <InputGroup inputType="selectCustom"
                            placeholder="Բալանս"
                            name="purchasedBalance"
                            showSearch={false}
                            value={purchasedBalance}
                            onChange={this.changeFilter}
                            options={purchasedBalanceOptions}>
                    {purchasedBalance && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                </InputGroup>
            </div>
            }
            {historyType === tabs[3].key &&
            <div className="form-group-items">
                <InputGroup inputType="selectCustom"
                            placeholder="Միջոց"
                            name="replenishmentMethod"
                            showSearch={false}
                            value={replenishmentMethod}
                            onChange={this.changeFilter}
                            options={this.replenishmentMethodOptions}>
                    {replenishmentMethod && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                </InputGroup>
                <InputGroup inputType="selectCustom"
                            placeholder="Բալանս"
                            name="replenishmentBalance"
                            showSearch={false}
                            value={replenishmentBalance}
                            onChange={this.changeFilter}
                            options={this.replenishmentBalanceOptions}>
                    {replenishmentBalance && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                </InputGroup>
            </div>
            }
        </div>
    }
}


const mapDispatchToProps = {
    GetHistory,
    GetStations,
    GetCompanies
};

export default connect(mapStateToProps, mapDispatchToProps)(FilteringRow)
