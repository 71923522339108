//--------------------------------------- Official-server --------------------------------------------
const AUTH_URL = "https://api.petrol.am/auth/api/v1";
const ADMIN_URL =  "https://api.petrol.am/admin/api/v1";
const USER_URL =  "https://api.petrol.am/users/api/v1";
const HISTORY_URL =  "https://api.petrol.am/history/api/v1";
const FILE_MANAGER_URL =  "https://api.petrol.am/files-manager/api/v1";
const NOTIFICATION_URL =  "https://notifications-api.petrol.am/api/v1";

const SOCKET_NOTIFICATION_URL = "https://notifications-api.petrol.am";

export const HOST_MEDIA_URL =  "https://api.petrol.am/files-manager";

//--------------------------------------- 4Steps-server --------------------------------------------


// const AUTH_URL = "http://173.249.20.192:2010/api/v1";
// const ADMIN_URL =  "http://173.249.20.192:2020/api/v1";
// const USER_URL =  "http://173.249.20.192:2011/api/v1";
// const HISTORY_URL =  "http://173.249.20.192:2014/api/v1";
// const FILE_MANAGER_URL =  "http://173.249.20.192:2013/api/v1";
// const NOTIFICATION_URL =  "http://173.249.20.192:2015/api/v1";
//
// const SOCKET_NOTIFICATION_URL = "http://173.249.20.192:2015";
//
// export const HOST_MEDIA_URL =  "http://173.249.20.192:2013";


export const _urlOauth = AUTH_URL + "/oauth2";
export const _urlMedia = FILE_MANAGER_URL + "/files";
export const _urlMediaImages = FILE_MANAGER_URL;
export const _urlLanguages = ADMIN_URL + "/languages";
export const _urlFuel = ADMIN_URL + "/fuels";
export const _urlStations = ADMIN_URL + "/stations";
export const _urlNetworks = ADMIN_URL + "/station-networks";
export const _urlCompanies = ADMIN_URL + "/companies";
export const _urlFeatures = ADMIN_URL + "/features";
export const _urlReviews = ADMIN_URL + "/stations/reviews";
export const _urlUsers = ADMIN_URL + "/users";
export const _urlHistory = HISTORY_URL + "/admin";
export const _urlHistoryUpdate = ADMIN_URL + "/history";
export const _urlRegion = USER_URL + "/regions";
export const _urlOthers = ADMIN_URL + "/others";
export const _urlStory = ADMIN_URL + "/services-ads";
export const _urlInsurance = ADMIN_URL + "/insurance-requests";
export const _urlOffers = ADMIN_URL;

export const _urlNotif = ADMIN_URL + "/notifications";
export const _urlModerator = ADMIN_URL + "/moderators";
export const _urlStaticTexts = ADMIN_URL + "/others/static-texts";
export const _urlStaticTextsUpdate = ADMIN_URL + "/static-texts";
export const _urlFilters = ADMIN_URL + "/filters";
export const _urlCategories = ADMIN_URL + "/categories";
export const _urlProducts = ADMIN_URL + "/products";
export const _urlPromoCodes = ADMIN_URL + "/promo-codes";
export const _urlOrders = ADMIN_URL + "/orders";
export const _urlNotifications = NOTIFICATION_URL + "/notifications";

export const _urlSocketNotification = SOCKET_NOTIFICATION_URL;
