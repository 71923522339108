export function changeStateField(field, value) {
    this.setState({
        [field]: value,
    })
}

export function changeStateInputValue({value, name}) {
    this.setState({
        [name]: value,
    })
}

export function toggleStateField(field) {
    this.setState({
        [field]: !this.state[field],
    })
}

export function initTranslations(fieldsList, activeLanguages) {
    const translationsData = {};
    activeLanguages && activeLanguages.forEach(lg => {
        translationsData[lg.id] = {};
        fieldsList.forEach(field => {
            translationsData[lg.id][field] = '';
        })

    });
    return translationsData;
}

export function getTranslationData(translations) {
    let TR_data = [];
    Object.keys(translations).forEach(key => {
        const reqTrItem = {
            language: key,
        };
        const trItem = translations[key];
        Object.keys(trItem).forEach(field => {
            trItem[field] && (reqTrItem[field] = trItem[field]);
        });
        TR_data.push(reqTrItem);
    });
    return TR_data;
}

export function getInputValues({name, value, haveChanges}) {
    //console.log(name, value, haveChanges)
    const {isEditing, fieldsData} = this.state;
    if ((name === 'latitude') && value && (value < -90 || value > 90)) {
        return;
    }
    if ((name === 'longitude') && value && (value < -180 || value > 180)) {
        return;
    }
    if (isEditing && name !== 'repeatPassword') {
        let mapValue = value
        if (name.startsWith('residence')) {
            mapValue = value || null;
        }
        haveChanges
            ? this.updatedDataMap.set(name, mapValue)
            : this.updatedDataMap.delete(name);
    }
    this.setState({
        fieldsData: {
            ...fieldsData,
            [name]: value,
        }
    })
    // console.log("updatedDataMap", this.updatedDataMap)
}

export function getTranslatableInputValues({name, value, haveChanges}) {
    const {fieldsData, isEditing, languageTab} = this.state;
    const {translations} = fieldsData;
    if (isEditing) {
        haveChanges
            ? this.updatedDataMap.set(name + '_' + languageTab, value)
            : this.updatedDataMap.delete(name + '_' + languageTab);

    }
    this.setState({
        fieldsData: {
            ...fieldsData,
            translations: {
                ...translations,
                [languageTab]: {
                    ...translations[languageTab],
                    [name]: value
                }
            }

        }
    })
    //console.log("updatedDataMap", this.updatedDataMap)
}


export function disabledStartDate(startValue , endValue) {

    if (!startValue || !endValue) {
        return false;
    }
    return startValue.valueOf() > endValue.valueOf();
}
export function disabledEndDate (endValue, startValue) {

    if (!endValue || !startValue) {
        return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
}

export const getFilterIds = filterValues => {
    let filterIds = [];
    Object.keys(filterValues).forEach(id => {
        const filter = filterValues?.[id];
        filter && (filterIds = filterIds.concat(filter));
    });
    return filterIds;
};


export const arraysEquals = (arr1, arr2) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
        return false;
    }
    return arr1.length === arr2.length
        && arr1.every(id => arr2.some(_id => _id === id));
};

export const truncNumber = (number) => {
    if (!number || number === 0|| number === '0') {
        return number;
    }
    const numberStr = String(number);
    if (numberStr.includes('.') && numberStr.split('.')[1].length < 3) {
        return number;
    } else {
        return Math.trunc(number * 100) / 100;
    }
};
