import {request, _urlInsurance} from "../api";
import {INSURANCE_CONSTS, UTIL_CONSTS} from "../constants";
import {LIMIT_GET_OFFERS} from "../../constants/constLimitCounts";

export const GetInsurance = ({
                                 reset = true,
                                 offset = 0,
                                 limit = LIMIT_GET_OFFERS,
                                 userId = null,
                             } = {}) => {
    const requestData = {
        url: `${_urlInsurance}/?offset=${offset}&limit=${limit}`,
        token: true,
        method: "GET",
    };
    userId && (requestData.url += `&userId=${userId}`);
    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: INSURANCE_CONSTS.GET_INSURANCE_REQUESTS,
                    payload: {
                        data: data,
                        reset,
                        hasMore: data.length === limit
                    }
                });
            }).catch(()=>{

            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const GetNotSeenInsuranceCount = () => {
    const requestData = {
        url: `${_urlInsurance}/not-seen-count`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                data && dispatch({
                    type: INSURANCE_CONSTS.GET_NOT_SEEN_INSURANCE_COUNT,
                    payload: data
                });
            })
    }
};

export const SetInsuranceAsSeen = (id) => {
    const requestData = {
        url: `${_urlInsurance}/${id}/seen`,
        token: true,
        method: "PUT",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: INSURANCE_CONSTS.SET_INSURANCE_AS_SEEN,
                    payload: id
                });
            })
    }
};

