
export const tabs = [
    {
        name: "Աշխատակիցներ",
        key: "workers"
    },
    {
        name: "Պատմություն",
        key: "history"
    },
    {
        name: "Ենթաընկերություններ",
        key: "subCompany"
    }
];
