// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";


// Import components
import PageWrapper from '../components/pageContentViews/pageWrapper'
import PageHeader from "../components/pageContentViews/pageHeader";
import {LinkButton} from "../components/buttons/buttons";
import MediaSelectorModal from "../components/media/MediaSelectorModal";
import {MediaFileCard} from "../components/media/mediaFileCard";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {GetCoverPhoto, UpdateCoverPhoto} from "../redux/actions";
import {InputGroup} from "../components/uiElements/inputGroup";
import {toggleStateField} from "../utils/helperFunctions";
import swal from "sweetalert";
import {ACCEPT_IMAGE_TYPES} from "../constants/acceptedTypes";

class Settings extends Component {
    constructor() {
        super();
        this.state = {
            initCover: '',
            coverPhoto: '',
            mediaModalOpen: false,
        };
        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.getMedia = this.getMedia.bind(this);
        this.updateCover = this.updateCover.bind(this);
    }

    componentDidMount() {
        this.props.GetCoverPhoto().then((res) => {
            this.setState({
                coverPhoto: res.data,
                initCover: res.data
            })
        }).catch(() => {
            swal({
                title: "Զգուշացում!",
                text: "Ինչ որ բան այն չէ, խնդրում ենք փորցել կրկին",
                icon: "warning",
                button: "Լավ"
            });
        })
    }


    getMedia(mediaArray) {
        const {initCover} = this.state;
        (!initCover || (initCover.id !== mediaArray[0].id)) ?
            this.updatedDataMap.set("coverPhoto", mediaArray[0].id) :
            this.updatedDataMap.delete("coverPhoto");

        this.setState({
            coverPhoto: mediaArray[0]
        })
    }

    updateCover() {
        const {coverPhoto} = this.state;
        const reqData = {
            media: coverPhoto.id
        };
        this.props.UpdateCoverPhoto(reqData).then(() => {
            this.updatedDataMap.clear();
            this.setState({
                initCover: coverPhoto
            });
            swal({
                title: "Հաջողվեց!",
                text: "Ետնանկարը փոփոխվեց հաջողությամբ!",
                icon: "success",
                button: "Լավ"
            });
        }).catch(() => {
            swal({
                title: "Զգուշացում!",
                text: "Ինչ որ բան այն չէ, խնդրում ենք փորցել կրկին",
                icon: "warning",
                button: "Լավ"
            });
        })
    }

    render() {
        const {requestLoading} = this.props;
        const {coverPhoto, mediaModalOpen} = this.state;
        return <PageWrapper>
            <PageHeader pageTitle={'Կարգավորումներ'}/>
            <InputGroup inputType={"wrapper"}
                        label={<>Ետնանկար<span
                            className="field-required">*</span></>}>
                <LinkButton title="Ընտրել մեդիադարանից"
                            className={'bg-orange'}
                            cb={this.toggleMediaModal}
                />
            </InputGroup>
            {coverPhoto && <div className={'main-media'}>
                <MediaFileCard inModal={false}
                               item={coverPhoto}/>
            </div>}
            <div className="flex-wrapper-right">
                <LinkButton title="Պահպանել"
                            disabled={!this.updatedDataMap.size}
                            loading={requestLoading}
                            cb={this.updateCover}
                />
            </div>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetCoverPhoto,
    UpdateCoverPhoto
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
