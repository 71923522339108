import {request, _urlFilters} from "../api";
import {FILTER_CONSTS} from "../constants";
import swal from "sweetalert";
import {history} from "../../configs/history";

export const GetFilters = () => {
    const requestData = {
        url: _urlFilters,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: FILTER_CONSTS.GET_FILTERS,
                    payload: data
                });
            })
    }
};

export const GetFilterById = (id) => {
    const requestData = {
        url: `${_urlFilters}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: FILTER_CONSTS.GET_FILTER_BY_ID,
                    payload: res.data
                })
            })
    }
};

export const DeleteFilterById = id => {
    const requestData = {
        url: `${_urlFilters}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: FILTER_CONSTS.DELETE_FILTER_BY_ID,
                    payload: id
                });
            })
    }
};

export const UpdateFiltersPositions = items => {
    const requestData = {
        url: _urlFilters,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: FILTER_CONSTS.UPDATE_FILTERS_POSITIONS
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Ֆիլտրը հաջողությամբ թարմացվեց!",
                    icon: "success",
                    button: "Լավ"
                })
            })
    }
};

export const CreateFilter = data => {
    const requestData = {
        url: _urlFilters,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: FILTER_CONSTS.CREATE_FILTER,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Ֆիլտրը հաջողությամբ ստեղծվեց",
                    icon: "success",
                    button: "Լավ",
                });
                history.push('/filters');
            })
    }
};

export const UpdateFilterById = (id, data) => {
    const requestData = {
        url: `${_urlFilters}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: FILTER_CONSTS.UPDATE_FILTER_BY_ID,
                    payload: res.data
                });
            })

    }
};

export const CreateFilterValue = (id, data) => {
    const requestData = {
        url: `${_urlFilters}/${id}/values`,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
    }
};

export const UpdateFilterValueById = (id, valueId, data) => {
    const requestData = {
        url: `${_urlFilters}/${id}/values/${valueId}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
    }
};

export const DeleteFilterValueById = (id, valueId) => {
    const requestData = {
        url: `${_urlFilters}/${id}/values/${valueId}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
    }
};
