// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Select} from "antd";
import swal from "sweetalert";

// Import styles
import '../../assets/styles/containerStyles/promo-codes.scss';

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import {InputGroup} from "../../components/uiElements/inputGroup";
import ItemsList from "../../components/ItemsList";

// Import utils
import {MEASUREMENT_TYPES, PROMO_CODE_STATUS, PROMO_CODE_TYPE} from "../../constants/constTypes";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetPromoCodes, DeletePromoCode} from "../../redux/actions";


class PromoCodes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            status: undefined,
            type: undefined,
            fuelId: undefined,
            searchValue: '',
        };
        this.PromoCodeStatusOptions = Object.keys(PROMO_CODE_STATUS).map(key => ({
            id: key,
            name: PROMO_CODE_STATUS[key]
        }));

        this.PromoCodeTypeOptions = Object.keys(PROMO_CODE_TYPE).map(key => ({
            id: key,
            name: PROMO_CODE_TYPE[key]
        }));

        this.PromoCodeFuelsOptions = props.fuelList.filter(item => !item.isHidden).map(fuel => ({
            id: fuel.id,
            name: fuel.translations.find(lg => lg.language === props.mainLanguage).title
        }));

        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.getSearchValue = this.getSearchValue.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
    }

    componentDidMount() {
        this.props.GetPromoCodes()
    }

    changeFilter({name, value}) {
        const {status, type, fuelId, searchValue} = this.state;
        const newFilter = {
            text: searchValue,
            fuelId: (value === 'MONEY') ? undefined : fuelId,
            status,
            type: value ? 'FUEL' : type,
            [name]: value,
        };
        this.props.GetPromoCodes(newFilter).then(() => {
            this.setState({
                ...newFilter,
            });
        });
    }

    changeStatus({name, value}) {
        const {status, type, fuelId, searchValue} = this.state;
        const newFilter = {
            text: searchValue,
            fuelId,
            status,
            type,
            [name]: value,
        };
        this.props.GetPromoCodes(newFilter).then(() => {
            this.setState({
                ...newFilter,
            });
        });
    }

    loadMoreItems() {
        const {promoCodes} = this.props;
        const {fuelId, status, type, searchValue} = this.state;
        promoCodes.hasMore && this.props.GetPromoCodes({
            reset: false,
            offset: promoCodes.itemsList.length,
            text: searchValue,
            fuelId,
            status,
            type
        });
    };

    getSearchValue(searchValue) {
        this.setState({
            ...this.state,
            searchValue
        }, () => !searchValue && this.searchItems())
    }

    searchItems() {
        const {searchValue, fuelId, status, type} = this.state;
        searchValue?.length !== 1 && this.props.GetPromoCodes({
            text: searchValue,
            fuelId,
            status,
            type
        });
    }

    deletePromoCode = id => {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել նվեր քարտը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeletePromoCode(id);
            }
        });
    };

    render() {
        let {promoCodes, mainLanguage, requestLoading} = this.props;
        let {status, type, fuelId, searchValue} = this.state;

        let itemListHeaderInfo = {
            generalInfo: ["Տեսակ", "Կոդ", "Արժեք"],
            status: true,
            largeItems: [0, 1, 2, 3],
            actions: ["delete"],
        };
        let itemListInfo = [];
        promoCodes.itemsList.forEach(item => {
            let type = item?.fuel?.translations?.find(lg => lg.language === mainLanguage)?.title;
            itemListInfo.push({
                id: item.id,
                status: item.status,
                values: [
                    type ? type : 'Գումարային',
                    item?.code,
                    // item?.sum + " AMD",
                    item?.amount + (!item?.fuel? " Դրամ" : MEASUREMENT_TYPES[item?.fuel.measurementUnit])
                ],
                deleteCb: this.deletePromoCode.bind(this, item.id)
            });
        });

        return <PageWrapper>
            <section className="promo-codes">
                <PageHeader pageTitle={'Նվեր քարտեր'}
                            linkTitle={"Ավելացնել"}
                            addingLink={"/promoCodes/add"}
                            getSearchValue={this.getSearchValue}
                            searchItems={this.searchItems}
                            searchValue={searchValue}
                            search={true}/>
                <div className="promo-code-filters-wrapper">
                    <div className={'wrapper'}>
                        <InputGroup inputType="selectCustom"
                                    placeholder="Վառելիք"
                                    name="fuelId"
                                    showSearch={false}
                                    value={fuelId}
                                    onChange={this.changeFilter}
                                    options={this.PromoCodeFuelsOptions}>
                            {fuelId && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                        </InputGroup>
                        <InputGroup inputType="selectCustom"
                                    placeholder="Կարգավիճակ"
                                    name="status"
                                    showSearch={false}
                                    value={status}
                                    onChange={this.changeStatus}
                                    options={this.PromoCodeStatusOptions}>
                            {status && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                        </InputGroup>
                        <InputGroup inputType="selectCustom"
                                    placeholder="Տեսակ"
                                    name="type"
                                    showSearch={false}
                                    value={type}
                                    onChange={this.changeFilter}
                                    options={this.PromoCodeTypeOptions}>
                            {type && <Select.Option value={undefined}>Բոլորը</Select.Option>}
                        </InputGroup>
                    </div>
                </div>

                <ItemsList itemListInfo={itemListInfo}
                           itemListHeaderInfo={itemListHeaderInfo}
                           loading={requestLoading}
                           infiniteScroll={true}
                           useWindow={false}
                           hasMore={promoCodes?.hasMore}
                           loadMoreItems={this.loadMoreItems}
                />
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetPromoCodes,
    DeletePromoCode
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodes);
