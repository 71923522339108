import {STORY_CONSTS} from "../constants";

export const initialState = {
    storyList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case STORY_CONSTS.GET_STORY:
            return {
                ...state,
                storyList: action.payload || [],
            };
        case STORY_CONSTS.ADD_STORY:
            return {
                ...state,
                storyList: [action.payload].concat(state.storyList)
            };
        case STORY_CONSTS.DELETE_STORY:
            return {
                ...state,
                storyList: state.storyList.filter(story => story.id !== action.payload)
            };
        default:
            return state;
    }
}
