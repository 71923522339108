// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";

// Import utils
import {mapStateToProps} from "../../../redux/mapStateToProps";
import {GetOffers} from "../../../redux/actions";
import ItemsList from "../../../components/ItemsList";

class Spares extends Component {
    constructor() {
        super();
        this.fetching = false;
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    async loadMoreItems() {
        if (!this.fetching) {
            this.fetching = true;
            const {data} = this.props;
            data.hasMore && await this.props.GetOffers({
                reset: false,
                offset: data.itemsList.length,
                type: 'spares'
            });
            this.fetching = false
        }
    };

    render() {
        const {data, requestLoading} = this.props;
        // console.log(data.notSeenCount, 'SPARES');
        const itemListHeaderInfo = {
            generalInfo: ["Անվանում", "Մակնիշ", "Մոդել", "VIN Համար", "Տարեթիվ"],
            largeItems: [4],
            status: true,
        };
        let itemListInfo = [];
        data.itemsList && data.itemsList.forEach(item => {
            itemListInfo.push({
                id: item.id,
                status: item.status,
                newItem: !(item?.seen ?? true),
                //         // onClick: this.setInsuranceAsSeen.bind(this, item.id),
                values: [
                    item?.user?.firstName + " " + item?.user?.lastName,
                    item?.carMake?.name || '-',
                    item?.carModel?.name || '-',
                    item?.vinCode || '-',
                    item?.createdAt ? moment(item.createdAt).format('DD.MM.YYYY') : '-',
                ],
            });
        });
        return <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                          itemListInfo={itemListInfo}
                          detailsLink={"offers/spares"}
                          loading={requestLoading}
                          infiniteScroll={true}
                          hasMore={data.hasMore}
                          loadMoreItems={this.loadMoreItems}
        />

    }
}

const mapDispatchToProps = {GetOffers};

export default connect(mapStateToProps, mapDispatchToProps)(Spares)
