// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon, Dropdown, Menu} from "antd";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";


// Import Styles
import '../../assets/styles/cards/details-header.scss';

// Import components
import {LinkButton} from "../../components/buttons/buttons";
import {SkeletonSmallItem} from "../uiElements/SkelatonISmalltem";


class DetailsHeader extends Component {

    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        const {data, titleDetails, rightSideProps, withEditAction, headerDate, mainLanguage, loading} = this.props;
        const stationTranslations = data?.station?.translations?.find(item => item.language === mainLanguage);
        const {title, addressCity, addressRegion, address} = stationTranslations || {};
        const menu = (
            <Menu>
                <Menu.Item>
                    Edit Profile
                </Menu.Item>
            </Menu>
        );
        return <div className="partner-details-header">
            <div className="left-side">
                {loading ? <SkeletonSmallItem/> : <h1 className="page-title">{data?.name}</h1>}
                {withEditAction}

                {titleDetails && <Dropdown overlay={menu}>
                    <span className="ant-dropdown-link">
                        <LinkButton className="bg-white" title={<Icon type="ellipsis"/>}/>
                    </span>
                </Dropdown>}
                <table className="address-part">
                    <tbody>
                    {loading ? <SkeletonSmallItem/> : <>
                        {data?.address && <tr>
                            <td className="text-bold">Հասցե։</td>
                            <td className="text-grey">{data?.address}</td>
                        </tr>}
                    </>}
                    {loading ? <SkeletonSmallItem/> : <>
                        {data?.email && <tr>
                            <td className="text-bold">Էլ․ հասցե։</td>
                            <td className="text-grey">{data?.email}</td>
                        </tr>}
                    </>}
                    {loading ? <SkeletonSmallItem/> : <>
                        {data?.phone && <tr>
                            <td className="text-bold">Հեռախոս։</td>
                            <td className="text-grey">{data?.phone}</td>
                        </tr>}
                    </>}
                    {loading ? <SkeletonSmallItem/> : <>
                        {data?.company && <tr>
                            <td className="text-bold">Կազմակերպություն։</td>
                            <td className="text-grey">{data?.company}</td>
                        </tr>}
                    </>}
                    </tbody>
                </table>
            </div>
            {rightSideProps}
            <div>
                {!rightSideProps && <div className="right-side">

                    {data.status && <div className={'isHidden-state'}>
                        <span> {data?.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</span>
                    </div>}

                    {data?.date && <span
                        className="update-time">{headerDate ? "Ամսաթիվ։" : "Օգտագործման ամսաթիվ։"} {data?.date}
                    </span>}

                    {data?.createdAt && <span
                        className="update-time create-time">{"Գրանցման  ամսաթիվ։"} {data?.createdAt}
                    </span>}

                    {data?.isPaid && <span
                        className="update-time">{data?.isPaid}
                    </span>}

                </div>
                }
                {data.station &&
                <table className="station-info">
                    <tbody>
                    <tr>
                        <td className="text-bold">Լցակայան։</td>
                        <td className="text-grey">{title}</td>
                    </tr>
                    <tr>
                        <td className="text-bold">Հասցե։</td>
                        <td className="text-grey">{`${addressCity ?? ''} ${addressRegion ?? ''} ${address ?? ''}`}</td>
                    </tr>
                    <tr>
                        <td className="text-bold">Հեռախոս։</td>
                        <td className="text-grey">{data?.station?.phoneNumber}</td>
                    </tr>
                    </tbody>
                </table>
                }
            </div>
        </div>
    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsHeader)
