import {COMPANY_CONSTS, AUTH_CONSTS,} from "../constants";

export const initialState = {
    companies: {
        itemsList: [],
        text: ''
    },
    companyById: null,
    companyUsers: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case COMPANY_CONSTS.GET_COMPANIES:
            return {
                ...state,
                companies: {
                    itemsList: action.payload.data || [],
                    balancesSum: action.payload.balancesSum,
                    userBalancesSum: action.payload.userBalancesSum,
                    text: action.payload.text,
                }
            };
        case COMPANY_CONSTS.GET_COMPANY_BY_ID:
            return {
                ...state,
                companyById: action.payload
            };
        case COMPANY_CONSTS.GET_COMPANY_USERS:
            return {
                ...state,
                companyUsers: action.payload
            };
        case COMPANY_CONSTS.ADD_COMPANY:
            return {
                ...state,
                companies: !action.payload?.parent ? {
                    ...state.companies,
                    itemsList: [action.payload, ...state.companies.itemsList],
                    count: state.companies.count + 1
                } : state.companies
            };
        case COMPANY_CONSTS.DELETE_COMPANY:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    itemsList: state.companies.itemsList.filter(company => company.id !== action.payload),
                    count: state.companies.count - 1
                },
                companyById: {
                    ...state.companyById,
                    subCompanies: state?.companyById?.subCompanies?.filter(company => company.id !== action.payload)
                }
            };
        case COMPANY_CONSTS.EDIT_COMPANY:
            const editedList = state?.companies?.itemsList?.map(company => company.id === action.payload.id ?
                action.payload : company);
            return {
                ...state,
                companies: {
                    ...state.companies,
                    itemsList: editedList,
                }
            };
        case COMPANY_CONSTS.EDIT_COMPANY_BALANCE:
            return {
                ...state,
                companyById: {
                    ...state.companyById,
                    balances: action.payload.data,
                },
                companies: {
                    ...state.companies,
                    itemsList: state.companies?.itemsList.map(company => {
                        return company.id === action.payload.companyId ?
                            {...company, balances: action.payload.data}
                            : company
                    }),
                }
            };
        case COMPANY_CONSTS.CLEAR_SINGLE_COMPANY_DATA:
            return {
                ...state,
                companyById: null
            };
        case COMPANY_CONSTS.CLEAR_COMPANY_USERS:
            return {
                ...state,
                companyUsers: []
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
