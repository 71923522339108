//import icons

import pdf from '../assets/images/file-icons/pdf.png';
import wmv from '../assets/images/file-icons/wmv.png';
import mpeg from '../assets/images/file-icons/mpeg.png';
import avi from '../assets/images/file-icons/avi.png';
import flv from '../assets/images/file-icons/flv.png';
import mp3 from '../assets/images/file-icons/mp3.png';
import mp4 from '../assets/images/file-icons/mp4.png';
import wav from '../assets/images/file-icons/wav.png';
import zip from '../assets/images/file-icons/zip.png';
import rar from '../assets/images/file-icons/rar.png';
import doc from '../assets/images/file-icons/doc.png';
import docx from '../assets/images/file-icons/docx.png';
import ppt from '../assets/images/file-icons/ppt.png';
import pptx from '../assets/images/file-icons/pptx.png';
import xls from '../assets/images/file-icons/xls.png';
import xlsx from '../assets/images/file-icons/xlsx.png';
import unspecified from '../assets/images/file-icons/unspecified.png';

export const getFileIcon = (extension) => {
    switch (extension) {
        case 'pdf':
            return pdf;
        case 'wmv':
            return wmv;
        case 'mpeg':
            return mpeg;
        case 'avi':
            return avi;
        case 'flv':
            return flv;
        case 'mp3':
            return mp3;
        case 'mp4':
            return mp4;
        case 'wav':
            return wav;
        case 'zip':
            return zip;
        case 'rar':
            return rar;
        case 'doc':
            return doc;
        case 'docx':
            return docx;
        case 'ppt':
            return ppt;
        case 'pptx':
            return pptx;
        case 'xls':
            return xls;
        case 'xlsx':
            return xlsx;
        default:
            return unspecified;
    }
};
