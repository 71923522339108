import {request, _urlUsers} from "../api";
import {USERS_CONSTS, UTIL_CONSTS} from "../constants";
import {LIMIT_GET_USERS} from "../../constants/constLimitCounts";
import swal from "sweetalert";
import {stringify} from "query-string";
import {history} from "../../configs/history";
import {concatDate} from "../../utils/concatTime";

export const GetUsers = ({
                             reset = true,
                             offset = 0,
                             text = '',
                             createdAtStartDate = null,
                             createdAtEndDate = null,
                             lastUpdateStartDate = null,
                             lastUpdateEndDate = null,
                             type = null,
                             limit = LIMIT_GET_USERS,
                             getResponse = false
                         } = {}) => {

    let url = `${_urlUsers}?offset=${offset}&limit=${limit}`;
    text && (url += `&text=${encodeURIComponent(text)}`);
    type && (url += `&type=${type}`);

    const _createdAtStartDate = concatDate(createdAtStartDate, "", "start");
    const _createdAtEndDate = concatDate(createdAtEndDate,"", "end");
    const _lastUpdateStartDate = concatDate(lastUpdateStartDate, "", "start");
    const _lastUpdateEndDate = concatDate(lastUpdateEndDate,"", "end");

    _createdAtStartDate && (url += `&createdAtStartDate=${_createdAtStartDate}`);
    _createdAtEndDate && (url += `&createdAtEndDate=${_createdAtEndDate}`);
    _lastUpdateStartDate && (url += `&lastUpdateStartDate=${_lastUpdateStartDate}`);
    _lastUpdateEndDate && (url += `&lastUpdateEndDate=${_lastUpdateEndDate}`);

    const requestData = {
        url: url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && !getResponse && dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then((res) => {
                const data = res.data;
                //console.log('%c users', 'color:red', data);
                data && !getResponse && dispatch({
                    type: USERS_CONSTS.GET_USERS,
                    payload: {
                        data,
                        count: data?.usersQuantity,
                        reset,
                        type,
                        hasMore: data?.users?.length === limit
                    }
                });
                if (getResponse) {
                    return data?.users
                }
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};


export const GetUserById = (id) => {
    const requestData = {
        url: `${_urlUsers}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: USERS_CONSTS.GET_USER_BY_ID,
                    payload: data
                });
                //  console.log("user",data);
            })
    }
};


export const AddUser = (data) => {
    const requestData = {
        url: `${_urlUsers}`,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                res?.data && dispatch({
                    type: USERS_CONSTS.ADD_USER,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Օգտատերը հաջողությամբ ստեղծվեց",
                    icon: "success",
                    button: "Լավ"
                });
                history.push('/users');
            })
    }
};

export const UpdateUser = (id, data) => {
    const requestData = {
        url: `${_urlUsers}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                res?.data && dispatch({
                    type: USERS_CONSTS.UPDATE_USER,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Օգտատերը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ"
                });
                history.push('/users');
            })
    }
};


export const EditUserBalance = (userId, fuelId, amount) => {
    const requestData = {
        url: `${_urlUsers}/${userId}/balance`,
        token: true,
        method: "POST",
        data: stringify({fuelId, amount}),
        customHeaders: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res.data;
                await dispatch({
                    type: USERS_CONSTS.EDIT_USER_BALANCE,
                    payload: {userId, data},
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Օգտատիրոջ Բալանսը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ",
                });
            })
    }
};

export const SendNotification = (data) => {
    const requestData = {
        url: `${_urlUsers}/notifications`,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
    }
};

export const ClearUserById = () => {
    return dispatch => {
        dispatch({
            type: USERS_CONSTS.CLEAR_USER_BY_ID,
        });
    }
};
