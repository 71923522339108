export function validateFields(errors, validationData) {
    this.errorsSet.clear();
    this.errorsTabs.clear();
    errors.requiredFields && errors.requiredFields.forEach(key => {
        if (!validationData[key] || validationData[key] === ""
            || (Array.isArray(validationData[key]) && !validationData[key]?.length)) {
            this.errorsSet.add(key);
            return false;
        }
        if((key === 'amount')){
            if (this.state.fieldsData.fuel !== null && (validationData?.[key] < 0 || validationData?.[key] > 1000)) {
                return;
            }else if (validationData?.[key] < 0 || validationData?.[key] > 500000){
                return;
            }
        }
        if ((key === 'sum') && (validationData?.[key] < 0 || validationData?.[key] > 500000)) {
            this.errorsSet.add(key);
            return false;
        }
        if ((key === 'quantity') && (validationData?.[key] < 1 || validationData?.[key] > 10000)) {
            this.errorsSet.add(key);
            return false;
        }
    });
    return !this.errorsSet.size;
}
