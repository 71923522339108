import {USERS_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    usersList: {
        itemsList: [],
        count: 0,
        balancesSum: [],
        type: null,
        hasMore: true,
    },
    userById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USERS_CONSTS.GET_USERS:
            const itemsList=action.payload?.data?.users;
            return {
                ...state,
                usersList: {
                    itemsList: action.payload.reset ? itemsList : [...state.usersList.itemsList, ...itemsList],
                    count: action.payload.count,
                    type: action.payload.type,
                    balancesSum: action.payload?.data?.balancesSum,
                    hasMore: action.payload.hasMore,
                }
            };
        case USERS_CONSTS.GET_USER_BY_ID:
            return {
                ...state,
                userById: action.payload
            };
        case USERS_CONSTS.ADD_USER:
            return {
                ...state,
                usersList: {
                    ...state.usersList,
                    itemsList: [action.payload, ...state.usersList.itemsList]
                }
            };
        case USERS_CONSTS.UPDATE_USER:
            return {
                ...state,
                usersList: {
                    ...state.usersList,
                    itemsList: state.usersList.itemsList.map(user => user.id === action.payload.id
                        ? action.payload
                        : user
                    )
                }
            };
        case USERS_CONSTS.EDIT_USER_BALANCE:
            return {
                ...state,
                usersList: {
                    ...state.usersList,
                    itemsList: state.usersList.itemsList.map(user => user.id === action.payload.userId
                        ? {
                            ...user,
                            balances: action.payload.data
                        }
                        : user)
                },
                userById: {
                    ...state.userById,
                    balances: action.payload.data
                }
            };
        case USERS_CONSTS.CLEAR_USER_BY_ID:
            return {
                ...state,
                userById: null
            };

        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
