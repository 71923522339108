export const ACCESS_PAGES = {
    fuels: {
        key: 'FUELS',
        name: 'Վառելիքներ',
        icon: '',
        isHidden: false,
    },
    station: {
        key: 'STATIONS',
        name: 'Լցակայաններ',
        icon: '',
        isHidden: false,
    },
    networks: {
        key: 'STATION_NETWORKS',
        name: 'Ցանցեր',
        icon: 'account_tree',
        isHidden: false,
    },
    transfers: {
        key: 'TRANSFERS',
        name: 'Փոխանցումներ',
        icon: 'payment',
        isHidden: false,
    },
    history: {
        key: 'HISTORIES',
        name: 'Պատմություն',
        icon: 'history',
        isHidden: false,
    },
    companies: {
        key: 'COMPANIES',
        name: 'Ընկերություններ',
        icon: 'inbox',
        isHidden: false,
    },
    users: {
        key: 'USERS',
        name: 'Օգտատերեր',
        icon: 'person',
        isHidden: false,
    },
    media: {
        key: 'FILES',
        name: 'Ֆայլեր',
        icon: 'insert_drive_file',
        isHidden: false,
    },
    reviews: {
        key: 'REVIEWS',
        name: 'Մեկնաբանություններ',
        icon: 'rate_review',
        isHidden: false,
    },
    products: {
        key: 'PRODUCTS',
        name: 'Ապրանքներ',
        icon: 'add_shopping_cart',
        isHidden: false,
    },
    promoCodes: {
        key: 'PROMO_CODES',
        name: 'Նվեր քարտեր',
        icon: 'card_giftcard',
        isHidden: false,
    },
    orders: {
        key: 'ORDERS',
        name: 'Պատվերներ',
        icon: 'notes',
        isHidden: false,
    },

    categories: {
        key: 'CATEGORIES',
        name: 'Բաժիններ',
        icon: 'category',
        isHidden: false,
    },
    filters: {
        key: 'FILTERS',
        name: 'Ֆիլտրեր',
        icon: 'filter',
        isHidden: false,
    },
    stories: {
        key: 'STORY',
        name: 'Գովազդ',
        icon: 'change_history',
        isHidden: false,
    },
    services: {
        key: 'SERVICES',
        name: 'Ծառայություններ',
        icon: 'share',
        isHidden: true,
    },
    insurances: {
        key: 'INSURANCES',
        name: 'Ապահովագրություն',
        icon: 'playlist_add_check',
        isHidden: false,
    },
    offers: {
        key: 'OFFERS',
        name: 'Հայտեր',
        icon: 'library_books',
        isHidden: false,
    },
    languages: {
        key: 'LANGUAGES',
        name: 'Լեզուներ',
        icon: 'language',
        isHidden: true,
    },
    contact: {
        key: 'CONTACTS',
        name: 'Կապ',
        icon: 'contact_phone',
        isHidden: false,
    },
    staticTexts: {
        key: 'STATIC_TEXTS',
        name: 'Թարգմանություններ',
        icon: 'text_format',
        isHidden: false,
    },
    settings: {
        key: 'SETTINGS',
        name: 'Կարգավորումներ',
        icon: 'settings',
        isHidden: false,
    },
};
