import {MEDIA_CONSTS,AUTH_CONSTS} from "../constants";

export const initialState = {
    galleryImages: {
        itemsList: [],
        hasMore: false,
        text: '',
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MEDIA_CONSTS.GET_MEDIA:
            const newGalleryList = {
                itemsList: action.payload.reset ? action.payload.data : [...state.galleryImages.itemsList, ...action.payload.data],
                text: action.payload.text,
                hasMore: action.payload.hasMore
            };
            return {
                ...state,
                galleryImages: newGalleryList
            };
        case MEDIA_CONSTS.UPLOAD_FILE:
        case MEDIA_CONSTS.ADD_FOLDER:
            return {
                ...state,
                galleryImages: {
                    ...state.galleryImages,
                    itemsList: addFile(state.galleryImages.itemsList, action.payload.pathList, action.payload.data)
                }
            };

        case MEDIA_CONSTS.DELETE_MEDIA_BY_ID:
        case MEDIA_CONSTS.DELETE_FOLDER:
            return {
                ...state,
                galleryImages: {
                    ...state.galleryImages,
                    itemsList: deleteFile(state.galleryImages.itemsList, action.payload.pathList, action.payload.id)
                }
            };
        case MEDIA_CONSTS.UPDATE_MEDIA:
            return {
                ...state,
                galleryImages: {
                    ...state.galleryImages,
                    itemsList: state.galleryImages.itemsList.map(img => img.id === action.payload.id ? action.payload : img),
                }
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}

const addFile = (filesList = [], pathList, data) => {
    // debugger;
    // console.log('pathList', pathList)
    // console.log('filesList', filesList)
    if (!pathList || !pathList.length) {
        return [data, ...filesList];
    }
    const currPath = pathList[0];
    const currFolderIndex = filesList && filesList.findIndex(folder => {
        return folder.type === "FOLDER" && folder.name === currPath
    });
    if (currFolderIndex === -1) {
        return filesList;
    }
    const pathRightPart = pathList.slice(1);
    const newList = [...filesList];
    const currentFolder = newList[currFolderIndex];
    //console.log('currentFolder', currentFolder)
    newList[currFolderIndex] = {
        ...currentFolder,
        children: addFile(currentFolder.children, pathRightPart, data)
    };
    //console.log('newList', newList)
    return newList;
}

const deleteFile = (filesList = [], pathList, id) => {
    // debugger;
    //console.log('pathList', pathList)
    //console.log('filesList', filesList)
    if (!pathList || !pathList.length) {
        return filesList.filter(file => file.id !== id);
    }
    const currPath = pathList[0];
    const currFolderIndex = filesList && filesList.findIndex(folder => {
        return folder.type === "FOLDER" && folder.name === currPath
    });
    if (currFolderIndex === -1) {
        return filesList;
    }
    const pathRightPart = pathList.slice(1);
    const newList = [...filesList];
    const currentFolder = newList[currFolderIndex];
    //console.log('currentFolder', currentFolder)
    newList[currFolderIndex] = {
        ...currentFolder,
        children: deleteFile(currentFolder.children, pathRightPart, id)
    };
    //console.log('newList', newList)
    return newList;
}
