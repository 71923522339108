import {socketNotificationConnection} from "../api/socketNotificationConnection";
import {NOTIFICATION_TYPES} from "../constants";
import {store} from "../../redux/store";
import {isNotificationsConnected} from "../api/isConnected";
import swal from "sweetalert";
// console.log(store, 'emmiter');
export const GetNonOpenedNotificationsCount = () => {
    isNotificationsConnected() &&
    socketNotificationConnection.instance.emit(NOTIFICATION_TYPES.GET_NON_OPENED_NOTIFICATIONS_COUNT,
        (error, data) => {
            // console.log(data, 'Data GetNonOpenedNotificationsCount');
            data && store.dispatch({
                type: NOTIFICATION_TYPES.GET_NON_OPENED_NOTIFICATIONS_COUNT,
                payload: data.count
            });
        })
};


export const SetAllNotificationsAsOpened = () => {
    isNotificationsConnected() &&
    socketNotificationConnection.instance.emit(NOTIFICATION_TYPES.SET_ALL_NOTIFICATIONS_OPENED,
        (error, data) => {
           // console.log('set-notificationes-opened...', error, data.success)
            data && store.dispatch({
                type: NOTIFICATION_TYPES.SET_ALL_NOTIFICATIONS_OPENED
            })
        })
};

export const DeleteAllNotifications = () => {
    isNotificationsConnected() &&
    socketNotificationConnection.instance.emit(NOTIFICATION_TYPES.REMOVE_ALL_NOTIFICATIONS,
        (error, data) => {
            // console.log('Delete-all-notifications...', error, data)
            data && store.dispatch({
                type: NOTIFICATION_TYPES.REMOVE_ALL_NOTIFICATIONS,
                payload: data
            });
        })
};

// console.log(store.getState().general?.staticTexts?.notification_delete_success, 'STORE');
export const DeleteNotification = (notificationId) => {
    const payload = {notificationId};
    isNotificationsConnected() &&
    socketNotificationConnection.instance.emit(NOTIFICATION_TYPES.REMOVE_NOTIFICATION, payload,
        (error, data) => {
            data && store.dispatch({
                type: NOTIFICATION_TYPES.REMOVE_NOTIFICATION,
                payload: notificationId
            });
        })
};
