// Import packages
import React, {Component} from "react";

// Import Styles
import '../../assets/styles/cards/title-row.scss';

// Import components


class TitleRow extends Component {

    render() {
        const {isHidden} = this.props;
        const {generalInfo} = this.props.titleRowContent;

        return <thead className="title-row-wrapper">
            <tr className="general-info">
                {generalInfo && !!generalInfo.length && generalInfo.map((item, index) => {
                    return <th className={"general-info-item"} key={index}>{item}</th>
                })}
                {!isHidden && <th className="actions"> Գործողություններ</th>}

            </tr>
        </thead>
    }
}

export default TitleRow;
