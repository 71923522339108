// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import styles

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetFilters, UpdateFiltersPositions, DeleteFilterById} from "../../redux/actions";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import swal from "sweetalert";

class Filters extends Component {
    constructor() {
        super();

        this.deleteFilter = this.deleteFilter.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    componentDidMount() {
        this.props.GetFilters();
    }

    deleteFilter(removingId) {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել ֆիլտրը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteFilterById(removingId);
            }
        });
    }

    getNestableItems(list, link) {
        const items = [];
        list && !!list.length && list.forEach((item, index) => {

            items.push({
                id: item.id,
                index: index,
                title: item.title,
                withImage: false,
                largeItem: false,
                link: `filters/edit-${link}/${item.id}`,
                deleteCb: () => this.deleteFilter(item.id),
            })
        });
        return items;
    }

    async onPositionChange(items, item) {
        const movedFilterId = item.id;
        const {filtersList} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~filtersList.findIndex(m => m.id === movedFilterId) &&
            ~positionedItems.findIndex(m => m.id === movedFilterId)) {
            // first level menu was re-positioned in first level
            await this.props.UpdateFiltersPositions(positionedItems);
            this.props.GetFilters();
        }
    }

    render() {
        let {filtersList} = this.props;

        let items = this.getNestableItems(filtersList, 'filter');

        return <PageWrapper>
            <section className="categories">
                <PageHeader pageTitle={'Ֆիլտրեր'}
                            linkTitle={"Ավելացնել"}
                            addingLink={"/filters/add"}/>
                <div className="nestable-items-wrapper">
                    {items && items.length !== 0 &&
                    <Nestable
                        items={items}
                        maxDepth={1}
                        onChange={this.onPositionChange}
                        renderItem={NestableItem}
                    />}
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetFilters,
    UpdateFiltersPositions,
    DeleteFilterById
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);