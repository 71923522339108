import {STATION_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    stations: {
        itemsList: [],
        count: 0,
        hasMore: true,
        networkId: null,
        type: null,
        regionId: null,
        residenceId: null,
        text: ''
    },
    stationById: null,
    networks: [],
    networkById: null,
    supervisors: [],
    saleRequestsList: {
        itemsList: [],
        operationsSum: 0,
        operationsQuantity: 0,
        fuelsSum: [],
        hasMore: false,
        text: '',
        status: null,
        startDate: null,
        endDate: null,
        type: null,
    },
    paidAmountList: {
        itemsList: [],
        operationsSum: 0,
        operationsQuantity: 0,
        fuelsSum: [],
        hasMore: false,
        text: '',
        status: null,
        startDate: null,
        endDate: null,
        type: null,
    },
    remainingFuels: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case STATION_CONSTS.GET_STATIONS:
            return {
                ...state,
                stations: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.stations.itemsList, ...action.payload.data],
                    count: action.payload.count,
                    text: action.payload.text,
                    networkId: action.payload.networkId,
                    type: action.payload.type,
                    regionId: action.payload.regionId,
                    residenceId: action.payload.residenceId,
                    hasMore: action.payload.hasMore
                }
            };
        case STATION_CONSTS.GET_STATION_BY_ID:
            return {
                ...state,
                stationById: action.payload
            };
        case STATION_CONSTS.ADD_STATION:
            return {
                ...state,
                stations: {
                    ...state.stations,
                    itemsList: [action.payload, ...state.stations.itemsList],
                    count: state.stations.count + 1
                }
            };
        case STATION_CONSTS.EDIT_STATION:
            const editedList = state?.stations?.itemsList?.map(station => station.id === action.payload.id ?
                action.payload : station);
            return {
                ...state,
                stations: {
                    ...state.stations,
                    itemsList: editedList,
                }
            };
        case STATION_CONSTS.DELETE_STATION:
            return {
                ...state,
                stations: {
                    ...state.stations,
                    itemsList: state.stations.itemsList.filter(station => station.id !== action.payload),
                    count: state.stations.count - 1
                }
            };

        case STATION_CONSTS.GET_STATION_NETWORKS:
            return {
                ...state,
                networks: action.payload
            };

        case STATION_CONSTS.GET_STATION_NETWORK_BY_ID:
            return {
                ...state,
                networkById: action.payload
            };
        case STATION_CONSTS.DELETE_STATION_NETWORK:
            return {
                ...state,
                networks: state.networks.filter(network => network.id !== action.payload),
            };
        case STATION_CONSTS.GET_REMAINING_FUELS:
            return {
                ...state,
                remainingFuels: action.payload,
            };
        case STATION_CONSTS.GET_STATION_NETWORK_SUPERVISORS:
            return {
                ...state,
                supervisors: action.payload
            };
        case STATION_CONSTS.DELETE_STATION_NETWORK_SUPERVISOR:
            return {
                ...state,
                supervisors: state.supervisors.filter(visor => visor.id !== action.payload),
            };

        case STATION_CONSTS.GET_TRANSFERS_REQUEST:
            return {
                ...state,
                [action.payload.key]: {
                    itemsList: action.payload.reset ? action.payload.data
                        : [...state[action.payload.key].itemsList, ...action.payload.data],
                    operationsSum: action.payload.operationsSum,
                    operationsQuantity: action.payload.operationsQuantity,
                    fuelsSum: action.payload.fuelsSum,
                    status: action.payload.status,
                    text: action.payload.text,
                    startDate: action.payload.startDate,
                    endDate: action.payload.endDate,
                    type: action.payload.type,
                    stationNetworkId: action.payload.stationNetworkId,
                    hasMore: action.payload.hasMore,
                },
            };
        case STATION_CONSTS.ADD_PAID_AMOUNT:
            let fuelList = [action.payload, ...state.paidAmountList.itemsList];
            return {
                ...state,
                paidAmountList: {
                    itemsList: fuelList,
                },
            };
        case STATION_CONSTS.UPDATE_SALE_FUEL:
            return {
                ...state,
                saleRequestsList: {
                    ...state.saleRequestsList,
                    itemsList: state.saleRequestsList.itemsList.map(transfer => transfer.id === action.payload.id ? {
                        ...transfer,
                        status: action.payload.status
                    } : transfer )
                }
            };

        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
