// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";

// Import styles
import "../../assets/styles/containerStyles/insurance-details.scss";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {PURPOSE_TYPE} from "../../constants/constTypes";

// Import utils
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {changeStateInputValue} from "../../utils/helperFunctions";

class InsuranceDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            amount: "",
        };
        this.insuranceData = props.insuranceList?.itemsList?.find(insurance => insurance.id === props.id);
        this.getInputValues = changeStateInputValue.bind(this);
    }

    render() {
        const {mainLanguage} = this.props;
        return <PageWrapper>
            <section className="insurance-details">
                <PageHeader pageTitle={"ԱՊՊԱ պայմանագրի կնքում"}/>
                <div className="insurance-details-wrapper">
                    <div className="left-part">
                        <h1 className="data-title">Հայտի տվյալներ</h1>
                        <table className="data-view">
                            <tbody>
                            {this.insuranceData?.insuranceCompany &&
                            <tr>
                                <td className="text-bold">Կազմակերպություն։</td>
                                <td className="company">
                                    <img
                                        src={generateImageMediaUrl(this.insuranceData?.insuranceCompany?.iconPath?.path)}
                                        alt=""/>
                                    <span
                                        className="text-grey company-name">{this.insuranceData?.insuranceCompany?.translations?.find(item => item.language === mainLanguage).title || ''}</span>
                                </td>

                            </tr>
                            }
                            {this.insuranceData?.createdAt &&
                            <tr>
                                <td className="text-bold">Ուղարկման ամսաթիվ։</td>
                                <td className="text-grey">{moment(this.insuranceData?.createdAt).format('DD.MM.YYYY')}</td>
                            </tr>
                            }
                            {this.insuranceData?.carUsePurpose &&
                            <tr>
                                <td className="text-bold">ՏՄ-ի օգտագործման նպատակը։</td>
                                <td className="text-grey">{PURPOSE_TYPE[this.insuranceData?.carUsePurpose]}</td>
                            </tr>
                            }
                            {this.insuranceData?.durationMonths &&
                            <tr>
                                <td className="text-bold">Ժամկետ։</td>
                                <td className="text-grey">{this.insuranceData?.durationMonths} ամիս</td>
                            </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="right-part">
                        <h1 className="data-title">Հայտատուի տվյալներ</h1>
                        <table className="data-view">
                            <tbody>
                            {this.insuranceData?.user?.firstName &&
                            <tr>
                                <td className="text-bold">Անվանում։</td>
                                <td className="text-grey">{this.insuranceData?.user?.firstName + " " + this.insuranceData?.user?.lastName}</td>
                            </tr>
                            }
                            {this.insuranceData?.user?.ownerPassportNumber &&
                            <tr>
                                <td className="text-bold">Անձնագրի սերիա։</td>
                                <td className="text-grey">{this.insuranceData?.user?.ownerPassportNumber}</td>
                            </tr>
                            }
                            {this.insuranceData?.carPlateNumber &&
                            <tr>
                                <td className="text-bold">Պետ․ համարանիշ։</td>
                                <td className="text-grey">{this.insuranceData?.carPlateNumber}</td>
                            </tr>
                            }
                            {this.insuranceData?.phoneNumber &&
                            <tr>
                                <td className="text-bold">Հեռախոս։</td>
                                <td className="text-grey">{this.insuranceData?.phoneNumber}</td>
                            </tr>
                            }
                            {this.insuranceData?.user?.email &&
                            <tr>
                                <td className="text-bold">Էլ․ հասցե։</td>
                                <td className="text-grey">{this.insuranceData?.user?.email}</td>
                            </tr>
                            }
                            </tbody>
                        </table>

                    </div>
                </div>
                {/*<div className="separator"/>*/}
                {/*<div>*/}
                {/*    <InputGroup inputType="input"*/}
                {/*                type="number"*/}
                {/*                label="Գումար"*/}
                {/*                placeholder="Գումար"*/}
                {/*                name="amount"*/}
                {/*                maxLength={256}*/}
                {/*                value={this.state.amount}*/}
                {/*                onChange={this.getInputValues}*/}
                {/*                />*/}
                {/*</div>*/}
                {/*<div className="send-offer">*/}
                {/*    <LinkButton title={"Ուղարկել"}*/}
                {/*                disabled={!amount}/>*/}
                {/*</div>*/}
            </section>

        </PageWrapper>;

    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceDetails)
