import {HOST_MEDIA_URL} from "../redux/api";
import defaultLogo from '../assets/images/no_product.png';

export function generateImageMediaUrl(path) {
    if (path?.startsWith('http')){
        return path;
    }
    return path ? `${HOST_MEDIA_URL}/images${path}` : defaultLogo;
}

export function generateFileMediaUrl(path) {
    if (path?.startsWith('http')){
        return path;
    }
    return path ? `${HOST_MEDIA_URL}/files${path}` : defaultLogo;
}
