import { STATIC_TEXT_CONSTS} from "../constants";
import {_urlStaticTexts, _urlStaticTextsUpdate} from "../api";
import {request} from "../api";
import swal from "sweetalert";

export const GetStaticTexts = () => {
    const requestData = {
        url: _urlStaticTexts,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res.data;
                data && dispatch({
                    type: STATIC_TEXT_CONSTS.GET_STATIC_TEXTS,
                    payload: res.data
                });
            })
    }
};

export const UpdateStaticTexts = (data, code, key) => {
    const requestData = {
        url:  `${_urlStaticTexts}/${code}.${key}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: STATIC_TEXT_CONSTS.UPDATE_STATIC_TEXTS,
                    payload: {
                        key,
                        code,
                        value: data.value
                    }
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Թարգմանությունը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ"
                });
            })
            .catch(() => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ որ բան այն չէ, Խնդրում ենք  փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};
