// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {history} from "../../configs/history";
import {Icon} from "antd";

// Import styles
import "../../assets/styles/containerStyles/media.scss";

// Import components
import {LinkButton} from "../../components/buttons/buttons";
import {MediaFileCard} from "../../components/media/mediaFileCard";
import {MediaFolderCard} from "../../components/media/mediaFolderCard";
import {InputGroup} from "../../components/uiElements/inputGroup";
import AddFolderModal from '../../components/media/AddFolderModal';
import PreviewModal from '../../components/media/PreviewModal';
import FileUploader from "./fileUploader";
import swal from "sweetalert";
import PageWrapper from "../../components/pageContentViews/pageWrapper";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {getFolderData} from "./utils/helperFunctions";
import {
    AddFolder,
    DeleteFolder,
    DeleteGalleryImage,
    GetGallery,
    UpdateFile,
} from "../../redux/actions";
import {MAX_FILE_SIZE} from "../../constants/acceptedTypes";
import {changeStateInputValue, toggleStateField} from "../../utils/helperFunctions";


class Media extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accessList: {},
            searchValue: '',
            folderList: [],
            draggableFile: null,
            addFolderModalOpen: false,
            previewModalOpen: false,
            previewItem: null,
        };

        this.toggleAddFolderModal = toggleStateField.bind(this, 'addFolderModalOpen');
        this.getSearchValue = changeStateInputValue.bind(this);
        this.addFolder = this.addFolder.bind(this);
        this.openFolder = this.openFolder.bind(this);
        this.deleteFolder = this.deleteFolder.bind(this);
        this.onDropToFolder = this.onDropToFolder.bind(this);
        this.dragFile = this.dragFile.bind(this);
        this.openPreview = this.openPreview.bind(this,);
        this.closePreview = this.closePreview.bind(this,);
    }

    componentDidMount() {
        //console.log('mounted')
        const {location, galleryImages} = this.props;
        const folderList = location && location.state && location.state.folderList;
        const reGetData = location && location.state && location.state.reGetData;
        folderList && history.replace();
        this.props.GetGallery();
        this.setState({
            folderList: folderList ? folderList : []
        })
    }

    deleteFile = (id) => {
        const {folderList} = this.state;
        const currentFolder = folderList && !!folderList.length && folderList[folderList.length - 1];
        const pathList = currentFolder && currentFolder.path && currentFolder.path.split('/').slice(1);

        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել ֆայլը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteGalleryImage(id, pathList);
            }
        });
    };

    deleteFolder = (id) => {
        const {folderList} = this.state;
        const currentFolder = folderList && !!folderList.length && folderList[folderList.length - 1];
        const pathList = currentFolder && currentFolder.path && currentFolder.path.split('/').slice(1);

        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել թղթապանակը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteFolder(id, pathList)
            }
        });
    };

    addFolder(folderName) {
        const {folderList} = this.state;
        const currentFolder = folderList && !!folderList.length && folderList[folderList.length - 1];
        const pathList = currentFolder && currentFolder.path && currentFolder.path.split('/').slice(1);
        const reqData = {
            name: folderName,
        };
        (currentFolder && currentFolder.id) && (reqData.parent = currentFolder.id);
        (currentFolder && currentFolder.path) && (reqData.parentPath = currentFolder.path);
        //console.log(folderName)
        this.props.AddFolder(reqData, pathList)
            .catch((error) => {
                if (error && error.response && error.response.status === 409) {
                    swal({
                        title: "Զգուշացում!",
                        text: "Այս անունով թղթապանակ արդեն գոյություն ունի",
                        icon: "warning",
                        button: "Լավ"
                    });
                } else {
                }
            })
    }

    openFolder(folderData) {
        this.setState({
            folderList: [...this.state.folderList, folderData]
        })
    }

    changeFolder = (id) => {
        const {folderList} = this.state;
        if (id === null) {
            this.setState({
                folderList: []
            })
        } else {
            const newList = [];
            folderList.some(f => {
                newList.push(f);
                return f.id === id
            });
            this.setState({
                folderList: newList
            })
        }
    };


    onDropToFolder(folder) {
        //console.log('on-drop')
        const {draggableFile} = this.state;
        if (draggableFile && draggableFile.id !== folder.id) {
            const reqData = {
                parentPath: folder.path || '/',
                name: draggableFile.name,
                parent: folder.id || null,
            };
            // console.log("reqData", reqData);
            this.props.UpdateFile(draggableFile.id, reqData, draggableFile.type).then(() => {
                this.props.GetGallery();
            }).catch((error) => {
                if (error && error.response && error.response.status === 409) {

                } else {

                }
            })
        }
    }


    dragFile(draggableFile) {
        this.setState({
            draggableFile,
        })
    }

    openPreview(item) {
        this.setState({
            previewItem: item,
            previewModalOpen: true
        })
    }

    closePreview() {
        this.setState({
            previewItem: null,
            previewModalOpen: false
        })
    }

    render() {
        const {galleryImages} = this.props;
        const {searchValue, folderList, addFolderModalOpen, previewModalOpen, previewItem} = this.state;
        let itemsList = galleryImages ? galleryImages.itemsList : [];
        let currentFolder = null;
        let pathList = [];
        //console.log('folderList.length', folderList.length);
        if (folderList && folderList.length) {
            currentFolder = folderList[folderList.length - 1];
            pathList = currentFolder && currentFolder.path && currentFolder.path.split('/').slice(1);
            console.log("pathList", pathList);
            console.log("currentFolder", currentFolder);
            // console.log('folderData', folderData);
            itemsList = getFolderData(itemsList, pathList);
        }
        if (searchValue) {
            itemsList = itemsList.filter((item => item?.name?.toLowerCase().includes(searchValue?.toLowerCase())))
        }
        return <PageWrapper>
            <div className={`admin-gallery`}>
                <h1 className="page-title">
                        <span className={'path-item'}
                              onClick={() => this.changeFolder(null)}
                              onDragOver={(e) => currentFolder && e.preventDefault()}
                              onDrop={() => currentFolder && this.onDropToFolder({})}
                        >
                        Ֆայլեր
                    </span>
                    {
                        folderList && !!folderList.length && folderList.map((folder, index) => {
                            const notLastFolderInPath = folderList.length !== index + 1;
                            return <>
                                <span> / </span>
                                <span className={'path-item'}
                                      onClick={() => notLastFolderInPath && this.changeFolder(folder.id)}
                                      onDragOver={(e) => notLastFolderInPath && e.preventDefault()}
                                      onDrop={() => notLastFolderInPath && this.onDropToFolder(folder)}>
                                        {folder.name}
                                    </span>
                            </>
                        })
                    }
                </h1>
                <section>
                    <div className={'image-action-buttons'}>
                        <div className={'add-buttons-wrapper'}>
                            <FileUploader maxFileSize={MAX_FILE_SIZE}
                                          currentFolder={currentFolder}/>
                            <LinkButton title="Ավելացնել Թղթապանակ"
                                        cb={this.toggleAddFolderModal}/>
                        </div>
                        <InputGroup
                            inputType={"input"}
                            type={'text'}
                            value={searchValue}
                            placeholder={'Որոնել'}
                            name={'searchValue'}
                            onChange={this.getSearchValue}>
                            <div className={'search-icon'}>
                                <Icon type={"search"}/>
                            </div>
                        </InputGroup>
                    </div>
                    <div className={'gallery-wrapper'}>
                        {itemsList && !!itemsList.length && itemsList.map((item) => {
                            return item.type === "FOLDER"
                                ? <MediaFolderCard key={item.id}
                                                   deleteMedia={this.deleteFolder}
                                                   openFolder={this.openFolder}
                                                   withEdit={true}
                                                   draggable={true}
                                                   dragFile={this.dragFile}
                                                   onDrop={this.onDropToFolder}
                                                   folderList={folderList}
                                                   item={item}/>
                                :
                                <MediaFileCard key={item.id}
                                               deleteMedia={this.deleteFile}
                                               inModal={false}
                                               withEdit={true}
                                               withCopy={true}
                                               draggable={true}
                                               onDoubleClick={this.openPreview}
                                               dragFile={this.dragFile}
                                               folderList={folderList}
                                               item={item}/>
                        })}
                    </div>
                </section>
                <PreviewModal visible={previewModalOpen}
                              data={previewItem}
                              closeModal={this.closePreview}/>
                <AddFolderModal visible={addFolderModalOpen}
                                addFolder={this.addFolder}
                                closeModal={this.toggleAddFolderModal}/>
            </div>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetGallery,
    AddFolder,
    UpdateFile,
    DeleteGalleryImage,
    DeleteFolder,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Media));
