// Import packages
import React, {Component} from "react";

// Import Styles
import '../assets/styles/components/items-list.scss';
import noDataImage from '../assets/images/ic_empty.svg';

// Import components
import InfiniteScroll from 'react-infinite-scroller';
import ListItemCard from "./cards/ListItemCard";
import {SkeletonItem} from "./uiElements/SkelatonItem";
import {Checkbox} from "antd";

class ItemsList extends Component {

    constructor(props) {
        super(props);
        this.columnsWidth = {};
        this.listWrapperRef = React.createRef();
        this.mouseDown = false;
        this.currentColIndex = null;
        this.currentItemPosX = 0;
        const {generalInfo} = props.itemListHeaderInfo || {};
        for (let i = 0; i < generalInfo.length; i++) {
            this['controlRef_' + i] = React.createRef();
        }
    }

    componentDidMount() {
        const {generalInfo, actions, status, minWidth} = this.props.itemListHeaderInfo || {};
        this.columnsWidth = {};

        const itemsCount = generalInfo?.length;
        let contentWidth = this.listWrapperRef.current.clientWidth
        if(minWidth && contentWidth < minWidth) {
            contentWidth = minWidth
        }
        let itemsLength = (contentWidth - 20 - (status ? 140 : 0) - (actions && actions.length ? 150 : 0) ) / itemsCount;
        for (let i = 0; i < itemsCount; i++) {
            this.columnsWidth[i] = itemsLength;
            this['controlRef_' + i].current.style.left = itemsLength - 20 + 'px';
        }
        this.forceUpdate()
    }

    onMouseDown = (index, event) => {
        if (event.clientX) {
            const elem = this['controlRef_' + index].current;
            this.mouseDown = true;
            this.currentColIndex = index;
            this.currentItemPosX = elem.offsetLeft - event.clientX;
        }
    };

    onMouseUp = () => {
        this.mouseDown = false;
        this.currentColIndex = null;
        this.currentItemPosX = 0;
    };

    onMouseLeave = () => {
        if (this.mouseDown) {
            this.mouseDown = false;
            this.currentColIndex = null;
            this.currentItemPosX = 0;
        }
    };

    onMouseMove = (index, event) => {
        if (this.mouseDown && event.clientX && this.currentColIndex !== null) {
            const newPosition = event.clientX + this.currentItemPosX;
            if (newPosition > 20) {
                this['controlRef_' + this.currentColIndex].current.style.left = newPosition + 'px';
                this.columnsWidth = {
                    ...this.columnsWidth,
                    [this.currentColIndex]: newPosition + 20
                }
                this.setState({});
            }
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {generalInfo, actions, status, minWidth} = this.props.itemListHeaderInfo || {};

        if(prevProps.id !== this.props.id) {
            this.columnsWidth = {};
            const itemsCount = generalInfo?.length;
            let contentWidth = this.listWrapperRef.current.clientWidth
            if (minWidth && contentWidth < minWidth) {
                contentWidth = minWidth
            }
            let itemsLength = (contentWidth - 20 - (status ? 140 : 0) - (actions && actions.length ? 150 : 0)) / itemsCount;
            for (let i = 0; i < itemsCount; i++) {
                this.columnsWidth[i] = itemsLength;
                this['controlRef_' + i].current.style.left = itemsLength - 20 + 'px';
            }
            this.forceUpdate()
        }
    }

    render() {
        const {
            itemListInfo, remove, details, detailsLink, editLink, selectingActive, allSelected,
            infiniteScroll, hasMore, useWindow, loading, className
        } = this.props;

        const {generalInfo, actions, status} = this.props.itemListHeaderInfo || {};

        let wrapperWidth = 0;
        Object.keys(this.columnsWidth).forEach(key => wrapperWidth += this.columnsWidth[key]);
        status && (wrapperWidth += 140);
        actions && (wrapperWidth += 170);
        return <div className={`items-list-wrapper ${className ? className : ""}`}
                    ref={this.listWrapperRef}>
            {generalInfo &&
            <div className={`items-list-header`}
                 style={{width: wrapperWidth}}
            >
                {selectingActive && <Checkbox checked={allSelected}
                                              onChange={this.props.selectAll}/>}
                <div className="general-info"
                     onMouseLeave={this.onMouseLeave}>
                    {!!generalInfo?.length && generalInfo.map((item, index) => {
                        const width = this.columnsWidth[index] + 'px';
                        return <div className={"general-info-item"}
                                    draggable={false}
                                    style={{width: width}}
                                    onMouseUp={this.onMouseUp.bind(this, index)}
                                    onMouseMove={this.onMouseMove.bind(this, index)}
                                    key={index}>
                            <span className={'title'} draggable={false}>{item}</span>
                            <div className={'resizing-control'} draggable={false}
                                 ref={this['controlRef_' + index]}
                                 onMouseDown={this.onMouseDown.bind(this, index)}>
                                <span className={'resize-handle-line'}/>
                                <span className={'resize-handle-line'}/>
                            </div>
                        </div>
                    })}
                </div>
                <div className="items-header-actions-part">
                    {status && <div className="status">Կարգավիճակ</div>}
                    {actions && !!actions?.length &&
                    <div className="actions"> Գործողություններ</div>}
                </div>
            </div>}
            {loading && (!itemListInfo || !itemListInfo?.length)
                ? <div className={'items-list-content'}>
                    <SkeletonItem/>
                </div>
                : itemListInfo && !!itemListInfo?.length ? (infiniteScroll ? <InfiniteScroll
                        hasMore={hasMore}
                        loadMore={this.props.loadMoreItems}
                        className={'items-list-content'}
                        pageStart={0}
                        useWindow={!!useWindow}
                        initialLoad={false}>
                        {itemListInfo.map((item) => {
                            return <ListItemCard key={item.id}
                                                 data={item}
                                                 status={status}
                                                 actions={actions}
                                                 selectingActive={selectingActive}
                                                 columnsWidth={this.columnsWidth}
                                                 width={wrapperWidth}
                                                 remove={remove}
                                                 details={details}
                                                 detailsLink={detailsLink}
                                                 editLink={editLink}/>

                        })}
                    </InfiniteScroll>
                    :
                    <div className={'items-list-content'}>
                        {itemListInfo.map((item) => {
                            return <ListItemCard key={item.id}
                                                 data={item}
                                                 status={status}
                                                 actions={actions}
                                                 selectingActive={selectingActive}
                                                 columnsWidth={this.columnsWidth}
                                                 width={wrapperWidth}
                                                 remove={remove}
                                                 details={details}
                                                 detailsLink={detailsLink}
                                                 editLink={editLink}/>

                        })}
                    </div>) :
                    <div className={'no-items'}>
                        <img src={noDataImage} alt={'no-items'}/>
                    </div>}
        </div>
    }
}

export default ItemsList
