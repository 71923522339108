// Import packages
import React, { Component } from "react";
import { Select } from "antd";

// Import Styles
import '../../assets/styles/cards/balance-editing-card.scss';

// Import components
import { InputGroup } from "../uiElements/inputGroup";
import { LinkButton } from "../buttons/buttons";
import { generateImageMediaUrl } from "../../utils/generateMemberMediaUrl";
import swal from "sweetalert";
import { truncNumber } from "../../utils/helperFunctions";

// Import utils

export default class BalanceEditingCard extends Component {
    constructor(){
        super();
        this.state = {
            price: "",
            count: "",
            amount: "",
            selectedFuel: null
        };

        this.getInputValues = this.getInputValues.bind(this);
        this.getInputAmount = this.getInputAmount.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        let { fuelList } = this.props;
        fuelList && this.setState({
            selectedFuel: fuelList?.[0]?.id
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        let { fuelList } = this.props;
        if ( fuelList?.length && !prevProps?.fuelList?.length ) {
            fuelList && this.setState({
                selectedFuel: fuelList?.[0]?.id
            })
        }
    }


    handleChange(value){
        const newState = {
            selectedFuel: value,
        };
        if ( value === 'amount' ) {
            newState.amount = '';
            newState.count = '';
            newState.price = '';
        }
        this.setState(newState, () =>
            value !== 'amount' && this.getInputValues({ name: 'count', value: this.state.count }
            ));
    }

    getInputValues({ name, value }){
        let { fuelList, inCompanyDetails } = this.props;
        let { selectedFuel } = this.state;
        if ( !value ) {
            this.setState({
                ...this.state,
                price: "",
                count: "",
            });
            return;
        }
        if ( !Number(value) || value < 0 ) {
            return;
        }
        if ( value && value.includes('.') && (value.length - value.indexOf('.') > 3) ) {
            return;
        }

        const currentFuel = fuelList?.find(fuel => fuel.id === selectedFuel);
        const calculateName = name === 'price' ? 'count' : 'price';
        const _price = inCompanyDetails ? currentFuel?.companyPrice : currentFuel?.price
        const calculateValue = name === 'price'
            ? Number(value / _price)
            : Number(value * _price);
        this.setState({
            ...this.state,
            [name]: value,
            [calculateName]: truncNumber(isFinite(calculateValue) ? calculateValue : 0),
            amount: ''
        });
    }

    getInputAmount({ value }){
        this.setState({
            amount: value,
            price: "",
            count: "",
        });
    }

    changeBalance(sign){
        let { selectedFuel, count, amount } = this.state;
        const { data, inCompanyDetails } = this.props;
        if ( inCompanyDetails && amount ) {
            this.props.editBalance(this.props.id, null, amount).then(() => {
                this.setState({
                    price: "",
                    count: "",
                    amount: ''
                })
            })
            return;
        }
        if ( sign < 0 ) {
            const changingBalanceAmount = data?.find(balance => balance?.fuel?.id === selectedFuel)?.amount;
            //console.log('changingBalanceAmount',changingBalanceAmount);
            //console.log('count',count);
            if ( !changingBalanceAmount || changingBalanceAmount - count < 0 ) {
                swal({
                    title: "Զգուշացում!",
                    text: "Բալանսի մնացորդը չի բավարարում գործողությունը կատարելու համար",
                    icon: "info",
                    button: "Լավ",
                });
                return;
            }
        }
        this.props.editBalance(this.props.id, selectedFuel, count * sign).then(() => {
            this.setState({
                price: "",
                count: "",
            })
        }).catch((error) => {
            this.props.getData && this.props.getData(this.props.id);
            if ( error && error.response && error.response.status === 403 ) {
                swal({
                    title: "Զգուշացում!",
                    text: "Բալանսի մնացորդը չի բավարարում գործողությունը կատարելու համար",
                    icon: "info",
                    button: "Լավ",
                });
            }
        })
    }

    render(){
        const { price, count, amount, selectedFuel, } = this.state;
        let { fuelList, mainLanguage, inCompanyDetails } = this.props;
        return <div className="balance-editing-box">
            <div className="up-part">
                <Select value={selectedFuel}
                        onChange={this.handleChange}
                        dropdownClassName={"fuels-dropdown"}
                        suffixIcon={<img src={require("../../assets/images/arrow_select.svg")} alt=""/>}>
                    {inCompanyDetails && <Select.Option key={'amount'} value={'amount'}>
                        <img src={require('../../assets/images/logo.svg')} alt=""/>
                        <div className="fuel-info-text">
                            <div className="name">Բալանս</div>
                        </div>
                    </Select.Option>}
                    {fuelList && !!fuelList.length && fuelList.map((item) => {
                        const mainTr = item?.translations?.find(tr => tr?.language === mainLanguage);
                        // console.log('mainLanguage', mainLanguage);
                        return <Select.Option key={item.id} value={item.id}>
                            <img src={generateImageMediaUrl(item?.iconPath?.path)} alt=""/>
                            <div className="fuel-info-text">
                                <div className="name">{mainTr?.title}</div>
                                <div className="price">{inCompanyDetails ? item?.companyPrice : item?.price} Դ</div>
                            </div>
                        </Select.Option>
                    })}
                </Select>
            </div>
            <div className="down-part">
                <div className="fuel-inputs">
                    <InputGroup inputType="input"
                                type="text"
                                placeholder="Քանակ"
                                name="count"
                                disabled={selectedFuel === 'amount'}
                                value={count}
                                maxLength={256}
                                onChange={this.getInputValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                placeholder="Գումար"
                                name="price"
                                value={selectedFuel === 'amount' ? amount : price}
                                maxLength={256}
                                onChange={selectedFuel === 'amount' ? this.getInputAmount : this.getInputValues}/>

                </div>
                <div className="fuel-buttons">
                    <LinkButton className="bg-red"
                                title={"Հեռացնել"}
                                disabled={!count || selectedFuel === 'amount'}
                                cb={this.changeBalance.bind(this, -1)}/>
                    <LinkButton title={"Ավելացնել"}
                                disabled={selectedFuel === 'amount' ? !amount : !count}
                                cb={this.changeBalance.bind(this, 1)}/>
                </div>
            </div>
        </div>
    }
}
