// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/networks.scss';

// Import components
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetNetworkSupervisor, DeleteNetworkSupervisor} from "../../redux/actions";
import swal from "sweetalert";

class Supervisor extends Component {

    componentDidMount() {
        this.props.GetNetworkSupervisor(this.props.networkId);
    }

    deleteNetwork = id => {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել պատասխանատուին?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteNetworkSupervisor(this.props.networkId, id);
            }
        });
    };


    render() {
        const {requestLoading, supervisors, networkId} = this.props;

        let itemListHeaderInfo = {
            generalInfo: ["Անուն", 'Ազգանուն', 'Էլ․ հասցե'],
            largeItems: [2, 3],
            status: true,
            actions: ["edit", "delete"],
        };

        let itemListInfo = supervisors?.map((item) => {
            return {
                id: item.id,
                values: [
                    item.firstName  ?? '-',
                    item.lastName  ?? '-',
                    item.username ?? '-',],
                status: !item.isHidden,
                deleteCb: this.deleteNetwork.bind(this, item.id)
            }
        });
        return <section className="networks-supervisor">
                <PageHeader pageTitle={'Պատասխանատուներ'}
                            linkTitle={"Ավելացնել"}
                            addingLink={`/networks/${networkId}/supervisors/add`}/>
                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={itemListInfo}
                           loading={requestLoading}
                           detailsLink={`networks/${networkId}/supervisors/edit`}
                           editLink={`/networks/${networkId}/supervisors/edit`}/>
            </section>
    }
}

const mapDispatchToProps = {
    GetNetworkSupervisor,
    DeleteNetworkSupervisor
};

export default connect(mapStateToProps, mapDispatchToProps)(Supervisor)
