export const COMPANY_CONSTS = {
    GET_COMPANIES: "GET_COMPANIES",
    GET_COMPANY_BY_ID: "GET_COMPANY_BY_ID",
    GET_COMPANY_USERS: 'GET_COMPANY_USERS',
    ADD_COMPANY: "ADD_COMPANY",
    EDIT_COMPANY: "EDIT_COMPANY",
    EDIT_COMPANY_BALANCE: "EDIT_COMPANY_BALANCE",
    DELETE_COMPANY: "DELETE_COMPANY",
    CLEAR_SINGLE_COMPANY_DATA: 'CLEAR_SINGLE_COMPANY_DATA',
    CLEAR_COMPANY_USERS: 'CLEAR_COMPANY_USERS',
};
