import moment from "moment";

export function concatDate(date, time, type){

    if (!date && !time) {
        return null;
    }
    !date && (date = moment());
    const _firstPart = date.format("YYYY-MM-DD");
    const dt = type === 'start' ? '00:00:00' : '23:59:59'
    const _lastPart = time ? time.format("HH:mm:ss") : dt;

    // console.log('_firstPart', _firstPart)
    // console.log('_lastPart', _lastPart);

    const t = new Date(`${_firstPart}T${_lastPart}`).toISOString()
    // console.log('t', t)
    return t
}
