export const STATION_CONSTS = {
    GET_STATIONS: "GET_STATIONS",
    GET_STATION_BY_ID: "GET_STATION_BY_ID",
    ADD_STATION: "ADD_STATION",
    EDIT_STATION: "EDIT_STATION",
    DELETE_STATION: "DELETE_STATION",


    GET_STATION_NETWORKS: "GET_STATION_NETWORKS",
    GET_STATION_NETWORK_BY_ID: "GET_STATION_NETWORK_BY_ID",
    ADD_STATION_NETWORK: "ADD_STATION_NETWORK",
    EDIT_STATION_NETWORK: "EDIT_STATION_NETWORK",
    DELETE_STATION_NETWORK: "DELETE_STATION_NETWORK",
    GET_REMAINING_FUELS: "GET_REMAINING_FUELS",

    GET_STATION_NETWORK_SUPERVISORS: "GET_STATION_NETWORK_SUPERVISORS",
    ADD_STATION_NETWORK_SUPERVISOR: "ADD_STATION_NETWORK_SUPERVISOR",
    DELETE_STATION_NETWORK_SUPERVISOR: "DELETE_STATION_NETWORK_SUPERVISOR",
    EDIT_STATION_NETWORK_SUPERVISOR: "EDIT_STATION_NETWORK_SUPERVISOR",

    GET_TRANSFERS_REQUEST: "GET_TRANSFERS_REQUEST",
    UPDATE_SALE_FUEL: "UPDATE_SALE_FUEL",
    ADD_PAID_AMOUNT: "ADD_PAID_AMOUNT",

    GET_SALE_FUEL_REQUEST: "GET_SALE_FUEL_REQUEST",
    GET_PAID_AMOUNT_REQUEST: "GET_PAID_AMOUNT_REQUEST",
};
