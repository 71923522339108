// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {hyAm} from "../../constants/datePikerLocal-HY";
import 'moment/locale/hy-am';

// Import Styles
import '../../assets/styles/containerStyles/transfers.scss';
import noDataImage from '../../assets/images/ic_empty.svg';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import ItemsList from "../../components/ItemsList";
import {DatePicker, Select} from "antd";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";

// Import utils
import {history} from "../../configs/history";
import {MEASUREMENT_TYPES, STATION_NETWORK_SALES_TYPES} from "../../constants/constTypes";
import {stationsHeaderSaleInfo, stationsHeaderTransferInfo, tabs} from "./utils/constants";
import {
    GetFuels,
    GetTransferRequests,
    UpdateSaleFuel
} from "../../redux/actions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    disabledEndDate,
    disabledStartDate,
    changeStateField, truncNumber
} from "../../utils/helperFunctions";
import {CustomTabs} from "../../components/uiElements/Tabs";
import {
    parseStationHistorySaleData,
    parseStationHistoryTransfersData,
} from "../transfers/utils/actionFunctions";
import DownloadTransfersExcel from "../../components/excel/DownloadTransfersExcel";
import TransferModal from "../../components/TransferModal";
import PageHeader from "../../components/pageContentViews/pageHeader";
import moment from "moment";


const dateFormat = 'DD.MM.YYYY';

class Transfers extends Component {
    constructor() {
        super();
        this.state = {
            activeTab: tabs[0].key,
            openTransferModal: false,
        };

        this.changeTab = changeStateField.bind(this, "activeTab");
        this.searchItems = this.searchItems.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this,);
        this.getInputValues = this.getInputValues.bind(this);
        this.toggleTransferModal = this.toggleTransferModal.bind(this);
        this.statusOptions = Object.keys(STATION_NETWORK_SALES_TYPES).map(key => ({
            id: key,
            name: STATION_NETWORK_SALES_TYPES[key]
        }))
    }

    async componentDidMount() {
        if (this.props.isAdmin) {
            this.props.GetTransferRequests({
                transferType: tabs[0].transferType,
                key: tabs[0].key
            });
            this.props.GetTransferRequests({
                transferType: tabs[1].transferType,
                key: tabs[1].key,
            });

            this.fuelOptions = this.props?.fuelList.filter(item => !item.isHidden).map(fuel => ({
                id: fuel.id,
                name: fuel.translations.find(lg => lg.language === this.props?.mainLanguage).title
            }));
            this.networksOptions = this.props.networks?.map(network => ({
                id: network.id,
                name: network?.translations?.find(tr => tr?.language === this.props.mainLanguage)?.title
            }))
        } else {
            history.push('/dashboard');
        }
    }

    getInputValues({name, value}) {
        this.searchItems({[name]: value})
    }

    searchItems(updatedData = {}) {
        const {activeTab} = this.state;
        const currentHistory = this.props[activeTab]
        const {status, type, stationNetworkId, startDate, endDate} = currentHistory;
        let filterData = {
            key: activeTab,
            status, type, stationNetworkId, startDate, endDate, ...updatedData,
        };
        filterData.transferType = tabs[this.state.activeTab === 'saleRequestsList' ? 0 : 1].transferType;
        this.props.GetTransferRequests(filterData);
    }

    loadMoreItems() {
        const {activeTab} = this.state;
        const historyData = this.props[activeTab];
        const {saleRequestsList} = this.props;

        historyData.hasMore && this.props.GetTransferRequests({
            key: activeTab,
            reset: false,
            offset: saleRequestsList.itemsList.length,
            status: historyData.status,
            startDate: historyData.startDate,
            endDate: historyData.endDate,
            type: historyData.type,
            stationNetworkId: historyData.stationNetworkId,
            transferType: tabs[activeTab === 'saleRequestsList' ? 0 : 1].transferType,
        });
    };

    handleChangeData(name, value) {
        this.getInputValues({name, value})
    }

    toggleTransferModal = () => {
        this.setState({
            openTransferModal: !this.state.openTransferModal
        })
    };

    render() {
        const {
            requestLoading, paidAmountList, saleRequestsList, fuelList, mainLanguage
        } = this.props;
        const {openTransferModal, activeTab} = this.state;
        const currentHistory = this.props[activeTab];
        const hasMore = currentHistory?.hasMore;
        const {
            startDate,
            endDate,
            type,
            status,
            stationNetworkId,
            fuelsSum,
            operationsQuantity,
            operationsSum
        } = currentHistory || {};

        let headerInfo, itemsInfo, className;

        switch (activeTab) {
            case tabs[0].key:
                headerInfo = stationsHeaderSaleInfo;
                itemsInfo = saleRequestsList?.itemsList?.length ? parseStationHistorySaleData(saleRequestsList, this.props.UpdateSaleFuel) : [];
                className = 'sale-history';
                break;
            case tabs[1].key:
                headerInfo = stationsHeaderTransferInfo;
                itemsInfo = parseStationHistoryTransfersData(paidAmountList);
                className = 'transfers-history';
                break;
        }
        return <PageWrapper withActions={true}>
            <section className="transfer">
                <PageHeader pageTitle={'Փոխանցումներ'}/>
                <div className="filters-result">
                    <span>{operationsQuantity &&
                    <span className={"amount"}>{operationsQuantity ?? 0}</span>} գործարք &nbsp;</span>
                    {fuelsSum && !!fuelsSum.length && <div className={'fuels-sum-wrapper'}>
                        {fuelList?.map((item) => {
                            const balance = fuelsSum?.find(balance => balance?.fuel?.id === item?.id);
                            const balanceTitle = item?.translations?.find(tr => tr.language === mainLanguage)?.shortName ?? '-'
                            const amount = truncNumber(balance?.amount);
                            return <div className="fuel-item" key={item?.id}>
                                <span>{balanceTitle}</span>
                                <span className={'sum'}>
                                    {amount ?? 0} {MEASUREMENT_TYPES[item?.measurementUnit]}
                                </span>
                            </div>
                        })}
                    </div>
                    }
                    <span>{operationsSum &&
                    <span className={"amount"}>&nbsp; {Number(operationsSum) || 0}</span>} դր․</span>
                </div>
                <div className={'filter-wrapper'}>
                    <LinkButton title={"Փոխանցում"}
                                className={'add-button'}
                                cb={this.toggleTransferModal}/>

                    <div className={'selects-wrapper'}>
                        <InputGroup inputType="selectCustom"
                                    placeholder="Կարգավիճակ"
                                    name="status"
                                    showSearch={false}
                                    value={status || undefined}
                                    onChange={this.getInputValues}
                                    options={this.statusOptions}>
                            <Select.Option value={undefined}>{'Բոլորը'}</Select.Option>
                        </InputGroup>
                        <InputGroup inputType="selectCustom"
                                    placeholder="Ցանց"
                                    name="stationNetworkId"
                                    showSearch={false}
                                    value={stationNetworkId || undefined}
                                    onChange={this.getInputValues}
                                    options={this.networksOptions}>
                            <Select.Option value={undefined}>{'Բոլորը'}</Select.Option>
                        </InputGroup>
                        <InputGroup inputType="selectCustom"
                                    placeholder="Տեսակ"
                                    name="type"
                                    disabled={activeTab === tabs[1].key}
                                    showSearch={false}
                                    value={type || undefined}
                                    onChange={this.getInputValues}
                                    options={this.fuelOptions}>
                            <Select.Option value={undefined}>{'Բոլորը'}</Select.Option>
                        </InputGroup>
                    </div>
                    <div className={'down-part'}>
                        <div className="transfer-filters-date-part">
                            <InputGroup inputType={"wrapper"}>
                                <DatePicker
                                    value={startDate ? moment(startDate) : undefined}
                                    locale={hyAm}
                                    format={dateFormat}
                                    showToday={true}
                                    allowClear={true}
                                    disabledDate={(date) => disabledStartDate(date, endDate)}
                                    placeholder="Սկիզբ"
                                    className={`date-picker`}
                                    onChange={(value) =>
                                        this.handleChangeData('startDate', value)}>
                                </DatePicker>
                            </InputGroup>

                            <InputGroup inputType={"wrapper"}>
                                <DatePicker
                                    value={endDate ? moment(endDate) : undefined}
                                    locale={hyAm}
                                    format={dateFormat}
                                    showToday={true}
                                    allowClear={true}
                                    disabledDate={(date) => disabledEndDate(date, startDate)}
                                    placeholder="Ավարտ"
                                    className={`date-picker`}
                                    onChange={(value) =>
                                        this.handleChangeData('endDate', value)}>
                                </DatePicker>
                            </InputGroup>
                        </div>
                        <div className={'wrapper'}>
                            <DownloadTransfersExcel historyList={currentHistory}
                                                    limit={Number(operationsQuantity)}
                                                    isSale={activeTab === 'saleRequestsList'}
                                                    reqData={activeTab !== 'saleRequestsList' && {
                                                        transferType: tabs[1].transferType,
                                                        key: tabs[1].key
                                                    }}
                                                    GetTransferRequests={this.props.GetTransferRequests}/>
                        </div>
                    </div>
                </div>
                <div className="tabs-wrapper" style={{position: "relative"}}>
                    <CustomTabs changeTab={this.changeTab} activeTab={activeTab} tabsList={tabs}/>
                </div>
                <ItemsList
                    className={className}
                    itemListHeaderInfo={headerInfo}
                    itemListInfo={itemsInfo}
                    noDataImage={noDataImage}
                    loading={requestLoading}
                    infiniteScroll={true}
                    hasMore={hasMore}
                    loadMoreItems={this.loadMoreItems}/>
            </section>
            <TransferModal visible={openTransferModal}
                           closeModal={this.toggleTransferModal}
                           networks={this.props.networks}
                           fuelOptions={this.fuelOptions}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetFuels,
    GetTransferRequests,
    UpdateSaleFuel
};

export default connect(mapStateToProps, mapDispatchToProps)(Transfers)
