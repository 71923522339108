// Import packages
import React, {PureComponent} from "react";

// Import components

// Import styles
import "../../assets/styles/components/uiElements/post-image-uploader.scss";


//import utils
import {hasExtension} from "../../utils/hasExtension";
import { ACCEPT_IMAGE_SIZE} from "../../constants/acceptedTypes";
import {ImageCropper} from "./ImageCropper";


export default class PostImageUploader extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imageUrl: "",
            imageError: false,
            errorMessage: "",
            imageExist: true,
            isEditing: false,
            editingImage: null,
            editingImageIndex: null,
        };
        this.imageSelector = React.createRef();
        this.getCropImage = this.getCropImage.bind(this);
        this.closeCrop = this.closeCrop.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.editImage = this.editImage.bind(this);
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Read the image via FileReader API and save image result in state.
            reader.onload = function (e) {
                let dataURL = e.target.result;
                resolve({file, dataURL});
            };
            reader.onerror = function (e) {
                //console.log(e);
                reject(e);
            }
            reader.readAsDataURL(file);
        });
    }

    handleImageChange(e) {
        let file = e && e.target && e.target.files && e.target.files[0];
       // console.log('selected-file', file);
        if (this.props.images?.length >= 8){
            this.setState({
                imageError: true,
                errorMessage: 'Max. 8 medias allowed!'
            });
            return;
        }
        if (file) {
            if (!hasExtension(file.name)) {
                this.setState({
                    imageError: true,
                    errorMessage: 'Not Supported file format!'
                });
                return;
            }
            if (file.size > ACCEPT_IMAGE_SIZE) {
                this.setState({
                    imageError: true,
                    errorMessage: `File size is too big ${Math.floor(ACCEPT_IMAGE_SIZE / 1048576)}mb`
                });
                return;
            }

            //create url to file with createObjectURL
            // this.setState({
            //     imageUrl: URL.createObjectURL(file),
            //     imageError: false,
            //     errorMessage: "",
            //     imageExist: true,
            // });

            this.readFile(file)
                .then(res => {
                    // console.log(res);
                    this.setState({
                        imageUrl: res.dataURL,
                        imageError: false,
                        errorMessage: "",
                        imageExist: true,
                    })
                });
        }
    }

    editImage(editingImage, index) {
        this.setState({
            imageUrl: editingImage.imageUrl,
            isEditing: true,
            editingImage: editingImage,
            editingImageIndex: index
        });
    }

    getCropImage(croppedImage) {
        const {isEditing, editingImageIndex} = this.state;
        let cropImgList = [...this.props.images];
        if (isEditing) {
            cropImgList[editingImageIndex] = croppedImage;
        } else {
            cropImgList.push(croppedImage);
        }

        this.setState({
            imageUrl: "",
            isEditing: false,
            editingImage: null,
            editingImageIndex: null
        });
        this.props.getImages(cropImgList)
    }

    deleteImage(position, id) {
        const {mainImageIndex, productIsEditing} = this.props;
        let cropImgList = this.props.images.filter((_, index) => index !== position);
        this.props.getImages(cropImgList);
        position <= mainImageIndex && mainImageIndex > 0 && this.props.changeMainImage(mainImageIndex - 1);
        productIsEditing && id && this.props.imageDeleted(id);
        this.setState({
            imageError: false,
            errorMessage: "",
        })
    }

    closeCrop() {
        this.setState({
            imageUrl: ''
        })
    }


    render() {
        let {imageUrl, editingImage, isEditing, errorMessage} = this.state;
        const {images, mainImageIndex, productIsEditing, error, aspect} = this.props;
        return <div className={`post-image-uploader`}>
            <ImageCropper
                imageUrl={imageUrl}
                isEditing={isEditing}
                editingImage={editingImage}
                aspect={aspect}
                getCropImage={this.getCropImage}
                closeCrop={this.closeCrop}
            />

            <div className={'crop-img-wrapper'}>
                {
                    !!images?.length && images.map((item, index) => {
                        return <div className="crop-img-content" key={index}>
                            <button
                                className={'btn delete-btn'}
                                onClick={() => {
                                    this.deleteImage(index, item.id)
                                }}>
                                <img src={require("../../assets/images/ic_close.svg")} alt=""/>
                            </button>
                            {!item.id && <button
                                className={'btn edit-btn'}
                                onClick={() => {
                                    this.editImage(item, index)
                                }}>
                                <img src={require("../../assets/images/edit.svg")} alt=""/>
                            </button>}
                            {(!productIsEditing || item.id) && <button
                                className={`main-button ${mainImageIndex === index ? 'isMain' : ''}`}
                                onClick={() => {
                                    this.props.changeMainImage(index, item.id);
                                }}>
                                Main
                            </button>}
                            <img src={item?.path} className={'crop-img'}/>
                        </div>
                    })
                }
            </div>

            {!imageUrl && <div className={`crop-img-selector ${error ? 'error-media' : ''}`}
                               onClick={() => this.imageSelector.current.click()}>
                <div className={'upload-box'}>
                    <div className="upload-icon-wrapper">
                        <img src={require("../../assets/images/ic_post_upload.svg")} alt=""/>
                    </div>
                    <div className={'upload-text-wrapper'}>
                        <span>{isEditing ? "Թարմացնել նկարը" : 'Բեռնել նկար'}</span>
                        <div className="img-format">PNG, JPG, JPEG, SVG</div>
                    </div>
                </div>
                <div className={'error-wrapper'}>
                    {errorMessage}
                </div>
            </div>}
            <input type="file" accept="image/*" ref={this.imageSelector}
                   onChange={(e) => this.handleImageChange(e)}/>
        </div>
    }
}
