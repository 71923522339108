import {OFFERS_CONSTS} from "../constants";

export const initialState = {
    repairingList: {
        itemsList: [],
        notSeenCount: 0,
    },
    sparesList: {
        itemsList: [],
        notSeenCount: 0,
    },
    tyreChangeList: {
        itemsList: [],
        notSeenCount: 0,
    },
    // notSeenOffersCount: 0,
};

export default (state = initialState, action) => {
    let key = action?.payload?.type !== 'tyre-change' ? action?.payload?.type+'List' : 'tyreChangeList';

    switch (action.type) {
        case OFFERS_CONSTS.GET_OFFERS:
            return {
                ...state,
                [key]: {
                    ...state[key],
                    itemsList: action.payload.reset ? action.payload.data : [...state[key].itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore
                },
            };
        case OFFERS_CONSTS.GET_NOT_SEEN_OFFERS_COUNT:
          //  console.log(key,  action.payload?.data?.count, 'action');
            return {
                ...state,
                [key]: {
                    ...state[key],
                    notSeenCount: action.payload?.data?.count || 0
                },
            };
        case OFFERS_CONSTS.SET_OFFERS_AS_SEEN:
            const nonSeenCount = state[key].notSeenCount;
            return {
                ...state,
                [key]: {
                    ...state[key],
                    itemsList: state[key].itemsList.map(offer => offer.id === action.payload.id ? {
                        ...offer,
                        seen: true
                    } : offer),
                    notSeenCount: nonSeenCount ? nonSeenCount - 1 : 0
                },
                // notSeenInsuranceCount: state.notSeenInsuranceCount - 1
            };
        default:
            return state;
    }
}
