import {UTIL_CONSTS} from "../constants";

export const toggleNotificationRow = (data) => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.TOGGLE_NOTIFICATION_ROW,
            payload: data
        })
    }
};


export const ToggleLeftSideMenu = () => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.TOGGLE_LEFT_SIDE_MENU
        })
    }
};

export const RedirectFirstTime = () => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.REDIRECT_FIRST_TIME
        })
    }
};