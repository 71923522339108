// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import "../../assets/styles/containerStyles/languages.scss"

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetLanguages} from "../../redux/actions";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";


class Languages extends Component {
    constructor() {
        super();
        this.state = {
            alertDialogOpen: false,
            alertMode: "",
            removingIndex: null
        };
    }

    componentDidMount() {
        const {languages} = this.props;
        !languages?.length && this.props.GetLanguages();
    }

    render() {
        const {languages} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Կոդ", "Անուն"],
            largeItems: [0],
            status: true,
            actions: ["edit"],
        };
        let itemListInfo = [];
        languages && !!languages.length && languages.forEach(language => {
            itemListInfo.push({
                id: language.id,
                values: [language.code, language.name],
                status: !language.isHidden
            })
        });

        return <PageWrapper>
            <PageHeader pageTitle={'Լեզուներ'}
                        search={false}>
            </PageHeader>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       editLink={"/languages/edit-language"}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetLanguages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Languages);
