import { request, _urlHistory, _urlHistoryUpdate } from "../api";
import { CATEGORY_CONSTS, HISTORY_CONSTS, UTIL_CONSTS } from "../constants";
import {LIMIT_GET_HISTORY} from "../../constants/constLimitCounts";
import {tabs} from "../../containers/history/utils/constants";
import swal from "sweetalert";
import { history } from "../../configs/history";
import {concatDate} from "../../utils/concatTime";

export const GetHistory = ({
                               key,
                               offset = 0,
                               limit = LIMIT_GET_HISTORY,
                               reset = true,
                               fuelId = null,
                               text = '',
                               startDate = null,
                               endDate = null,
                               stationId = null,
                               companyId = null,
                               status = null,
                               type = null,
                           } = {}) => {

    let url = `${_urlHistory}/history?offset=${offset}&limit=${limit}`;
    const types = type || tabs.find(tab => tab.key === key)?.types;

    text && (url += `&text=${text}`);
    url += `&types=${Array.isArray(types) ? types.join(',') : type }`;
    fuelId && (url += `&fuelId=${fuelId}`);
    stationId && (url += `&stationId=${stationId}`);
    companyId && (url += `&companyId=${companyId}`);
    status && (url += `&status=${status}`);

    const _startDate = concatDate(startDate, "", "start");
    const _endDate = concatDate(endDate,"", "end");

    _startDate && (url += `&startDate=${_startDate}`);
    _endDate && (url += `&endDate=${_endDate}`);

    if(key === tabs[3].key) {
        url += `&operationType=${tabs[3].operationType}`
    }
    if(key === tabs[2].key) {
        url += `&operationType=${tabs[2].operationType}`
    }
    if(key === tabs[1].key) {
        url += `&operationType=${tabs[1].operationType}`
    }

    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then(async ({data}) => {
                data && await dispatch({
                    type: HISTORY_CONSTS.GET_HISTORY,
                    payload: {
                        key,
                        data: data.histories,
                        operationsSum: data.operationsSum,
                        operationsAmount: data.operationsAmount,
                        operationsQuantity: data.operationsQuantity,
                        fuelsSum: data.fuelsSum,
                        hasMore: data.histories.length === limit,
                        reset,
                        text,
                        startDate,
                        endDate,
                        types,
                        stationId,
                        fuelId,
                        companyId,
                        // stationNetworkId,
                        status,
                    }
                });
                return data
                //console.log(data);
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
}

export const UpdateHistoryById = (id, data, storePayload) => {
    const requestData = {
        url: `${_urlHistoryUpdate}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: HISTORY_CONSTS.UPDATE_HISTORY_BY_ID,
                    payload: { id, data:storePayload }
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Պատմություն հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ",
                })
            })
            .catch(() => {
            })
    }
};

//==================================================================================================


const _GetHistoryById = ({
                             urlPart,
                             actionType,
                             id,
                             offset = 0,
                             limit = LIMIT_GET_HISTORY,
                             reset = true,

                             text = '',
                             startDate = null,
                             endDate = null,
                             type = null,
                             stationId = null,
                             stationNetworkId = null,
                             status = null,
                         } = {}) => {

    let url = `${_urlHistory}/${urlPart}/${id}/history?offset=${offset}&limit=${limit}`;
    text && (url += `&text=${text}`);
    startDate && (url += `&startDate=${startDate?.format("YYYY-MM-DD") + "T00:00:00.000Z"}`);
    endDate && (url += `&endDate=${endDate?.format("YYYY-MM-DD") + "T23:59:59.000Z"}`);
    type && (url += `&types=${type}`);
    stationId && (url += `&stationId=${stationId}`);
    stationNetworkId && (url += `&stationNetworkId=${stationNetworkId}`);
    status && (url += `&status=${status}`);

    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then(async ({data}) => {
                data && await dispatch({
                    type: actionType,
                    payload: {
                        urlPart,
                        data,
                        hasMore: data.length === limit,
                        reset,

                        text,
                        startDate,
                        endDate,
                        type,
                        stationId,
                        stationNetworkId,
                        status,
                    }
                });
                //console.log(data);
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
}


export const GetHistoryByCompanyId = (fields) => {
    // console.log(fields);
    return _GetHistoryById({
        actionType: HISTORY_CONSTS.GET_HISTORY_BY_COMPANY_ID,
        urlPart: 'companies',
        ...fields
    });
};

export const GetHistoryByUserId = (fields) => {
    // console.log(fields);
    return _GetHistoryById({
        actionType: HISTORY_CONSTS.GET_HISTORY_BY_USER_ID,
        urlPart: 'users',
        ...fields
    });
};

export const GetHistoryByStationId = (fields) => {
    // console.log(fields);
    return _GetHistoryById({
        actionType: HISTORY_CONSTS.GET_HISTORY_BY_STATION_ID,
        urlPart: 'stations',
        ...fields
    });
};

//==================================================================================================


export const ClearHistory = () => {
    return dispatch => {
        dispatch({
            type: HISTORY_CONSTS.CLEAR_HISTORY
        })
    }
};
export const ClearByIdHistory = () => {
    return dispatch => {
        dispatch({
            type: HISTORY_CONSTS.CLEAR_BY_ID_HISTORY
        })
    }
};

