import {request, _urlPromoCodes, _urlProducts} from "../api";
import {PRODUCT_CONSTS, PROMO_CODES_CONSTS, UTIL_CONSTS} from "../constants";
import swal from "sweetalert";
import {LIMIT_GET_PROMO_CODES} from "../../constants/constLimitCounts";
import {history} from "../../configs/history";

export const GetPromoCodes = ({
                                  reset = true,
                                  offset = 0,
                                  fuelId = null,
                                  type = null,
                                  userId = null,
                                  status = null,
                                  text = null,
                                  limit = LIMIT_GET_PROMO_CODES,
                              } = {}) => {
    let url = `${_urlPromoCodes}?offset=${offset}&limit=${limit}`;
    type && (url += `&type=${type}`);
    status && (url += `&status=${status}`);
    fuelId && (url += `&fuelId=${fuelId}`);
    userId && (url += `&userId=${userId}`);
    text && (url += `&text=${text}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                data && dispatch({
                    type: PROMO_CODES_CONSTS.GET_PROMO_CODES,
                    payload: {
                        data: data,
                        type,
                        status,
                        fuelId,
                        userId,
                        reset,
                        hasMore: data.length === limit
                    }
                });
                return data
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const AddPromoCode = (data) => {
    const requestData = {
        url: _urlPromoCodes,
        token: true,
        method: "POST",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                swal({
                    title: "Հաջողվեց!",
                    text: "Նվեր քարտը հաջողությամբ ստեղծվեց",
                    icon: "success",
                    button: "Լավ",
                });
                history.push('/promoCodes')
            }).catch((error) => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ-որ բան այն չէ",
                    icon: "warning",
                    button: "Լավ",
                })
            })
    }
};

export const DeletePromoCode = (id) => {
    const requestData = {
        url: `${_urlPromoCodes}/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: PROMO_CODES_CONSTS.DELETE_PROMO_CODE,
                    payload: id
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Նվեր Քարտը հաջողությամբ ջնջվեց",
                    icon: "success",
                    button: "Լավ",
                });
            })
    }
};
