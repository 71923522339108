export const OrderData = [
    {
        id: 1,
        clientName: "Ադամյան Արամ",
        clientEmail: "aramadamyan@gmail.com",
        orderStatus: "Նոր",
        orderPrice: "22000",
        orderDate: "02.02.2020",
    },
    {
        id: 2,
        clientName: "Ադամյան Մարիամ",
        clientEmail: "aramadamyan@gmail.com",
        orderStatus: "Մերժված",
        orderPrice: "22000",
        orderDate: "02.02.2020",
    },
];

export const ServiceData = [
    {
        id: 1,
        serviceImg: "evacuator.png",
        serviceName: "Էվակուատոր",


    },
    {
        id: 2,
        serviceImg: "vulcanization.png",
        serviceName: "Շարժական վուլկանիզացիա",

    },
    {
        id: 2,
        serviceImg: "tires.png",
        serviceName: "Անվադողերի պահեստավորում",

    },
]
