// Import packages
import React, {useEffect, useState} from "react";

// Import Styles
import '../../assets/styles/cards/fuel-card.scss';

// Import utils
import {MaterialIcon} from "../utils/Icon";

// Import components
import {LinkButton} from "../buttons/buttons";
import {generateFileMediaUrl} from "../../utils/generateMemberMediaUrl";

function FuelCard(props) {

    const {data, isEdited, mainLanguage} = props;
    const [windowWidth, updateWidth] = useState(window.innerWidth);
    const mainTr = data?.translations?.find(lg => lg.language === mainLanguage);

    useEffect(() => {
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    function updateSize() {
        updateWidth(window.innerWidth);
    }

    return <div className={"fuel-card"}>
        {!isEdited && data ? <>
                {/*<img className="fuel-img" src={generateFileMediaUrl(data?.iconPath?.path)} alt=""/>*/}
                <div className="fuel-title">{mainTr?.title}</div>
            <div>
                <div className="fuel-price"><span>Վաճ․ գին: </span><span> {data.price}</span></div>
                <div className="fuel-price"><span>Կազմ․ գին: </span><span> {data.companyPrice}</span></div>
            </div>

            </> :
            <LinkButton title={windowWidth > 1200 ? "Փոփոխել" : <MaterialIcon icon="edit"/>}
                        className={'bg-orange uppercase'}
                        link={`/fuels`}/>
        }

    </div>
}

export default FuelCard;
