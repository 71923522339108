// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch} from "antd";


// Import Styles
import '../../assets/styles/containerStyles/story.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {Editor} from "../../components/uiElements/Editor";
import PostImageUploader from "../../components/uiElements/PostImageUploader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    getInputValues,
    getTranslatableInputValues,
    initTranslations,
    changeStateField,
} from "../../utils/helperFunctions";
import {
    AddStory, UpdateStory, GetProductById, AddStoryImage,
    DeleteStoryImage, ChangeStoryMainImage
} from "../../redux/actions";
import {DEFAULT_IMAGE_ID} from "../../constants/constTypes";
import {validateFields} from "./utils/actionFunctions";
import {resizeImage} from "../../utils/resizeImage";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";

class AddEditStory extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'subTitle',
            'description',
        ];
        this.state = {
            fieldsData: {
                images: [],
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                isHidden: false,
                url: undefined,
            },
            storyData: null,
            mainImageIndex: 0,
            deletedImageIds: [],
            loading: false,
            languageTab: props.mainLanguage,
            isEditing: !!props?.id,
            storyId: props?.id,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.validateFields = validateFields.bind(this);
        this.getTempValue = this.getTempValue.bind(this);
        this.addStory = this.addStory.bind(this);
        this.updateProduct = this.updateProduct.bind(this);

        this.getImages = this.getImages.bind(this);
        this.changeMainImage = this.changeMainImage.bind(this);
        this.imageDeleted = this.imageDeleted.bind(this);
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;
        if (isEditing) {
            const productById = this.props?.location?.state?.storyById;
            const translations = {...fieldsData.translations};

            //images list
            const images = [];
            const mediaMain = productById?.medias && productById?.medias?.find(media =>
                media?.isMain === true && media?.id !== DEFAULT_IMAGE_ID);
            if (mediaMain) {
                images[0] = {
                    id: mediaMain?.id,
                    path: resizeImage(generateImageMediaUrl(mediaMain?.path), 100, 100),
                    imageUrl: generateImageMediaUrl(mediaMain?.path),
                }
            }

            if (productById?.medias?.length) {
                productById.medias.forEach(media => {
                    media?.id !== DEFAULT_IMAGE_ID && media?.isMain !== true && images.push({
                        id: media?.id,
                        path: resizeImage(generateImageMediaUrl(media?.path), 100, 100),
                        imageUrl: generateImageMediaUrl(media?.path),
                    })
                })
            }

            productById.translations && productById.translations.forEach(translation => {
                this.translationsFields.forEach(key => {
                    translations[translation.language] &&
                    (translations[translation.language][key] = translation[key]);
                });
            });
            const storyData = {
                images: images,
                isHidden: !!productById?.isHidden,
                url: productById?.url,
                translations: translations
            };

            this.setState({
                fieldsData: {...storyData,},
                storyData: JSON.parse(JSON.stringify(storyData)),
            })
        }

    }

    changeMainImage(mainImageIndex) {
        mainImageIndex ? this.updatedDataMap.set('changeMediaMainAction', '')
            : this.updatedDataMap.delete('changeMediaMainAction');
        this.setState({
            mainImageIndex
        })
    }

    imageDeleted(id) {
        this.updatedDataMap.set('mediaDeleteAction', '');
        const deletedImageIds = [id, ...this.state.deletedImageIds];
        this.setState({
            deletedImageIds,
        })
    }

    getImages(images) {
        const {fieldsData, storyData, isEditing} = this.state;
        if (isEditing) {
            images.length === storyData.images.length ?
                this.updatedDataMap.delete('mediaAddAction') :
                this.updatedDataMap.set('mediaAddAction', '');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                images: images,
            },
        });
        this.errorsSet.delete('images');
    }

    getTempValue({value}) {
        const {storyData, fieldsData, isEditing, languageTab} = this.state;
        const {translations} = fieldsData;
        if (isEditing) {
            const initTr = storyData?.translations?.[languageTab];
            initTr?.description !== value
                ? this.updatedDataMap.set('description_' + languageTab, value)
                : this.updatedDataMap.delete('description_' + languageTab);

        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: {
                    ...translations,
                    [languageTab]: {
                        ...translations[languageTab],
                        description: value
                    }
                }

            }
        })
    }

    async addStory() {
        await this.setState({loading: true});
        const {fieldsData, mainImageIndex} = this.state;
        const {translations} = fieldsData;

        const errors = {
            requiredFields: ["images"],
            translations: ["title", "subTitle", "description"]
        };
        const validationResult = this.validateFields(errors, fieldsData);
        if (validationResult) {
            await this.setState({
                loading: true
            })
            let TR_data = [];
            Object.keys(translations).forEach(key => {
                const reqTrItem = {
                    language: key,
                };
                const trItem = translations[key];
                Object.keys(trItem).forEach(field => {
                    trItem[field] && (reqTrItem[field] = trItem[field]);
                });
                TR_data.push(reqTrItem);
            });

            let formData = new FormData();

            Object.keys(fieldsData).forEach(key => {
                // console.log(key)
                if (key === 'images') {
                    //continue next key;
                } else if (key === 'translations') {
                    formData.append(key, JSON.stringify(TR_data))
                } else if (fieldsData[key]) {
                    const data = fieldsData[key];
                    formData.append(key, data)
                }
            })
            fieldsData.images?.length && formData.append('mediaMain', fieldsData.images[mainImageIndex]?.croppedImageFile);
            if (fieldsData.images?.length > 1) {
                fieldsData.images.forEach((media, index) => {
                    if (index !== mainImageIndex) {
                        formData.append('media', media.croppedImageFile);
                    }
                });
            }
            await this.props.AddStory(formData).finally(() => {
                this.setState({loading: false});
            })
        } else {
            this.setState({loading: false});
        }
    }

    async updateProduct() {
        const {fieldsData, storyData, mainImageIndex, deletedImageIds, storyId} = this.state;
        const {translations} = fieldsData;

        const errors = {
            requiredFields: ["images"],
            translations: ["title", "subTitle", "description"]
        };

        const validationResult = this.validateFields(errors, fieldsData);
        if (validationResult) {
            await this.setState({
                loading: true
            })
            let TR_data = [];
            Object.keys(translations).forEach(key => {
                const reqTrItem = {
                    language: key,
                };
                const trItem = translations[key];
                Object.keys(trItem).forEach(field => {
                    trItem[field] && (reqTrItem[field] = trItem[field]);
                });
                TR_data.push(reqTrItem);
            });
            let reqData = {};

            this.updatedDataMap.delete('mediaAddAction');
            this.updatedDataMap.delete('mediaDeleteAction');
            this.updatedDataMap.delete('changeMediaMainAction');
            this.updatedDataMap.forEach((value, key) => {
                if (this.translationsFields.some(field => key.includes(field))) {
                    reqData.translations = TR_data;
                } else {
                    reqData[key] = value;
                }
            })

            let newImages = fieldsData.images.filter(image => !image.id);
            let uploadedImages = null;
            if (newImages?.length) {
                let formData = new FormData();
                formData.append('servicesAdId', storyId);
                newImages.forEach(media => {
                    formData.append('media', media.croppedImageFile);
                })
                uploadedImages = await AddStoryImage(formData);

            }

            const allOldImagesDeleted = deletedImageIds?.length === storyData?.images?.length;
            deletedImageIds.length && await DeleteStoryImage(storyId, deletedImageIds);

            if (allOldImagesDeleted) {
                uploadedImages && await ChangeStoryMainImage(storyId, uploadedImages[0]?.id);
            } else {
                if (fieldsData?.images?.[mainImageIndex]?.id !== storyData?.images?.[0]?.id) {
                    await ChangeStoryMainImage(storyId, fieldsData?.images?.[mainImageIndex]?.id)
                }
            }

            await this.props.UpdateStory(storyId, reqData).finally(() => {
                this.setState({loading: false});
            });

        } else {
            this.setState({loading: false});
        }
    }

    render() {
        const {storyData, fieldsData, languageTab, isEditing, loading, mainImageIndex} = this.state;
        const {translations} = fieldsData;
        const languageKey = '_' + languageTab;
        const trData = translations[languageTab] || {};
        const initTrData = storyData?.translations[languageTab] || {};

        const checkbox = <div className="right-side">
            <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
            <Switch checked={!fieldsData.isHidden}
                    onChange={(checked) => this.getInputValues({
                        name: 'isHidden',
                        value: !checked,
                        haveChanges: storyData?.isHidden !== !checked
                    })}/>
        </div>
        return <PageWrapper>
            <section className="story-add-edit">
                <PageHeader pageTitle={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'} Գովազդ`} rightTopProps={checkbox}/>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              activeTab={languageTab}
                />
                <div className="story-wrapper">
                    <div className="story-left-part">
                        <div className={`story-image-upload`}>
                            <label>Նկարներ<span>*</span></label>
                            <PostImageUploader noImageError={''}
                                               changeMainImage={this.changeMainImage}
                                               imageDeleted={this.imageDeleted}
                                               getImages={this.getImages}
                                               error={this.errorsSet.has('images')}
                                               mainImageIndex={mainImageIndex}
                                               productIsEditing={isEditing}
                                               // aspect={{x: 375, y: 812}}
                                               images={fieldsData.images}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Անվանում<span
                                            className="field-required">*</span></>}
                                        placeholder="Անվանում"
                                        name="title"
                                        maxLength={100}
                                        value={trData?.title}
                                        initValue={initTrData?.title}
                                        error={this.errorsSet.has('title' + languageKey)}
                                        onChange={this.getTranslatableInputValues}/>
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Ենթավերնագիր<span
                                            className="field-required">*</span></>}
                                        placeholder="Ենթավերնագիր"
                                        name="subTitle"
                                        maxLength={256}
                                        value={trData?.subTitle}
                                        initValue={initTrData?.subTitle}
                                        error={this.errorsSet.has('subTitle' + languageKey)}
                                        onChange={this.getTranslatableInputValues}/>
                            <InputGroup inputType="input"
                                        type="text"
                                        label="Հղում"
                                        placeholder="Հղում"
                                        name="url"
                                        maxLength={1000}
                                        value={fieldsData?.url}
                                        initValue={storyData?.url}
                                        error={this.errorsSet.has('url')}
                                        onChange={this.getInputValues}/>
                        </div>
                    </div>
                    <div className="story-right-part">
                        <div className={`editor-wrapper`}>
                            <label>Նկարագրություն</label>
                            <Editor value={trData?.description || ""}
                                    error={this.errorsSet.has('description' + languageKey)}
                                    onChange={this.getTempValue}/>
                        </div>
                    </div>
                </div>
                <div className="add-story">
                    <LinkButton title={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'}`}
                                loading={loading}
                                disabled={!this.updatedDataMap.size && isEditing}
                                cb={isEditing ? this.updateProduct : this.addStory}/>
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    AddStory,
    UpdateStory,
    GetProductById,
    AddStoryImage,
    DeleteStoryImage,
    ChangeStoryMainImage
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditStory)
