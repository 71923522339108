// Import packages
import React, {Component} from "react";

//import components
import ReactExport from "react-data-export";
import {LinkButton} from "../buttons/buttons";

//import utils
import {BALANCE_TYPES, STATION_TYPES} from "../../constants/constTypes";
import moment from "moment";

//import excel styles
import {headerWidth, headerStyle, oddRowStyle, headerFuelItemWidth} from "../../constants/excelConstStyles";
import {truncNumber} from "../../utils/helperFunctions";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


class DownloadCompaniesExcel extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            loading: false,
        }
        this.excelButton = React.createRef();
    }

    downloadExcel = async () => {
        const {companies} = this.props;
        const {text} = companies;
        await this.setState({loading: true})
        try {
            await this.props.GetCompanies({
                limit: companies?.itemsList?.length,
                text,
            });
            await this.setState({data: companies?.itemsList})
            this.excelButton.current.click();
        } catch (err) {
        }
        this.setState({loading: false, data: []})
    }

    render() {
        const {mainLanguage, companies, fuelList} = this.props;
        const {data, loading} = this.state;

        const emptyRow = [{value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}]
        const downloadedData = !!data?.length && [{
            columns: [
                {title: "Անվանում", width: headerWidth, style: headerStyle},
                {title: "Պատասխանատու", width: headerWidth, style: headerStyle},
                {title: "Հեռ", width: headerWidth, style: headerStyle},
                {title: "Էլ․ հասցե", width: headerWidth, style: headerStyle},
                {title: "Աշխ. քանակ", width: headerWidth, style: headerStyle},
                {title: "Մեք. քանակ", width: headerWidth, style: headerStyle},
                {title: "Գր․ ամսաթիվ", width: headerWidth, style: headerStyle},
                {title: "Հաշիվ", width: headerWidth, style: headerStyle},
            ],
            data: [emptyRow, ...data?.map((company, index) => {
                const {
                    translations,
                    phoneNumber1,
                    phoneNumber2,
                    email,
                    usersQuantity,
                    carsQuantity,
                    createdAt,
                    balances,
                    usersBalances
                } = company;
                const mainTranslations = translations?.find(item => item.language === mainLanguage);
                const {marketingName, supervisor} = mainTranslations || {};
                const bgStyle = index % 2 === 0 ? oddRowStyle : {};
                const cellStyle = {font: {sz: "11"}, ...bgStyle};


                const cellData = [
                    {value: marketingName ?? '-', style: cellStyle},
                    {value: supervisor ?? "-", style: cellStyle},
                    {value: (phoneNumber1 || phoneNumber2) ?? '-', style: cellStyle},
                    {value: email ?? "-", style: cellStyle},
                    {value: usersQuantity ?? "-", style: cellStyle},
                    {value: carsQuantity ?? "-", style: cellStyle},
                    {
                        value: createdAt ? moment(createdAt).format("DD.MM.YY / HH:mm") : "-",
                        style: cellStyle
                    },
                    {
                        value: (balances && Array.isArray(balances)) ? balances.find(item => item.type === BALANCE_TYPES.MONEY)?.amount || 0 : 0,
                        style: cellStyle
                    },
                ]
                fuelList && fuelList.forEach(fuel => {
                    const currBalance = balances && balances.find(balance => balance?.fuel === fuel.id);
                    const userBalance = usersBalances && Array.isArray(usersBalances) && usersBalances.find(item => item?.fuel === currBalance?.fuel)
                    const amountSum = (currBalance?.amount || 0) + (userBalance?.amount || 0)
                    cellData.push({value: `${truncNumber(amountSum) || 0} / ${truncNumber(currBalance?.amount) || 0}`, style: cellStyle});
                })
                return cellData;
            })],

        }];
        if (Array.isArray(downloadedData)) {
            fuelList && fuelList.forEach(fuel => {
                const mainTr = fuel?.translations?.find(item => item.language === mainLanguage);
                downloadedData[0].columns.push({
                    title: mainTr?.title || '-',
                    width: headerFuelItemWidth,
                    style: headerStyle
                });
                emptyRow.push({value: ''});
            });
        }

        return <div>
            {!!data?.length && <ExcelFile element={<span ref={this.excelButton} style={{visibility: 'hidden'}}/>}
                                          filename={'Companies' + moment().format('DD-MM-YYYY')}>
                <ExcelSheet dataSet={downloadedData}
                            name={'Companies'}/>
            </ExcelFile>}
            <LinkButton title={'Excel'}
                        cb={this.downloadExcel}
                        loadingWithTitle
                        disabled={!companies?.itemsList?.length}
                        loading={loading}/>
        </div>
    }
}

export default DownloadCompaniesExcel
