// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";


// Import Styles
import '../../assets/styles/containerStyles/users.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";
import {LinkButton} from "../../components/buttons/buttons";
import ModalSendNotification from "../../components/uiElements/ModalSendNotification";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {DatePicker, Select} from "antd";
import DownloadUsersExcel from "../../components/excel/DownloadUsersExcel";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetUsers} from "../../redux/actions";
import {BALANCE_TYPES, MEASUREMENT_TYPES, STATION_TYPES} from "../../constants/constTypes";
import {disabledStartDate, disabledEndDate} from "../../utils/helperFunctions";



const stationTypeOptions = Object.keys(STATION_TYPES).map(key => ({
    id: key,
    name: STATION_TYPES[key]
}));
const dateFormat = 'DD.MM.YYYY';

class Users extends Component {
    constructor() {
        super();
        this.state = {
            filterData: {
                text: '',
                createdAtStartDate: null,
                createdAtEndDate: null,
                lastUpdateStartDate: null,
                lastUpdateEndDate: null,
                type: undefined,
            },
            selectingActive: false,
            selectedUsers: [],
            sendModalOpen: false,
            allSelected: false,
        };
        this.toggleNotificationModal = this.toggleNotificationModal.bind(this);
        this.toggleSelecting = this.toggleSelecting.bind(this);
        this.getSearchValue = this.getSearchValue.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.selectUser = this.selectUser.bind(this);
        this.selectAll = this.selectAll.bind(this);
    }

    componentDidMount() {
        this.props.GetUsers();
    }

    getSearchValue(text) {
        this.setState({
            filterData: {
                ...this.state.filterData,
                text
            }
        }, () => !text && this.searchItems())
    }


    toggleSelecting() {
        const newState = {selectingActive: !this.state.selectingActive};
        if (this.state.selectingActive){
            newState.selectedUsers = [];
            newState.allSelected = false;
        }
        this.setState(newState)
    }

    toggleNotificationModal(notificationSuccessfulSend = false) {
        const newState = {
            sendModalOpen: !this.state.sendModalOpen
        }
        this.state.allSelected && (newState.allSelected = false)
        if (notificationSuccessfulSend) {
            newState.selectingActive = false;
            newState.selectedUsers = [];
            newState.sendModalOpen = false;
            newState.allSelected = false;
        }
        this.setState(newState)
    }

    searchItems(updatedData) {
        const {
            text, createdAtStartDate, createdAtEndDate, lastUpdateStartDate, lastUpdateEndDate, type
        } = this.state.filterData;
        let filterData = {text, type};
        updatedData && (filterData = {...filterData, ...updatedData})
        createdAtStartDate && (filterData.createdAtStartDate = createdAtStartDate)
        createdAtEndDate && (filterData.createdAtEndDate = createdAtEndDate)
        lastUpdateStartDate && (filterData.lastUpdateStartDate = lastUpdateStartDate)
        lastUpdateEndDate && (filterData.lastUpdateEndDate = lastUpdateEndDate)
        return this.props.GetUsers(filterData);
    }

    loadMoreItems() {
        //console.log('users get more');
        const {usersList} = this.props;
        usersList?.hasMore && this.searchItems({
            reset: false,
            offset: usersList?.itemsList?.length,
            text: usersList?.text
        });
    };

    selectUser(userId) {
        let {selectingActive, selectedUsers} = this.state;
        if (selectingActive) {
            const filteredUsers = selectedUsers.filter(id => id !== userId);
            this.setState({
                selectedUsers: filteredUsers.length === selectedUsers.length ?
                    [userId, ...selectedUsers] : filteredUsers
            })
        }
    }


    selectAll() {
        this.setState({
            allSelected: true,
            selectedUsers: [],
            sendModalOpen: true
        })
    }

    handleChangeData(field, value) {
        this.setState({
            filterData: {
                ...this.state.filterData,
                [field]: value
            }
        }, this.searchItems)
    }

    render() {
        const {selectingActive, selectedUsers, sendModalOpen, allSelected, filterData,} = this.state;
        const {text, createdAtStartDate, createdAtEndDate, lastUpdateStartDate, lastUpdateEndDate, type} = filterData;
        const {usersList, fuelList, mainLanguage, requestLoading} = this.props;

        const balanceMoney = usersList?.balancesSum?.find(b => !b?.fuel)?.sum;
        const balanceFuels = usersList?.balancesSum?.filter(b => b?.fuel);
        const balanceFuelUnit = MEASUREMENT_TYPES[balanceFuels?.[0]?.fuel?.measurementUnit];
        const balanceFuelsSum = balanceFuels?.reduce((sum, current) => sum + current?.sum, 0);

        let itemListHeaderInfo = {
            generalInfo: ["Անուն", "Հեռախոս", "Հաշիվ"],
            largeItems: [0, 1],
            status: true,
            minWidth: 1500,
            actions: ["edit", 'details'],
        };
        fuelList && fuelList.forEach(fuel => {
            const mainTr = fuel?.translations?.find(item => item.language === mainLanguage);
            mainTr && itemListHeaderInfo.generalInfo.push(mainTr.title);
        });
        let itemListInfo = usersList?.itemsList?.map(user => {
            const {id, username, firstName, lastName, isBlocked, balances} = user;
            const money = balances?.find(b => b.type === BALANCE_TYPES.MONEY)?.amount ?? 0;
            const userData = {
                id: id,
                handleSelect: this.selectUser.bind(this, id),
                selected: selectingActive && (selectedUsers.find(userId => userId === id) || allSelected),
                values: [`${firstName} ${lastName}`, username, money],
                status: !isBlocked,
            };
            fuelList && fuelList.forEach(fuel => {
                const currBalance = balances && balances.find(balance => balance?.fuel?.id === fuel.id);
                userData.values.push(currBalance?.amount ?? 0);
            });
            return userData;
        });
        const sendNotificationJsx = <div className={'send-notification-wrapper'}>
            {selectingActive && <LinkButton title={'Առաջ'}
                                            className={`bg-white size-sm`}
                                            disabled={!selectedUsers?.length}
                                            cb={this.toggleNotificationModal}/>}
            <LinkButton title={'Ուղարկել ծանուցում'}
                        className={`bg-white size-sm ${selectingActive ? "bg-orange-important" : ''}`}
                        cb={this.toggleSelecting}/>

        </div>;


        return <PageWrapper withActions={true}>
            <section className="users">
                <PageHeader pageTitle={'Օգտատերեր'}
                            linkTitle={"Ավելացնել"}
                            addingLink={"/users/add"}
                            rightTopProps={sendNotificationJsx}
                            renderingItemsTitleWithCount={`Օգտատերերի քանակ ${usersList?.count}`}
                            search={true}
                            getSearchValue={this.getSearchValue}
                            searchItems={this.searchItems}
                            searchValue={text}
                            filters={<div className="user-filters-wrapper">
                                <div className={'selects-wrapper'}>
                                    <div>
                                        <InputGroup inputType={"wrapper"}>
                                            <DatePicker value={createdAtStartDate}
                                                        format={dateFormat}
                                                        showToday={false}
                                                        allowClear={true}
                                                        disabledDate={(date) => disabledStartDate(date, createdAtEndDate)}
                                                        placeholder="Գրանցման ամսաթիվ սկիզբ"
                                                        className={`date-picker`}
                                                        onChange={(value) =>
                                                            this.handleChangeData('createdAtStartDate', value)}>
                                            </DatePicker>
                                        </InputGroup>
                                        <InputGroup inputType={"wrapper"}>
                                            <DatePicker value={createdAtEndDate}
                                                        format={dateFormat}
                                                        showToday={false}
                                                        allowClear={true}
                                                        disabledDate={(date) => disabledEndDate(date, createdAtStartDate)}
                                                        placeholder="Գրանցման ամսաթիվ ավարտ"
                                                        className={`date-picker`}
                                                        onChange={(value) =>
                                                            this.handleChangeData('createdAtEndDate', value)}>
                                            </DatePicker>
                                        </InputGroup>
                                    </div>
                                    <div>
                                        <InputGroup inputType={"wrapper"}>
                                            <DatePicker value={lastUpdateStartDate}
                                                        format={dateFormat}
                                                        showToday={false}
                                                        allowClear={true}
                                                        disabledDate={(date) => disabledStartDate(date, lastUpdateEndDate)}
                                                        placeholder="Օգտագործման ամսաթիվ սկիզբ"
                                                        className={`date-picker`}
                                                        onChange={(value) =>
                                                            this.handleChangeData('lastUpdateStartDate', value)}>
                                            </DatePicker>
                                        </InputGroup>
                                        <InputGroup inputType={"wrapper"}>
                                            <DatePicker value={lastUpdateEndDate}
                                                        format={dateFormat}
                                                        showToday={false}
                                                        allowClear={true}
                                                        disabledDate={(date) => disabledEndDate(date, lastUpdateStartDate)}
                                                        placeholder="Օգտագործման ամսաթիվ ավարտ"
                                                        className={`date-picker`}
                                                        onChange={(value) =>
                                                            this.handleChangeData('lastUpdateEndDate', value)}>
                                            </DatePicker>
                                        </InputGroup>
                                    </div>
                                </div>
                                <div className={'type-result'}>
                                    {/*<InputGroup inputType="selectCustom"*/}
                                    {/*            placeholder="Տեսակ"*/}
                                    {/*            name="type"*/}
                                    {/*            showSearch={false}*/}
                                    {/*            value={type}*/}
                                    {/*            onChange={({value}) => this.handleChangeData('type', value)}*/}
                                    {/*            options={stationTypeOptions}>*/}
                                    {/*    {type && <Select.Option value={undefined}>Բոլորը</Select.Option>}*/}
                                    {/*</InputGroup>*/}
                                    <div className="user-filters-result">
                                            <div className={'fuels-sum-wrapper'}>
                                                {balanceFuels && balanceFuels.map((balance,index) => {
                                                    const balanceTitle = balance?.fuel?.translations?.find(tr => tr.language === mainLanguage)?.shortName ?? '-'
                                                    return <div className={'fuel-item'} key={index}>
                                                        <span>{balanceTitle} </span>
                                                        <span className={'sum'}>
                                                            {Number(balance?.sum) || 0} {MEASUREMENT_TYPES[balance.fuel?.measurementUnit]}
                                                        </span>
                                                    </div>
                                                })}
                                            </div>
                                     {/*   {!!Number(balanceFuelsSum) && usersList?.type &&
                                        <span>{balanceFuelsSum} {balanceFuelUnit}</span>}*/}
                                        { <span className={"dram"}>{Number(balanceMoney) || 0} դր․</span>}
                                        <DownloadUsersExcel usersList={usersList}
                                                            fuelList={fuelList}
                                                            mainLanguage={mainLanguage}
                                                            GetUsers={this.searchItems}/>
                                    </div>
                                </div>
                            </div>}/>

                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={itemListInfo}
                           loading={requestLoading}
                           allSelected={allSelected}
                           selectAll={this.selectAll}
                           detailsLink={!selectingActive && "users/user"}
                           editLink={"/users/edit"}
                           selectingActive={selectingActive}
                           infiniteScroll={true}
                           hasMore={usersList.hasMore}
                           loadMoreItems={this.loadMoreItems}/>
            </section>
            <ModalSendNotification visible={sendModalOpen}
                                   sendToAllUsers={allSelected}
                                   userIds={selectedUsers}
                                   closeModal={this.toggleNotificationModal}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(Users)
