export const ContactAddingList = [
    {
        label: "Էլ․ հասցե",
        inputType: "input",
        type: "text",
        placeholder: "Էլ․ հասցե",
        name: "email",
        maxLength: 500
    },
    {
        label: "Հեռախոս 1",
        inputType: "input",
        type: "text",
        regExp:/^[+\d]\d*$/,
        placeholder: "Հեռախոս 1",
        name: "phoneNumber1",
        maxLength: 20
    },
    {
        label: "Հեռախոս 1",
        inputType: "input",
        type: "text",
        regExp:/^[+\d]\d*$/,
        placeholder: "Հեռախոս 2",
        name: "phoneNumber2",
        maxLength: 20
    },
    {
        inputType: "input",
        type: "text",
        placeholder: "Messenger",
        name: "messenger",
        maxLength: 1000
    },
    {
        inputType: "input",
        type: "text",
        placeholder: "Viber",
        name: "viber",
        maxLength: 1000
    },
    {
        inputType: "input",
        type: "text",
        placeholder: "WhatsApp",
        name: "whatsApp",
        maxLength: 1000
    }
];
