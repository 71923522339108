// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/history.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import { CustomTabs } from "../../components/uiElements/Tabs";
import ItemsList from "../../components/ItemsList";
import { DatePicker, Select } from "antd";
import { InputGroup } from "../../components/uiElements/inputGroup";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { ClearHistory, GetHistory, UpdateHistoryById } from "../../redux/actions";
import {
    tabs,
    chargingHeaderInfo,
    transfersHeaderInfo,
    purchasedFuelHeaderInfo,
    accountReplenishmentHeaderInfo
} from "./utils/constants";
import { changeStateField, truncNumber } from "../../utils/helperFunctions";
import {
    parseAccountReplenishmentHistoryData,
    parseChargingHistoryData,
    parsePurchasedFuelHistoryData,
    parseTransferHistoryData
} from "./utils/actionFunctions";
import { MEASUREMENT_TYPES, STATION_HISTORY_TYPES } from "../../constants/constTypes";
import FilteringRow from "../../components/uiElements/FilteringRow";
import DownloadUsersExcel from "../../components/excel/DownloadUsersExcel";
import types from "less/lib/less/functions/types";
import moment from "moment";
import DownloadStationsExcel from "../../components/excel/DownloadStationsExcel";
import DownloadHistoryExcel from "../../components/excel/DownloadHistoryExcel";

const dateFormat = 'DD.MM.YYYY';

class History extends Component {
    constructor () {
        super();
        this.state = {
            activeTab: tabs[0].key,
            text: '',
            updatedFieldId: null,
            tempAmount: ''
        };
        this.listRef = React.createRef();
        this.changeTab = changeStateField.bind(this, "activeTab");
        this.getSearchValue = this.getSearchValue.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.statusOptions = Object.keys(STATION_HISTORY_TYPES).map(key => ({
            id: key,
            name: STATION_HISTORY_TYPES[key]
        }))
    }

    componentDidMount () {
        this.props.GetHistory({
            key: tabs[0].key,
            startDate: moment()
        }).then(() => {
            tabs.slice(1).forEach(tab => {
                this.props.GetHistory({
                    key: tab.key,
                    startDate: moment()
                })
            })
        });
    }

    componentWillUnmount () {
        this.props.ClearHistory()
    }

    getSnapshotBeforeUpdate (prevProps, prevState) {
        if (this.state.activeTab !== prevState.activeTab) {
            // console.group('snapshot');
            // console.log('scrollTop', this.listRef?.current?.scrollTop);
            // console.log('searchValue', prevState.searchValue);
            // console.groupEnd();
            this[prevState.activeTab] = {
                scrollTop: this.listRef?.current?.scrollTop ?? 0,
                text: prevState.text
            };
        }
        return null;
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        const { activeTab } = this.state;
        if (activeTab !== prevState.activeTab) {
            this.listRef.current && (this.listRef.current.scrollTop = this[activeTab]?.scrollTop ?? 0);
            this.setState({
                text: this[activeTab]?.text ?? ''
            })
        }
    }

    getSearchValue (text) {
        this.setState({
            text,
        }, () => !text && this.searchItems({ text }))
    }

    searchItems (updatedData = {}) {
        const { activeTab } = this.state;
        const currentHistory = this.props[activeTab]
        const { text, startDate, endDate, stationId, stationNetworkId, status, fuelId } = currentHistory;
        let filterData = {
            key: activeTab,
            startDate, endDate, text,
            stationId, stationNetworkId, status, fuelId,
            ...updatedData,
        };
        if (filterData?.text && filterData.text?.length === 1) {
            return;
        }
        this.props.GetHistory(filterData);
    }

    loadMoreItems () {
        const { activeTab } = this.state;
        const historyData = this.props[activeTab];
        const { types } = historyData;
        historyData.hasMore && this.searchItems({
            reset: false,
            offset: historyData.itemsList.length,
            type: types
        });
    };

    startUpdate = (updatedFieldId, amount) => {
        this.setState({
            updatedFieldId,
            tempAmount: amount
        })
    }

    handleUpdate = ({ name, value }) => {
        if (value && value !== '0' && value !== '0.' && (!Number(value) || value < 0)) {
            return;
        }
        if (value && value.includes('.') && (value.length - value.indexOf('.') > 3)) {
            return;
        }
        this.setState({
            tempAmount: value
        })
    }

    handleBlur = () => {
        this.setState({
            updatedFieldId: null,
            tempAmount: '',
        })
    }

    handleKeyDown = (history, e) => {
        const { updatedFieldId, tempAmount } = this.state;
        if (updatedFieldId && e && e.key === 'Enter' && tempAmount && !tempAmount.endsWith('.') && history.amount !== tempAmount) {
            const price = history.amount ? history.sum / history.amount : 0
            const payload = {
                amount: tempAmount,
            }
            const storePayload = {
                amount: tempAmount,
                sum: tempAmount * price
            }
            this.props.UpdateHistoryById(updatedFieldId, payload, storePayload).then(() => {
                this.setState({
                    updatedFieldId: null,
                    tempAmount: '',
                })
            })
        }
    }


    render () {
        const { mainLanguage, requestLoading, isAdmin, fuelList } = this.props;
        const { activeTab, text, updatedFieldId, tempAmount } = this.state;
        const currentHistory = this.props[activeTab];
        const hasMore = currentHistory?.hasMore;
        const { types, fuelsSum, operationsQuantity, operationsSum, operationsAmount } = currentHistory;
        let headerInfo, itemsInfo, className;
        const updateProps = {
            isAdmin,
            updatedFieldId,
            tempAmount,
            startUpdate: this.startUpdate,
            handleUpdate: this.handleUpdate,
            handleBlur: this.handleBlur,
            handleKeyDown: this.handleKeyDown
        }
        switch ( activeTab ) {
            case tabs[0].key:
                headerInfo = chargingHeaderInfo;
                itemsInfo = parseChargingHistoryData(currentHistory, mainLanguage, updateProps);
                className = 'charging-history'
                break;
            case tabs[1].key:
                headerInfo = transfersHeaderInfo;
                itemsInfo = parseTransferHistoryData(currentHistory, mainLanguage);
                className = 'transfers-history'
                break;
            case tabs[2].key:
                headerInfo = purchasedFuelHeaderInfo;
                itemsInfo = parsePurchasedFuelHistoryData(currentHistory, mainLanguage);
                className = 'purchasedFuel-history'
                break;
            case tabs[3].key:
                headerInfo = accountReplenishmentHeaderInfo;
                itemsInfo = parseAccountReplenishmentHistoryData(currentHistory, mainLanguage);
                className = 'accountReplenishment-history'
                break;
        }
        return <PageWrapper withActions={true}>
            <section className="history">
                <PageHeader pageTitle={'Պատմություն'}
                            getSearchValue={this.getSearchValue}
                            searchItems={() => this.searchItems({ text, type: types })}
                            searchValue={text}
                            search={true}
                            renderingCustomFuelsListOrExcel={
                                <DownloadHistoryExcel mainLanguage={mainLanguage}
                                                      limit={Number(operationsQuantity)}
                                                      activeTab={activeTab}
                                                      historyList={currentHistory}
                                                      GetHistory={this.props.GetHistory}/>
                            }
                />
                <div className="sum-result">
                    {<span
                        className={`amount ${activeTab === tabs[3].key ? "border-right" : ""}`}>{operationsQuantity} գործարք</span>}
                    {activeTab !== tabs[3].key && <div className={'fuels-sum-wrapper'}>
                        {fuelList?.map((item) => {
                            const balance = fuelsSum?.find(balance => balance?.fuel?.id === item?.id);
                            const balanceTitle = item?.translations?.find(tr => tr.language === mainLanguage)?.shortName ?? '-'
                            const amount = truncNumber(balance?.amount);
                            return <div className="fuel-item" key={item?.id}>
                                <span>{balanceTitle}</span>
                                <span className={'sum'}>
                                    {amount ?? 0} {MEASUREMENT_TYPES[item?.measurementUnit]}
                                </span>
                            </div>
                        })}
                    </div>}
                    {<span
                        className={"amount"}>{activeTab === tabs[3].key ? (Number(operationsAmount) || 0) : (Number(operationsSum) || 0)} դր․</span>}
                </div>
                <FilteringRow historyType={activeTab}
                              searchValue={text}
                              activeTab={activeTab}
                              searchItems={this.searchItems}
                />
                <div className="tabs-wrapper" style={{ position: "relative" }}>
                    <CustomTabs changeTab={this.changeTab} activeTab={activeTab} tabsList={tabs}/>
                </div>
                <ItemsList
                    className={className}
                    id={activeTab}
                    itemListHeaderInfo={headerInfo}
                    itemListInfo={itemsInfo}
                    loading={requestLoading}
                    infiniteScroll={true}
                    hasMore={hasMore}
                    listRef={this.listRef}
                    loadMoreItems={this.loadMoreItems}/>
            </section>

        </PageWrapper>
    }
}


const mapDispatchToProps = {
    GetHistory,
    ClearHistory,
    UpdateHistoryById
};

export default connect(mapStateToProps, mapDispatchToProps)(History)
