// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/partner-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {Icon, Switch} from "antd";
import {LanguageTabs} from "../../components/uiElements/Tabs";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {validateFields} from "./utils/actionFunctions";
import {GetCompanyById, AddCompany, EditCompany, GetCompanies, GetRegionResidences} from "../../redux/actions";
import {
    initTranslations,
    changeStateField,
    toggleStateField,
    getInputValues,
    getTranslatableInputValues
} from "../../utils/helperFunctions";
import {MaterialIcon} from "../../components/utils/Icon";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";


class AddEditCompany extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'name',
            'marketingName',
            'legalAddress',
            'director',
            'supervisor',
            'bank',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                username: "",
                password: "",
                repeatPassword: "",
                email: "",
                taxCode: "",
                phoneNumber1: undefined,
                phoneNumber2: "",
                bankAccount: "",
                notes: "",
                parent: undefined,
                contract: null,
                isHidden: false,

                regionHead: undefined,
                residenceHead: undefined,
                addressHead: '',

                region1: undefined,
                residence1: undefined,
                address1: '',

                region2: undefined,
                residence2: undefined,
                address2: '',
            },
            loading: false,
            showParentSelect: true,
            mediaModalOpen: false,
            languageTab: props.mainLanguage,
            isEditing: !!props?.id,
            passwordVisible: false,
            repeatPasswordVisible: false,
            regionResidences: {
                residenceHead: [],
                residence1: [],
                residence2: [],
            }
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.validateFields = validateFields.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.getRegionId = this.getRegionId.bind(this);
        this.deleteContract = this.deleteContract.bind(this);
        this.addEditCompany = this.addEditCompany.bind(this);
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;

        if (isEditing) {
            const id = this.props?.id;
            await this.props.GetCompanyById(id);
            const {companyById} = this.props;
            //console.log(companyById);
            const companyData = {};
            const translations = {...fieldsData.translations};
            Object.keys(fieldsData).forEach(field => {
                if (field !== "translations") {
                    if (field.startsWith('region') || field.startsWith('residence')) {
                        companyData[field] = companyById?.[field]?.id;
                    } else {
                        companyData[field] = companyById[field] ?? '';
                    }
                }
            });
            if (!companyData.parent) {
                companyData.parent = undefined
            }

            companyById.translations && companyById.translations.forEach(translation => {
                this.translationsFields.forEach(key => {
                    translations[translation.language] &&
                    (translations[translation.language][key] = translation[key]);
                });
            });
            const regionResidences = {
                residenceHead: [],
                residence1: [],
                residence2: [],
            }
            try {
                companyById?.regionHead?.id &&
                (regionResidences.residenceHead = await this.props.GetRegionResidences(companyById?.regionHead?.id, true));
                companyById?.region1?.id &&
                (regionResidences.residence1 = await this.props.GetRegionResidences(companyById?.region1?.id, true));
                companyById?.region2?.id &&
                (regionResidences.residence2 = await this.props.GetRegionResidences(companyById?.region2?.id, true));
            } catch {

            }


            this.setState({
                fieldsData: {
                    ...companyData,
                    translations: translations
                },
                regionResidences,
                showParentSelect: !companyById?.subCompanies?.length
            })
        }
        !this.props.companies?.itemsList?.length && await this.props.GetCompanies();
        const {companies, mainLanguage, id} = this.props;
        this.parentOptions = [{
            id: undefined,
            name: 'Ջնջել'
        }];
        companies?.itemsList && companies.itemsList.forEach(parent => {
            (!isEditing || parent.id !== id) &&
            this.parentOptions.push({
                id: parent.id,
                name: parent?.translations?.find(tr => tr.language === mainLanguage)?.name ?? ''
            })
        });
        this.forceUpdate();
    }

    getMedia(mediaArray) {
        const {fieldsData, isEditing} = this.state;
        const {companyById} = this.props;
        if (isEditing) {
            (!companyById?.contract || (companyById?.contract?.id !== mediaArray[0].id)) ?
                this.updatedDataMap.set("contract", mediaArray[0].id) :
                this.updatedDataMap.delete("contract");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                contract: mediaArray[0]
            }
        })
    }

    async getRegionId({name, value, haveChanges}) {
        const {companyById} = this.props;
        const {isEditing, fieldsData} = this.state;
        let residence = [];
        if (value) {
            residence = await this.props.GetRegionResidences(value, true);
        }
        const residenceName = 'residence' + name.slice(6);
        if (isEditing) {
            haveChanges ? this.updatedDataMap.set(name, value || null)
                : this.updatedDataMap.delete(name);
            companyById?.[residenceName]?.id && this.updatedDataMap.set(residenceName, null)
        }

        this.setState({
            fieldsData: {
                ...fieldsData,
                [name]: value,
                [residenceName]: undefined
            },
            regionResidences: {
                ...this.state.regionResidences,
                [residenceName]: residence
            }
        })
    }

    deleteContract() {
        const {fieldsData, isEditing} = this.state;
        const {companyById} = this.props;
        if (isEditing) {
            companyById?.contract ?
                this.updatedDataMap.set("contract", null) :
                this.updatedDataMap.delete("contract");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                contract: null
            }
        })
    }

    async addEditCompany() {
        await this.setState({loading: true});
        const {companyById} = this.props;
        const {fieldsData, isEditing} = this.state;
        const {translations} = fieldsData;

        const errors = {
            requiredFields: ['taxCode', 'email'],
            translations: ["name", 'marketingName', 'director', 'supervisor'],
        };
        // Փոփոխություն անելուց password դաշտը ոչ պարդատիր է
        if (isEditing) {
            if (!fieldsData.parent) {
                if (companyById.parent) {
                    // Եթե հիմա ծնող չունի, բայց ունեցելա
                    errors.requiredFields.push("username", 'password', 'repeatPassword');
                } else {
                    // Եթե հիմա ծնող չունի ու չի Էլ ունեցել
                    errors.requiredFields.push("username");
                    if (fieldsData.password) {
                        errors.requiredFields.push('password', 'repeatPassword');
                    }
                }
            } else {
                //Ուրեմն արդեն ծնող ունի ուրեմն ամեն ինչ նորմալա
                //console.log(this.updatedDataMap);
                this.updatedDataMap.delete('username');
                //console.log(this.updatedDataMap);
            }

        } else {
            if (!fieldsData.parent) {
                errors.requiredFields.push("username", 'password', 'repeatPassword');
            }
        }
        const validationResult = this.validateFields(errors, fieldsData);
        // console.log(validationResult);
        // console.log(this.errorsSet);
        if (validationResult) {
            let TR_data = [];
            Object.keys(translations).forEach(key => {
                const reqTrItem = {
                    language: key,
                };
                const trItem = translations[key];
                Object.keys(trItem).forEach(field => {
                    //  console.log(field, trItem[field]);
                    trItem[field] && (reqTrItem[field] = trItem[field]);
                });
                TR_data.push(reqTrItem);
            });
            const reqData = {};
            if (isEditing) {
                this.updatedDataMap.forEach((value, key) => {
                    const tr_key = key.split('_')[0];
                    if (this.translationsFields.some(field => field === tr_key)) {
                        reqData.translations = TR_data;
                        return;
                    }
                    reqData[key] = value ?? null;
                });
                this.props.EditCompany(companyById?.id, reqData).finally(() => {
                    this.setState({loading: false});
                })
            } else {
                Object.keys(fieldsData).forEach(field => {
                    fieldsData[field] && (reqData[field] = fieldsData[field]);
                });
                reqData.contract && (reqData.contract = reqData.contract.id);
                reqData.translations = TR_data;
                delete reqData.repeatPassword;
                //console.log(reqData);
                this.props.AddCompany(reqData).finally(() => {
                    this.setState({loading: false});
                })
            }
        } else {
            this.setState({loading: false});
        }
    }

    render() {
        const {companyById, regions} = this.props;
        const {
            fieldsData, passwordVisible, repeatPasswordVisible, isEditing, languageTab,
            showParentSelect, loading, mediaModalOpen, regionResidences
        } = this.state;
        const {translations} = fieldsData;
        const languageKey = '_' + languageTab;
        const initTranslations = companyById?.translations?.find(lg => lg.language === languageTab);

        const trData = translations[languageTab] || {};


        const checkbox = <div className="right-side">
            <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
            <Switch checked={!fieldsData.isHidden}
                    onChange={(checked) => this.getInputValues({
                        name: "isHidden",
                        value: !checked,
                        haveChanges: companyById?.isHidden !== !checked
                    })}/>
        </div>
        return <PageWrapper withActions={true}>
            <section className="partner-add-edit">
                <PageHeader
                    pageTitle={`${isEditing ? 'Փոփոխել Ընկերությունը' : 'Ավելացնել Ընկերություն'}`}
                    rightTopProps={checkbox}/>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={this.errorsTabs}
                              activeTab={languageTab}
                />
                <div className="partner-fields">
                    {showParentSelect && <div className="fields-section">
                        <div className="input-form">
                            <InputGroup inputType="selectCustom"
                                        label={"Հոլդինգ"}
                                        placeholder="Հոլդինգ"
                                        name="parent"
                                        className={(fieldsData?.username || fieldsData?.password) ? 'disabled' : ''}
                                        showSearch={false}
                                        disabled={fieldsData?.username || fieldsData?.password}
                                        value={fieldsData?.parent}
                                        initValue={companyById?.parent}
                                        error={this.errorsSet.has('parent')}
                                        onChange={this.getInputValues}
                                        options={this.parentOptions}/>
                        </div>
                    </div>}
                    <div className="fields-section">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Անվանում<span
                                            className="field-required">*</span></>}
                                        placeholder="Անվանում"
                                        name="name"
                                        maxLength={256}
                                        value={trData?.name}
                                        initValue={initTranslations?.name}
                                        error={this.errorsSet.has('name' + languageKey)}
                                        onChange={this.getTranslatableInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Մարք. անվանում<span
                                            className="field-required">*</span></>}
                                        placeholder="Անվանում"
                                        name="marketingName"
                                        maxLength={256}
                                        value={trData?.marketingName}
                                        initValue={initTranslations?.marketingName}
                                        error={this.errorsSet.has('marketingName' + languageKey)}
                                        onChange={this.getTranslatableInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Բանկ"}
                                        placeholder="Բանկ"
                                        name="bank"
                                        maxLength={256}
                                        value={trData?.bank}
                                        initValue={initTranslations?.bank}
                                        error={this.errorsSet.has('bank' + languageKey)}
                                        onChange={this.getTranslatableInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Հաշվեհամար"}
                                        placeholder="Հաշվեհամար"
                                        name="bankAccount"
                                        regExp={/^\d*$/}
                                        maxLength={16}
                                        value={fieldsData.bankAccount}
                                        initValue={companyById?.bankAccount}
                                        error={this.errorsSet.has('bankAccount')}
                                        onChange={this.getInputValues}/>
                        </div>
                    </div>
                    <div className="fields-section">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>ՀՎՀՀ<span className="field-required">*</span></>}
                                        placeholder="ՀՎՀՀ"
                                        name="taxCode"
                                        regExp={/^\d*$/}
                                        maxLength={8}
                                        value={fieldsData.taxCode}
                                        initValue={companyById?.taxCode}
                                        error={this.errorsSet.has('taxCode')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="email"
                                        label={<>Մուտքանուն
                                            {!fieldsData?.parent &&
                                            <span className="field-required">*</span>}</>}
                                        placeholder="Մուտքանուն"
                                        disabled={fieldsData?.parent}
                                        name="username"
                                        maxLength={50}
                                        value={fieldsData.username}
                                        initValue={companyById?.username}
                                        error={this.errorsSet.has('username')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type={`${passwordVisible ? "text" : "password"}`}
                                        label={<>Գաղտնաբառ {!fieldsData?.parent &&
                                        <span className="field-required">*</span>}</>}
                                        placeholder="Գաղտնաբառ"
                                        disabled={fieldsData?.parent}
                                        name="password"
                                        maxLength={20}
                                        autocomplete={'new-password'}
                                        value={fieldsData.password}
                                        initValue={companyById?.password}
                                        error={this.errorsSet.has('password')}
                                        onChange={this.getInputValues}>
                            <span className={'visibility-icon'}
                                  onClick={toggleStateField.bind(this, "passwordVisible")}>
                                <Icon type={`${passwordVisible ? "eye" : "eye-invisible"}`}/>
                            </span>
                            </InputGroup>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type={`${repeatPasswordVisible ? "text" : "password"}`}
                                        label={<>Կրկնել գաղտնաբառը {!fieldsData?.parent &&
                                        <span className="field-required">*</span>}</>}
                                        placeholder="Գաղտնաբառ"
                                        disabled={fieldsData?.parent}
                                        name="repeatPassword"
                                        maxLength={20}
                                        value={fieldsData.repeatPassword}
                                        error={this.errorsSet.has('repeatPassword')}
                                        onChange={this.getInputValues}>
                                <span className={'visibility-icon'}
                                      onClick={toggleStateField.bind(this, "repeatPasswordVisible")}>
                                <Icon type={`${repeatPasswordVisible ? "eye" : "eye-invisible"}`}/>
                            </span>
                            </InputGroup>
                        </div>
                    </div>

                    {/*Իրավաբանական Հասցե*/}
                    <div className="fields-section">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Իրավաբանական Հասցե"}
                                        placeholder="Հասցե"
                                        name="legalAddress"
                                        maxLength={256}
                                        value={trData?.legalAddress}
                                        initValue={initTranslations?.legalAddress}
                                        error={this.errorsSet.has('legalAddress' + languageKey)}
                                        onChange={this.getTranslatableInputValues}/>
                        </div>
                        <div className="fields-right-side">
                            <div className="input-sm-form">
                                <InputGroup inputType="selectCustom"
                                            label={" Գործ. հասցե"}
                                            placeholder="Մարզ/Քաղաք"
                                            name="regionHead"
                                            showSearch={false}
                                            value={fieldsData?.regionHead}
                                            initValue={companyById?.regionHead?.id}
                                            withClear
                                            error={this.errorsSet.has('regionHead')}
                                            onChange={this.getRegionId}
                                            options={regions}/>
                            </div>
                            <div className="input-sm-form">
                                <InputGroup inputType="selectCustom"
                                            label={<>&nbsp;</>}
                                            placeholder="Քաղաք/Համայնք"
                                            name="residenceHead"
                                            showSearch={false}
                                            value={fieldsData?.residenceHead}
                                            initValue={companyById?.residenceHead?.id}
                                            withClear
                                            error={this.errorsSet.has('residenceHead')}
                                            onChange={this.getInputValues}
                                            options={regionResidences?.residenceHead}/>
                            </div>
                            <div className="input-sm-form">
                                <InputGroup inputType="input"
                                            type="text"
                                            label={<>&nbsp;</>}
                                            placeholder="Հասցե"
                                            name="addressHead"
                                            maxLength={256}
                                            value={fieldsData?.addressHead}
                                            initValue={companyById?.addressHead}
                                            error={this.errorsSet.has('addressHead')}
                                            onChange={this.getInputValues}/>
                            </div>
                        </div>
                    </div>
                    {/*Տնօրեն*/}
                    <div className="fields-section">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Տնօրեն<span className="field-required">*</span></>}
                                        placeholder="Տնօրեն"
                                        name="director"
                                        maxLength={256}
                                        value={trData?.director}
                                        initValue={initTranslations?.director}
                                        error={this.errorsSet.has('director' + languageKey)}
                                        onChange={this.getTranslatableInputValues}/>
                        </div>
                        <div className="fields-right-side">
                            <div className="input-sm-form">
                                <InputGroup inputType="selectCustom"
                                            label={" Գործ. հասցե"}
                                            placeholder="Մարզ/Քաղաք"
                                            name="region1"
                                            showSearch={false}
                                            value={fieldsData?.region1}
                                            initValue={companyById?.region1?.id}
                                            withClear
                                            error={this.errorsSet.has('region1')}
                                            onChange={this.getRegionId}
                                            options={regions}/>
                            </div>
                            <div className="input-sm-form">
                                <InputGroup inputType="selectCustom"
                                            label={<>&nbsp;</>}
                                            placeholder="Քաղաք/Համայնք"
                                            name="residence1"
                                            showSearch={false}
                                            value={fieldsData?.residence1}
                                            initValue={companyById?.residence1?.id}
                                            withClear
                                            error={this.errorsSet.has('residence1')}
                                            onChange={this.getInputValues}
                                            options={regionResidences?.residence1}/>
                            </div>
                            <div className="input-sm-form">
                                <InputGroup inputType="input"
                                            type="text"
                                            label={<>&nbsp;</>}
                                            placeholder="Հասցե"
                                            name="address1"
                                            maxLength={256}
                                            value={fieldsData?.address1}
                                            initValue={companyById?.address1}
                                            error={this.errorsSet.has('address1')}
                                            onChange={this.getInputValues}/>
                            </div>
                        </div>
                    </div>
                    {/*Պատասխանատու*/}
                    <div className="fields-section">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Պատասխանատու<span
                                            className="field-required">*</span></>}
                                        placeholder="Պատասխանատու"
                                        name="supervisor"
                                        maxLength={256}
                                        value={trData?.supervisor}
                                        initValue={initTranslations?.supervisor}
                                        error={this.errorsSet.has('supervisor' + languageKey)}
                                        onChange={this.getTranslatableInputValues}/>
                        </div>
                        <div className="fields-right-side">
                            <div className="input-sm-form">
                                <InputGroup inputType="selectCustom"
                                            label={" Գործ. հասցե"}
                                            placeholder="Մարզ/Քաղաք"
                                            name="region2"
                                            showSearch={false}
                                            value={fieldsData?.region2}
                                            initValue={companyById?.region2?.id}
                                            withClear
                                            error={this.errorsSet.has('region2')}
                                            onChange={this.getRegionId}
                                            options={regions}/>
                            </div>
                            <div className="input-sm-form">
                                <InputGroup inputType="selectCustom"
                                            label={<>&nbsp;</>}
                                            placeholder="Քաղաք/Համայնք"
                                            name="residence2"
                                            showSearch={false}
                                            value={fieldsData?.residence2}
                                            initValue={companyById?.residence2?.id}
                                            withClear
                                            error={this.errorsSet.has('residence2')}
                                            onChange={this.getInputValues}
                                            options={regionResidences?.residence2}/>
                            </div>
                            <div className="input-sm-form">
                                <InputGroup inputType="input"
                                            type="text"
                                            label={<>&nbsp;</>}
                                            placeholder="Հասցե"
                                            name="address2"
                                            maxLength={256}
                                            value={fieldsData?.address2}
                                            initValue={companyById?.address2}
                                            error={this.errorsSet.has('address2')}
                                            onChange={this.getInputValues}/>
                            </div>
                        </div>
                    </div>

                    <div className="fields-section">
                        <div className="fields-left-side">
                            <div className="input-form">
                                <InputGroup inputType="input"
                                            type="text"
                                            label="Հեռախոս 1"
                                            placeholder="Հեռախոս"
                                            name="phoneNumber1"
                                            regExp={/^[+\d]\d*$/}
                                            maxLength={256}
                                            value={fieldsData.phoneNumber1}
                                            initValue={companyById?.phoneNumber1}
                                            error={this.errorsSet.has('phoneNumber1')}
                                            onChange={this.getInputValues}/>
                            </div>
                            <div className="input-form">
                                <InputGroup inputType="input"
                                            type="text"
                                            label="Հեռախոս 2"
                                            placeholder="Հեռախոս"
                                            name="phoneNumber2"
                                            regExp={/^[+\d]\d*$/}
                                            maxLength={256}
                                            value={fieldsData.phoneNumber2}
                                            initValue={companyById?.phoneNumber2}
                                            error={this.errorsSet.has('phoneNumber2')}
                                            onChange={this.getInputValues}/>
                            </div>
                        </div>
                        <div className="fields-right-side">
                            <div className="textarea-form">
                                <InputGroup inputType="textarea"
                                            type="text"
                                            label="Նշումներ"
                                            placeholder="Նշումներ"
                                            name="notes"
                                            maxLength={1024}
                                            value={fieldsData.notes}
                                            initValue={companyById?.notes}
                                            error={this.errorsSet.has('notes')}
                                            onChange={this.getInputValues}/>
                            </div>
                        </div>
                    </div>
                    <div className="fields-section">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="email"
                                        label={<>Էլ․ հասցե<span
                                            className="field-required">*</span></>}
                                        placeholder="Էլ․ հասցե"
                                        name="email"
                                        maxLength={50}
                                        value={fieldsData.email}
                                        initValue={companyById?.email}
                                        error={this.errorsSet.has('email')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="fields-right-side contract">
                            <div className="upload-btn">
                                <LinkButton className={"bg-white"}
                                            title={"Ընտրել Պայմանագիրը"}
                                            cb={this.toggleMediaModal}/>
                            </div>
                            <div className=" contract-name">
                                {fieldsData.contract && <div className={'wrapper'}>
                                    <span>Պայմանագիր՝ {fieldsData.contract.name}</span>
                                    <MaterialIcon icon="delete" onClick={this.deleteContract}/>
                                </div>}
                            </div>
                        </div>

                    </div>
                    <div className="add-partner">
                        <LinkButton title={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'}`}
                                    loading={loading}
                                    disabled={!this.updatedDataMap.size && isEditing}
                                    cb={this.addEditCompany}/>
                    </div>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={['pdf', 'docx']}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetCompanyById,
    AddCompany,
    EditCompany,
    GetCompanies,
    GetRegionResidences
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCompany)
