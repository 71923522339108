import {request, _urlOrders} from "../api";
import {ORDER_CONSTS, UTIL_CONSTS} from "../constants";
import swal from "sweetalert";
import {LIMIT_GET_ORDERS} from "../../constants/constLimitCounts";

export const GetOrders = ({
                              reset = true,
                              offset = 0,
                              limit = LIMIT_GET_ORDERS,
                              status = null
                          } = {}) => {
    const requestData = {
        url: `${_urlOrders}/?offset=${offset}&limit=${limit}`,
        token: true,
        method: "GET",
    };
    status && (requestData.url += `&status=${status}`);
    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: ORDER_CONSTS.GET_ORDERS,
                    payload: {
                        data: data,
                        reset,
                        hasMore: data.length === limit
                    }
                });
            })
            .finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const ClearOrders = () => {
    return dispatch => {
        dispatch({
            type: ORDER_CONSTS.CLEAR_ORDERS,
        });
    }
};

export const GetOrderById = (id) => {
    const requestData = {
        url: `${_urlOrders}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ORDER_CONSTS.GET_ORDER_BY_ID,
                    payload: res.data
                })
            })
            .finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
            .catch(() => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ որ բան այն չէ, Խնդրում ենք  փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const DeleteOrderProductById = (id, productId) => {
    const requestData = {
        url:  `${_urlOrders}/${id}/products/${productId}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ORDER_CONSTS.DELETE_ORDER_PRODUCT_BY_ID,
                    payload: productId
                });
            })
            .catch(() => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ որ բան այն չէ, Խնդրում ենք  փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const UpdateOrderStatus = (id, order ) => {
    const requestData = {
        url: `${_urlOrders}/${id}`,
        token: true,
        method: "PUT",
        data: order
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                dispatch({
                    type: ORDER_CONSTS.UPDATE_ORDER_STATUS,
                    payload: res.data.status,
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Պատվերը հաջողությամբ թարմացվեց!",
                    icon: "success",
                    button: "Լավ"
                })
            })
            .catch(() => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ որ բան այն չէ, Խնդրում ենք  փորձել կրկին",
                    icon: "warning",
                    button: "Լավ"
                });
            })
    }
};

export const GetNotSeenOrdersCount = () => {
    const requestData = {
        url: `${_urlOrders}/not-seen-count`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                data && dispatch({
                    type: ORDER_CONSTS.GET_NOT_SEEN_ORDERS_COUNT,
                    payload: data
                });
            })
    }
};

export const SetOrderAsSeen = (id) => {
    const requestData = {
        url: `${_urlOrders}/${id}/seen`,
        token: true,
        method: "PUT",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ORDER_CONSTS.SET_ORDER_AS_SEEN,
                    payload: id
                });
            })
    }
};