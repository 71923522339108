import React from "react";
import {Link} from "react-router-dom";
import moment from "moment"

import "../assets/styles/containerStyles/footer.scss";

export function Footer() {


    return <footer className="footer">
        <div>&copy; <Link to="/dashboard">Petrol.am</Link> 2019-{moment().format("YYYY")}</div>
        <div>Իրագործումը՝ <a href="https://4steps.am"><img src={require("../assets/images/4steps.svg")} alt="icon"/></a></div>
    </footer>
}