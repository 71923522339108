// Import packages
import React, {Component} from "react";

// Import Styles
import '../../assets/styles/cards/fuel-row.scss';

// Import components
import {InputGroup} from "../uiElements/inputGroup";
import {MaterialIcon} from "../utils/Icon";
import {LinkButton} from "../buttons/buttons";
import {Switch} from "antd";
import MediaSelectorModal from "../media/MediaSelectorModal";

//import utils
import {generateFileMediaUrl} from "../../utils/generateMemberMediaUrl";
import {toggleStateField} from "../../utils/helperFunctions";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";

class FuelRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            translations: {},
            price: "",
            marketPrice: "",
            companyPrice: "",
            iconPath: null,
            isHidden: false,
            loading: false,
            mediaModalOpen: false,
        };
        this.errorsSet = new Set();
        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.getInputValues = this.getInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.getTranslatableInputValues = this.getTranslatableInputValues.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.updateFuelData = this.updateFuelData.bind(this);
    }

    componentDidMount() {
        const {activeLanguages, data} = this.props;
        const translationsData = {};
        activeLanguages && activeLanguages.forEach(lg => {
            translationsData[lg.id] = {
                title: '',
                shortName: '',
            }
        });

        data.translations.forEach((item) => {
            translationsData[item.language] = {
                title: item.title || '',
                shortName: item.shortName || '',
            };
        });

        translationsData && this.setState({
            translations: translationsData,
            price: data?.price,
            iconPath: data?.iconPath,
            marketPrice: data?.marketPrice,
            companyPrice: data?.companyPrice,
            isHidden: data?.isHidden,
        });
    }

    getInputValues({name, value, haveChanges}) {
        haveChanges
            ? this.updatedDataMap.set(name, value)
            : this.updatedDataMap.delete(name);
        this.setState({
            [name]: value
        })
    }

    getMedia(mediaArray) {
        const {data} = this.props;
        (!data?.iconPath || (data?.iconPath?.id !== mediaArray[0].id)) ?
            this.updatedDataMap.set("iconPath", mediaArray[0].id) :
            this.updatedDataMap.delete("iconPath");

        this.setState({
            iconPath: mediaArray[0]
        })
    }

    getTranslatableInputValues({name, value, haveChanges}) {
        const {languageTab} = this.props;
        const {translations} = this.state;
        haveChanges
            ? this.updatedDataMap.set(name + languageTab, value)
            : this.updatedDataMap.delete(name + languageTab);
        this.setState({
            translations: {
                ...translations,
                [languageTab]: {
                    ...translations[languageTab],
                    [name]: value
                }
            }
        })
    }

    validateFields(errors, validationData) {
        const {data} = this.props;
        this.errorsSet.clear();
        errors.fields && errors.fields.forEach(key => {
            if (!validationData[key] || validationData[key] === "" || validationData[key] === 0) {
                // console.log(key)
                // console.log(validationData)
                this.errorsSet.add(key);
            }
        });
        errors.translations && errors.translations.forEach(key => {
            const {activeLanguages} = this.props;
            //console.log('activeLanguages', activeLanguages);
            activeLanguages && activeLanguages.forEach(lg => {
                const trData = validationData?.translations?.[lg?.id] || {};
                if (!trData || !trData[key] || trData[key] === "") {
                    // console.groupCollapsed(` %c Translation`, "color:red");
                    // console.log( trData);
                    // console.log( key);
                    // console.log( lg);
                    // console.groupEnd();
                    this.errorsSet.add(key + lg.id);
                    this.props.errorsTabs.add(`${lg.id}-${data.id}`);
                    // console.log('this.props.errorsTabs', this.props.errorsTabs);
                }
            });
        });
        return !this.errorsSet.size;
    }

    async updateFuelData() {
        await this.setState({loading: true});
        const errors = {
            translations: ["title", 'shortName'],
            fields: ["price", 'marketPrice', 'companyPrice'],
        };
        const validationResult = this.validateFields(errors, this.state);
        // console.log(`%c validationResult`, "color:red", validationResult);
        // console.log(`%c errorSet`, "color:orange", this.errorsSet);


        if (validationResult) {
            const {data, activeLanguages} = this.props;
            const {translations} = this.state;
            let TR_data = [];
            Object.keys(translations).forEach(key => {
                TR_data.push({
                    language: key,
                    title: translations[key].title,
                    shortName: translations[key].shortName
                });
            });

            const reqData = {};
            this.updatedDataMap.forEach((_, key) => {
                if (key.includes('title')
                    || key.includes('shortName')) {
                    reqData.translations = TR_data
                }
                key === 'price' && (reqData.price = this.state.price);
                key === 'marketPrice' && (reqData.marketPrice = this.state.marketPrice);
                key === 'companyPrice' && (reqData.companyPrice = this.state.companyPrice);
                key === 'isHidden' && (reqData.isHidden = this.state.isHidden);
                key === 'iconPath' && (reqData.iconPath = this.state.iconPath?.id);
            });
            this.props.UpdateFuel(data.id, reqData).then(() => {
                this.updatedDataMap.clear();
                this.errorsSet.clear();
                activeLanguages.forEach((lg) => {
                    this.props.errorsTabs.delete(`${lg.id}-${data.id}`);
                });
            }).finally(() => {
                this.setState({loading: false});
                this.props.handleTabError();
            })
        } else {
            this.setState({loading: false});
            this.props.handleTabError();
        }

    }

    render() {
        const {languageTab, data} = this.props;
        const {price, marketPrice, companyPrice, isHidden, loading, translations, mediaModalOpen, iconPath} = this.state;
        const {title = '', shortName = ''} = translations[languageTab] || {};

        const initTranslations = data?.translations?.find(lg => lg.language === languageTab);

        // console.groupCollapsed(`Fuel %c ${title}`, "color:red");
        // console.log("initTranslations", initTranslations);
        // console.groupEnd();
        return <tbody className="fuel-row-wrapper">
        <tr className="fuel-row-content">
            <td className="fuel-row-item">
                <img className="fuel-img" src={generateFileMediaUrl(iconPath?.path)}
                     onClick={this.toggleMediaModal} alt=""/>
            </td>
            <td className="fuel-row-item">
                <InputGroup inputType="input"
                            type="text"
                            placeholder="Անվանում"
                            name="title"
                            maxLength={256}
                            value={title}
                            initValue={initTranslations?.title}
                            error={this.errorsSet.has('title' + languageTab)}
                            onChange={this.getTranslatableInputValues}/></td>
            <td className="fuel-row-item">
                <InputGroup inputType="input"
                            type="text"
                            placeholder="Կարճ անուն"
                            name={"shortName"}
                            maxLength={256}
                            value={shortName}
                            initValue={initTranslations?.shortName}
                            error={this.errorsSet.has('shortName' + languageTab)}
                            onChange={this.getTranslatableInputValues}/></td>
            <td className="fuel-row-item">
                <InputGroup inputType="input"
                            type="number"
                            placeholder="Արժեք"
                            name="marketPrice"
                            value={marketPrice}
                            initValue={data.marketPrice}
                            error={this.errorsSet.has('marketPrice')}
                            onChange={this.getInputValues}/></td>
            <td className="fuel-row-item">
                <InputGroup inputType="input"
                            type="number"
                            placeholder="Արժեք"
                            name="price"
                            value={price}
                            initValue={data.price}
                            error={this.errorsSet.has('price')}
                            onChange={this.getInputValues}/></td>
            <td className="fuel-row-item">
                <InputGroup inputType="input"
                            type="number"
                            placeholder="Արժեք"
                            name="companyPrice"
                            value={companyPrice}
                            initValue={data.companyPrice}
                            error={this.errorsSet.has('companyPrice')}
                            onChange={this.getInputValues}/></td>
            <td className="fuel-row-item">
                <Switch checked={!isHidden}
                        onChange={(checked) => this.getInputValues({
                            name: "isHidden",
                            value: !checked,
                            haveChanges: data.isHidden !== !checked
                        })}/>
            </td>
            <td className="actions">

                <LinkButton className={'bg-green'}
                            disabled={!this.updatedDataMap.size}
                            loading={loading}
                            title={<MaterialIcon icon="check"/>}
                            cb={this.updateFuelData}/>
            </td>
        </tr>
        <MediaSelectorModal
            isOpen={!!mediaModalOpen}
            acceptTypes={ACCEPT_IMAGE_TYPES}
            getMedia={this.getMedia}
            closeModal={this.toggleMediaModal}
        />
        </tbody>
    }
}

export default FuelRow;
