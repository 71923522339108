import {request, _urlReviews} from "../api";
import {REVIEW_CONSTS, UTIL_CONSTS} from "../constants";

export const GetReviews = ({text = '',} = {}) => {
    let url = `${_urlReviews}`;
    text && (url += `?text=${encodeURIComponent(text)}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then(({data}) => {
                data && dispatch({
                    type: REVIEW_CONSTS.GET_REVIEWS,
                    payload: {
                        data: data?.reviews,
                        text,
                    }
                });
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const GetNotSeenReviewsCount = () => {
    let url = `${_urlReviews}/not-seen-count`;
    const requestData = {
        url,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({data}) => {
               // console.log('non-seen-count', data);
                data && dispatch({
                    type: REVIEW_CONSTS.GET_NOT_SEEN_REVIEWS_COUNT,
                    payload: data
                });
            })
    }
};

export const SetReviewAsSeen = (id) => {
    let url = `${_urlReviews}/${id}/seen`;
    const requestData = {
        url,
        token: true,
        method: "PUT",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: REVIEW_CONSTS.SET_REVIEW_AS_SEEN,
                    payload: id
                });
            })
    }
};

export const SetAllReviewsAsSeen = () => {
    let url = `${_urlReviews}/seen-all`;
    const requestData = {
        url,
        token: true,
        method: "PUT",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                 dispatch({
                    type: REVIEW_CONSTS.SET_ALL_REVIEWS_AS_SEEN,
                });
            })
    }
};

