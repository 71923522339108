import React, {useEffect, useState} from "react";

import {HeaderLogo} from "./headerLogo";
import {MenuDropdown} from "./MenuDropdown";

import "../../assets/styles/headerStyles/mainHeader.scss";
import {ReactComponent as NotificationIcon} from "../../assets/images/ic_notification.svg";
import NotificationDropdown from "../uiElements/NotificationDropdown";

export function MainHeader(props) {
    const {toggleMenu, LogOut, user, isAdmin, leftSideMenuOpen, nonOpenedNotificationCount} = props;
    const [toggleDropdown, setToggleDropdown] = useState(false);

    useEffect(()=> {
        window.addEventListener('click', toggleWindow);
        return () => {
            window.removeEventListener('click', toggleWindow)
        }
    }, []);

    const toggleWindow = () => {
        setToggleDropdown(false)
    };

    return <header className="main-header">
        <HeaderLogo toggleMenu={toggleMenu}
                    leftSideMenuOpen={leftSideMenuOpen}/>
        <div className="right-content">
            {isAdmin && <div className="notification-wrapper">
                <div className="notification-icon" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setToggleDropdown(!toggleDropdown)
                }}>
                    <NotificationIcon title={''}/>
                    {!!nonOpenedNotificationCount && <div className="badge"/>}
                </div>
                {toggleDropdown && <NotificationDropdown/>}
            </div>}
            <MenuDropdown LogOut={LogOut}
                          isAdmin={isAdmin}
                          user={user}/>
        </div>
    </header>
}