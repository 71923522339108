// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {DatePicker} from "antd";

// Import Styles
import '../../assets/styles/containerStyles/user-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {Switch} from "antd";
import moment from "moment";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {validateFields} from "./utils/actionFunctions";
import {GENDER_TYPES, CAR_Types} from "../../constants/constTypes";
import {AddUser, GetUserById, UpdateUser, ClearUserById} from "../../redux/actions";
import {getInputValues} from "../../utils/helperFunctions";
import ItemsList from "../../components/ItemsList";

const dateFormat = 'DD.MM.YYYY';
const genderOptions = Object.keys(GENDER_TYPES).map(key => ({id: key, name: GENDER_TYPES[key]}));

class AddEditUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsData: {
                username: '',
                firstName: "",
                lastName: "",
                password: "",
                repeatPassword: "",
                language: props.mainLanguage,
                birthday: '',
                gender: 'MALE',
                address: "",
                email: "",
                driverLicense: "",
                passportNumber: "",
                socialServicesNumber: "",
                isBlocked: false
            },
            loading: false,
            isEditing: !!props?.id,
        };
        this.editingData = null;
        this.errorsSet = new Set();
        this.updatedDataMap = new Map();
        this.validateFields = validateFields.bind(this);
        this.getInputValues = getInputValues.bind(this);
        this.handleChangeData = this.handleChangeData.bind(this);
        this.AddEditUser = this.AddEditUser.bind(this);
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;
        if (isEditing) {
            const id = this.props?.id;
            await this.props.GetUserById(id);
            const {userById} = this.props;
            if (userById) {
                const userData = {};
                Object.keys(fieldsData).forEach(field => {
                    if (field !== 'language' && field !== 'balances') {
                        if (field === 'birthday') {
                            userData[field] = userById[field] ? moment(userById[field]) : '';
                        } else {
                            userData[field] = userById[field] ?? '';
                        }
                    }
                });
                this.setState({
                    fieldsData: userData
                })
            }

        }

    }

    componentWillUnmount() {
        this.props.ClearUserById();
    }

    disabledStartDate = value => {
        if (value.valueOf() > moment(new Date()).add(-1, 'days').valueOf()) {
            return true;
        }
    };

    handleChangeData(field, value) {
        const {userById} = this.props;
        if (this.state.isEditing) {
            !userById?.birthday || moment(value).format('YYYY-MM-DD') !== moment(userById.birthday).format('YYYY-MM-DD')
                ? this.updatedDataMap.set(field, value)
                : this.updatedDataMap.delete(field);
        }
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                [field]: value
            }
        })
    }


    async AddEditUser() {
        await this.setState({loading: true});
        const {fieldsData, isEditing} = this.state;
        const errors = {
            requiredFields: ["username", 'firstName', 'lastName',],
        };
        // Փոփոխություն անելուց password դաշտը ոչ պարդատիր է
        if (!isEditing || fieldsData.password) {
            errors.requiredFields.push('password', 'repeatPassword');
        }
        const validationResult = this.validateFields(errors, fieldsData);
        // console.log('validationResult', validationResult);
        // console.log('errors', this.errorsSet);
        if (validationResult) {
            const reqData = {};

            if (isEditing) {
                const reqData = Object.fromEntries(this.updatedDataMap);
                this.props.UpdateUser(this.props.userById.id, reqData).finally(() => {
                    this.setState({loading: false});
                })
            } else {
                Object.keys(fieldsData).forEach(field => {
                    fieldsData[field] && (reqData[field] = fieldsData[field]);
                });
                delete reqData.repeatPassword;
                fieldsData.birthday && (reqData.birthday = moment(fieldsData.birthday).format("YYYY-MM-DDTHH:mm:ss.SSS") + 'Z');

                this.props.AddUser(reqData).finally(() => {
                    this.setState({loading: false});
                })
            }
        } else {
            this.setState({loading: false});
        }
    }


    render() {
        const {fieldsData, loading, isEditing} = this.state;
        let {userById, requestLoading} = this.props;
        const checkbox = <div className="right-side">
            <label>{!fieldsData.isBlocked ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
            <Switch checked={!fieldsData.isBlocked}
                    onChange={(checked) => this.getInputValues({
                        name: "isBlocked",
                        value: !checked,
                        haveChanges: userById?.isBlocked !== !checked
                    })}/>
        </div>;
        let itemListHeaderInfo = {
            generalInfo: ["Ավտոմեքենա", "Հաշվառման համարանիշ", "Տ/Մ տեսակ", "Գույն", "Տարեթիվ", "Նույն. համար", "Շարժիչի հզոր.", "Վառելիք", "100 կմ/լ", "Կարգավիճակ", "ԱՊՊԱ պայմ․ ավարտ"],
            largeItems: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        };

        let itemListInfo = userById?.cars?.map((car) => {
            const isHidden = !car?.isHidden ? "Ակտիվ" : "Ոչ ակտիվ";

            return {
                id: car.id,
                values: [
                    car?.carMake?.name + " " + car?.carModel?.name || "",
                    car?.plateNumber || "-",
                    CAR_Types[car?.type] || "-",
                    car?.color || "-",
                    car?.year || "-",
                    car?.vinCode || "-",
                    car?.enginePower || "-",
                    car?.fuel?.shortName || "-",
                    car?.fuelConsumption || "-",
                    isHidden,
                    moment(car?.insuranceContractExpiryDate).format('DD.MM.YYYY') || "-",
                ],
            }
        });
        return <PageWrapper>
            <section className="user-add-edit">
                <PageHeader pageTitle={`${isEditing ? 'Փոփոխել Օգտատիրոջը' : 'Ավելացնել Օգտատեր'} `}
                            rightTopProps={checkbox}/>
                <div className="user-fields">
                    <div className="fields-section-one">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Անուն<span className="field-required">*</span></>}
                                        placeholder="Անուն"
                                        name="firstName"
                                        value={fieldsData.firstName}
                                        initValue={userById?.firstName}
                                        maxLength={50}
                                        error={this.errorsSet.has('firstName')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Ազգանուն<span
                                            className="field-required">*</span></>}
                                        placeholder="Ազգանուն"
                                        name="lastName"
                                        value={fieldsData.lastName}
                                        initValue={userById?.lastName}
                                        maxLength={50}
                                        error={this.errorsSet.has('lastName')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="selectCustom"
                                        label={"Սեռ"}
                                        placeholder="Սեռ"
                                        name="gender"
                                        showSearch={false}
                                        className={"gender-select"}
                                        value={fieldsData.gender}
                                        initValue={userById?.gender}
                                        onChange={this.getInputValues}
                                        options={genderOptions}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType={"wrapper"}
                                        label={"Ծննդյան ամսաթիվ"}>
                                <DatePicker value={fieldsData.birthday}
                                            format={dateFormat}
                                            showToday={false}
                                            allowClear={false}
                                            disabledDate={this.disabledStartDate}
                                            placeholder="Ծննդյան ամսաթիվ"
                                            className={`date-picker ${this.errorsSet.has('birthday') ? 'invalid' : ''}`}
                                            onChange={(value) =>
                                                this.handleChangeData('birthday', value)}>
                                </DatePicker>
                            </InputGroup>
                        </div>
                    </div>
                    <div className="fields-section-two">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Հեռ․ համար<span
                                            className="field-required">*</span></>}
                                        placeholder="+374..."
                                        name="username"
                                        value={fieldsData.username}
                                        initValue={userById?.username}
                                        maxLength={20}
                                        error={this.errorsSet.has('username')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="email"
                                        label={'Էլ․ հասցե'}
                                        placeholder="Էլ․ հասցե"
                                        name="email"
                                        value={fieldsData.email}
                                        initValue={userById?.email}
                                        maxLength={50}
                                        error={this.errorsSet.has('email')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Հասցե"}
                                        placeholder="Հասցե"
                                        name="address"
                                        value={fieldsData.address}
                                        initValue={userById?.address}
                                        maxLength={1000}
                                        onChange={this.getInputValues}/>
                        </div>
                    </div>
                    <div className="fields-section-three">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Վարորդական վկայական"}
                                        placeholder="Վարորդական վկայական"
                                        name="driverLicense"
                                        value={fieldsData.driverLicense}
                                        initValue={userById?.driverLicense}
                                        maxLength={8}
                                        error={this.errorsSet.has('driverLicense')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Անձնագրի սերիա"}
                                        placeholder="Անձնագրի սերիա"
                                        name="passportNumber"
                                        value={fieldsData.passportNumber}
                                        initValue={userById?.passportNumber}
                                        maxLength={9}
                                        error={this.errorsSet.has('passportNumber')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"ՀԾՀ"}
                                        placeholder="ՀԾՀ"
                                        name="socialServicesNumber"
                                        value={fieldsData.socialServicesNumber}
                                        initValue={userById?.socialServicesNumber}
                                        regExp={/^\d*$/}
                                        maxLength={11}
                                        error={this.errorsSet.has('socialServicesNumber')}
                                        onChange={this.getInputValues}/>
                        </div>
                    </div>
                    <div className="fields-section-four">
                        <input style={{opacity: 0, position: "absolute", top: "-100000px"}}/>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="password"
                                        label={<>Գաղտնաբառ{!isEditing && <span
                                            className="field-required">*</span>}</>}
                                        placeholder="Գաղտնաբառ"
                                        autocomplete={'new-password'}
                                        name="password"
                                        value={fieldsData.password}
                                        initValue={''}
                                        maxLength={20}
                                        error={this.errorsSet.has('password')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="password"
                                        label={<>Կրկնել Գաղտնաբառը{!isEditing && <span
                                            className="field-required">*</span>}</>}
                                        placeholder="Կրկնել Գաղտնաբառը"
                                        autocomplete={'new-password'}
                                        name="repeatPassword"
                                        value={fieldsData.repeatPassword}
                                        initValue={userById?.name}
                                        maxLength={20}
                                        error={this.errorsSet.has('repeatPassword')}
                                        onChange={this.getInputValues}/>
                        </div>

                    </div>
                    {/*                    <div className="separator"/>
                    <div className="fields-section-five">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Մակնիշ"}
                                        placeholder="Մակնիշ"
                                        name="make"
                                        value={fieldsData.make}
                                        maxLength={256}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Մոդել"}
                                        placeholder="Մոդել"
                                        name="model"
                                        value={fieldsData.model}
                                        maxLength={256}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Տարեթիվ"}
                                        placeholder="Տարեթիվ"
                                        name="carDate"
                                        value={fieldsData.carDate}
                                        maxLength={256}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Համարանիշ"}
                                        placeholder="Համարանիշ"
                                        name="carNumber"
                                        value={fieldsData.carNumber}
                                        maxLength={256}
                                        onChange={this.getInputValues}/>
                        </div>
                    </div>
                    <div className="fields-section-six">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Նույնականացման համար"}
                                        placeholder="Նույնականացման համար"
                                        name="idNum"
                                        value={fieldsData.idNum}
                                        maxLength={256}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="wrapper"
                                        label={"Շահագործվող վառելիք"}>
                                <Select
                                    className={"add-user-select"}
                                    dropdownClassName={"add-user-dropdown"}
                                    showSearch
                                    placeholder="Select a fuel"
                                    optionFilterProp="children"
                                    showArrow={false}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {!!fuelList && fuelList.length && fuelList.map((item, index) => {
                                        return <Select.Option
                                            key={index}>{item.fuelTitle}</Select.Option>
                                    })}

                                </Select>
                            </InputGroup>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType={"wrapper"}
                                        label={"ԱՊՊԱ պայմանագրի ավարտ"}>
                                <DatePicker value={fieldsData.insurance}
                                            format={dateFormat}
                                            placeholder="ԱՊՊԱ պայմանագրի ավարտ"
                                            disabledDate={this.disabledStartDate}
                                            className={`date-picker`}
                                            onChange={(value) =>
                                                this.handleChangeData('insurance', value)}>
                                </DatePicker>
                            </InputGroup>
                        </div>
                    </div>*/}
                    <div className="add-user">
                        <LinkButton title={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'}`}
                                    loading={loading}
                                    disabled={!this.updatedDataMap.size && isEditing}
                                    cb={this.AddEditUser}/>
                    </div>
                    {isEditing && !!userById?.cars?.length &&  <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                                itemListInfo={itemListInfo}
                                loading={requestLoading}/>}
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    AddUser,
    ClearUserById,
    UpdateUser,
    GetUserById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUser)
