// Import packages
import React, { Component } from "react";

//import components
import ReactExport from "react-data-export";
import { LinkButton } from "../buttons/buttons";

//import utils
import {STATION_HISTORY_TYPES, STATION_NETWORK_SALES_TYPES} from "../../constants/constTypes";
import moment from "moment";

//import excel styles
import { headerStyle, headerWidth, oddRowStyle } from "../../constants/excelConstStyles";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


class DownloadTransfersExcel extends Component {
    constructor(){
        super();
        this.state = {
            data: [],
            loading: false,
        }
        this.excelButton = React.createRef();
    }

    downloadExcel = async() => {
        const { historyList, limit, reqData } = this.props;
        const { text, status, stationId, startDate, endDate, type, stationNetwork} = historyList;
        await this.setState({ loading: true });
        try {
            const exportData = await this.props.GetTransferRequests({
                limit: limit,
                text,
                status,
                stationId,
                startDate,
                endDate,
                type,
                stationNetwork,
                getResponse: true,
                ...reqData
            });
            await this.setState({ data: exportData?.requests });
            this.excelButton.current.click();

        } catch {
            console.log('ERROE');
        }
        this.setState({ loading: false, data: [] });
    }

    render(){
        const { historyList, isSale } = this.props;
        const { data, loading } = this.state;
        const emptyRow = [{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }];
        const downloadedData = !!data?.length && [{
            columns: isSale ? [
                { title: "Ամսաթիվ", width: headerWidth, style: headerStyle },
                { title: "Ցանց", width: headerWidth, style: headerStyle },
                { title: "Տեսակ", width: headerWidth, style: headerStyle },
                { title: "Քանակ", width: headerWidth, style: headerStyle },
                { title: "Գումար", width: headerWidth, style: headerStyle },
                { title: "Կարգավիճակ", width: headerWidth, style: headerStyle },
            ] : [
                { title: "Ամսաթիվ", width: headerWidth, style: headerStyle },
                { title: "Ցանց", width: headerWidth, style: headerStyle },
                { title: "Գումար", width: headerWidth, style: headerStyle },
                { title: "Կարգավիճակ", width: headerWidth, style: headerStyle },
            ],
            data: [emptyRow, ...data?.map((history, index) => {
                const {  amount, sum, fuel, filledDate, status, stationNetwork } = history || {};
                const bgStyle = index % 2 === 0 ? oddRowStyle : {};
                const cellStyle = { font: { sz: "11" }, ...bgStyle };

                return isSale? [
                    { value: filledDate ? moment(filledDate).format("DD.MM.YY") : '-', style: cellStyle },
                    { value: stationNetwork?.title || '-', style: cellStyle },
                    { value: fuel?.shortName || '-', style: cellStyle },
                    { value: amount || "-", style: cellStyle },
                    { value: sum || '-', style: cellStyle },
                    { value: status ? STATION_NETWORK_SALES_TYPES[status] : '-', style: cellStyle },
                ] : [
                    { value: filledDate ? moment(filledDate).format("DD.MM.YY") : '-', style: cellStyle },
                    { value: stationNetwork?.title || '-', style: cellStyle },
                    { value: sum || '-', style: cellStyle },
                    { value: status ? STATION_NETWORK_SALES_TYPES[status] : '-', style: cellStyle },
                ]
            })],
        }];

        return <div>
            {!!data?.length && <ExcelFile element={<span ref={this.excelButton} style={{ visibility: 'hidden' }}/>}
                                          filename={'History_' + moment().format('DD-MM-YYYY')}>
                <ExcelSheet dataSet={downloadedData}
                            name={'History'}/>
            </ExcelFile>}
            <LinkButton title={'Excel'}
                        cb={this.downloadExcel}
                        loadingWithTitle
                        disabled={!historyList?.itemsList?.length}
                        loading={loading}/>
        </div>
    }
}

export default DownloadTransfersExcel
