import isURL from "validator/lib/isURL";

export function validateFields(errors, validationData) {
    const {activeLanguages} = this.props;
    this.errorsSet.clear();
    this.errorsTabs.clear();
    errors.requiredFields && errors.requiredFields.forEach(key => {
        if (!validationData[key] || validationData[key] === ""
            || (Array.isArray(validationData[key]) && !validationData[key]?.length)) {
            this.errorsSet.add(key);
            return false;
        }
    });
    if (validationData.url && !isURL(validationData.url)) {
        this.errorsSet.add('url');
    }
    activeLanguages && activeLanguages.forEach(lg => {
        const trData = validationData?.translations?.[lg.id] || {};
        let tabHasError = false;
        errors.translations && errors.translations.forEach(key => {

            if (!trData || !trData[key] || trData[key] === "") {
                this.errorsSet.add(key + '_' + lg.id);
                tabHasError = true;
            }
        });
        tabHasError && this.errorsTabs.add(lg.id);
    });
         // console.log(this.errorsSet)
    return !this.errorsSet.size;
}
