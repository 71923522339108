// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";


// Import styles
import "../assets/styles/containerStyles/contact.scss";

// Import components
import {InputGroup} from "../components/uiElements/inputGroup";
import PageWrapper from '../components/pageContentViews/pageWrapper'
import {LinkButton} from "../components/buttons/buttons";
import {LanguageTabs} from "../components/uiElements/Tabs";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {GetContact, UpdateContact} from "../redux/actions";
import {ContactAddingList} from "../constants/contactAddingList";
import {
    changeStateField,
    getInputValues,
    initTranslations,
    getTranslatableInputValues
} from "../utils/helperFunctions";

export class Contact extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = ['address', 'privacyPolicy',];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                email: "",
                phoneNumber1: "",
                phoneNumber2: "",
                messenger: "",
                viber: "",
                whatsApp: "",
            },
            isEditing: true,
            languageTab: props.mainLanguage,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.validateFields = this.validateFields.bind(this);
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.updateContact = this.updateContact.bind(this);
    }


    async componentDidMount() {
        !this.props?.contactData?.id && await this.props.GetContact();
        const {contactData} = this.props;

        const translations = {...this.state.fieldsData.translations};
        contactData?.translations && contactData.translations.map(translation => {
            this.translationsFields.forEach(item => {
                translations[translation.language] && (translations[translation.language][item] = translation[item] ?? '');
            });
        });
        contactData && this.setState({
            fieldsData: {
                email: contactData.email || "",
                phoneNumber1: contactData.phoneNumber1 || "",
                phoneNumber2: contactData.phoneNumber2 || "",
                messenger: contactData.messenger || "",
                viber: contactData.viber || "",
                whatsApp: contactData.whatsApp || "",
                translations: translations
            },
        });

    }


    validateFields(constacData) {
        this.errorsSet.clear();
        this.errorsTabs.clear();
        Object.keys(constacData.translations).forEach(key => {
            const trData = constacData.translations[key] || {};
            let tabHasError = false;
            this.translationsFields.forEach(item => {
                if (!trData || !trData[item] || trData[item] === "") {
                    this.errorsSet.add(item + '_' + key);
                    tabHasError = true;
                }
            });
            tabHasError && this.errorsTabs.add(key);
        });
        const phoneNumberRegExp = /^[+]?[0-9]{9,20}$/;
        if (constacData?.phoneNumber1?.length && !phoneNumberRegExp.test(constacData.phoneNumber1)) {
            this.errorsSet.add('phoneNumber1');
        }
        if (constacData?.phoneNumber2?.length && !phoneNumberRegExp.test(constacData.phoneNumber2)) {
            this.errorsSet.add('phoneNumber2');
        }
        return !this.errorsSet.size;
    }

    updateContact() {
        const {fieldsData} = this.state;
        const {translations} = fieldsData;
        if (this.validateFields(fieldsData)) {
            let TR_data = [];
            Object.keys(translations).forEach(key => {
                const reqTrItem = {
                    language: key,
                };
                const trItem = translations[key];
                Object.keys(trItem).forEach(field => {
                    reqTrItem[field] = trItem[field];
                });
                TR_data.push(reqTrItem);
            });
            let reqData = {
                email: fieldsData.email,
                phoneNumber1: fieldsData.phoneNumber1,
                phoneNumber2: fieldsData.phoneNumber2,
                messenger: fieldsData.messenger,
                viber: fieldsData.viber,
                whatsApp: fieldsData.whatsApp,
                translations: TR_data,
            };
            //console.log('reqData', reqData);
            //
            this.props.UpdateContact(reqData).then(() => {
                this.updatedDataMap.clear();
                this.forceUpdate();
            })
        } else {
            this.forceUpdate();
        }
    }

    render() {
        const {requestLoading, contactData: contact} = this.props;
        const {fieldsData, languageTab} = this.state;
        const languageKey = '_' + languageTab;
        const {translations} = fieldsData;
        const initTranslations = contact?.translations?.find(lg => lg.language === languageTab);

        const trData = translations[languageTab] || {};
        return <PageWrapper pageTitle={'Contact'}>
            <section className="contact">

                <div className="info-adding-fields">
                    {ContactAddingList.slice(0, 3).map((item, index) => {
                        return <InputGroup key={index}
                                           {...item}
                                           error={this.errorsSet.has(item.name)}
                                           value={fieldsData?.[item.name]}
                                           initValue={contact?.[item.name]}
                                           onChange={this.getInputValues}/>
                    })}
                    <div className="social-links">
                        <label>Սոցիալական հղումներ</label>
                        {ContactAddingList.slice(3).map((item, index) => {
                            return <InputGroup key={index}
                                               {...item}
                                               error={this.errorsSet.has(item.name)}
                                               value={fieldsData?.[item.name]}
                                               initValue={contact?.[item.name]}
                                               onChange={this.getInputValues}/>
                        })}
                    </div>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={this.errorsTabs}
                              activeTab={languageTab}/>
                <InputGroup inputType="input"
                            type="text"
                            label={<>Հասցե<span
                                className="field-required">*</span></>}
                            name="address"
                            maxLength={100}
                            value={trData.address}
                            initValue={initTranslations?.address}
                            vasya={'address' + languageKey}
                            error={this.errorsSet.has('address' + languageKey)}
                            onChange={this.getTranslatableInputValues}/>
                <InputGroup inputType="textarea"
                            label={<>Գաղտնիության քաղաքականություն<span
                                className="field-required">*</span></>}
                            name="privacyPolicy"

                            value={trData.privacyPolicy}
                            initValue={initTranslations?.privacyPolicy}
                            error={this.errorsSet.has('privacyPolicy' + languageKey)}
                            onChange={this.getTranslatableInputValues}/>
                <div className="flex-wrapper-right">
                    <LinkButton title="Պահպանել"
                                disabled={!this.updatedDataMap.size}
                                loading={requestLoading}
                                cb={this.updateContact}/>
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetContact,
    UpdateContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
