// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import "../../assets/styles/containerStyles/offers.scss";

// Import components
import PageHeader from "../../components/pageContentViews/pageHeader";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import {CustomTabs} from "../../components/uiElements/Tabs";
import Tires from "./tires/Tires";
import Spares from "./spares/Spares";
import Repairing from "./repairing/Repairing";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetOffers, GetNotSeenOffersCount} from "../../redux/actions";
import {tabs} from "./utils/constants";
import {OFFERS_TYPE} from "../../constants/constTypes";
import {changeStateField} from "../../utils/helperFunctions";


class Offers extends Component {

    constructor() {
        super();
        this.state = {
            activeTab: OFFERS_TYPE.TYRE_CHANGE,
        };
        this.changeTab = changeStateField.bind(this, 'activeTab');
    }

    componentDidMount() {
        Object.keys(OFFERS_TYPE).forEach(offer => {
            this.props.GetOffers({type: OFFERS_TYPE[offer]});
            this.props.GetNotSeenOffersCount(OFFERS_TYPE[offer]);
        });
    }

    render() {
        const {tyreChangeList, repairingList, sparesList} = this.props;
        const {activeTab} = this.state;
        const  tabs = [
            {
                name: "Անվադողեր",
                key: "tyre-change",
                notSeenCount: tyreChangeList?.notSeenCount,
            },
            {
                name: "Ավտոպահեստամասեր",
                key: "spares",
                notSeenCount: sparesList?.notSeenCount,
            },
            {
                name: "Ավտոտեխսպասարկում",
                key: "repairing",
                notSeenCount: repairingList?.notSeenCount,
            }
        ]
        // console.log(tabs, 'tabs');
        return <PageWrapper>
            <section className="offers">
                <PageHeader pageTitle={'Հայտեր'}
                            search={false}/>
                <CustomTabs changeTab={this.changeTab}
                            activeTab={activeTab}
                            tabsList={tabs}/>
                {activeTab === OFFERS_TYPE.TYRE_CHANGE && <Tires data={tyreChangeList}/>}
                {activeTab === OFFERS_TYPE.SPARES && <Spares data={sparesList}/>}
                {activeTab === OFFERS_TYPE.REPAIRING && <Repairing data={repairingList}/>}
            </section>

        </PageWrapper>

    }
}

const mapDispatchToProps = {
    GetOffers,
    GetNotSeenOffersCount
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers)
