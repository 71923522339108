// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import "../../../assets/styles/containerStyles/tire-details.scss";

// Import components
import PageHeader from "../../../components/pageContentViews/pageHeader";
import PageWrapper from "../../../components/pageContentViews/pageWrapper";

// Import utils
import {mapStateToProps} from "../../../redux/mapStateToProps";
import {SetOffersAsSeen} from "../../../redux/actions";
import moment from "moment";
import {OFFERS_TYPE} from "../../../constants/constTypes";


class SparesDetails extends Component {

    constructor(props) {
        super(props);
        this.sparesData = props.sparesList?.itemsList?.find(spares => spares.id === props.id);
    }

    componentDidMount() {
        const {id} = this.props;
        this.sparesData && !this.sparesData.seen && this.props.SetOffersAsSeen(id, OFFERS_TYPE.SPARES);
    }

    render() {
        return <PageWrapper>
            <section className="tire-details">
                <PageHeader pageTitle={"Ավտոպահեստամաս"}/>
                <div className="tire-details-wrapper">
                    <div className="left-part">
                        <h1 className="data-title">Հայտի տվյալներ</h1>
                        <table className="data-view">
                            <tbody>
                            {this.sparesData?.createdAt &&
                            <tr>
                                <td className="text-bold">Ուղարկման ամսաթիվ։</td>
                                <td className="text-grey">{moment(this.sparesData?.createdAt).format('DD.MM.YYYY')}</td>
                            </tr>
                            }
                            {this.sparesData?.carMake?.name &&
                            <tr>
                                <td className="text-bold">Մակնիշ։</td>
                                <td className="text-grey">{this.sparesData?.carMake?.name}</td>
                            </tr>
                            }
                            {this.sparesData?.carModel?.name &&
                            <tr>
                                <td className="text-bold">Մոդել։</td>
                                <td className="text-grey">{this.sparesData?.carModel?.name}</td>
                            </tr>
                            }
                            {this.sparesData?.year &&
                            <tr>
                                <td className="text-bold">Տարեթիվ։</td>
                                <td className="text-grey">{this.sparesData?.year}</td>
                            </tr>
                            }
                            {this.sparesData?.vinCode &&
                            <tr>
                                <td className="text-bold">VIN համար։</td>
                                <td className="text-grey">{this.sparesData?.vinCode}</td>
                            </tr>
                            }
                            {this.sparesData?.description &&
                            <tr>
                                <td className="text-bold">Պահանջվող պահեստամաս։</td>
                                <td className="text-grey">{this.sparesData?.description}</td>
                            </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="right-part">
                        <h1 className="data-title">Հայտատուի տվյալներ</h1>
                        <table className="data-view">
                            <tbody>
                            {this.sparesData?.user?.firstName &&
                            <tr>
                                <td className="text-bold">Անվանում։</td>
                                <td className="text-grey">{this.sparesData?.user?.firstName + " " + this.sparesData?.user?.lastName}</td>
                            </tr>
                            }
                            {this.sparesData?.user?.username &&
                            <tr>
                                <td className="text-bold">Հեռախոս։</td>
                                <td className="text-grey">{this.sparesData?.user?.username}</td>
                            </tr>
                            }
                            {this.sparesData?.user?.email &&
                            <tr>
                                <td className="text-bold">Էլ․ հասցե։</td>
                                <td className="text-grey">{this.sparesData?.user?.email}</td>
                            </tr>
                            }
                            </tbody>
                        </table>

                    </div>
                </div>
                {/*<div className="offer-buttons">*/}
                {/*    <LinkButton title={"Հաստատել"} className="bg-green"/>*/}
                {/*    <LinkButton title={"Մերժել"} className="bg-red"/>*/}
                {/*</div>*/}
            </section>

        </PageWrapper>;

    }
}

const mapDispatchToProps = {
    SetOffersAsSeen
};

export default connect(mapStateToProps, mapDispatchToProps)(SparesDetails)
