// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import swal from "sweetalert";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetOrderById, DeleteOrderProductById, UpdateOrderStatus, ClearOrders} from "../../redux/actions";


// Import Styles
import '../../assets/styles/containerStyles/order-details.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import DetailsHeader from "../../components/cards/DetailsHeader";
import ItemsList from "../../components/ItemsList";
import {LinkButton} from "../../components/buttons/buttons";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {SkeletonItem} from "../../components/uiElements/SkelatonItem";
import {SkeletonSmallItem} from "../../components/uiElements/SkelatonISmalltem";
import {STATUS_LIST} from "../../constants/constTypes";

class PartnerDetails extends Component {
    async componentDidMount() {
        await this.props.GetOrderById(this.props?.id);
    }

    componentWillUnmount() {
        this.props.ClearOrders();
    }

    deleteOrderProduct = id => {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել ապրանքը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteOrderProductById(this.props?.id, id);
            }
        });
    };

    render() {
        const {orderById, requestLoading} = this.props;
        let action = orderById?.status === 'ACTIVE' && "delete";
        let itemListHeaderInfo = {
            generalInfo: ["Նկար", "Անվանում", "Գին", "Քանակ"],
            largeItems: [1],
            // actions: action && [action]
        };
        let itemListInfo = [];

        orderById?.products?.length && orderById.products.forEach(item => {
            let price = item?.price ? item.price + " AMD" : "-";
            itemListInfo.push({
                id: item?.productId,
                orderStatus: item?.status,
                values: [
                    <img src={generateImageMediaUrl(item?.mediaMain?.path)} alt=""/>,
                    item?.title || "-",
                    price,
                    item?.purchasedQuantity || "-",
                ],
                deleteCb: this.deleteOrderProduct.bind(this, item?.productId)
            });
        });

        const orderData = {
            id: orderById?.id,
            name: (orderById?.user?.firstName || '') + " " + (orderById?.user?.lastName  || ''),
            email: orderById?.user?.email || '',
            phone: orderById?.user?.username || '',
            date: orderById?.createdAt ? moment(orderById?.createdAt).format('DD.MM.YYYY') : '',
            address: orderById?.address || '',
            orderStatus: orderById?.status,
            isPaid: orderById?.isPaid ? "Վճարված է" : "Վճարված չէ",
        };
        return <PageWrapper>
            <section className="order-details">
                <DetailsHeader
                    data={orderData}
                    headerDate={orderData}
                    loading={requestLoading}
                />
                <div className="separator"/>
                <h1 className="orders-header">Պատվիրված ապրանքների ցուցակը</h1>
                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={itemListInfo}
                           loading={requestLoading}
                />
                <div className="down-part">
                    <table className="address-part">
                        <tbody>
                        {orderById?.user?.address &&
                        <tr>
                            <td className="text-bold">Հասցե։</td>
                            <td className="text-grey">{orderById?.user?.address}</td>
                        </tr>
                        }
                        </tbody>
                    </table>
                    {orderById?.sum && <div className="amount">{orderById?.sum} Դ</div>}
                </div>
                <div className="buttons">
                    <LinkButton title={orderById?.status === STATUS_LIST.APPROVED.key ? STATUS_LIST.DONE.title : STATUS_LIST.APPROVED.title}
                                className={"bg-green"}
                                disabled={(orderById?.status !== STATUS_LIST.ACTIVE.key) && (orderById?.status !== STATUS_LIST.APPROVED.key)}
                                cb={orderById?.status === STATUS_LIST.APPROVED.key ? () => this.props.UpdateOrderStatus(this.props?.id, {status: STATUS_LIST.DONE.key})
                                    : () => this.props.UpdateOrderStatus(this.props?.id, {status: STATUS_LIST.APPROVED.key})}
                    />
                    <LinkButton title="Մերժել"
                                className={"bg-red"}
                                disabled={orderById?.status !== STATUS_LIST.ACTIVE.key || orderById.isPaid}
                                cb={() => this.props.UpdateOrderStatus(this.props?.id, {status: STATUS_LIST.REJECTED.key})}
                    />
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetOrderById,
    DeleteOrderProductById,
    UpdateOrderStatus,
    ClearOrders
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerDetails)