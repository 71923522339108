// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon, Select, Switch} from "antd";

// Import Styles
import '../../assets/styles/containerStyles/station-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {validateFields} from "./utils/actionFunctions";
import {
    GetNetworksById,
    AddNetworkSupervisor,
    EditNetworkSupervisor,
    GetStations
} from "../../redux/actions";
import {
    getInputValues,
    getTranslatableInputValues,
    changeStateField,
    toggleStateField,
} from "../../utils/helperFunctions";

class AddEditSupervisor extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [];
        this.state = {
            fieldsData: {
                password: "",
                firstName: "",
                lastName: "",
                repeatPassword: "",
                email: "",
                stations: null,
            },
            mediaModalOpen: false,
            loading: false,
            languageTab: props.mainLanguage,
            isEditing: !!props?.params?.supervisorId,
            passwordVisible: false,
            repeatPasswordVisible: false,
            stationsOptions: undefined,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.validateFields = validateFields.bind(this);
        this.addEditSupervisor = this.addEditSupervisor.bind(this);
    }

    async componentDidMount() {
        const {isEditing} = this.state;
        const {supervisors, params} = this.props;

        this.props.GetStations({
            getResponse: true,
            networkId: this.props?.id
        }).then((res) => {
            let options = res?.map(station => ({
                id: station.id,
                name: station?.translations?.find(tr => tr?.language === this.props.mainLanguage)?.title
            }));
            this.setState({
                stationsOptions: options,
            });

        });
        if (isEditing) {
            this.supervisorsById = supervisors.find(item => item.id === params.supervisorId);
            this.setState({
                fieldsData: {
                    ...this.supervisorsById,
                },
            });
        }
    }

    async addEditSupervisor() {
        await this.setState({loading: true});
        const {params, id} = this.props;
        const {fieldsData, isEditing} = this.state;

        const errors = {
            requiredFields: ['username', "firstName", "lastName", "stations"],
            translations: [],
        };
        // Փոփոխություն անելուց password դաշտը ոչ պարտադիր է
        if (!isEditing || fieldsData.password) {
            errors.requiredFields.push('password', 'repeatPassword');
        }
        const validationResult = this.validateFields(errors, fieldsData);

        if (validationResult) {

            let reqData = {};
            if (isEditing) {
                this.updatedDataMap.forEach((value, item) => {
                    const key = item.split('_')[0];
                    (reqData[key] = value);
                });

                this.props.EditNetworkSupervisor(id, params.supervisorId ,reqData).finally(() => {
                    this.setState({loading: false});
                });

                this.setState({loading: false});

            } else {
                reqData = {...fieldsData};
                Object.keys(fieldsData).forEach(key => !reqData[key] && delete reqData[key]);

                delete reqData.repeatPassword;

                this.props.AddNetworkSupervisor(id, reqData).finally(() => {
                    this.setState({loading: false});
                });

                this.setState({loading: false});
            }
        } else {
            this.setState({loading: false});
        }

    }

    render() {
        const {networkById} = this.props;
        const {
            fieldsData, passwordVisible, repeatPasswordVisible, languageTab, isEditing,
            loading, stationsOptions
        } = this.state;

        return <PageWrapper withActions={true}>
            <section className="station-add-edit">
                <PageHeader pageTitle={`${isEditing ? 'Փոփոխել պատասխանատուին' : 'Ավելացնել պատասխանատու'} `}/>

                <div className="station-fields">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={this.errorsTabs}
                                  activeTab={languageTab}
                    />
                </div>
                <div className="station-fields">
                    <div className="fields-section-one">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="username"
                                        label={<>Էլ․ հասցե<span
                                            className="field-required">*</span></>}
                                        placeholder="Էլ․ հասցե"
                                        name="username"
                                        maxLength={50}
                                        value={fieldsData.username}
                                        initValue={this.supervisorsById?.username}
                                        error={this.errorsSet.has('username')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Անուն<span
                                            className="field-required">*</span></>}
                                        placeholder="Անուն"
                                        name="firstName"
                                        maxLength={256}
                                        value={fieldsData.firstName}
                                        initValue={this.supervisorsById?.firstName}
                                        error={this.errorsSet.has('firstName')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Ազգանուն<span
                                            className="field-required">*</span></>}
                                        placeholder="Ազգանուն"
                                        name="lastName"
                                        maxLength={256}
                                        value={fieldsData.lastName}
                                        initValue={this.supervisorsById?.lastName}
                                        error={this.errorsSet.has('lastName')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="selectCustom"
                                        label={<>Ընտրել լցակայան<span
                                            className="field-required">*</span></>}
                                        placeholder="Ընտրել լցակայան"
                                        name="stations"
                                        mode="multiple"
                                        showSearch={false}
                                        value={fieldsData?.stations || undefined}
                                        error={this.errorsSet.has('stations')}
                                        initValue={this.supervisorsById?.stations}
                                        onChange={this.getInputValues}
                                        options={stationsOptions}>
                            </InputGroup>
                        </div>
                    </div>
                    <div className="fields-section-two">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type={`${passwordVisible ? "text" : "password"}`}
                                        label={<>Գաղտնաբառ<span
                                            className="field-required">*</span></>}
                                        placeholder="Գաղտնաբառ"
                                        name="password"
                                        maxLength={20}
                                        value={fieldsData.password}
                                        autocomplete={'new-password'}
                                        initValue={networkById?.password}
                                        error={this.errorsSet.has('password')}
                                        onChange={this.getInputValues}>
                            <span className={'visibility-icon'}
                                  onClick={toggleStateField.bind(this, "passwordVisible")}>
                                <Icon type={`${passwordVisible ? "eye" : "eye-invisible"}`}/>
                            </span>
                            </InputGroup>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type={`${repeatPasswordVisible ? "text" : "password"}`}
                                        label={<>Կրկնել Գաղտնաբառ<span
                                            className="field-required">*</span></>}
                                        placeholder="Կրկնել Գաղտնաբառը"
                                        name="repeatPassword"
                                        maxLength={20}
                                        autocomplete={'new-password'}
                                        value={fieldsData.repeatPassword}
                                        initValue={networkById?.repeatPassword}
                                        error={this.errorsSet.has('repeatPassword')}
                                        onChange={this.getInputValues}>
                            <span className={'visibility-icon'}
                                  onClick={toggleStateField.bind(this, "repeatPasswordVisible")}>
                                <Icon type={`${repeatPasswordVisible ? "eye" : "eye-invisible"}`}/>
                            </span>
                            </InputGroup>
                        </div>
                    </div>

                    <div className="flex-wrapper-right">
                        <LinkButton title={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'}`}
                                    loading={loading}
                                    disabled={!this.updatedDataMap.size && isEditing}
                                    cb={this.addEditSupervisor}/>
                    </div>
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetNetworksById,
    AddNetworkSupervisor,
    EditNetworkSupervisor,
    GetStations
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSupervisor)
