import moment from "moment";
import isEmail from "validator/lib/isEmail";

export function validateFields(errors, validationData) {
    this.errorsSet.clear();
    const usernameRegExp = /^[+]?[0-9]{9,20}$/;
    const driverLicenseRegExp = /^[a-zA-Z]{2}[0-9]{6}$/;
    const passportNumber1RegExp = /^[a-zA-Z]{2}[0-9]{7}$/;
    const passportNumber2RegExp = /^[0-9]{9}$/;

    errors.requiredFields && errors.requiredFields.forEach(key => {
        if (!validationData[key] || validationData[key] === "") {
            this.errorsSet.add(key);
            return;
        }

        if (key === 'username' && (validationData.username.length < 9
            || validationData.username.length > 20 || !usernameRegExp.test(validationData.username))) {
            this.errorsSet.add(key);
            return;
        }
        if (key === 'password' && (validationData.password.length < 8
            || validationData.password.length > 20)) {
            this.errorsSet.add(key);
            return;
        }

        if (key === 'repeatPassword' && validationData.password !== validationData.repeatPassword) {
            this.errorsSet.add(key);
            return;
        }

        if (validationData?.email?.length && !isEmail(validationData.email)) {
            this.errorsSet.add('email');
            return;
        }

        if (validationData?.driverLicense?.length &&
            (validationData.driverLicense.length > 8 || !driverLicenseRegExp.test(validationData.driverLicense))) {
            this.errorsSet.add('driverLicense');
            return;
        }

        if (validationData?.passportNumber?.length &&
            (validationData.passportNumber.length > 9
                || !(passportNumber1RegExp.test(validationData.passportNumber)
                    || passportNumber2RegExp.test(validationData.passportNumber)))) {
            this.errorsSet.add('passportNumber');
        }
    });
    return !this.errorsSet.size;
}


export function getUserDetailsHeader(userData, mainLanguage) {
    const {id, company, username, lastUpdatedAt,createdAt, firstName, lastName, isBlocked,} = userData || {};
    return {
        id: id,
        name: firstName ? `${firstName} ${lastName}` : '-',
        company: company?.translations?.find(tr => tr.language === mainLanguage)?.name || '-',
        phone: username || '-',
        createdAt:createdAt ? moment(createdAt).format('DD.MM.YYYY') : '-',
        date: lastUpdatedAt ? moment(lastUpdatedAt).format('DD.MM.YYYY') : '-',
        isHidden: !isBlocked,
    };
}
