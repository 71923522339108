import {history} from "../../configs/history";
import {request, _urlLanguages} from "../api";
import {LANGUAGES_CONSTS, UTIL_CONSTS,} from "../constants";
import swal from "sweetalert";

export const GetLanguages = () => {
    const requestData = {
        url: _urlLanguages,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res.data;
                data && await dispatch({
                    type: LANGUAGES_CONSTS.GET_LANGUAGES,
                    payload: data,
                });
            })
    }
};



export const UpdateLanguage = (data, id) => {
    const requestData = {
        url:  `${_urlLanguages}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING,
        });
        return request(requestData)
            .then(res => {
                dispatch({
                    type: LANGUAGES_CONSTS.UPDATE_LANGUAGE,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Լեզուն հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ"
                }).then(() => history.push("/languages"));
            }).finally(()=>{
                dispatch({
                    type: UTIL_CONSTS.END_LOADING,
                });
            })
    }
};
