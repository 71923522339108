import moment from "moment";
import {getCompanyHistoryTitle, getUserHistoryTitle} from "../../../utils/getTitleByHistoryType";
import React from "react";
import {HISTORY_TYPES, STATION_HISTORY_TYPES} from "../../../constants/constTypes";
import {truncNumber} from "../../../utils/helperFunctions";

export function parseStationHistoryData(historyData, mainLanguage) {
    return historyData?.itemsList?.map(history => {
        const {station, transactionNumber, sender, amount, sum, fuel, date, bid} = history || {};
        return {
            id: history.id,
            values: [
                station?.translations?.find(lg => lg.language === mainLanguage)?.title || '-',
                transactionNumber ?? '-',
                sender && sender.firstName ? `${sender?.firstName} ${sender?.lastName}` : '-',
                sender && sender.username ? sender.username : '-',
                bid?.registeredCarNumber || '-',
                bid?.filledCarNumber || '-',
                <span><span className={"type-color"}
                            style={{backgroundColor: fuel?.color ?? 'white'}}/>
                    {fuel?.translations?.find(lg => lg.language === mainLanguage)?.title || '-'}
                </span>,
                truncNumber(amount) ?? '-',
                sum ?? '-',
                bid?.status ? STATION_HISTORY_TYPES[bid?.status] : '-',
                moment(date).format("DD.MM.YY / HH:mm"),
            ],
        }
    });
}


export function parseUserHistoryData(historyData, mainLanguage) {
    //console.log('historyData', historyData)
    return historyData?.itemsList?.map(history => {
        //console.log('history', history)
        const {receiver, station, transactionNumber, type, sender, company, amount, sum, fuel, date} = history || {};
        const userData = {
            id: history.id,
            values: [
                getUserHistoryTitle(type, amount),
                transactionNumber ?? '-',
                '-',
                '-',
                '-',
                '-',
                moment(date).format("DD.MM.YY / HH:mm"),
            ],
        };
        //console.log('userData', userData)
//Վճարող
        switch (type) {
            case HISTORY_TYPES.ADMIN_TO_USER:
            case HISTORY_TYPES.FUEL_TO_OR_FROM_FRIEND:
            case HISTORY_TYPES.SPENT_COMPANY_FUEL:
            case HISTORY_TYPES.SPENT_PERSONAL_FUEL:
                userData.values[2] = sender?.firstName ? `${sender?.firstName} ${sender?.lastName}` : '-';
                break;
            case HISTORY_TYPES.GET_FUEL_FROM_COMPANY:
                userData.values[2] = company?.translations?.find(lg => lg.language === mainLanguage)?.name ?? '-';
                break;
            case HISTORY_TYPES.FILLED_ACCOUNT_FROM_CARD:
                userData.values[2] = 'Կցված քարտ';
                break;
            case HISTORY_TYPES.MONEY_VIA_GIFT_CARD:
            case HISTORY_TYPES.FUEL_VIA_GIFT_CARD:
                userData.values[2] = 'Նվեր քարտ';
                break;
            case HISTORY_TYPES.ORDER_VIA_CARD:
                userData.values[2] = sender?.firstName ? `${sender?.firstName} ${sender?.lastName}, քարտ` : '-';
                break;
            case HISTORY_TYPES.ORDER_VIA_ACCOUNT:
                userData.values[2] = sender?.firstName ? `${sender?.firstName} ${sender?.lastName}, Petrol հաշիվ` : '-';
                break;
        }

//Անվանում
        switch (type) {
            case HISTORY_TYPES.FILLED_ACCOUNT_FROM_CARD:
            case HISTORY_TYPES.MONEY_VIA_GIFT_CARD:
                userData.values[3] = `Գումար`;
                break;
            case HISTORY_TYPES.ORDER_VIA_ACCOUNT:
            case HISTORY_TYPES.ORDER_VIA_CARD:
                userData.values[3] = `Ապրանքներ`;
                break;
            default:
                userData.values[3] = <span>
                    <span className={"type-color"}
                          style={{backgroundColor: fuel?.color ?? 'white'}}/>
                    {fuel?.translations?.find(lg => lg.language === mainLanguage)?.title || '-'}
                </span>;
        }

//Գումար/լիտր
        switch (type) {
            case HISTORY_TYPES.FILLED_ACCOUNT_FROM_CARD:
                userData.values[4] = `${sum ?? ''} Դ`;
                break;
            case HISTORY_TYPES.MONEY_VIA_GIFT_CARD:
            case HISTORY_TYPES.ORDER_VIA_ACCOUNT:
            case HISTORY_TYPES.ORDER_VIA_CARD:
                userData.values[4] = `${truncNumber(amount) ?? ''} Դ`;
                break;
            default:
                userData.values[4] = `${sum ?? '-'}/${truncNumber(amount) ?? '-'}`;
        }
//Ստացող
        switch (type) {
            case HISTORY_TYPES.ADMIN_TO_USER:
            case HISTORY_TYPES.FILLED_ACCOUNT_FROM_CARD:
            case HISTORY_TYPES.GET_FUEL_VIA_CARD:
            case HISTORY_TYPES.GET_FUEL_VIA_ACCOUNT:
            case HISTORY_TYPES.FUEL_TO_OR_FROM_FRIEND:
            case HISTORY_TYPES.GET_FUEL_FROM_COMPANY:
            case HISTORY_TYPES.FUEL_VIA_GIFT_CARD:
            case HISTORY_TYPES.MONEY_VIA_GIFT_CARD:
                userData.values[5] = receiver?.firstName ? `${receiver?.firstName} ${receiver?.lastName}` : '-';
                break;
            case HISTORY_TYPES.SPENT_COMPANY_FUEL:
            case HISTORY_TYPES.SPENT_PERSONAL_FUEL:
                userData.values[5] = station?.translations?.find(lg => lg.language === mainLanguage)?.title || '-';
                break;
            case HISTORY_TYPES.ORDER_VIA_ACCOUNT:
            case HISTORY_TYPES.ORDER_VIA_CARD:
                userData.values[5] = 'Admin';
                break;
        }
        return userData;
    });
}


export function parseCompanyHistoryData(historyData, mainLanguage) {
    return historyData?.itemsList?.map(history => {
        const {station, receiver, transactionNumber, type, sender, company, amount, sum, fuel, date} = history || {};
        const companyData = {
            id: history.id,
            values: [
                getCompanyHistoryTitle(type, amount),
                transactionNumber ?? '-',
                '-',
                <span><span className={"type-color"}
                            style={{backgroundColor: fuel?.color ?? 'white'}}/>
                    {fuel?.translations?.find(lg => lg.language === mainLanguage)?.title || '-'}
                </span>,
                '-',
                '-',
                moment(date).format("DD.MM.YY / HH:mm"),
            ]
        };

        switch (type) {
            case HISTORY_TYPES.ADMIN_TO_COMPANY:
            case HISTORY_TYPES.SPENT_COMPANY_FUEL:
                companyData.values[2] = sender && sender.firstName ? `${sender?.firstName} ${sender?.lastName}` : '-';
                break;
            case HISTORY_TYPES.GET_FUEL_FROM_COMPANY:
                companyData.values[2] = company?.translations?.find(lg => lg.language === mainLanguage)?.name ?? '-';
                break;
            default:
                companyData.values[2] = '-'
        }

        switch (type) {
            case HISTORY_TYPES.ADMIN_TO_COMPANY:
                companyData.values[4] = fuel ? `${sum ?? '-'}/${truncNumber(amount) ?? '-'}` : `${truncNumber(amount) ?? '-'}/ -`;
                break;
            default:
                companyData.values[4] = `${sum ?? '-'}/${truncNumber(amount) ?? '-'}`

        }
        switch (type) {
            case HISTORY_TYPES.SPENT_COMPANY_FUEL:
                companyData.values[5] = station?.translations?.find(lg => lg.language === mainLanguage)?.title || '-';
                break;

            case HISTORY_TYPES.ADMIN_TO_COMPANY:
                companyData.values[5] = company?.translations?.find(lg => lg.language === mainLanguage)?.name ?? '-';
                break;
            case HISTORY_TYPES.GET_FUEL_FROM_COMPANY:
                companyData.values[5] = receiver?.firstName ? `${receiver?.firstName} ${receiver?.lastName}` : '-';
                break;
            default:
                companyData.values[5] = '-'

        }
        return companyData;
    });
}
