//file upload
export const MAX_FILE_SIZE = 209715200;
export const ACCEPT_IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'bpm', 'svg'];

//media modal
export const MEDIA_MODAL_TYPES = {
    MEDIA_MAIN: 'MEDIA_MAIN',
    MEDIA_ARRAY: 'MEDIA_ARRAY',
};

