import {INSURANCE_CONSTS} from "../constants";

export const initialState = {
    insuranceList: {
        itemsList: [],
        hasMore: false,
    },
    notSeenInsuranceCount: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case INSURANCE_CONSTS.GET_INSURANCE_REQUESTS:
            return {
                ...state,
                insuranceList: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.insuranceList.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore
                },
            };
        case INSURANCE_CONSTS.GET_NOT_SEEN_INSURANCE_COUNT:
            return {
                ...state,
                notSeenInsuranceCount: action.payload?.count || 0
            };
        case INSURANCE_CONSTS.SET_INSURANCE_AS_SEEN:
            const nonSeenCount = state.notSeenInsuranceCount;
            return {
                ...state,
                insuranceList: {
                    itemsList: state.insuranceList.itemsList.map(insurance => insurance.id === action.payload ? {
                        ...insurance,
                        seen: true
                    } : insurance),
                },
                notSeenInsuranceCount: nonSeenCount ? nonSeenCount - 1 : 0
            };
        default:
            return state;
    }
}
