// Import packages
import React from "react";
import {Route, Switch, Router, Redirect} from "react-router-dom";

// Import utils
import {history} from "../configs/history";
import {ProtectedRoute} from "./protectedRoute";

// Import pages
import AdminLayout from '../containers/AdminLayout';
import Login from '../containers/Login';
import Dashboard from '../containers/Dashboard';
import FuelsEdit from '../containers/FuelsEdit';
import StationDetails from '../containers/stations/StationDetails';

import Moderators from '../containers/Moderators/Moderators';
import AddEditModerator from "../containers/Moderators/AddEditModerator";

import Companies from '../containers/companies/Companies';
import AddEditCompany from '../containers/companies/AddEditCompany';
import CompanyDetails from '../containers/companies/CompanyDetails';

import Users from '../containers/users/Users';
import Contact from '../containers/Contact';

import Settings from '../containers/Settings';
import NotFound from '../containers/NotFound';
import UserDetails from "../containers/users/UserDetails";

import Languages from "../containers/languages/Languages";
import EditLanguage from "../containers/languages/EditLanguage";

import Media from "../containers/media/Media";

import Products from "../containers/product/Products";
import AddEditProduct from "../containers/product/AddEditProduct";
import PromoCodes from "../containers/promocodes/PromoCodes";
import AddPromoCode from "../containers/promocodes/AddPromoCode";
import Orders from "../containers/orders/Orders";
import OrderDetails from "../containers/orders/OrderDetails";
import Services from "../containers/services/Services";
import AddEditService from "../containers/services/AddEditService";
import Category from "../containers/category/Category";
import AddEditCategory from "../containers/category/AddEditCategory";
import Filters from "../containers/filters/Filters";
import AddFilter from "../containers/filters/AddFilter";
import History from "../containers/history/History";
import EditFile from "../containers/media/EditFile";
import AddEditStation from "../containers/stations/AddEditStation";
import AddEditUser from "../containers/users/AddEditUser";
import Offers from "../containers/offers/Offers";
import InsuranceDetails from "../containers/insurance/InsuranceDetails";
import TireDetails from "../containers/offers/tires/TireDetails";
import SparesDetails from "../containers/offers/spares/SparesDetails";
import Reviews from "../containers/reviews/Reviews";
import ReviewDetails from "../containers/reviews/ReviewDetails";


import Networks from "../containers/networks/Networks";
import AddEditNetwork from "../containers/networks/AddEditNetwork";
import EditFilter from "../containers/filters/EditFilter";
import Story from "../containers/story/Story";
import AddEditStory from "../containers/story/AddEditStory";
import Insurances from "../containers/insurance/Insurances";
import RepairingDetails from "../containers/offers/repairing/RepairingDetails";
import AddEditSupervisor from "../containers/networks/AddEditSupervisor";
import Transfers from "../containers/transfers/Transfers";
import StaticTexts from "../containers/staticTexts/StaticTexts";


export default () => {

    return <Router history={history}>
        <Switch>
            <Redirect exact from="/" to="/login"/>
            <Route exact path="/login" component={Login}/>
            <AdminLayout>
                <Switch>
                    <Route exact path="/dashboard" component={Dashboard}/>

                    <ProtectedRoute exact path="/station/add" component={AddEditStation}/>
                    <ProtectedRoute exact path="/station/edit/:id" component={AddEditStation}/>
                    <ProtectedRoute exact path="/station/:id" component={StationDetails}/>

                    <ProtectedRoute exact path="/moderators" component={Moderators}/>
                    <ProtectedRoute exact path="/moderators/add" component={AddEditModerator}/>
                    <ProtectedRoute exact path="/moderators/edit/:id" component={AddEditModerator}/>

                    <ProtectedRoute exact path="/companies" component={Companies}/>
                    <ProtectedRoute exact path="/companies/add" component={AddEditCompany}/>
                    <ProtectedRoute exact path="/companies/edit/:id" component={AddEditCompany}/>
                    <ProtectedRoute exact path="/companies/company/:id" component={CompanyDetails}/>

                    <ProtectedRoute exact path="/users" component={Users}/>
                    <ProtectedRoute exact path="/users/add" component={AddEditUser}/>
                    <ProtectedRoute exact path="/users/edit/:id" component={AddEditUser}/>
                    <ProtectedRoute exact path="/users/user/:id" component={UserDetails}/>

                    <ProtectedRoute exact path="/networks" component={Networks}/>
                    <ProtectedRoute exact path="/networks/add" component={AddEditNetwork}/>
                    <ProtectedRoute exact path="/networks/edit/:id" component={AddEditNetwork}/>

                    <ProtectedRoute exact path="/networks/:id/supervisors/add" component={AddEditSupervisor}/>
                    <ProtectedRoute exact path="/networks/:id/supervisors/edit/:supervisorId" component={AddEditSupervisor}/>

                    <ProtectedRoute exact path="/transfers" component={Transfers}/>

                    <ProtectedRoute exact path="/media" component={Media}/>
                    <ProtectedRoute exact path="/media/edit-file/:id" component={EditFile}/>

                    <ProtectedRoute exact path="/products" component={Products}/>
                    <ProtectedRoute exact path="/products/add" component={AddEditProduct}/>
                    <ProtectedRoute exact path="/products/edit/:id" component={AddEditProduct}/>

                    <ProtectedRoute exact path="/promoCodes" component={PromoCodes}/>
                    <ProtectedRoute exact path="/promoCodes/add" component={AddPromoCode}/>

                    <ProtectedRoute exact path="/orders" component={Orders}/>
                    <ProtectedRoute exact path="/orders/order/:id" component={OrderDetails}/>

                    <ProtectedRoute exact path="/services" component={Services}/>
                    <ProtectedRoute exact path="/services/add" component={AddEditService}/>
                    <ProtectedRoute exact path="/services/edit/:id" component={AddEditService}/>

                    <ProtectedRoute exact path="/insurances" component={Insurances}/>
                    <ProtectedRoute exact path="/insurances/:id" component={InsuranceDetails}/>

                    <ProtectedRoute exact path="/offers" component={Offers}/>
                    <ProtectedRoute exact path="/offers/tires/:id" component={TireDetails}/>
                    <ProtectedRoute exact path="/offers/spares/:id" component={SparesDetails}/>
                    <ProtectedRoute exact path="/offers/repairing/:id" component={RepairingDetails}/>

                    <ProtectedRoute exact path="/categories" component={Category}/>
                    <ProtectedRoute exact path="/categories/add" component={AddEditCategory}/>
                    <ProtectedRoute exact path="/categories/edit-category/:id" component={AddEditCategory}/>

                    <ProtectedRoute exact path="/filters" component={Filters}/>
                    <ProtectedRoute exact path="/filters/add" component={AddFilter}/>
                    <ProtectedRoute exact path="/filters/edit-filter/:id" component={EditFilter}/>

                    <ProtectedRoute exact path="/reviews" component={Reviews}/>
                    <ProtectedRoute exact path="/reviews/review/:id" component={ReviewDetails}/>

                    <ProtectedRoute exact path="/stories" component={Story}/>
                    <ProtectedRoute exact path="/stories/add" component={AddEditStory}/>
                    <ProtectedRoute exact path="/stories/edit-story/:id" component={AddEditStory}/>

                    <ProtectedRoute exact path="/languages" component={Languages}/>
                    <ProtectedRoute exact path="/languages/edit-language/:id" component={EditLanguage}/>

                    <ProtectedRoute exact path="/fuels" component={FuelsEdit}/>
                    <ProtectedRoute exact path="/history" component={History}/>
                    <ProtectedRoute exact path="/contact" component={Contact}/>
                    <ProtectedRoute exact path="/settings" component={Settings}/>
                    <ProtectedRoute exact path="/staticTexts" component={StaticTexts}/>
                    <Route exact path="/404" component={NotFound}/>
                    <Route component={NotFound}/>
                </Switch>
            </AdminLayout>
        </Switch>
    </Router>
}
