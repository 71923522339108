// Import packages
import React from "react";

// Import components
import {InputGroup} from "./inputGroup";
import {Icon} from "antd";

// Import styles
import "../../assets/styles/dataDisplay/staticTextRow.scss"

export class StaticTextRow extends React.PureComponent {
    render() {
        const {
            label, editingField, name, value, tempValue, getTempValue, startEditing,
            saveStaticTexts, cancelEditing,
        } = this.props;
        return <div className="static-input-row">
            <label>{label}</label>
            <div className="flex-row">
                {name === "director-greetings-text" ? <>
                        {editingField === name ?
                            <InputGroup inputType="textarea"
                                        name={name}
                                        maxLength={300}
                                        autoFocus={true}
                                        value={tempValue}
                                        onChange={getTempValue}/> :
                            <pre className="text-area-view">{value}</pre>}
                    </>
                    : <>
                        {editingField === name ?
                            <InputGroup inputType="input"
                                        type="text"
                                        name={name}
                                        maxLength={300}
                                        autoFocus={true}
                                        value={tempValue}
                                        onChange={getTempValue}/> :
                            <span className="input-view">{value}</span>}
                    </>}

                {<div className="action-buttons">
                    {editingField !== name ?
                        <button className="editing-btn"
                                onClick={() => startEditing(name)}>
                            <img src={require("../../assets/images/icon-edit.svg")} alt="edit"/>
                        </button> :
                        <>
                            <button className="saving-btn"
                                    onClick={saveStaticTexts}>
                                <Icon type="save"/>
                            </button>
                            <button className="canceling-btn"
                                    onClick={cancelEditing}>
                                <Icon type="close"/>
                            </button>
                        </>}
                </div>}
            </div>
        </div>
    }
}
