import {request, _urlCompanies,} from "../api";
import {stringify} from "query-string";
import swal from "sweetalert";
import {COMPANY_CONSTS, UTIL_CONSTS} from "../constants";
import {history} from "../../configs/history";


export const GetCompanies = ({text = '',} = {}) => {
    let url = `${_urlCompanies}`;
    text && (url += `?text=${encodeURIComponent(text)}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then(async (res) => {
                const data = res?.data;
                data && await dispatch({
                    type: COMPANY_CONSTS.GET_COMPANIES,
                    payload: {
                        data: data.companies,
                        balancesSum: data.balancesSum,
                        userBalancesSum: data.userBalancesSum,
                        text,
                    }
                });
                return data
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const GetCompanyUsers = (id) => {
    const requestData = {
        url: `${_urlCompanies}/${id}/users`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async ({data}) => {
                await dispatch({
                    type: COMPANY_CONSTS.GET_COMPANY_USERS,
                    payload: data
                });
                // console.log("user",data);
            })
    }
};
export const GetCompanyById = (id) => {
    const requestData = {
        url: `${_urlCompanies}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async ({data}) => {
                await dispatch({
                    type: COMPANY_CONSTS.GET_COMPANY_BY_ID,
                    payload: data
                });
                // console.log("company by ID", data);
            })
    }
};


export const AddCompany = (data) => {
    const requestData = {
        url: _urlCompanies,
        token: true,
        method: "POST",
        data: data
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: COMPANY_CONSTS.ADD_COMPANY,
                    payload: data
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Ընկերությունը հաջողությամբ ստեղծվեց",
                    icon: "success",
                    button: "Լավ",
                });
                history.push({
                    pathname: '/companies',
                })
            }).catch((error) => {
                if (error && error.response && error.response.status === 409) {
                    swal({
                        title: "Զգուշացում!",
                        text: "Այս էլ․ փոստով կամ ՀՎՀՀ-ով ընկերություն արդեն գոյություն ունի",
                        icon: "warning",
                        button: "Լավ",
                    })
                }
            })
    }
};

export const EditCompany = (id, data) => {
    const requestData = {
        url: `${_urlCompanies}/${id}`,
        token: true,
        method: "PATCH",
        data: data
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: COMPANY_CONSTS.EDIT_COMPANY,
                    payload: data,
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Ընկերությունը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ",
                });
                history.push({
                    pathname: '/companies',
                })
            })
    }
};

export const EditCompanyBalance = (companyId, data) => {
    const requestData = {
        url: `${_urlCompanies}/${companyId}/balance`,
        token: true,
        method: "POST",
        data: stringify(data),
        customHeaders: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res.data;
                data && dispatch({
                    type: COMPANY_CONSTS.EDIT_COMPANY_BALANCE,
                    payload: {companyId, data},
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Բալանսը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ",
                });
            })
    }
};
export const DeleteCompany = (id) => {
    const requestData = {
        url: `${_urlCompanies}/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: COMPANY_CONSTS.DELETE_COMPANY,
                    payload: id
                });
                swal({
                    title:'Հաջողվեց!',
                    text: "Ընկերությունը հաջողությամբ ջնջվեց",
                    icon: "success",
                    button: "Լավ",
                });
            })
    }
};


export const ClearSingleCompanyData = () => {
    return dispatch => {
        dispatch({
            type: COMPANY_CONSTS.CLEAR_SINGLE_COMPANY_DATA
        })
    }
};


export const ClearCompanyUsers = () => {
    return dispatch => {
        dispatch({
            type: COMPANY_CONSTS.CLEAR_COMPANY_USERS,
        });
    }
};
