import {request, _urlOffers} from "../api";
import {OFFERS_CONSTS, UTIL_CONSTS} from "../constants";
import {LIMIT_GET_OFFERS} from "../../constants/constLimitCounts";

export const GetOffers = ({
                                 type,
                                 reset = true,
                                 offset = 0,
                                 limit = LIMIT_GET_OFFERS,
                                 userId = null,
                             } = {}) => {
    const requestData = {
        url: `${_urlOffers}/${type}-requests/?offset=${offset}&limit=${limit}`,
        token: true,
        method: "GET",
    };
    userId && (requestData.url += `&userId=${userId}`);
    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: OFFERS_CONSTS.GET_OFFERS,
                    payload: {
                        data: data,
                        reset,
                        type,
                        hasMore: data.length === limit
                    }
                });
            })
            .finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const GetNotSeenOffersCount = (type) => {
    const requestData = {
        url: `${_urlOffers}/${type}-requests/not-seen-count`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({data}) => {
               // console.log(data, type, 'DATA');
                data && dispatch({
                    type: OFFERS_CONSTS.GET_NOT_SEEN_OFFERS_COUNT,
                    payload: {
                        data,
                        type,
                    }
                });
            })
    }
};

export const SetOffersAsSeen = (id, type) => {
    const requestData = {
        url: `${_urlOffers}/${type}-requests/${id}/seen`,
        token: true,
        method: "PUT",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: OFFERS_CONSTS.SET_OFFERS_AS_SEEN,
                    payload: {
                        id,
                        type
                    }
                });
            })
    }
};

