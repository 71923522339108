// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Icon, Dropdown, Menu} from "antd";

// Import Styles
import '../../assets/styles/cards/product-card.scss';

// Import components
import {LinkButton} from "../buttons/buttons";
import {MaterialIcon} from "../utils/Icon";
import swal from "sweetalert";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {
    DeleteProduct,
    ToggleProductVisibility
} from "../../redux/actions";


class ProductCard extends Component {
    constructor() {
        super();
        this.state = {};
    }


    componentDidMount() {

    }

    deleteProduct = id => {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել ապրանքը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteProduct(id);
            }
        });
    };

    render() {
        const {data} = this.props;
        const menu = (
            <Menu>
                <Menu.Item key="1">
                    <Link to={"products/edit/" + data.id}>Edit</Link>
                </Menu.Item>
                <Menu.Item key="2" onClick={() => {
                    this.props.ToggleProductVisibility(data.id, {isHidden: !data?.isHidden})
                }}>
                    {data?.isHidden ? 'Show' : 'Hide'}
                </Menu.Item>
                <Menu.Item key="3">
                    <span onClick={this.deleteProduct.bind(this, data.id)}>Delete</span>
                </Menu.Item>
            </Menu>

        );
        return <div className={"product-card"}>
            <Dropdown overlay={menu}
                      placement={"bottomRight"}>
                <Icon className="more" type="more"/>
            </Dropdown>
            {data?.isHidden &&
                <img className="hidden-product-icon" src={require("../../assets/images/hide.svg")}/>
            }
            <div>
                <img className="product-img" src={generateImageMediaUrl(data?.mediaMain?.path)} alt=""/>
                <div className="product-title">{data?.title}</div>
                <div className="old-price">{data?.oldPrice ? data?.oldPrice + " Դ" : ""}</div>
                <div className="discounted-price">{data?.price} Դ</div>
                <div className="details-btn">
                    <LinkButton link={{
                        pathname: `/products/edit/${data.id}`
                    }} title={<MaterialIcon
                        icon="keyboard_arrow_right"/>}/>
                </div>
            </div>
        </div>

    }
}

const mapDispatchToProps = {
    DeleteProduct,
    ToggleProductVisibility
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard)
