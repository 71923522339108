import {combineReducers} from "redux";

// import reducers
import auth from "./auth";
import utils from "./utils";
import languages from "./languages";
import fuel from "./fuel";
import stations from "./stations";
import company from "./company";
import users from "./users";
import history from "./history";
import reviews from "./reviews";
import moderators from "./moderators";
import staticTexts from "./staticTexts";
import media from "./media";
import composite from "./composite";
import filter from "./filter";
import category from "./category";
import product from "./product";
import promoCodes from "./promoCodes";
import order from "./order";
import story from "./story";
import insurance from "./insurance";
import offers from "./offers";
import notifications from "./notifications";


export default combineReducers({
    auth,
    utils,
    languages,
    fuel,
    stations,
    company,
    users,
    history,
    media,
    composite,
    reviews,
    moderators,
    staticTexts,
    filter,
    category,
    product,
    promoCodes,
    order,
    story,
    insurance,
    offers,
    notifications,
});
