export const hyAm = {
    "lang": {
    "locale": "en_US",
        "placeholder": "Select date",
        "rangePlaceholder": ["Start date", "End date"],
        "today": "Այսօր",
        "now": "Հիմա",
        "backToToday": "Back to today",
        "ok": "Լավ",
        "clear": "Clear",
        "month": "Month",
        "year": "Year",
        "timeSelect": "Ընտրել ժամ",
        "dateSelect": "Ընտրել ամսաթիվ",
        "monthSelect": "Choose a month",
        "yearSelect": "Choose a year",
        "decadeSelect": "Choose a decade",
        "yearFormat": "YYYY",
        "dateFormat": "M/D/YYYY",
        "dayFormat": "D",
        "dateTimeFormat": "M/D/YYYY HH:mm:ss",
        "monthFormat": "MMMM",
        "monthBeforeYear": true,
        "previousMonth": "Previous month (PageUp)",
        "nextMonth": "Next month (PageDown)",
        "previousYear": "Last year (Control + left)",
        "nextYear": "Next year (Control + right)",
        "previousDecade": "Last decade",
        "nextDecade": "Next decade",
        "previousCentury": "Last century",
        "nextCentury": "Next century"
},
    "timePickerLocale": {
    "placeholder": "Ժամ"
},
    "dateFormat": "YYYY-MM-DD",
    "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
    "weekFormat": "YYYY-wo",
    "monthFormat": "YYYY-MM"
}
