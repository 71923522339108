import moment from "moment";
import React from "react";
import { HISTORY_TYPES, STATION_HISTORY_TYPES } from "../../../constants/constTypes";
import { truncNumber } from "../../../utils/helperFunctions";
import { Link } from "react-router-dom";
import { InputGroup } from "../../../components/uiElements/inputGroup";

export function parseChargingHistoryData (historyData, mainLanguage, updateProps = {}) {
    const {
        isAdmin, startUpdate, updatedFieldId, handleUpdate, handleBlur,
        tempAmount, handleKeyDown
    } = updateProps
    // console.log('tempAmount', tempAmount, typeof tempAmount)
    return historyData?.itemsList?.map(history => {
        const { id, isEdited, station, transactionNumber, sender, amount, sum, fuel, date, bid } = history || {};

        return {
            id: history.id,
            values: [
                station?.translations?.find(lg => lg.language === mainLanguage)?.title || '-',
                transactionNumber ?? '-',
                <Link
                    to={`/users/user/${sender?.id}`}>{sender && sender.firstName ? `${sender?.firstName} ${sender?.lastName}` : '-'}</Link>,
                sender && sender.username ? sender.username : '-',
                bid?.registeredCarNumber || '-',
                bid?.filledCarNumber || '-',
                <span><span className={"type-color"}
                            style={{ backgroundColor: fuel?.color ?? 'white' }}/>
                    {fuel?.translations?.find(lg => lg.language === mainLanguage)?.title || '-'}
                </span>,
                <div className={'editable-item-wrapper'}
                     onDoubleClick={isAdmin && startUpdate && startUpdate.bind(null, id, amount)}
                >{
                    updatedFieldId === id ?
                        <InputGroup inputType="input"
                                    type="text"
                                    placeholder="Քանակ"
                                    name="count"
                                    autoFocus={true}
                                    onBlur={handleBlur}
                                    value={truncNumber(tempAmount)}
                                    maxLength={256}
                                    onKeyDown={handleKeyDown.bind(null, history)}
                                    error={tempAmount === ''}
                                    onChange={handleUpdate}/>
                        : truncNumber(amount)}
                </div>,
                sum ? truncNumber(sum) : '-',
                bid?.status ? STATION_HISTORY_TYPES[bid?.status] : '-',
                moment(date).format("DD.MM.YY / HH:mm"),
            ],
            isEdited: isAdmin && isEdited,
        }
    });
}


export function parseTransferHistoryData (historyData, mainLanguage) {
    //console.log('historyData', historyData)
    return historyData?.itemsList?.map(history => {
        //console.log('history', history)
        const { receiver, transactionNumber, sender, amount, fuel, date, company, subCompany, type } = history || {};
        let _sender = '-';
        let _receiver = receiver?.firstName ? `${receiver?.firstName} ${receiver?.lastName}` : '-'
        if (sender) {
            _sender = sender?.firstName ? `${sender?.firstName} ${sender?.lastName}` : '-'
        } else if (subCompany) {
            _sender = subCompany?.translations?.find(lg => lg.language === mainLanguage)?.name || '-'

        } else if (company) {
            _sender = company?.translations?.find(lg => lg.language === mainLanguage)?.name || '-'
        }
        return {
            id: history.id,
            values: [
                _sender,
                transactionNumber ?? '-',
                <Link to={`users/user/${receiver?.id}`}>{_receiver}</Link>,
                receiver?.username || company?.phoneNumber1 || company?.phoneNumber2 || '-',

                <span><span className={"type-color"}
                            style={{ backgroundColor: fuel?.color ?? 'white' }}/>
                    {fuel?.translations?.find(lg => lg.language === mainLanguage)?.title || '-'}
                </span>,
                truncNumber(amount) || "-",
                moment(date).format("DD.MM.YY / HH:mm"),
            ],
        };
    });
}

export function parsePurchasedFuelHistoryData (historyData, mainLanguage) {
    //console.log('historyData', historyData)
    return historyData?.itemsList?.map(history => {
        //console.log('history', history)
        const { receiver, transactionNumber, type, company, amount, sum, fuel, date } = history || {};
        const getter = receiver?.firstName ? `${receiver?.firstName} ${receiver?.lastName || ''}`
            : (company?.translations?.find(lg => lg.language === mainLanguage)?.name || '-');
        const phoneNumber = receiver ? receiver?.username : (company?.phoneNumber1 || company?.phoneNumber2);
        const section = receiver ? 'Օգտատեր' : 'Ընկերություն';
        const method = type === HISTORY_TYPES.GET_FUEL_VIA_CARD ? 'Կցված քարտ' : (type === HISTORY_TYPES.ADMIN_TO_COMPANY || type === HISTORY_TYPES.ADMIN_TO_USER ? 'Ադմին' : 'Petrol.am հաշիվ');
        return {
            id: history.id,
            values: [
                receiver?.firstName ? <Link to={`users/user/${receiver?.id}`}>{getter}</Link> : (company ?
                    <Link to={`companies/company/${company?.id}`}>{getter}</Link> : getter),
                phoneNumber || '-',
                transactionNumber ?? '-',
                method,
                <span><span className={"type-color"}
                            style={{ backgroundColor: fuel?.color ?? 'white' }}/>
                    {fuel?.translations?.find(lg => lg.language === mainLanguage)?.title || '-'}
                </span>,
                section,
                sum || "-",
                truncNumber(amount) || "-",
                moment(date).format("DD.MM.YY / HH:mm"),
            ],
        };
    });
}


export function parseAccountReplenishmentHistoryData (historyData, mainLanguage) {
    //console.log('historyData', historyData)
    const parsedData = [];
    historyData && historyData.itemsList && historyData.itemsList.forEach(history => {
        //console.log('history', history)
        const { receiver, transactionNumber, type, company, amount, fuel, date } = history || {};
        // if (fuel) {
        //     return;
        // }

        const getter = receiver?.firstName ? `${receiver?.firstName} ${receiver?.lastName || ''}`
            : (company?.translations?.find(lg => lg.language === mainLanguage)?.name || '-');
        const phoneNumber = receiver ? receiver?.username : (company?.phoneNumber1 || company?.phoneNumber2);
        const section = receiver ? 'Օգտատեր' : 'Ընկերություն';
        parsedData.push({
            id: history.id,
            values: [
                receiver?.firstName ? <Link to={`users/user/${receiver?.id}`}>{getter}</Link> : (company ?
                    <Link to={`companies/company/${company?.id}`}>{getter}</Link> : getter),
                phoneNumber || '-',
                transactionNumber ?? '-',
                type === HISTORY_TYPES.FILLED_ACCOUNT_FROM_CARD ? 'Կցված քարտ' : 'Սուպեր Ադմին',
                section,
                truncNumber(amount) || "-",
                moment(date).format("DD.MM.YY / HH:mm"),
            ],
        });
    });
    return parsedData;
}
