// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/user-details.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import BalanceEditingCard from "../../components/cards/BalanceEditingCard";
import BalanceCard from "../../components/cards/BalanceCard";
import DetailsHeader from "../../components/cards/DetailsHeader";
import ItemsList from "../../components/ItemsList";
import {LinkButton} from "../../components/buttons/buttons";
import {MaterialIcon} from "../../components/utils/Icon";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    EditUserBalance,
    GetHistoryByUserId,
    ClearByIdHistory,
    GetUserById,
    ClearUserById
} from "../../redux/actions";
import {getUserDetailsHeader} from "./utils/actionFunctions";
import {parseUserHistoryData} from "../history/utils/actionFunctionsForDetails";
import {usersHistoryHeaderInfo} from "../history/utils/constants";

class UserDetails extends Component {
    constructor() {
        super();
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.editBalance = this.editBalance.bind(this);
    }

    componentDidMount() {
        const id = this.props.id;
        this.props.GetHistoryByUserId({id});
        this.props.GetUserById(id);
    }

    componentWillUnmount() {
        this.props.ClearByIdHistory();
        this.props.ClearUserById();
    }

    loadMoreItems() {
        const {historyById, id} = this.props;
        historyById?.hasMore && this.props.GetHistoryByUserId({
            id,
            reset: false,
            offset: historyById?.itemsList?.length,
            startDate: historyById?.startDate,
            endDate: historyById?.endDate
        });
    };

    editBalance(userId, fuelId, amount) {
        return this.props.EditUserBalance(userId, fuelId, amount).then(() => {
            this.props.GetHistoryByUserId({id: userId});
            const {usersList} = this.props;
            const userData = usersList?.itemsList?.find(user => user.id === userId);
            userData && this.setState({
                userData
            })
        });
    }

    render() {
        const {fuelList, historyById, mainLanguage, requestLoading, userById, id} = this.props;
        const historyInfo = parseUserHistoryData(historyById, mainLanguage);
        const userDataHeader = getUserDetailsHeader(userById, mainLanguage);

        return <PageWrapper withActions={true}>
            <section className="user-details">
                <DetailsHeader data={userDataHeader}
                               withEditAction={<LinkButton
                                   link={`/users/edit/${id}`}
                                   title={<MaterialIcon icon="edit"/>}
                                   className="bg-orange"/>}/>
                <div className="separator"/>
                <div className="balance-part">
                    <BalanceEditingCard data={userById?.balances}
                                        id={userById?.id}
                                        fuelList={fuelList}
                                        mainLanguage={mainLanguage}
                                        editBalance={this.editBalance}/>
                    <div>
                        <BalanceCard data={userById?.balances}
                                     userId={userById?.id}
                                     withNotification={true}
                                     withMoney={true}
                                     mainLanguage={mainLanguage}/>
                        {userById?.companyBalances &&
                        <BalanceCard data={userById?.companyBalances}
                                     userId={userById?.id}
                                     mainLanguage={mainLanguage}
                                     companyBalance={true}/>
                        }
                    </div>
                </div>
                <div className="separator"/>
                <div className="user-history">
                    <h1 className="history-title">Պատմություն</h1>
                    <div className="short-separator"/>
                    <ItemsList itemListHeaderInfo={usersHistoryHeaderInfo}
                               itemListInfo={historyInfo}
                               loading={requestLoading}
                               infiniteScroll={true}
                               hasMore={historyById.hasMore}
                               useWindow={true}
                               loadMoreItems={this.loadMoreItems}/>
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    EditUserBalance,
    GetUserById,
    GetHistoryByUserId,
    ClearByIdHistory,
    ClearUserById
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)
