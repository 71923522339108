// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';

// Import styles
import "../../assets/styles/containerStyles/edit-file.scss";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {UpdateFile} from "../../redux/actions";
import {history} from "../../configs/history";
import {LanguageTabs} from "../../components/uiElements/Tabs";


class EditFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageData: {
                translations: {},
                name: '',
                path: '',
            },
            errors: {
                name: ''
            },

            requestLoading: false,
            alertDialogOpen: false,
            alertMode: "",
            activeTab: '',
        };
        this.type = null;
        this.currentFile = null;
        this.updatedDataMap = new Map();
        this.getInputValues = this.getInputValues.bind(this);
        this.updateImage = this.updateImage.bind(this);
        this.changeTabs = this.changeTabs.bind(this);
    }

    componentDidMount() {
        const {languages, mainLanguage, location} = this.props;
        const imgData = location && location.state && location.state.data;
        this.type = imgData && imgData.type;
        this.folderList = location && location.state && location.state.folderList;
        if (!this.type) {
            history.push('/media')
        } else {
            const translationsData = {};
            languages && languages.forEach(lg => {
                translationsData[lg.id] = {
                    altAttribute: "",
                    description: "",
                }
            });

            imgData && imgData.translations && imgData.translations.forEach((item) => {
                !!Object.keys(item).length && (translationsData[item.language] = {
                    altAttribute: item.altAttribute || '',
                    description: item.description || '',
                })
            });
            this.currentFile = imgData ? {...imgData} : {};
            imgData && this.setState({
                ...this.state,
                imageData: {
                    name: imgData.name,
                    path: imgData.path,
                    translations: translationsData
                },
                activeTab: mainLanguage
            })
        }
    }

    changeTabs(activeTab) {
        this.setState({
            activeTab
        });
    }


    getInputValues({name, value}, languageId) {
        const item = languageId && this.state.imageData.translations[languageId];
        //console.log(/[^-a-zA-Zա-ֆԱ-Ֆа-яА-Я0-9]*$/.test(value))
        if (name === 'name' && !(/^[-a-zA-Zա-ֆԱ-Ֆа-яА-Я0-9]*$/.test(value))) {
            return;
            // value = value.replace(/[^-a-zA-Zա-ֆԱ-Ֆа-яА-Я0-9]*$/gi, '');
        }
        const init_Tr = languageId && this.currentFile && this.currentFile.translations &&
            this.currentFile.translations.find(tr => tr.language === languageId);

        languageId && (
            (!init_Tr && value) ||
            (init_Tr && !init_Tr[name] && value) ||
            (init_Tr && init_Tr[name] && (init_Tr[name] !== value))) ?
            this.updatedDataMap.set(name + languageId, value) :
            this.updatedDataMap.delete(name + languageId);

        !languageId && (this.currentFile[name] !== value) ? this.updatedDataMap.set(name, value) :
            this.updatedDataMap.delete(name);
        if (languageId) {
            this.setState({
                imageData: {
                    ...this.state.imageData,
                    translations: {
                        ...this.state.imageData.translations,
                        [languageId]: {
                            ...item,
                            [name]: value
                        }
                    }
                }
            })
        } else {
            this.setState({
                imageData: {
                    ...this.state.imageData,
                    [name]: value
                }
            })
        }
    }

    async updateImage() {
        const {translations, name} = this.state.imageData;
        const TR_data = [];
        if (!name) {
            this.setState({
                errors: {
                    name: true
                }
            });
            return;
        }
        Object.keys(translations).forEach(key => {
            let tr_item = {
                language: key,
            };
            let tr_itemIsEmpty = true;
            if (translations[key].altAttribute) {
                tr_item.altAttribute = translations[key].altAttribute;
                tr_itemIsEmpty = false;
            }
            if (translations[key].description) {
                tr_item.description = translations[key].description;
                tr_itemIsEmpty = false;
            }
            !tr_itemIsEmpty && TR_data.push(tr_item);
        });
        if (this.updatedDataMap.size) {
            let reqData = {};
            let parentPath = this.currentFile && this.currentFile.path;
            let parent = this.currentFile && this.currentFile.parent;
            parentPath = parentPath && parentPath.slice(0, parentPath.lastIndexOf('/'))
            //const parentFolder = this.folderList && this.folderList.length && this.folderList[this.folderList.length - 1];
            if (parentPath) {
                reqData.parentPath = parentPath;
                reqData.name = name;
                reqData.parent = parent
            }
            TR_data.length && (reqData.translations = TR_data);
            name && this.updatedDataMap.has("name") && (reqData.name = name);

            this.props.UpdateFile(this.props.id, reqData, this.type).then(() => {
                history.push({
                    pathname: '/media',
                    state: {
                        folderList: this.folderList,
                        reGetData: true,
                    }
                })
            }).catch((error) => {
            });
        }
    }

    render() {
        const {requestLoading} = this.props;
        const {imageData, errors, activeTab} = this.state;
        const currentData = imageData.translations[activeTab] || {};
        return <PageWrapper pageTitle={`Edit ${this.type === 'FOLDER' ? ' Folder' : ' File'}`}>
            <div className="admin-edit-file">
                <InputGroup inputType="input"
                            type="text"
                            label="Name"
                            name="name"
                            maxLength={50}
                            error={errors.name}
                            value={imageData.name}
                            onChange={(fields) => this.getInputValues(fields, null)}/>


                {this.type === "FILE" && <>
                    <LanguageTabs changeLanguageTab={this.changeTabs}
                                  activeTab={activeTab}
                                  errorsList={[errors]}/>

                    <InputGroup inputType="input"
                                type="text"
                                label="Alt"
                                name="altAttribute"
                                maxLength={256}
                                value={currentData.altAttribute}
                                onChange={(fields) => this.getInputValues(fields, activeTab)}/>
                </>}


                <div className="flex-wrapper-right">
                    <LinkButton cb={this.updateImage}
                                disabled={!this.updatedDataMap.size}
                                loading={requestLoading}
                                title="Edit"/>
                </div>
            </div>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    UpdateFile
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditFile));
