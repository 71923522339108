// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";

// Import components


class PageWrapper extends Component {

    render() {
        const {
            leftSideMenuOpen, children
        } = this.props;

        return <div className={`admin-inner-component ${leftSideMenuOpen ? "size-sm" : ""}`}>
            <section className="content-wrapper">
                <div className={'main-content'}>
                    {children}
                </div>
            </section>
        </div>
    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageWrapper)
