import {HISTORY_TYPES} from "../../../constants/constTypes";

export const tabs = [
    {
        name: "Լիցքավորումներ",
        key: "charging",
        types: [
            HISTORY_TYPES.SPENT_COMPANY_FUEL,
            HISTORY_TYPES.SPENT_PERSONAL_FUEL,
        ],
    },
    {
        name: "Փոխանցումներ",
        key: "transfers",
        types: [
            HISTORY_TYPES.FUEL_TO_OR_FROM_FRIEND,
            HISTORY_TYPES.GET_FUEL_FROM_COMPANY,
        ],
        operationType: "FUEL"
    },
    {
        name: "Գնված վառելիք",
        key: "purchasedFuel",
        types: [
            HISTORY_TYPES.COMPANY_GET_FUEL_VIA_ACCOUNT,
            HISTORY_TYPES.GET_FUEL_VIA_ACCOUNT,
            HISTORY_TYPES.GET_FUEL_VIA_CARD,
            HISTORY_TYPES.ADMIN_TO_COMPANY,
            HISTORY_TYPES.ADMIN_TO_USER,
        ],
        operationType: "FUEL"
    },
    {
        name: "Հաշվի համալրում",
        key: "accountReplenishment",
        types: [
            HISTORY_TYPES.FILLED_ACCOUNT_FROM_CARD,
            HISTORY_TYPES.ADMIN_TO_COMPANY,
            // HISTORY_TYPES.ADMIN_TO_USER,
        ],
        operationType: "MONEY"
    }
];

export const chargingHeaderInfo = {
    generalInfo: [
        "Լցակայան",
        "Համար",
        "Օգտատեր",
        "Հեռ․",
        "Պետ․ գրանցված",
        "Պետ․ լրացված",
        "Տեսակ",
        "Քանակ",
        "Գումար",
        'Կարգավիճակ',
        "Ամսաթիվ"],
    largeItems: [0],
    minWidth: 1600,
    status: false,
};
export const transfersHeaderInfo = {
    generalInfo: ["Փոխանցող", "Համար","Ստացող","Հեռ համար",  "Տեսակ", "Քանակ", "Ամսաթիվ"],
    largeItems: [0,3],
    minWidth: 1000,
    status: false,
};
export const purchasedFuelHeaderInfo = {
    generalInfo: ["Ստացող", "Հեռ համար", "Համար", "Միջոց", "Տեսակ", "Բալանս", "Գումար", "Քանակ", "Ամսաթիվ"],
    largeItems: [0,3],
    minWidth: 1400,
    status: false,
};
export const accountReplenishmentHeaderInfo = {
    generalInfo: ["Ստացող", "Հեռ համար", "Համար", "Միջոց", "Բալանս", "Գումար", "Ամսաթիվ"],
    largeItems: [0],
    minWidth: 1100,
    status: false,
};


//=============================================================================================
export const usersHistoryHeaderInfo = {
    generalInfo: ["Տիպ", "Համար", "Վճարող", "Անվանում", "Գումար/լիտր", "Ստացող", "Ամսաթիվ"],
    largeItems: [0],
    status: false,
};
export const companiesHistoryHeaderInfo = {
    generalInfo: ["Տիպ", "Համար", "Վճարող", "Անվանում", "Գումար/լիտր", "Ստացող", "Ամսաթիվ"],
    largeItems: [0],
    status: false,
};

export const stationsHistoryHeaderInfo = {
    generalInfo: [
        "Լցակայան",
        "Համար",
        "Օգտատեր",
        "Հեռ․",
        "Պետ․ գրանցված",
        "Պետ․ լրացված",
        "Տեսակ",
        "Քանակ",
        "Գումար",
        'Կարգավիճակ',
        "Ամսաթիվ"],
    largeItems: [0, 1, 2, 3],

};

export const StationHistoryTypes = {
    SPENT_COMPANY_FUEL: 'Կազմ․ վառելիքի ծախս',
    SPENT_PERSONAL_FUEL: 'Վառելիքի ծախս'
}
export const CompanyHistoryTypes = {
    GET_FUEL_FROM_COMPANY: 'Վառելիքի ստացում կազմ․',
    ADMIN_TO_COMPANY: 'Վառելիքի ստացում ադմինից'
}

export const UserHistoryTypes = {
    GET_FUEL_FROM_COMPANY: 'Վառելիքի ստացում կազմ․',
    ADMIN_TO_USER: 'Վառելիքի ստացում ադմինից',
    FILLED_ACCOUNT_FROM_CARD: 'Հաշվի համալրում կցված քարտից',
    FUEL_TO_OR_FROM_FRIEND: 'Վառելիքի փոխ․/ստաց․ ընկերոջը(ից)',
    GET_FUEL_VIA_ACCOUNT: 'Վառելիքի գնում',
    GET_FUEL_VIA_CARD: 'Վառելիքի գնում կցված քարտով',
    SPENT_PERSONAL_FUEL: 'Վառելիքի ծախս',
    SPENT_COMPANY_FUEL: 'Կազմ․ վառելիքի ծախս'
}

