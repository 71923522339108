import {initialState as auth} from "../reducers/auth";
import {initialState as utils} from "../reducers/utils";
import {initialState as languages} from "../reducers/languages";
import {initialState as fuel} from "../reducers/fuel";
import {initialState as stations} from "../reducers/stations";
import {initialState as company} from "../reducers/company";
import {initialState as users} from "../reducers/users";
import {initialState as history} from "../reducers/history";
import {initialState as reviews} from "../reducers/reviews";
import {initialState as moderators} from "../reducers/moderators";
import {initialState as staticTexts} from "../reducers/staticTexts";
import {initialState as media} from "../reducers/media";
import {initialState as composite} from "../reducers/composite";
import {initialState as filter} from "../reducers/filter";
import {initialState as category} from "../reducers/category";
import {initialState as product} from "../reducers/product";
import {initialState as promoCodes} from "../reducers/promoCodes";
import {initialState as order} from "../reducers/order";
import {initialState as story} from "../reducers/story";
import {initialState as insurance} from "../reducers/insurance";
import {initialState as offers} from "../reducers/offers";
import {initialState as notifications} from "../reducers/notifications";


const reducers = {
    auth,
    utils,
    languages,
    fuel,
    stations,
    company,
    users,
    history,
    reviews,
    moderators,
    staticTexts,
    media,
    composite,
    filter,
    category,
    product,
    promoCodes,
    order,
    story,
    insurance,
    offers,
    notifications,
};

export default state => {
    const sData = {};
    Object.keys(reducers).forEach(selectorKey => {
        Object.keys(reducers[selectorKey]).forEach(key => {
            sData[key] = state[selectorKey][key];
        });
    })
    return sData;
};

