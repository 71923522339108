import React, {useState} from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Tooltip} from "antd";
import {history} from "../../configs/history";
import "../../assets/styles/media/mediaCard.scss";

//import utils
import {resizeImage} from "../../utils/resizeImage";
import {generateFileMediaUrl, generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {IMAGES_TYPES_PATTERN} from "../../constants/acceptedTypes";
import {getFileIcon} from "../../utils/getFileIcon";

export const MediaFileCard = (props) => {
    const {
        item, isSelected = false, selectable = false, withEdit, customDelete,
        folderList, withCopy, draggable,
    } = props;
    const [copyed, setCopyed] = useState(false);
    const [dragging, setDragging] = useState(false);
    const isImage = new RegExp(IMAGES_TYPES_PATTERN, 'i').test(item.extension);
    const imgUrl = isImage ? resizeImage(generateImageMediaUrl(item.path))
        : getFileIcon(item.extension);

    function onDragEnd() {
        if (draggable) {
            props.dragFile(null);
            setDragging(false);
        }
    }

    function onDragStart() {
        if (draggable) {
            props.dragFile(item);
            setDragging(true);
        }
    }

    return <div className={`media-card ${isSelected ? 'selected' : ''}`}
                key={item.id}
                draggable={draggable}
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}>

        <div className={'image-wrapper'}
             onClick={() => selectable ? props.selectImage(item, isSelected) : null}
             onDoubleClick={() => props.onDoubleClick && props.onDoubleClick(item)}>
            <div className="action-buttons">
                {!selectable && <>
                    {withEdit &&
                    <button onClick={() => history.push({
                        pathname: `media/edit-file/${item.id}`,
                        state: {
                            data: item,
                            folderList
                        }
                    })}>
                        <img src={require("../../assets/images/ic_edit.svg")}
                             alt="edit"/>
                    </button>}
                    {(customDelete || props.deleteMedia) &&
                    <button onClick={() => customDelete ? customDelete()
                        : props.deleteMedia(item.id)}>
                        <img src={require("../../assets/images/icon-trash-colored.svg")}
                             alt="delete"/>
                    </button>}
                </>}
                {withCopy && <Tooltip placement={'left'} title={copyed ? "Copyed" : 'Copy'}
                                      onVisibleChange={() => setCopyed(false)}>
                    <CopyToClipboard text={generateFileMediaUrl(item.path, true)}
                                     onCopy={() => setCopyed(true)}>
                        <button onClick={e => {
                            e.stopPropagation()
                        }}>
                            <img src={require("../../assets/images/ic_copy.svg")}
                                 alt="delete"/>
                        </button>
                    </CopyToClipboard>
                </Tooltip>}
            </div>
            <div className={'card-image'}>
                <img className={'image'} src={imgUrl} alt="gallery"/>
            </div>
        </div>
        <span className={'img-name'}>{item.name}</span>
        <div className={`checked ${isSelected ? "show" : ''}`}>
            <img
                src={require("../../assets/images/check-mark.svg")}
                alt="delete"
            />
        </div>
    </div>

}
