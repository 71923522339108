import React from "react";
import moment from 'moment';
import {STATION_HISTORY_TYPES, STATION_NETWORK_SALES_TYPES, UPDATED_FUELS_TYPES, MEASUREMENT_TYPES} from "../../../constants/constTypes";
import {Menu} from "antd";

export function parseStationHistoryData1(historyData) {
    return historyData?.itemsList?.map(history => {
        const {fuel, count, date, amount, bid} = history;
        return {
            date: moment(date).format("DD.MM.YY / HH:mm"),
            fuel: fuel?.title,
            count: count || '-',
            amount: amount ?? '-',
            status: bid?.status ? STATION_HISTORY_TYPES[bid?.status] : '-',
        }
    });
}

export function parseStationHistorySaleData(historyData, UpdateSaleFuel) {
    return historyData?.itemsList?.map(history => {
        const {id, fuel, sum, filledDate, amount, status, stationNetwork} = history;
        const menu = (
            <Menu>
                <Menu.Item key="1"
                           disabled={!(STATION_NETWORK_SALES_TYPES[status] === STATION_NETWORK_SALES_TYPES.ACTIVE)}
                           onClick={() => {
                               UpdateSaleFuel(id, {
                                   status: "ACCEPTED",
                                   stationNetworkId: stationNetwork?.id
                               })
                           }}>
                    {UPDATED_FUELS_TYPES.ACCEPTED}
                </Menu.Item>
                <Menu.Item key="2"
                           disabled={!(STATION_NETWORK_SALES_TYPES[status] === STATION_NETWORK_SALES_TYPES.ACTIVE)}
                           onClick={() => {
                               UpdateSaleFuel(id, {
                                   status: "REJECTED",
                                   stationNetworkId: stationNetwork?.id
                               })
                           }}>
                    {UPDATED_FUELS_TYPES.REJECTED}
                </Menu.Item>
            </Menu>
        );
        return {
            id,
            values: [
                moment(filledDate).format("DD.MM.YY"),
                stationNetwork?.title || '-',
                fuel?.shortName || '-',
                amount + ' '+ MEASUREMENT_TYPES[fuel?.measurementUnit] || '-',
                sum + ' '+ 'Դ' || '-',
                status ? STATION_NETWORK_SALES_TYPES[status] : '-',
            ],
            updatedMenu: menu,
            status: STATION_NETWORK_SALES_TYPES[status],
            stationNetworkId: stationNetwork,
        }
    });
}

export function parseStationHistoryTransfersData(historyData) {
    return historyData?.itemsList?.map(history => {
        const {id, sum, filledDate, stationNetwork, status} = history;
        return {
            id,
            values: [
                moment(filledDate).format("DD.MM.YY"),
                stationNetwork?.title || '-',
                sum + ' '+ 'Դ' || '-',
                status ? STATION_NETWORK_SALES_TYPES[status] : '-',
            ],
        }
    });
}

