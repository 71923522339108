import {REVIEW_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    reviews: {
        itemsList: [],
        text: ''
    },
    notSeenReviewsCount: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REVIEW_CONSTS.GET_REVIEWS:
            return {
                ...state,
                reviews: {
                    itemsList: action.payload.data,
                    text: action.payload.text,
                }
            };
        case REVIEW_CONSTS.GET_NOT_SEEN_REVIEWS_COUNT:
            return {
                ...state,
                notSeenReviewsCount: action.payload?.count ?? 0
            };
        case REVIEW_CONSTS.SET_REVIEW_AS_SEEN:
            return {
                ...state,
                reviews: {
                    itemsList: state.reviews.itemsList.map(review => review.id === action.payload ? {
                        ...review,
                        seen: true
                    } : review),
                    text: action.payload.text,
                },
                notSeenReviewsCount: state.notSeenReviewsCount - 1
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
