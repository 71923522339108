// Import packages
import React, {Component} from "react";

//import components
import ReactExport from "react-data-export";
import {LinkButton} from "../buttons/buttons";

//import utils
import {STATION_TYPES} from "../../constants/constTypes";
import moment from "moment";

//import excel styles
import {headerWidth, headerStyle, oddRowStyle} from "../../constants/excelConstStyles";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


class DownloadStationsExcel extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            loading: false,
        }
        this.excelButton = React.createRef();
    }

    downloadExcel = async () => {
        const {stations} = this.props;
        const {networkId, regionId, residenceId, type, text, count} = stations;
        await this.setState({loading: true})
        try {
            const exportData = await this.props.GetStations({
                limit: count,
                text,
                networkId,
                regionId,
                residenceId,
                type,
                getResponse: true,
            });
            await this.setState({data: exportData})
            this.excelButton.current.click();

        } catch {

        }
        this.setState({loading: false,data: []})
    }

    render() {
        const {mainLanguage, stations} = this.props;
        const {data, loading} = this.state;


        const emptyRow = [{value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}]
        const downloadedData = !!data?.length && [{
            columns: [
                {title: "Անվանում", width: headerWidth, style: headerStyle},
                {title: "Ցանցի անվանում", width: headerWidth, style: headerStyle},
                {title: "Գործ․ հասցե", width: headerWidth, style: headerStyle},
                {title: "Պատասխանատու", width: headerWidth, style: headerStyle},
                {title: "Հեռ", width: headerWidth, style: headerStyle},
                {title: "Տեսակ", width: headerWidth, style: headerStyle},
                {title: "Վերջին գործ", width: headerWidth, style: headerStyle},
            ],
            data: [emptyRow, ...data?.map((station, index) => {
                const {stationNetwork, translations, lastUpdatedAt, phoneNumber, residence, type} = station;
                const mainTranslations = translations?.find(item => item.language === mainLanguage);
                const networkTranslations = stationNetwork?.translations?.find(item => item.language === mainLanguage);
                const {title, supervisor} = mainTranslations || {};
                const address = residence?.name;
                const bgStyle = index % 2 === 0 ? oddRowStyle : {};
                const cellStyle = {font: {sz: "11"}, ...bgStyle};

                return [
                    {value: title ?? '-', style: cellStyle},
                    {value: networkTranslations?.title ?? '-', style: cellStyle},
                    {value: address ?? "-", style: cellStyle},
                    {value: supervisor ?? "-", style: cellStyle},
                    {value: phoneNumber ?? "-", style: cellStyle},
                    {value: type ? STATION_TYPES[type] : '-', style: cellStyle},
                    {
                        value: lastUpdatedAt ? moment(lastUpdatedAt).format("DD.MM.YY / HH:mm") : "-",
                        style: cellStyle
                    },
                ]
            })],
        }];

        return <div>
            {!!data?.length && <ExcelFile element={<span ref={this.excelButton} style={{visibility: 'hidden'}}/>}
                                          filename={'Stations' + moment().format('DD-MM-YYYY')}>
                <ExcelSheet dataSet={downloadedData}
                            name={'Stations'}/>
            </ExcelFile>}
            <LinkButton title={'Excel'}
                        cb={this.downloadExcel}
                        loadingWithTitle
                        disabled={!stations?.itemsList?.length}
                        loading={loading}/>
        </div>
    }
}

export default DownloadStationsExcel
