// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {toggleStateField, truncNumber} from "../../utils/helperFunctions";
import {MEASUREMENT_TYPES} from "../../constants/constTypes";

// Import Styles
import '../../assets/styles/cards/balance-card.scss';

// Import components
import {LinkButton} from "../../components/buttons/buttons";
import {BALANCE_TYPES} from "../../constants/constTypes";
import ModalSendNotification from '../uiElements/ModalSendNotification';

class BalanceCard extends Component {
    constructor() {
        super();
        this.state = {
            price: "",
            count: "",
            activeTab: "workers",
            isBlocked: false,
            isOpen: false,
            modalOpen: false
        };
        this.toggleNotificationModal = toggleStateField.bind(this, 'modalOpen');
    }


    render() {
        let {data, mainLanguage, fuelList, userId, withNotification, withMoney, companyBalance} = this.props;
        const {modalOpen} = this.state;
        let money = data?.find(balance => balance && balance.type === BALANCE_TYPES.MONEY);
        const fuels = (data && Array.isArray(data)) ? data.filter(balance => balance && balance.type === BALANCE_TYPES.FUEL) : [];
        //console.log(money);
        return <div className="fuels-balance">
            <div className="fuels-balance-header">
                <div className="left-part">
                    <img src={require("../../assets/images/logo.svg")}
                         alt=""/>
                    <h1 className="balance-title">{companyBalance ? "Կազմակերպության կողմից տրված բալանս" : "Բալանս"}</h1>
                </div>
                {withNotification && <LinkButton className="bg-orange" title={"Ծանուցում"}
                                                 cb={this.toggleNotificationModal}/>}
            </div>
            <div className="fuel-volumes">
                {withMoney && <div className="volume-box">
                    <div className="volume">{truncNumber(money?.amount) ?? 0} Դ</div>
                    <div className="volume-title">Հաշիվ</div>
                </div>}
                {fuelList?.map((item) => {
                    const mainTr = item?.translations?.find(tr => tr?.language === mainLanguage);
                    const balance = fuels?.find(balance => balance?.fuel?.id === item?.id);
                    const amount=truncNumber(balance?.amount);
                    return <div className="volume-box" key={item?.id}>
                        <div className="volume">
                            {amount ?? 0} {MEASUREMENT_TYPES[item?.measurementUnit]}
                        </div>
                        <div className="volume-title">{mainTr?.title}</div>
                    </div>
                })}
            </div>
            {withNotification && <ModalSendNotification visible={modalOpen}
                                                        userIds={[userId]}
                                                        closeModal={this.toggleNotificationModal}/>}
        </div>

    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BalanceCard)
