// Import packages
import React, {Component} from "react";

//import assets
import '../../assets/styles/media/add-folder-modal.scss';


// Import components
import {InputGroup} from "../uiElements/inputGroup";
import {Modal} from "antd";
import {LinkButton} from "../buttons/buttons";

// Import utils

class AddFolderModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            folderNameBroken: false
        };
        this.getInputValues = this.getInputValues.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.addFolder = this.addFolder.bind(this);
    }

    getInputValues({name, value,}) {
        this.setState({
            [name]: value
        });
    }


    async addFolder() {
        const folderName = this.state.name;
        if (/^[-a-zA-Zա-ֆԱ-Ֆа-яА-Я0-9]+$/.test(folderName)) {
            this.props.addFolder(folderName);
            this.closeModal();
        } else {
            this.setState({
                folderNameBroken: true
            })
        }
    }

    closeModal(){
        this.setState({
            folderNameBroken: false,
            name: ''
        });
        this.props.closeModal()
    }

    render() {
        const {requestLoading, visible} = this.props;
        const {name, folderNameBroken} = this.state;
        return <Modal
            visible={visible}
            wrapClassName={'custom-modal'}
            onCancel={this.closeModal}>

            <div className={'add-folder-modal'}>
                <span className={'close-icon'} onClick={this.closeModal}>
                    <img src={require('../../assets/images/ic_clear.svg')}
                         className="close_icon" alt=""/>
                </span>
                <h2 className="title">Ավելացնել Թղթապանակ</h2>
                <section>
                    <InputGroup inputType={"input"}
                                type={"text"}
                                label={<>Անուն<span
                                    className="field-required">*</span></>}
                                placeholder={"Անուն"}
                                name={"name"}
                                maxLength={50}
                                error={folderNameBroken}
                                value={name}
                                onChange={this.getInputValues}/>
                    <div className={'folder-name-broken'}>
                        {folderNameBroken &&
                        <span>Միայն տառեր , թվեր և -</span>
                        }
                    </div>
                    <div className="flex-wrapper-right">
                        <LinkButton title="Ավելացնել"
                                    loading={requestLoading}
                                    cb={this.addFolder}/>
                    </div>
                </section>
            </div>
        </Modal>
    }
}


export default AddFolderModal;
