import {FUEL_CONSTS} from "../constants";

export const initialState = {
    fuelList: [],
    fuelsLastUpdatedDate: null,
    featureList: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FUEL_CONSTS.GET_FUELS:
            return {
                ...state,
                fuelList: action.payload?.items,
                fuelsLastUpdatedDate: action.payload?.lastUpdatedDate
            };
        case FUEL_CONSTS.UPDATE_FUEL:
            return {
                ...state,
                fuelList: state.fuelList.map(fuel =>
                    fuel.id === action.payload.id ? action.payload : fuel
                )
            };
        case FUEL_CONSTS.GET_FEATURES:
            return {
                ...state,
                featureList: action.payload
            };
        default:
            return state;
    }
}
