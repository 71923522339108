// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";
import swal from "sweetalert";

//Import styles
import "../../assets/styles/containerStyles/story.scss";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetStory,
    DeleteStory,
    UpdateStory,
    UpdateStoryPositions
} from "../../redux/actions";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";


class Story extends Component {
    constructor() {
        super();
        this.deleteStory = this.deleteStory.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    async componentDidMount() {
        await this.props.GetStory();
    }

    deleteStory(removingId) {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել գովազդը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteStory(removingId);
            }
        });
    }

    getNestableItems(list) {
        const {mainLanguage} = this.props;
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            const title = item?.translations?.find(item => item.language === mainLanguage).title || '';
            const description = item?.translations?.find(item => item.language === mainLanguage).subTitle || '';

            items.push({
                id: item.id,
                index: index,
                title: title,
                description: description,
                withImage: true,
                largeItem: true,
                withStatus: true,
                isHidden: item.isHidden,
                path: item?.medias[0]?.path,
                link: {
                    pathname: `stories/edit-story/${item.id}`,
                    state: {
                        storyById: item,
                    }
                },
                deleteCb: () => this.deleteStory(item.id),
            })
        });
        return items;
    }

    async onPositionChange(items, item) {
        const movedStoryId = item.id;
        const {storyList} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~storyList.findIndex(m => m.id === movedStoryId) &&
            ~positionedItems.findIndex(m => m.id === movedStoryId)) {
            // first level menu was re-positioned in first level
            await this.props.UpdateStoryPositions(positionedItems);
            this.props.GetStory();
        }
    }

    render() {
        let {storyList} = this.props;
        let items = this.getNestableItems(storyList);
        return <PageWrapper>
            <section className="story">
                <PageHeader pageTitle={'Գովազդ'}
                            linkTitle={"Ավելացնել"}
                            addingLink={"/stories/add"}/>
                <div className="nestable-items-wrapper">
                    {items && items.length !== 0 &&
                    <Nestable
                        items={items}
                        maxDepth={1}
                        onChange={this.onPositionChange}
                        renderItem={NestableItem}
                    />}
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetStory,
    DeleteStory,
    UpdateStory,
    UpdateStoryPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(Story);