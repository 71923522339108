// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch, Tree} from "antd";


// Import Styles
import '../../assets/styles/containerStyles/product/product-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {Editor} from "../../components/uiElements/Editor";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    getInputValues,
    getTranslatableInputValues,
    initTranslations,
    changeStateField,
} from "../../utils/helperFunctions";
import {
    GetFilters, GetCategories, AddProduct, UpdateProduct, GetProductById, toggleNotificationRow, AddProductImage,
    DeleteProductImage, ChangeProductMainImage
} from "../../redux/actions";
import {MEASUREMENT_TYPES, DEFAULT_IMAGE_ID} from "../../constants/constTypes";
import {validateFields} from "./utils/actionFunctions";
import {arraysEquals} from "../../utils/helperFunctions";
import PostImageUploader from "../../components/uiElements/PostImageUploader";
import {resizeImage} from "../../utils/resizeImage";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";

const measurementUnitOptions = Object.keys(MEASUREMENT_TYPES).map(key => ({
    id: key,
    name: MEASUREMENT_TYPES[key]
}));

class AddEditProduct extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                images: [],
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                oldPrice: "",
                price: "",
                quantity: 0,
                measurementUnit: "",
                isHidden: false,
                filterValues: [],
                categories: []
            },
            productData: null,
            mainImageIndex: 0,
            deletedImageIds: [],
            loading: false,
            languageTab: props.mainLanguage,
            isEditing: !!props?.id,
            productId: props?.id,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.validateFields = validateFields.bind(this);
        this.getTempValue = this.getTempValue.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.updateProduct = this.updateProduct.bind(this);

        this.getImages = this.getImages.bind(this);
        this.changeMainImage = this.changeMainImage.bind(this);
        this.imageDeleted = this.imageDeleted.bind(this);
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;
        this.props.GetFilters();
        this.props.GetCategories();
        if (isEditing) {
            const id = this.props?.id;
            await this.props.GetProductById(id);
            const {productById} = this.props;

            const translations = {...fieldsData.translations};
            //category ids
            const category = productById.categories?.map(c => c.id);

            //filter ids
            const filterValues = [];
            productById.filters && productById.filters.forEach(filter => {
                // filterValues.push(filter.id);
                filter.values && filter.values.forEach(value => filterValues.push(value.id))
            });

            //images list
            const images = [];
            const mediaMain = productById?.medias && productById?.medias?.find(media =>
                media?.isMain === true && media?.id !== DEFAULT_IMAGE_ID);
            if (mediaMain) {
                images[0] = {
                    id: mediaMain?.id,
                    path: resizeImage(generateImageMediaUrl(mediaMain?.path), 100, 100),
                    imageUrl: generateImageMediaUrl(mediaMain?.path),
                }
            }

            if (productById?.medias?.length) {
                productById.medias.forEach(media => {
                    media?.id !== DEFAULT_IMAGE_ID && media?.isMain !== true && images.push({
                        id: media?.id,
                        path: resizeImage(generateImageMediaUrl(media?.path), 100, 100),
                        imageUrl: generateImageMediaUrl(media?.path),
                    })
                })
            }

            productById.translations && productById.translations.forEach(translation => {
                this.translationsFields.forEach(key => {
                    translations[translation.language] &&
                    (translations[translation.language][key] = translation[key]);
                });
            });
            const productData = {
                images: images,
                price: productById.price || '',
                oldPrice: productById.oldPrice || '',
                measurementUnit: productById.measurementUnit || '',
                quantity: productById.quantity || '',
                categories: category,
                filterValues: filterValues,
                isHidden: !!productById?.isHidden,
                translations: translations
            }
            this.setState({
                fieldsData: {...productData,},
                productData: JSON.parse(JSON.stringify(productData)),
            })
        }

    }

    changeMainImage(mainImageIndex) {
        mainImageIndex ? this.updatedDataMap.set('changeMediaMainAction', '')
            : this.updatedDataMap.delete('changeMediaMainAction');
        this.setState({
            mainImageIndex
        })
    }

    imageDeleted(id) {
        this.updatedDataMap.set('mediaDeleteAction', '');
        const deletedImageIds = [id, ...this.state.deletedImageIds];
        this.setState({
            deletedImageIds,
        })
    }

    getImages(images) {
        const {fieldsData, errors, productData, isEditing} = this.state;
        if (isEditing) {
            images.length === productData.images.length ?
                this.updatedDataMap.delete('mediaAddAction') :
                this.updatedDataMap.set('mediaAddAction', '');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                images: images,
            },
        });
        this.errorsSet.delete('images');
    }

    getTempValue({value}) {
        const {productData, fieldsData, isEditing, languageTab} = this.state;
        const {translations} = fieldsData;
        if (isEditing) {
            const initTr = productData?.translations?.[languageTab];
            initTr?.description !== value
                ? this.updatedDataMap.set('description_' + languageTab, value)
                : this.updatedDataMap.delete('description_' + languageTab);

        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: {
                    ...translations,
                    [languageTab]: {
                        ...translations[languageTab],
                        description: value
                    }
                }

            }
        })
    }

    onCheckFilters = checkedKeys => {
        const {fieldsData, productData, isEditing} = this.state;
        if (isEditing) {
            const updatedValue=checkedKeys?.length ? checkedKeys :[]
            arraysEquals(productData?.filterValues, checkedKeys)
                ? this.updatedDataMap.delete("filterValues")
                : this.updatedDataMap.set('filterValues', updatedValue);

        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                filterValues: checkedKeys,
            }
        })
      //  console.log(this.updatedDataMap)
    };

    onCheckCategories = checkedKeys => {

        const {fieldsData, productData, isEditing} = this.state;
        if (isEditing) {
            const updatedValue=checkedKeys?.length ? checkedKeys :[]
            arraysEquals(productData?.categories, checkedKeys)
                ? this.updatedDataMap.delete("categories")
                : this.updatedDataMap.set('categories', updatedValue);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                categories: checkedKeys,
            }
        })
    //    console.log(this.updatedDataMap)
    };

    treeData = (data, children) =>
        data.map(item => {
            if (item[children]) {
                return (
                    <Tree.TreeNode title={item.title} key={item.id} dataRef={item}>
                        {this.treeData(item[children])}
                    </Tree.TreeNode>
                );
            }
            return <Tree.TreeNode key={item.id} {...item} />;
        });

    async addProduct() {
        await this.setState({loading: true});
        const {fieldsData, mainImageIndex} = this.state;
        const {translations} = fieldsData;

        const errors = {
            requiredFields: ["price"],
            translations: ["title"]
        };
        const validationResult = this.validateFields(errors, fieldsData);
        if (validationResult) {
            await this.setState({
                loading: true
            })
            let TR_data = [];
            Object.keys(translations).forEach(key => {
                const reqTrItem = {
                    language: key,
                };
                const trItem = translations[key];
                Object.keys(trItem).forEach(field => {
                    trItem[field] && (reqTrItem[field] = trItem[field]);
                });
                TR_data.push(reqTrItem);
            });

            let formData = new FormData();

            Object.keys(fieldsData).forEach(key => {
            //    console.log(key)
                if (key === 'images') {
                    //continue next key;
                } else if (key === 'categories') {
                    let categories = fieldsData.categories;
                    categories?.length &&
                    formData.append('categories', JSON.stringify(categories))
                } else if (key === 'translations') {
                    formData.append(key, JSON.stringify(TR_data))
                } else if (key === 'filterValues') {
                    let filterValues = fieldsData.filterValues;
                    filterValues.length && formData.append(key, JSON.stringify(filterValues))

                } else if (fieldsData[key]) {
                    const data = fieldsData[key];
                    formData.append(key, data)
                }
            })
            fieldsData.images?.length && formData.append('mediaMain', fieldsData.images[mainImageIndex]?.croppedImageFile);
            if (fieldsData.images?.length > 1) {
                fieldsData.images.forEach((media, index) => {
                    if (index !== mainImageIndex) {
                        formData.append('media', media.croppedImageFile);
                    }
                });
            }
            await this.props.AddProduct(formData).finally(() => {
                this.setState({loading: false});
            })
        } else {
            this.setState({loading: false});
        }

    }

    async updateProduct() {
        const {fieldsData, productData, mainImageIndex, deletedImageIds, productId} = this.state;
        const {translations} = fieldsData;

        const errors = {
            requiredFields: ["price"],
            translations: ["title"]
        };

        const validationResult = this.validateFields(errors, fieldsData);
        if (validationResult) {
            await this.setState({
                loading: true
            })
            let TR_data = [];
            Object.keys(translations).forEach(key => {
                const reqTrItem = {
                    language: key,
                };
                const trItem = translations[key];
                Object.keys(trItem).forEach(field => {
                    trItem[field] && (reqTrItem[field] = trItem[field]);
                });
                TR_data.push(reqTrItem);
            });
            let reqData = {};

            this.updatedDataMap.delete('mediaAddAction');
            this.updatedDataMap.delete('mediaDeleteAction');
            this.updatedDataMap.delete('changeMediaMainAction');
            this.updatedDataMap.forEach((value, key) => {
                if (this.translationsFields.some(field => key.includes(field))) {
                    reqData.translations = TR_data;
                } else {
                    reqData[key] = value;
                }
            })
            if (!fieldsData.measurementUnit) reqData.measurementUnit = null;

            let newImages = fieldsData.images.filter(image => !image.id);
            let uploadedImages = null;
            if (newImages?.length) {
                let formData = new FormData();
                formData.append('productId', productId);
                newImages.forEach(media => {
                    formData.append('media', media.croppedImageFile);
                })
                uploadedImages = await AddProductImage(formData);

            }

            const allOldImagesDeleted = deletedImageIds?.length === productData?.images?.length;
            deletedImageIds.length && await DeleteProductImage(productId, deletedImageIds)
            // console.log('uploadedImages', uploadedImages);
            if (allOldImagesDeleted) {
                uploadedImages && await ChangeProductMainImage(productId, uploadedImages[0]?.id);
            } else {
                if (fieldsData?.images?.[mainImageIndex]?.id !== productData?.images?.[0]?.id) {
                    await ChangeProductMainImage(productId, fieldsData?.images?.[mainImageIndex]?.id)
                }
            }

            await this.props.UpdateProduct(productId, reqData).finally(() => {
                this.setState({loading: false});
            });

        } else {
            this.setState({loading: false});
        }
    }

    render() {
        const {productById} = this.props;
        const {productData, fieldsData, languageTab, isEditing, loading, errors, mainImageIndex} = this.state;
        const {translations} = fieldsData;
        const languageKey = '_' + languageTab;
        const trData = translations[languageTab] || {};
        const initTrData = productData?.translations[languageTab] || {};
        let {filtersList, categoriesList} = this.props;

        const checkbox = <div className="right-side">
            <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
            <Switch checked={!fieldsData.isHidden}
                    onChange={(checked) => this.getInputValues({
                        name: 'isHidden',
                        value: !checked,
                        haveChanges: productById?.isHidden !== !checked
                    })}/>
        </div>
        return <PageWrapper>
            <section className="product-add-edit">
                <PageHeader pageTitle={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'} Ապրանք`} rightTopProps={checkbox}/>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              activeTab={languageTab}
                />
                <div className="product-wrapper">
                    <div className="product-left-part">
                        <div className={`post-image-upload ${this.errorsSet.has('images') ? 'error-images' : ''}`}>
                            <label>Նկարներ<span>*</span></label>
                            <PostImageUploader noImageError={''}
                                               changeMainImage={this.changeMainImage}
                                               imageDeleted={this.imageDeleted}
                                               getImages={this.getImages}
                                               mainImageIndex={mainImageIndex}
                                               productIsEditing={isEditing}
                                               images={fieldsData.images}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Անվանում<span
                                            className="field-required">*</span></>}
                                        placeholder="Անվանում"
                                        name="title"
                                        maxLength={100}
                                        value={trData?.title}
                                        initValue={initTrData?.title}
                                        error={this.errorsSet.has('title' + languageKey)}
                                        onChange={this.getTranslatableInputValues}/>
                        </div>
                        <div className="price-wrapper">
                            {isEditing &&
                            <InputGroup inputType="input"
                                        type="number"
                                        label="Հին գին"
                                        placeholder="Հին գին"
                                        name="oldPrice"
                                        min="0"
                                        step="1000"
                                        value={fieldsData?.oldPrice}
                                        initValue={productData?.oldPrice}
                                        onChange={this.getInputValues}/>}
                            <InputGroup inputType="input"
                                        type="number"
                                        label="Նոր գին"
                                        placeholder="Նոր գին"
                                        name="price"
                                        min="0"
                                        step="1000"
                                        value={fieldsData?.price}
                                        initValue={productData?.price}
                                        error={this.errorsSet.has('price')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="price-wrapper">
                            <InputGroup inputType="input"
                                        type="number"
                                        label="Քանակ"
                                        placeholder="Քանակ"
                                        name="quantity"
                                        regExp={/^[1-9]\d*$/}
                                        value={fieldsData?.quantity}
                                        initValue={productData?.quantity}
                                        error={this.errorsSet.has('quantity')}
                                        onChange={this.getInputValues}/>
                            <InputGroup inputType="selectCustom"
                                        label={"Չափման միավոր"}
                                        placeholder="Չափման միավոր"
                                        name="measurementUnit"
                                        showSearch={false}
                                        value={fieldsData?.measurementUnit}
                                        initValue={productData?.measurementUnit}
                                        withClear
                                        onChange={this.getInputValues}
                                        options={measurementUnitOptions}/>
                        </div>
                        <div className="product-categories-filters">
                            <div className="categories">
                                <label>Կատեգորիաներ</label>
                                <Tree
                                    checkable
                                    onCheck={this.onCheckCategories}
                                    checkedKeys={fieldsData.categories}
                                    showLine={true}
                                >
                                    {this.treeData(categoriesList, "subCategories")}
                                </Tree>
                            </div>
                            <div className="filters">
                                <label>Ֆիլտրեր</label>
                                <Tree
                                    checkable
                                    onCheck={this.onCheckFilters}
                                    checkedKeys={fieldsData.filterValues}
                                    showLine={true}
                                >
                                    {this.treeData(filtersList, "values")}
                                </Tree>
                            </div>
                        </div>
                    </div>
                    <div className="product-right-part">
                        <div className={'editor-wrapper'}>
                            <label>Նկարագրություն</label>
                            <Editor value={trData?.description || ""}
                                    onChange={this.getTempValue}/>
                        </div>
                    </div>
                </div>
                <div className="add-product">
                    <LinkButton title={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'}`}
                                loading={loading}
                                disabled={!this.updatedDataMap.size && isEditing}
                                cb={isEditing ? this.updateProduct : this.addProduct}/>
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetFilters,
    GetCategories,
    AddProduct,
    UpdateProduct,
    GetProductById,
    toggleNotificationRow,
    AddProductImage,
    DeleteProductImage,
    ChangeProductMainImage
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditProduct)
