export const PETROLAM_STATION_QR_PREFIX = "PetrolAM_Station_";

export const BALANCE_TYPES = {
    FUEL: 'FUEL',
    MONEY: 'MONEY',
};
export const MEDIA_TYPES = {
    FILE: 'FILE',
    FOLDER: 'FOLDER',
};

export const MEASUREMENT_TYPES = {
    LITER: 'Լ',
    KILOGRAM: 'Կգ',
    PIECES: 'Հատ',
};

export const STATION_TYPES = {
    GAS_STATION: 'Գազ',
    PETROL_STATION: 'Բենզին',
    PROPAN_STATION: 'Պրոպան',
};

export const NETWORK_STATION_TYPES = {
    GAS: 'Գազ',
    PETROL: 'Բենզին',
    PROPAN: 'Պրոպան',
};

export const TRANSFER_TYPES = {
    GET_FUEL_FROM_COMPANY: 'Կազմակերպություն',
    FUEL_TO_OR_FROM_FRIEND: 'Անձնական',
};

export const SPENT_TYPES = {
    SPENT_COMPANY_FUEL: 'Կազմակերպություն',
    SPENT_PERSONAL_FUEL: 'Անձնական',
};

export const TRANSFERS_NOTIFICATION_TYPES = {
    FUEL_SALE_TO_ADMIN: {
        type: 'FUEL_SALE_TO_ADMIN',
        message: {
            ACCEPTED: 'Հայտը հաստատված է',
            REJECTED: 'Հայտը մերժված է',
        }
    },
    PAID_AMOUNT_TO_STATION_NETWORK: {
        type: 'PAID_AMOUNT_TO_STATION_NETWORK',
    },
};

export const STATION_HISTORY_TYPES = {
    NEW: 'Նոր',
    COMPLETED: 'Ավարտված',
};

export const UPDATED_FUELS_TYPES = {
    ACCEPTED: 'Հաստատել',
    REJECTED: 'Մերժել',
};

export const STATION_NETWORK_SALES_TYPES = {
    ACTIVE: 'Ընթացիկ',
    ACCEPTED: 'Հաստատված',
    REJECTED: 'Մերժված',
};

export const GENDER_TYPES = {
    'MALE': 'Արական',
    'FEMALE': 'Իգական',
    'CUSTOM': 'Այլ',

};

export const HISTORY_TYPES = {
    ADMIN_TO_COMPANY: 'ADMIN_TO_COMPANY',
    ADMIN_TO_USER: 'ADMIN_TO_USER',
    FILLED_ACCOUNT_FROM_CARD: 'FILLED_ACCOUNT_FROM_CARD',
    FUEL_TO_OR_FROM_FRIEND: 'FUEL_TO_OR_FROM_FRIEND',
    GET_FUEL_VIA_ACCOUNT: 'GET_FUEL_VIA_ACCOUNT',
    COMPANY_GET_FUEL_VIA_ACCOUNT: 'COMPANY_GET_FUEL_VIA_ACCOUNT',
    GET_FUEL_VIA_CARD: 'GET_FUEL_VIA_CARD',
    GET_FUEL_FROM_COMPANY: 'GET_FUEL_FROM_COMPANY',
    SPENT_COMPANY_FUEL: 'SPENT_COMPANY_FUEL',
    SPENT_PERSONAL_FUEL: 'SPENT_PERSONAL_FUEL',

    ORDER_VIA_ACCOUNT: 'ORDER_VIA_ACCOUNT',
    ORDER_VIA_CARD: 'ORDER_VIA_CARD',

    FUEL_VIA_GIFT_CARD: 'FUEL_VIA_GIFT_CARD',
    MONEY_VIA_GIFT_CARD: 'MONEY_VIA_GIFT_CARD',

    GET_SALE_FUEL_REQUEST: 'GET_SALE_FUEL_REQUEST',
    GET_PAID_AMOUNT_REQUEST: 'GET_PAID_AMOUNT_REQUEST',
};

export const CAR_Types = {
    PASSENGER: 'Մարդատար',
    TRUCK: 'Բեռնատար',
    MOTORCYCLE: 'Մոտոցիկլ',
    SPECIAL: 'Հատուկ',
    BUS: 'Ավտոբուս',
};

export const DEFAULT_IMAGE_ID = '5db99765e2019423d6e0df80';


export const NOTIFICATION_ROW_MODES = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
};


export const STATUS_LIST = {
    ACTIVE: {
        title: "Նոր",
        key: 'ACTIVE',
        color: '#3BC200',
    },
    CANCELLED: {
        title: 'Չեղարկված',
        key: 'CANCELLED',
        color: '#ACB3CA',
    },
    APPROVED: {
        title: 'Հաստատված',
        key: 'APPROVED',
        color: '#042D5B',
    },
    REJECTED: {
        title: 'Մերժված',
        key: 'REJECTED',
        color: '#CA352B',
    },
    OPEN: {
        title: 'Չօգտագործված',
        key: 'OPEN',
        color: '#0BC584',
    },
    USED: {
        title: 'Օգտագործված',
        key: 'USED',
        color: '#EAECF3',
    },
    DONE: {
        title: 'Ավարտված',
        key: 'DONE',
        color: '#FFB800',
    },
};

export const PROMO_CODE_STATUS = {
    USED: "Օգտագործված",
    OPEN: "Չօգտագործված"
};

export const PROMO_CODE_TYPE = {
    FUEL: "Վառելիք",
    MONEY: "Գումար"
};

export const StatusList = [
    {
        id: 'ALL',
        name: "Բոլորը",
    }, {
        id: 'ACTIVE',
        name: "Նոր",
    },
    {
        id: 'CANCELLED',
        name: "Չեղարկված",
    },
    {
        id: 'REJECTED',
        name: "Մերժված",
    },
    {
        id: 'APPROVED',
        name: "Հաստատված",
    },
    {
        id: 'DONE',
        name: "Ավարտված",
    },
];

export const PURPOSE_TYPE = {
    PERSONAL: "Անձնական",
    PUBLIC: "Հանրային",
    TAXI: 'Տաքսի',
    TRADING: 'Կոմերցիոն',
    OTHER: 'Այլ'
};
export const OFFERS_TYPE = {
    TYRE_CHANGE: 'tyre-change', // Անվադողեր
    SPARES: 'spares', //Ավտոպահեստամասեր
    REPAIRING: 'repairing', //Ավտոտեխսպասարկում
};
