import React from 'react';
import {connect} from 'react-redux';

//import components
import {LinkButton} from "../../components/buttons/buttons";

//import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {UploadFile} from "../../redux/actions";
import {ACCEPT_FILE_TYPES} from "../../constants/acceptedTypes";
import swal from "sweetalert";


class FileUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requestLoading: false,
        };
        this.inputElement = React.createRef();
        this.onDropFile = this.onDropFile.bind(this);
        this.onUploadClick = this.onUploadClick.bind(this);
        this.triggerFileUpload = this.triggerFileUpload.bind(this);
    }

    hasExtension(fileName) {
        const extensionList = ACCEPT_FILE_TYPES.map(e => '.' + e);
        const pattern = '(' + extensionList.join('|').replace(/\./g, '\\.') + ')$';
        return new RegExp(pattern, 'i').test(fileName);
    }

    async onDropFile(e) {
        const files = e.target.files;
        await this.setState({
            ...this.state,
            requestLoading: true
        });
        //let file = files[0];

        let errorFiles = [];
        let haveBrokenImage = false;
        const filesPromises = [];

        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            const extensionSupported = this.hasExtension(file.name);
            if (!extensionSupported || file.size > this.props.maxFileSize) {
                haveBrokenImage = true;
                errorFiles.push({
                    name: file.name,
                    extension: !extensionSupported,
                    size: file.size > this.props.maxFileSize
                })
            } else {
                const {currentFolder} = this.props;
                let formData = new FormData();
                // const name = file.name.replace(/\\|\/| /g, '-');
                formData.append(file.name, file);
                currentFolder && currentFolder.id && formData.append('parent', currentFolder.id);
                currentFolder && currentFolder.path && formData.append('parentPath', currentFolder.path);
                const pathList = currentFolder && currentFolder.path && currentFolder.path.split('/').slice(1);

                const filePromise = this.props.UploadFile(formData, pathList);

                filesPromises.push(filePromise);
            }
        }

        if (filesPromises.length) {
            Promise.all(filesPromises).then(() => {
                haveBrokenImage && swal({
                    title: "Զգուշացում!",
                    text: errorFiles.map(errFile=>{
                        return `${errFile.name} - ${errFile.extension ? ' չթույլատրվաց ֆորմատով է, ' :''} ${errFile.size ? ' չափսը 200mb -ից մեծ է,' :''}`
                    }).join('\n'),
                    icon: "info",
                    button: "Ok"
                });
            }).finally(() => {
                this.setState({
                    requestLoading: false
                });
            })
        } else {
            haveBrokenImage && swal({
                title: "Զգուշացում!",
                text: errorFiles.map(errFile=>{
                    return `${errFile.name} - ֆայլը ${errFile.extension ? ' չթույլատրվաց ֆորմատով է, ' :''} ${errFile.size ? ' չափսը 200mb -ից մեծ է,' :''}`
                }).join('\n'),
                icon: "info",
                button: "Ok"
            });
            this.setState({
                requestLoading: false
            });
        }
    }

    onUploadClick(e) {
        e.target.value = null;
    }


    triggerFileUpload() {
        this.inputElement.current.click();
    }

    render() {
        const {requestLoading} = this.state;
        return (
            <div className={"file-uploader "}>
                <LinkButton disabled={this.props.disabled}
                            loading={requestLoading}
                            cb={this.triggerFileUpload}
                            title="Ավելացնել Ֆայլ"/>

                <input
                    style={{display: 'none'}}
                    type="file"
                    ref={this.inputElement}
                    multiple={true}
                    onChange={this.onDropFile}
                    onClick={this.onUploadClick}
                />

            </div>
        )
    }
}

const mapDispatchToProps = {
    UploadFile,
};
export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);
