// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";

// Import styles
import "../../assets/styles/containerStyles/offers.scss";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetInsurance, SetInsuranceAsSeen, GetNotSeenInsuranceCount} from "../../redux/actions";

//Import components
import ItemsList from "../../components/ItemsList";
import PageHeader from "../../components/pageContentViews/pageHeader";
import PageWrapper from "../../components/pageContentViews/pageWrapper";

class Insurances extends Component {

    constructor() {
        super();
        this.fetching=false;
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        this.props.GetNotSeenInsuranceCount();
        this.props.GetInsurance();
    }

    setInsuranceAsSeen(id) {
        const {insuranceList} = this.props;
        const insurance = insuranceList.itemsList.find(insurance => insurance.id === id);
        insurance && (insurance.seen === false) && this.props.SetInsuranceAsSeen(id);
    }

    async loadMoreItems() {
        if (!this.fetching) {
            this.fetching=true;
            const {insuranceList} = this.props;
            insuranceList.hasMore && await this.props.GetInsurance({
                reset: false,
                offset: insuranceList.itemsList.length,
            });
            this.fetching=false
        }
    };

    render() {
        const {insuranceList, mainLanguage, requestLoading} = this.props;
        const itemListHeaderInfo = {
            generalInfo: ["Կազմակերպություն", "Անվանում", "Հեռ․", "Ամսաթիվ"],
            largeItems: [0, 1],
            status: false,
        };
        let itemListInfo = [];
        insuranceList.itemsList.forEach(item => {
            itemListInfo.push({
                id: item.id,
                newItem: !(item?.seen ?? true),
                onClick: this.setInsuranceAsSeen.bind(this, item.id),
                values: [
                    item?.insuranceCompany?.translations?.find(item => item.language === mainLanguage)?.title || '',
                    item?.user?.firstName + " " + item?.user?.lastName,
                    item?.phoneNumber,
                    item?.createdAt ? moment(item.createdAt).format('DD.MM.YYYY') : '',
                ],
            });
        });
        return <PageWrapper>
            <section className="offers insurance">
                <PageHeader pageTitle={'Ապահովագրություն'}
                            search={false}/>
                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={itemListInfo}
                           detailsLink={"insurances"}
                           loading={requestLoading}
                           infiniteScroll={true}
                           hasMore={insuranceList.hasMore}
                           loadMoreItems={this.loadMoreItems}
                />
            </section>

        </PageWrapper>


    }
}

const mapDispatchToProps = {
    GetInsurance,
    GetNotSeenInsuranceCount,
    SetInsuranceAsSeen,
};

export default connect(mapStateToProps, mapDispatchToProps)(Insurances)
