//import utils
import {HISTORY_TYPES} from "../constants/constTypes";

export const getCompanyHistoryTitle = (type, amount) => {
    switch (type) {
        case HISTORY_TYPES.ADMIN_TO_COMPANY:
            return amount > 0 ? 'Հաշվի համալրում' : 'Հաշվի նվազեցում';
        case HISTORY_TYPES.SPENT_COMPANY_FUEL:
            return'Կազմ․ հաշվի օգտագործում';
        default:
            return 'Հաշվի օգտագործում';
    }
};

export const getUserHistoryTitle = (type, amount) => {
    switch (type) {
        case HISTORY_TYPES.ADMIN_TO_USER:
            return amount > 0 ? 'Հաշվի համալրում' : 'Հաշվի նվազեցում';
        case HISTORY_TYPES.GET_FUEL_FROM_COMPANY:
            return amount > 0 ? 'Կազմ․ Հաշվի համալրում' : 'Կազմ․ Հաշվի նվազեցում';
        case HISTORY_TYPES.FILLED_ACCOUNT_FROM_CARD:
        case HISTORY_TYPES.GET_FUEL_VIA_CARD:
        case HISTORY_TYPES.GET_FUEL_VIA_ACCOUNT:
        case HISTORY_TYPES.FUEL_TO_OR_FROM_FRIEND:
            return 'Հաշվի համալրում';
        case HISTORY_TYPES.SPENT_COMPANY_FUEL:
        case HISTORY_TYPES.SPENT_PERSONAL_FUEL:
            return 'Հաշվի օգտագործում';
        case HISTORY_TYPES.ORDER_VIA_CARD:
        case HISTORY_TYPES.ORDER_VIA_ACCOUNT:
            return 'Պատվեր';
        default:
            return 'Հաշվի համալրում';
    }
};
