// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import swal from 'sweetalert';
//import assets
import '../assets/styles/dataDisplay/transfer-modal.scss';

// Import components
import {DatePicker, Modal, Select} from "antd";
import {InputGroup} from "./uiElements/inputGroup";
import {LinkButton} from "./buttons/buttons";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {AddPaidAmount} from "../redux/actions";
import {hyAm} from "../constants/datePikerLocal-HY";

const dateFormat = 'DD.MM.YYYY';

class TransferModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            transfer: {
                filledDate: '',
                stationNetworkId: undefined,
                sum: '',
            },
            loading: false,
        };
        this.errorsSet = new Set();

        this.getInputValues = this.getInputValues.bind(this);
        this.handleChangeData = this.handleChangeData.bind(this);
        this.addSale = this.addSale.bind(this);
    }

    componentDidMount() {
        this.networkOptions = this.props?.networks.filter(item => !item.isHidden).map(network => ({
            id: network.id,
            name: network.translations.find(lg => lg.language === this.props?.mainLanguage).title
        }));
    }

    getInputValues({name, value,}) {
        const phoneRegExp = /^[+\d]\d*$/;
        if ((name === 'sum')
            && value && !phoneRegExp.test(value)) {
            return;
        }
        if (name === 'sum' && value > 10000000) {
            return;
        }
        this.setState({
            transfer: {
                ...this.state.transfer,
                [name]: value
            }
        });
    }

    handleChangeData(name, value) {
        this.getInputValues({name, value})
    }

    closeModal = () => {
        this.props.closeModal();
        this.setState({
            transfer: {
                filledDate: '',
                stationNetworkId: undefined,
                sum: '',
            },
        });
    };

    async addSale() {
        const {transfer} = this.state;
        this.errorsSet.clear();
        const requiredFields = ['filledDate', "stationNetworkId", "sum"];
        let isValid = true;

        requiredFields && requiredFields.forEach(key => {
            if (!transfer[key] || transfer[key] === "") {
                this.errorsSet.add(key);
                isValid = false;
            }
        });

        if (isValid) {
            let reqData = {};
            reqData = {...transfer};
            Object.keys(transfer).forEach(key => !reqData[key] && delete reqData[key]);
            this.props.AddPaidAmount(reqData).finally(() => {
                this.setState({loading: false});
                this.closeModal();
            });

            this.setState({loading: false});
        } else {
            this.setState({loading: false});
        }
    }

    render() {
        const {visible} = this.props;
        const {transfer} = this.state;

        return <Modal
            visible={visible}
            wrapClassName={'custom-modal'}
            onCancel={this.closeModal}>
            <div className={'transfer-modal'}>
                <span className={'close-icon'} onClick={this.closeModal}>
                    <img src={require('../assets/images/ic_clear.svg')}
                         className="close_icon" alt=""/>
                </span>
                <h2 className="title">Փոխանցում</h2>
                <section className="download">
                    <div className="info-adding-fields">
                        <InputGroup inputType={"wrapper"}
                                    label={"Ամսաթիվ"}>
                            <DatePicker value={transfer?.filledDate}
                                        locale={hyAm}
                                        format={dateFormat}
                                        showToday={true}
                                        allowClear={true}
                                        placeholder="Ամսաթիվ"
                                        className={`date-picker ${this.errorsSet.has('filledDate') ? 'invalid' : ''}`}
                                        onChange={(value) =>
                                            this.handleChangeData('filledDate', value)}>
                            </DatePicker>
                        </InputGroup>

                        <InputGroup inputType="selectCustom"
                                    label={"Ցանց"}
                                    name="stationNetworkId"
                                    showSearch={false}
                                    value={transfer.stationNetworkId}
                                    error={this.errorsSet.has('stationNetworkId')}
                                    onChange={this.getInputValues}
                                    options={this.networkOptions}>
                        </InputGroup>

                        <InputGroup inputType={"input"}
                                    type={'text'}
                                    label={"Գումար"}
                                    name={"sum"}
                                    error={this.errorsSet.has('sum')}
                                    value={transfer.sum}
                                    onChange={this.getInputValues}/>

                    </div>
                    <div className="btn-wrapper">
                        <LinkButton title={"Ավելացնել"}
                                    className={'add-button'}
                                    cb={this.addSale}/>
                    </div>
                </section>
            </div>
        </Modal>
    }
}


const mapDispatchToProps = {
    AddPaidAmount
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferModal)
