// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {history} from "../configs/history";
import {withRouter} from 'react-router-dom';

//Import styles
import "../assets/styles/containerStyles/adminLayout.scss";

//Import components
import {LeftSideMenu} from "../components/navbars/LeftSideMenu";
import {MainHeader} from "../components/headerViews/mainHeader";
import {Footer} from "./Footer";
import {Icon} from "antd";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {
    ToggleLeftSideMenu,
    TokenValidation,
    RedirectFirstTime,
    GetLanguages,
    GetFuels,
    GetFeatures,
    GetRegions,
    GetCategories,
    GetNotSeenReviewsCount,
    GetNotSeenOrdersCount,
    GetNotSeenInsuranceCount,
    GetNotSeenOffersCount,
    GetNotifications
} from "../redux/actions";
import {LogOut} from "../redux/actions";
import {OFFERS_TYPE} from "../constants/constTypes";
import {ACCESS_PAGES} from "../constants/accessPages";
import {socketNotificationConnection} from "../socket/api/socketNotificationConnection";


class AdminLayout extends Component {
    state = {
        isMounted: false,
    };

    async componentDidMount() {
        let redirectLoginPage = false;
        const {location} = this.props;
        if (!this.props.isLoggedIn) {
            redirectLoginPage = true;
        } else {
            await this.props.TokenValidation().then(async () => {
                await this.props.GetLanguages();
                await this.props.GetNotifications();
               // console.log('accessPages', this.props.accessPages)
                if (!socketNotificationConnection._instance && this.props.isLoggedIn) {
                    socketNotificationConnection.connectToSocket();
                }
            }).catch(() => {
                redirectLoginPage = true;
            });
        }
        if (redirectLoginPage) {
            this.props.LogOut();
        } else {
            const {accessPages} = this.props;
            this.props.GetRegions();
            this.props.GetFuels();
            accessPages.includes(ACCESS_PAGES.categories.key) && this.props.GetCategories();
            accessPages.includes(ACCESS_PAGES.reviews.key) && this.props.GetNotSeenReviewsCount();
            accessPages.includes(ACCESS_PAGES.orders.key) && this.props.GetNotSeenOrdersCount();
            accessPages.includes(ACCESS_PAGES.insurances.key) && this.props.GetNotSeenInsuranceCount();
            accessPages.includes(ACCESS_PAGES.offers.key) && Object.keys(OFFERS_TYPE).forEach(offer => {
                this.props.GetNotSeenOffersCount(OFFERS_TYPE[offer]);
            });
            if (location && location.pathname.includes('moderators') && !this.props.isAdmin) {
                history.push('/404');
            }
            this.setState({
                isMounted: true,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location} = this.props;
        const {isLoggedIn} = this.props;
        if (location?.pathname !== prevProps?.location?.pathname) {
            window.scrollTo(0, 0);
        }
        if (location && location.pathname.includes('moderators') && !this.props.isAdmin) {
            history.push('/404');
        }
        if (!prevProps.isLoggedIn && isLoggedIn) {
            if (!socketNotificationConnection._instance) {
                socketNotificationConnection.connectToSocket();
            }
        }
    }

    render() {
        const {isMounted} = this.state;
        const {
            children, leftSideMenuOpen, ToggleLeftSideMenu, LogOut, isAdmin, location,
            accessPages, notSeenReviewsCount, notSeenOrdersCount, notSeenInsuranceCount, user,
            repairingList, sparesList, tyreChangeList, nonOpenedNotificationCount
        } = this.props;
        const spinIcon = <Icon type="loading" style={{fontSize: 54, color: "#1F3A62"}} spin/>;
        const allOffersCount = (repairingList?.notSeenCount ?? 0) +
            (sparesList?.notSeenCount ?? 0) + (tyreChangeList?.notSeenCount ?? 0);

        return isMounted ? <div className={`admin-layout ${leftSideMenuOpen ? "menu-open" : ""}`}>
                <MainHeader toggleMenu={ToggleLeftSideMenu}
                            leftSideMenuOpen={leftSideMenuOpen}
                            isAdmin={isAdmin}
                            nonOpenedNotificationCount={nonOpenedNotificationCount}
                            user={user}
                            LogOut={LogOut}/>
                <LeftSideMenu leftSideMenuOpen={leftSideMenuOpen}
                              isAdmin={isAdmin}
                              accessPages={accessPages}
                              notSeenReviewsCount={notSeenReviewsCount}
                              notSeenOrdersCount={notSeenOrdersCount}
                              notSeenInsuranceCount={notSeenInsuranceCount}

                              allOffersCount={allOffersCount}

                              path={location && location.pathname}/>

                {children}
                <Footer/>
            </div> :
            <div className={'loading'}>
                {spinIcon}
            </div>
    }
}

const mapDispatchToProps = {
    ToggleLeftSideMenu,
    LogOut,
    GetLanguages,
    RedirectFirstTime,
    TokenValidation,
    GetFuels,
    GetFeatures,
    GetRegions,
    GetCategories,
    GetNotSeenReviewsCount,
    GetNotSeenOrdersCount,
    GetNotSeenInsuranceCount,
    GetNotSeenOffersCount,
    GetNotifications
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminLayout));
