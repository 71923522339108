// Import packages
import React, {useRef} from "react";

//import assets
import '../../assets/styles/dataDisplay/qr-modal.scss';


// Import components
import {Modal} from "antd";
import {LinkButton} from "../buttons/buttons";
import {MaterialIcon} from "../utils/Icon";

// Import utils
import {PETROLAM_STATION_QR_PREFIX} from "../../constants/constTypes";

var QRCode = require('qrcode.react');

function QRModal(props) {
    const {visible, stationId, stationTitle} = props;
    const componentRef = useRef();

    function closeModal() {
        props.closeModal()
    }

    const download = () => {
        const canvas = document.getElementById(`station-qr`);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");

        downloadLink.href = pngUrl;
        downloadLink.download = `${stationTitle}_QR.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    function imagetoPrint(source) {
        return "<html><head><script>function step1(){\n" +
            "setTimeout('step2()', 10);}\n" +
            "function step2(){window.print();window.close()}\n" +
            "</scri" + "pt></head><body style='width: 100%; height: 100%; display: flex;" +
            " justify-content: center; align-items: center; overflow: hidden' onload='step1()'>\n" +
            "<img src='" + source + "' /></body></html>";
    }

    function printImage() {
        const canvas = document.getElementById(`station-qr`);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");

        var pwa = window.open('', "_new");
        pwa.document.open();
        pwa.document.write(imagetoPrint(pngUrl));
        pwa.document.close();
    }

    return <Modal
        visible={visible}
        wrapClassName={'custom-modal'}
        onCancel={closeModal}>
        <div className={'qr-modal'}>
                <span className={'close-icon'} onClick={closeModal}>
                    <img src={require('../../assets/images/ic_clear.svg')}
                         className="close_icon" alt=""/>
                </span>
            <div className="header">
                <h2>{stationTitle} QR Կոդ</h2>
                <span>
                    <LinkButton title={<><MaterialIcon icon={'save_alt'}/>Ներբեռնել</>} cb={download}/>
                    <LinkButton title={<><MaterialIcon icon={'print'}/>Տպել</>} cb={printImage}/>
                </span>
            </div>
            <section className={'qr-wrapper'} ref={componentRef}>
                <QRCode value={`${PETROLAM_STATION_QR_PREFIX}${stationId}`}
                        imageSettings={{}}
                        size={500}
                        id="station-qr"
                    //renderAs={'svg'}
                    //fgColor={'#0073F2'}
                />
            </section>

        </div>
    </Modal>

}


export default QRModal;
