// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch} from "antd";
import {SketchPicker} from 'react-color';

// Import utils
import {
    changeStateField,
    getInputValues,
    getTranslatableInputValues,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateCategory, GetCategoryById, UpdateCategoryById} from "../../redux/actions";

// Import Styles
import '../../assets/styles/containerStyles/category-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {MediaFileCard} from "../../components/media/mediaFileCard";


class AddEditCategory extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'subTitle',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                iconPath: undefined,
                color: undefined,
                parent: undefined,
                isHidden: false,
            },
            errors: {},
            displayColorPicker: false,
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.toggleColorPicker = toggleStateField.bind(this, 'displayColorPicker');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.getColor = this.getColor.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditCategory = this.addEditCategory.bind(this);
    }

    async componentDidMount() {
        const {isEditing} = this.state;
        const translationsData = {};

        // init Categories Options
        this.categoriesOptions = Array.isArray(this.props.categoriesList) ? this.props.categoriesList.map(item => ({
            name: item?.title,
            id: item?.id,
        })) : [];

        if (isEditing) {
            await this.props.GetCategoryById(this.props.id);
            const editingData = this.props.categoryById;
            this.categoriesOptions =  this.categoriesOptions.filter(item => item.id !== this.props.id);
            if (editingData) {

                editingData.translations.forEach(item => {
                    translationsData[item.language] = {
                        ...translationsData[item.language],
                        title: item.title || '',
                        subTitle: item.subTitle || '',
                    };
                });

            }
            this.setState({
                ...this.state,
                fieldsData: {
                    ...this.state.fieldsData,
                    translations: translationsData,
                    iconPath: editingData?.iconPath,
                    color: editingData?.color,
                    parent: editingData?.parent,
                    isHidden: editingData?.isHidden,
                },
            });
            this.currentData = {
                ...editingData,
                translations: translationsData,
            };

        }

        this.forceUpdate();
    }

    getMedia(mediaArray) {
        const {fieldsData} = this.state;
        this.currentData?.iconPath?.id === fieldsData?.iconPath?.id
            ? this.updatedDataMap.set('iconPath', mediaArray[0])
            : this.updatedDataMap.delete('iconPath');
        this.setState({
            fieldsData: {
                ...fieldsData,
                iconPath: mediaArray[0],
            },
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        const {categoryById} = this.props;
        if (isEditing) {
            categoryById.iconPath ?
                this.updatedDataMap.set("iconPath", null) :
                this.updatedDataMap.delete("iconPath");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                iconPath: null
            }
        })
    }

    getColor = ({color, haveChanges}) => {
        haveChanges
            ? this.updatedDataMap.set('color', color.hex)
            : this.updatedDataMap.delete('color');
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                color: color.hex
            }
        })
    };

    async addEditCategory() {
        await this.setState({
            requestLoading: true
        });
        const {translations, iconPath, color, isHidden, parent} = this.state.fieldsData;

        let result = true;
        const errors = {};
        const TR_data = [];

        Object.keys(translations).forEach(key => {
            let tr_item = {
                language: key,
            };

            if (translations[key] && translations[key].title && translations[key].subTitle) {
                tr_item.title = translations[key].title;
                tr_item.subTitle = translations[key].subTitle;
                TR_data.push(tr_item);
            } else {
                errors['title' + key] = !translations[key].title;
                errors['subTitle' + key] = !translations[key].subTitle;
                result = false;
            }
        });

        if (result) {
            const reqData = {

                color,
                isHidden,
                translations: TR_data
            };
            if (iconPath) reqData.iconPath = iconPath.id;

            if (parent) reqData.parent = parent;

            if(!this.state.isEditing) {
                this.props.CreateCategory(reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    })
            }
            else{
                if (iconPath === null) reqData.iconPath = null;

                if (!parent) reqData.parent = null;

                this.props.UpdateCategoryById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }

        } else {
            this.setState({
                requestLoading: false,
                errors: errors
            })
        }
    }

    render() {
        const {fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};
        const styles = {
            color: {
                width: '100px',
                height: '25px',
                borderRadius: '2px',
                background: `${this.state.fieldsData.color}`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',

            },
        };
        const checkbox = <div className="right-side">
            <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
            <Switch checked={!fieldsData.isHidden}
                    onChange={(checked) => this.getInputValues({
                        name: "isHidden",
                        value: !checked,
                        haveChanges: this.currentData?.isHidden !== !checked
                    })}/>
        </div>
        return <PageWrapper>
            <section className="category-add-edit">
                <PageHeader pageTitle={!isEditing ? 'Ավելացնել Բաժին' : 'Փոփոխել Բաժինը'}
                            rightTopProps={checkbox}/>

                <div className="top-side">
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={'choose-btn'}
                                cb={this.toggleMediaModal}
                    />
                    {/*<div className="color-piker">
                        <span>Գույն</span>
                        <div>
                            <div style={styles.swatch} onClick={this.toggleColorPicker}>
                                <div style={styles.color}/>
                            </div>
                            {this.state.displayColorPicker ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.toggleColorPicker}/>
                                <SketchPicker color={this.state.fieldsData.color}
                                              onChange={(color) => this.getColor({
                                                  color,
                                                  haveChanges: this.currentData?.color !== !color.hex
                                              })}/>
                            </div> : null}

                        </div>
                    </div>*/}
                </div>
                {fieldsData.iconPath && <div className={'main-media'}>
                    <MediaFileCard inModal={false}
                                   item={fieldsData.iconPath}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              activeTab={languageTab}
                />
                <div className="category-wrapper">
                    <div className="category-left-part">
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Անվանում"
                                    placeholder="Անվանում"
                                    name="title"
                                    value={currentData.title}
                                    initValue={initData.title}
                                    error={errors['title' + languageTab]}
                                    maxLength={100}
                                    onChange={this.getTranslationValues}/>
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Ենթավերնագիր"
                                    placeholder="Ենթավերնագիր"
                                    name="subTitle"
                                    value={currentData.subTitle}
                                    initValue={initData.subTitle}
                                    error={errors['subTitle' + languageTab]}
                                    maxLength={1000}
                                    onChange={this.getTranslationValues}/>
                        {/*<InputGroup inputType="wrapper"
                                    label={"Ծնող բաժին"}>
                            <InputGroup
                                value={fieldsData.parent}
                                options={this.categoriesOptions}
                                initValue={this.currentData?.parent}
                                inputType="selectCustom"
                                name="parent"
                                withClear
                                placeholder={'Ծնող բաժին'}
                                onChange={this.getInputValues}
                            />
                        </InputGroup>*/}
                    </div>
                </div>

                <div className="add-category">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditCategory}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateCategory,
    GetCategoryById,
    UpdateCategoryById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCategory)
