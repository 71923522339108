import isEmail from "validator/lib/isEmail";
import moment from "moment";
import {BALANCE_TYPES} from "../../../constants/constTypes";
import {truncNumber} from "../../../utils/helperFunctions";

export function validateFields(errors, validationData) {
    const {activeLanguages} = this.props;
    this.errorsSet.clear();
    this.errorsTabs.clear();
    const phoneRegExp = /^[+]?[0-9]{9,20}$/;
    errors.requiredFields && errors.requiredFields.forEach(key => {
        if (!validationData[key] || validationData[key] === "") {
            this.errorsSet.add(key);
            return false;
        }
        if ((key === 'username' || key === 'password' || key === 'taxCode') && validationData?.[key]?.length < 8) {
            this.errorsSet.add(key);
            return false;
        }
        if (key === 'repeatPassword' && validationData.password !== validationData.repeatPassword) {
            this.errorsSet.add(key);
            return false;
        }
        if ((key === 'username' || key === 'email') && !isEmail(validationData?.[key])) {
            this.errorsSet.add(key);
            return false;
        }
        if (validationData.bankAccount.length && validationData.bankAccount.length < 16) {
            this.errorsSet.add('bankAccount');
        }
        if (validationData.phoneNumber1 && !phoneRegExp.test(validationData.phoneNumber1)) {
            this.errorsSet.add('phoneNumber1');
        }
        if (validationData.phoneNumber2 && !phoneRegExp.test(validationData.phoneNumber2)) {
            this.errorsSet.add('phoneNumber2');
        }
        return true;
    });


    activeLanguages && activeLanguages.forEach(lg => {
        const trData = validationData?.translations[lg.id] || {};
        let tabHasError = false;
        errors.translations && errors.translations.forEach(key => {

            if (!trData || !trData[key] || trData[key] === "") {
                // console.groupCollapsed(` %c Translation`, "color:red");
                // console.log( trData);
                // console.log( key);
                // console.log( lg);
                // console.groupEnd();
                this.errorsSet.add(key + '_' + lg.id);
                tabHasError = true;
            }
        });
        tabHasError && this.errorsTabs.add(lg.id);
    });
    //       console.log(this.errorsSet)
    return !this.errorsSet.size;
}


export function parseDetailsData(companyById, mainLanguage) {
    const mainTr = companyById?.translations?.find(tr => tr.language === mainLanguage);
    const {regionHead,residenceHead, addressHead}=companyById || {};
    return {
        id: companyById?.id,
        name: mainTr?.name || '-',
        address: `${regionHead?.name || ''} ${residenceHead?.name || ''} ${addressHead || ''}`,
        phone: companyById?.phoneNumber1 ?? '-',
        date: companyById?.lastUpdatedAt ? moment(companyById?.lastUpdatedAt).format('DD.MM.YYYY') : '-',
        isHidden: !companyById?.isHidden,
    };
}

export function getCompanyListData(companyList, withBalance = false) {
    const {fuelList, mainLanguage,} = this.props;
    return companyList && companyList.length ? companyList.map((company) => {
        const {phoneNumber1, phoneNumber2, email, balances, createdAt, usersQuantity, carsQuantity, usersBalances} = company;
        const mainTranslations = company?.translations?.find(item => item.language === mainLanguage);
        const {marketingName, supervisor} = mainTranslations || {};
        const companyData = {
            id: company.id,
            values: [
                marketingName ?? '-',
                supervisor ?? '-',
                (phoneNumber1 || phoneNumber2) ?? '-',
                email ?? '-',
                usersQuantity ?? "-",
                carsQuantity ?? "-",
                createdAt ? moment(createdAt).format('DD.MM.YYYY') : "-",
                (balances && Array.isArray(balances)) ? balances.find(item => item.type === BALANCE_TYPES.MONEY)?.amount || 0 : 0
            ],
            status: !company.isHidden,
            deleteCb: this.deleteCompany.bind(this, company.id)
        };
        if (withBalance) {
            fuelList && fuelList.forEach(fuel => {
                const currBalance = balances && balances.find(balance => balance?.fuel === fuel.id);
                const userBalance = usersBalances && Array.isArray(usersBalances) && usersBalances.find(item => item?.fuel === currBalance?.fuel)
                const amountSum = (currBalance?.amount || 0) + (userBalance?.amount || 0)
                companyData.values.push(truncNumber(amountSum) + " / " + (currBalance?.amount || 0) ?? 0);
            });
        }
        return companyData;
    }) : [];
}
