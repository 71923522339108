// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon, Switch} from "antd";


// Import Styles
import '../../assets/styles/containerStyles/station-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import MediaSelectorModal from '../../components/media/MediaSelectorModal';
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {validateFields} from "./utils/actionFunctions";
import {GetNetworksById, AddNetwork, EditNetwork, GetRemainingFuelsByNetworkId} from "../../redux/actions";
import {
    initTranslations,
    getInputValues,
    getTranslatableInputValues,
    changeStateField,
    toggleStateField,
    getTranslationData,
} from "../../utils/helperFunctions";
import {MaterialIcon} from "../../components/utils/Icon";
import Supervisor from "./Supervisor";
import {NETWORK_STATION_TYPES} from "../../constants/constTypes";

const stationTypeOptions = Object.keys(NETWORK_STATION_TYPES).map(key => ({
    id: key,
    name: NETWORK_STATION_TYPES[key]
}));

class AddEditStation extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'companyName',
            'legalAddress',
            'director',
            'supervisor',
            'bank',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                username: "",
                password: "",
                repeatPassword: "",
                email: "",
                type: undefined,
                taxCode: "",
                bankAccount: "",
                notes: "",
                phoneNumber: "",
                contract: null,
                isHidden: false,
            },
            remainingFuels: [],
            mediaModalOpen: false,
            loading: false,
            languageTab: props.mainLanguage,
            isEditing: !!props?.id,
            passwordVisible: false,
            repeatPasswordVisible: false,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.validateFields = validateFields.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.addEditNetwork = this.addEditNetwork.bind(this);
        this.deleteContract = this.deleteContract.bind(this);
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;
        if (isEditing) {

            const id = this.props?.id;
            const networkData = {};
            let remainingFuels = []
            await this.props.GetNetworksById(id);
            await this.props.GetRemainingFuelsByNetworkId(id).then((res) => {
                remainingFuels = res
            });
            const {networkById} = this.props;
            //console.log("networkById", networkById);
            const translations = {...fieldsData.translations};
            Object.keys(fieldsData).forEach(field => {
                if (field !== "translations") {
                    networkData[field] = networkById[field] ?? '';
                }
            });
            networkById.translations && networkById.translations.forEach(translation => {
                this.translationsFields.forEach(key => {
                    translations[translation.language] &&
                    (translations[translation.language][key] = translation[key]);
                });
            });
            this.setState({
                fieldsData: {
                    ...networkData,
                    translations: translations
                },
                remainingFuels
            });
        }
    }

    getMedia(mediaArray) {
        const {fieldsData, isEditing} = this.state;
        const {networkById} = this.props;
        if (isEditing) {
            (!networkById?.contract || (networkById?.contract?.id !== mediaArray[0].id)) ?
                this.updatedDataMap.set("contract", mediaArray[0].id) :
                this.updatedDataMap.delete("contract");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                contract: mediaArray[0]
            }
        })
    }

    deleteContract(){
        const {fieldsData, isEditing} = this.state;
        const {networkById} = this.props;
        if (isEditing) {
            networkById?.contract  ?
                this.updatedDataMap.set("contract", null) :
                this.updatedDataMap.delete("contract");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                contract: null
            }
        })
    }
    async addEditNetwork() {
        await this.setState({loading: true});
        const {networkById} = this.props;
        const {fieldsData, isEditing} = this.state;
        const {translations} = fieldsData;

        const errors = {
            requiredFields: ["username", 'taxCode', 'email', 'type'],
            translations: ["title", 'legalAddress', 'director', 'supervisor'],
        };
        // Փոփոխություն անելուց password դաշտը ոչ պարտադիր է
        if (!isEditing || fieldsData.password) {
            errors.requiredFields.push('password', 'repeatPassword');
        }
        const validationResult = this.validateFields(errors, fieldsData);
        //console.log("validationResult", validationResult);
       // console.log(this.errorsSet);
        if (validationResult) {
            let TR_data = getTranslationData(translations);
            //console.log("TR_data", TR_data)
            let reqData = {};
            if (isEditing) {
                this.updatedDataMap.forEach((value, item) => {
                    if (this.translationsFields.some(field => item.includes(field))) {
                        reqData.translations = TR_data
                    }
                    const key = item.split('_')[0];
                    key !== 'translations' && this.translationsFields.every(field => key !== field)
                    && (reqData[key] = value);
                });
                // reqData.contract && (reqData.contract = reqData.contract.id);
              //  console.log('reqData', reqData);
                this.props.EditNetwork(networkById.id, reqData).finally(() => {
                    this.setState({loading: false});
                });

                this.setState({loading: false});

            } else {
                reqData = {...fieldsData};
                Object.keys(fieldsData).forEach(key => !reqData[key] && delete reqData[key]);
                reqData.translations = TR_data;
                reqData.contract && (reqData.contract = reqData.contract.id);
              //  console.log('reqData', reqData);
                delete reqData.repeatPassword;

                this.props.AddNetwork(reqData).finally(() => {
                    this.setState({loading: false});
                })

                this.setState({loading: false});
            }
        } else {
            this.setState({loading: false});
        }

    }


    render() {
        const {networkById, id, mainLanguage} = this.props;

        const {
            fieldsData, passwordVisible, repeatPasswordVisible, languageTab, isEditing,
            loading, mediaModalOpen, remainingFuels
        } = this.state;
        const {translations} = fieldsData;
        const languageKey = '_' + languageTab;
        const initTranslations = networkById?.translations?.find(lg => lg.language === languageTab);
        const trData = translations[languageTab] || {};

        const remainingFuelsList = [];
        this.props?.fuelList && this.props.fuelList.forEach(fuel => {
            const soldAmount = remainingFuels.soldFuels &&
                remainingFuels.soldFuels.find(item => item.fuelId === fuel.id)?.sumAmounts || 0;
            const refuelAmount = remainingFuels.refueledFuels &&
                remainingFuels.refueledFuels.find(item => item.fuelId === fuel.id)?.sumAmounts || 0;

            const resultSum = soldAmount - refuelAmount;
            remainingFuelsList.push({
                id: fuel.id,
                sumAmounts: resultSum
            })
        });

        const rightTopProps = <div className="right-side">
            <div className={'fuels-sum-wrapper'}>
                {this.props?.fuelList && this.props?.fuelList.map((fuel, index) => {
                    let item = remainingFuelsList.find(item => item.id === fuel.id);
                    return <div className={'fuel-item'} key={index}>
                        <span>{fuel?.translations?.find(tr => tr.language === mainLanguage)?.title} </span>
                        <span className={'sum'}>
                                            {item?.sumAmounts ? item?.sumAmounts : 0}
                                    </span>
                    </div>
                })}
            </div>
            <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
            <Switch checked={!fieldsData.isHidden}
                    onChange={(checked) => this.getInputValues({
                        name: 'isHidden',
                        value: !checked,
                        haveChanges: networkById?.isHidden !== !checked
                    })}/>
        </div>
        return <PageWrapper withActions={true}>
            <section className="station-add-edit">
                <PageHeader pageTitle={`${isEditing ? 'Փոփոխել Ցանցը' : 'Ավելացնել Ցանց'} `}
                            rightTopProps={rightTopProps}/>
                <div className="station-fields">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={this.errorsTabs}
                                  activeTab={languageTab}
                    />
                </div>
                <div className="station-fields">
                    <div className="fields-section-one">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Անվանում<span
                                            className="field-required">*</span></>}
                                        placeholder="Անվանում"
                                        name="title"
                                        maxLength={256}
                                        value={trData.title}
                                        initValue={initTranslations?.title}
                                        error={this.errorsSet.has('title' + languageKey)}
                                        onChange={this.getTranslatableInputValues}/>
                        </div>

                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>ՀՎՀՀ<span className="field-required">*</span></>}
                                        placeholder="ՀՎՀՀ"
                                        name="taxCode"
                                        regExp={/^\d*$/}
                                        maxLength={8}
                                        value={fieldsData.taxCode}
                                        initValue={networkById?.taxCode}
                                        error={this.errorsSet.has('taxCode')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Կազմ․ ՍՊԸ անվանում"}
                                        placeholder="Կազմ․ ՍՊԸ անվանում"
                                        name="companyName"
                                        maxLength={256}
                                        value={trData.companyName}
                                        initValue={initTranslations?.companyName}
                                        error={this.errorsSet.has('companyName' + languageKey)}
                                        onChange={this.getTranslatableInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="selectCustom"
                                        label={<>Լցակայանի տիպ<span
                                            className="field-required">*</span></>}
                                        placeholder="Լցակայանի տիպ"
                                        name="type"
                                        showSearch={false}
                                        value={fieldsData?.type}
                                        initValue={networkById?.type}
                                        error={this.errorsSet.has('type')}
                                        onChange={this.getInputValues}
                                        options={stationTypeOptions}
                            />
                        </div>
                    </div>
                    <div className="fields-section-two">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Իրավաբանական Հասցե<span
                                            className="field-required">*</span></>}
                                        placeholder="Հասցե"
                                        name="legalAddress"
                                        maxLength={256}
                                        value={trData.legalAddress}
                                        initValue={initTranslations?.legalAddress}
                                        error={this.errorsSet.has('legalAddress' + languageKey)}
                                        onChange={this.getTranslatableInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="email"
                                        label={<>Էլ․ հասցե<span
                                            className="field-required">*</span></>}
                                        placeholder="Էլ․ հասցե"
                                        name="email"
                                        maxLength={50}
                                        value={fieldsData.email}
                                        initValue={networkById?.email}
                                        error={this.errorsSet.has('email')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Բանկ"}
                                        placeholder="Բանկ"
                                        name="bank"
                                        maxLength={256}
                                        value={trData.bank}
                                        initValue={initTranslations?.bank}
                                        error={this.errorsSet.has('bank' + languageKey)}
                                        onChange={this.getTranslatableInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={"Հաշվեհամար"}
                                        placeholder="Հաշվեհամար"
                                        name="bankAccount"
                                        regExp={/^\d*$/}
                                        maxLength={16}
                                        value={fieldsData.bankAccount}
                                        initValue={networkById?.bankAccount}
                                        error={this.errorsSet.has('bankAccount')}
                                        onChange={this.getInputValues}/>
                        </div>
                    </div>
                    <div className="fields-section-four">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="email"
                                        label={<>Մուտքանուն<span
                                            className="field-required">*</span></>}
                                        placeholder="Մուտքանուն"
                                        name="username"
                                        maxLength={50}
                                        value={fieldsData.username}
                                        initValue={networkById?.username}
                                        error={this.errorsSet.has('username')}
                                        onChange={this.getInputValues}>
                            </InputGroup>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label="Հեռախոս"
                                        placeholder="Հեռախոս"
                                        name="phoneNumber"
                                        regExp={/^[+\d]\d*$/}
                                        maxLength={20}
                                        value={fieldsData.phoneNumber}
                                        initValue={networkById?.phoneNumber}
                                        error={this.errorsSet.has('phoneNumber')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type={`${passwordVisible ? "text" : "password"}`}
                                        label={<>Գաղտնաբառ<span
                                            className="field-required">*</span></>}
                                        placeholder="Գաղտնաբառ"
                                        name="password"
                                        maxLength={20}
                                        value={fieldsData.password}
                                        autocomplete={'new-password'}
                                        initValue={networkById?.password}
                                        error={this.errorsSet.has('password')}
                                        onChange={this.getInputValues}>
                            <span className={'visibility-icon'}
                                  onClick={toggleStateField.bind(this, "passwordVisible")}>
                                <Icon type={`${passwordVisible ? "eye" : "eye-invisible"}`}/>
                            </span>
                            </InputGroup>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type={`${repeatPasswordVisible ? "text" : "password"}`}
                                        label={<>Կրկնել Գաղտնաբառ<span
                                            className="field-required">*</span></>}
                                        placeholder="Կրկնել Գաղտնաբառը"
                                        name="repeatPassword"
                                        maxLength={20}
                                        autocomplete={'new-password'}
                                        value={fieldsData.repeatPassword}
                                        initValue={networkById?.repeatPassword}
                                        error={this.errorsSet.has('repeatPassword')}
                                        onChange={this.getInputValues}>
                            <span className={'visibility-icon'}
                                  onClick={toggleStateField.bind(this, "repeatPasswordVisible")}>
                                <Icon type={`${repeatPasswordVisible ? "eye" : "eye-invisible"}`}/>
                            </span>
                            </InputGroup>
                        </div>
                    </div>
                    <div className="fields-section-five">
                        <div className="fields-left-side">
                            <div className="input-form">
                                <InputGroup inputType="input"
                                            type="text"
                                            label={<>Տնօրեն<span
                                                className="field-required">*</span></>}
                                            placeholder="Տնօրեն"
                                            name="director"
                                            maxLength={256}
                                            value={trData.director}
                                            initValue={initTranslations?.director}
                                            error={this.errorsSet.has('director' + languageKey)}
                                            onChange={this.getTranslatableInputValues}/>
                            </div>
                            <div className="input-form">
                                <InputGroup inputType="input"
                                            type="text"
                                            label={<>Պատասխանատու<span
                                                className="field-required">*</span></>}
                                            placeholder="Պատասխանատու"
                                            name="supervisor"
                                            maxLength={256}
                                            value={trData.supervisor}
                                            initValue={initTranslations?.supervisor}
                                            error={this.errorsSet.has('supervisor' + languageKey)}
                                            onChange={this.getTranslatableInputValues}/>
                            </div>
                        </div>
                        <div className="fields-right-side">
                            <div className="textarea-form">
                                <InputGroup inputType="textarea"
                                            type="text"
                                            label="Նշումներ"
                                            placeholder="Նշումներ"
                                            name="notes"
                                            maxLength={1024}
                                            value={fieldsData.notes}
                                            initValue={networkById?.notes}
                                            error={this.errorsSet.has('notes')}
                                            onChange={this.getInputValues}/>
                            </div>
                        </div>

                    </div>
                    <div className="fields-section-six">
                        <div className="input-form">
                            <div className="upload-btn">
                                <InputGroup inputType={"wrapper"}>
                                    <LinkButton className={"bg-white"}
                                                title={"Ընտրել Պայմանագիրը"}
                                                cb={this.toggleMediaModal}/>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="contract-wrapper">
                                {fieldsData.contract && <div className={'wrapper'}>
                                    <span>Պայմանագիր՝  {fieldsData?.contract?.name}</span>
                                    <MaterialIcon icon="delete" onClick={this.deleteContract}/>
                                </div>}
                        </div>
                    </div>
                    <div className="flex-wrapper-right">
                        <LinkButton title={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'}`}
                                    loading={loading}
                                    disabled={!this.updatedDataMap.size && isEditing}
                                    cb={this.addEditNetwork}/>
                    </div>
                </div>

               {isEditing && <Supervisor networkId={id}/>}
            </section>

            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={['pdf', 'docx']}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetNetworksById,
    AddNetwork,
    EditNetwork,
    GetRemainingFuelsByNetworkId
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditStation)
