import {request, _urlFuel, _urlFeatures} from "../api";
import {FUEL_CONSTS} from "../constants";
import swal from "sweetalert";


export const GetFuels = () => {
    const requestData = {
        url: _urlFuel,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: FUEL_CONSTS.GET_FUELS,
                    payload: data
                });
                //console.log('%c fuels','color:red', data);
            })
    }
};

export const UpdateFuel = (id, data) => {
    const requestData = {
        url: `${_urlFuel}/${id}`,
        token: true,
        method: "PATCH",
        data
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: FUEL_CONSTS.UPDATE_FUEL,
                    payload: data
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Վառելիքը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ"
                })
            })
    }
};
export const GetFeatures = () => {
    const requestData = {
        url: `${_urlFeatures}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: FUEL_CONSTS.GET_FEATURES,
                    payload: data
                });
                //console.log("stationFeatures",data);
            })
    }
}

