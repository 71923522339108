import { AUTH_CONSTS, HISTORY_CONSTS } from "../constants";


export const initialState = {
    historyById: {
        itemsList: [],
        hasMore: false,
        startDate: null,
        endDate: null,
        text: '',
        operationsQuantity: 0,
        operationsSum: 0,
        fuelsSum: [],
    },

    charging: {
        itemsList: [],
        hasMore: false,
        startDate: null,
        endDate: null,
        types: null,
        text: '',
        status: null,
        stationId: null,
        companyId: null,
        fuelId: null,
        operationsQuantity: 0,
        operationsSum: [],
        operationsAmount: null,
        fuelsSum: [],
    },
    transfers: {
        itemsList: [],
        hasMore: false,
        startDate: null,
        endDate: null,
        types: null,
        text: '',
        status: null,
        stationId: null,
        companyId: null,
        fuelId: null,
        operationsQuantity: 0,
        operationsSum: [],
        operationsAmount: null,
        fuelsSum: [],
    },
    purchasedFuel: {
        itemsList: [],
        hasMore: false,
        startDate: null,
        endDate: null,
        types: null,
        text: '',
        status: null,
        stationId: null,
        companyId: null,
        fuelId: null,
        operationsQuantity: 0,
        operationsSum: [],
        operationsAmount: null,
        fuelsSum: [],
    },
    accountReplenishment: {
        itemsList: [],
        hasMore: false,
        startDate: null,
        endDate: null,
        types: null,
        text: '',
        status: null,
        stationId: null,
        companyId: null,
        fuelId: null,
        operationsQuantity: 0,
        operationsSum: [],
        fuelsSum: [],
        operationsAmount: null,
    },
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case HISTORY_CONSTS.GET_HISTORY_BY_MODERATOR_ID:
        case HISTORY_CONSTS.GET_HISTORY_BY_COMPANY_ID:
        case HISTORY_CONSTS.GET_HISTORY_BY_STATION_ID:
        case HISTORY_CONSTS.GET_HISTORY_BY_USER_ID:
            return {
                ...state,
                historyById: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.historyById.itemsList, ...action.payload.data],
                    startDate: action.payload.startDate,
                    endDate: action.payload.endDate,
                    hasMore: action.payload.hasMore,
                    text: action.payload.text
                }
            };

        case HISTORY_CONSTS.GET_HISTORY:
            const stateField = action.payload.key;
            const historyData = {};

            // Object.keys(state[stateField]).forEach(key => {
            //     historyData[key] = action.payload[key];
            // });
            // let itemsList = action.payload.data;
            // if (action.payload.urlPart === 'stations') {
            //     itemsList = action.payload.data?.history;
            //     historyData.operationsQuantity = action.payload.data?.operationsQuantity
            //     historyData.operationsSum = action.payload.data?.operationsSum
            // }
            //
            // historyData.itemsList = action.payload.reset ? itemsList
            //     : [...state[stateField].itemsList, ...itemsList]
            //
            // return {
            //     ...state,
            //     [stateField]: historyData
            // };
            return {
                ...state,
                [stateField]: {
                    itemsList: action.payload.reset ? action.payload.data :
                        [...state[stateField].itemsList, ...action.payload.data],
                    operationsQuantity: action.payload.operationsQuantity,
                    operationsSum: action.payload.operationsSum,
                    operationsAmount: action.payload.operationsAmount,
                    fuelsSum: action.payload.fuelsSum,
                    hasMore: action.payload.hasMore,
                    startDate: action.payload.startDate,
                    endDate: action.payload.endDate,
                    status: action.payload.status,
                    stationId: action.payload.stationId,
                    companyId: action.payload.companyId,
                    fuelId: action.payload.fuelId,
                    types: action.payload.types,
                    text: action.payload.text
                }
            }

        case HISTORY_CONSTS.CLEAR_BY_ID_HISTORY:
            return {
                ...state,
                historyById: { ...initialState.historyById }
            };
        case HISTORY_CONSTS.UPDATE_HISTORY_BY_ID:
            console.log('action.payload', action.payload)
            return {
                ...state,
                charging: {
                    ...state.charging,
                    itemsList: state.charging.itemsList.map(item => item.id === action.payload.id ? {
                        ...item,
                        amount: action.payload.data.amount,
                        sum: action.payload.data.sum,
                        isEdited: true
                    } : item)
                }
            };
        case HISTORY_CONSTS.CLEAR_HISTORY:
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
