import {PROMO_CODES_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    promoCodes: {
        itemsList: [],
        hasMore: true,
        fuelId: null,
        userId: null,
        type: null,
        status: null,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PROMO_CODES_CONSTS.GET_PROMO_CODES:
          //  console.log(action.payload.hasMore, 'action.payload.hasMore');
            return {
                ...state,
                promoCodes: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.promoCodes.itemsList, ...action.payload.data],
                    userId: action.payload.userId,
                    fuelId: action.payload.fuelId,
                    status: action.payload.status,
                    hasMore: action.payload.hasMore
                }
            };
        case PROMO_CODES_CONSTS.ADD_PROMO_CODE:
            return {
                ...state,
                promoCodes: {
                    ...state.promoCodes,
                    itemsList: [action.payload, ...state.promoCodes.itemsList],
                    count: state.promoCodes.count + 1
                }
            };
        case PROMO_CODES_CONSTS.DELETE_PROMO_CODE:
            return {
                ...state,
                promoCodes: {
                    ...state.promoCodes,
                    itemsList: state.promoCodes.itemsList.filter(promoCode => promoCode.id !== action.payload),
                    count: state.promoCodes.count - 1
                }
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
