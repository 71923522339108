// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch} from "antd";


// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";


// Import Styles
import '../../assets/styles/containerStyles/service-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";


class AddEditService extends Component {
    constructor() {
        super();
        this.state = {
            serviceData: {
                title: "",
                description: "",
                subTitle: "",
                address: "",
                minPrice: "",
                isHidden: true
            },
            languageTab: "",
        };

        this.changeLanguageTab = this.changeLanguageTab.bind(this);
    }

    componentDidMount() {
    }

    changeLanguageTab(activeTab) {
        const {languages} = this.props;
        const languageCode = languages?.find(lg => lg.id === activeTab)?.code;
        this.setState({
            languageTab: activeTab,
            languageCode
        })
    }



    render() {
        const {serviceData, languageTab} = this.state;
        const checkbox = <div className="right-side">
            <label>{serviceData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
            <Switch checked={serviceData.isHidden}
                    onChange={(checked) => this.getInputValues({
                        target: {
                            name: 'isHidden',
                            value: checked
                        }
                    })}/>
        </div>
        return <PageWrapper>
            <section className="service-add-edit">
                <PageHeader pageTitle={'Ավելացնել ծառայություն'} rightTopProps={checkbox}/>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              activeTab={languageTab}
                />
                <div className="service-wrapper">
                    <div className="service-left-part">
                        <div className="choose-btn">
                            <LinkButton title={"Ընտրել Մեդիայից"}/>
                        </div>
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Անվանում"
                                    placeholder="Անվանում"
                                    name="name"
                                    value={serviceData.title}
                                    maxLength={256}/>
                        <InputGroup inputType="textarea"
                                    type="text"
                                    label="Նկարագրություն"
                                    placeholder="Նկարագրություն"
                                    name="description"
                                    value={serviceData.description}/>
                    </div>
                    <div className="service-right-part">
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Ենթավերնագիր"
                                    placeholder="Ենթավերնագիր"
                                    name="description"
                                    value={serviceData.subTitle}/>
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Հասցե"
                                    placeholder="Հասցե"
                                    name="description"
                                    value={serviceData.address}/>
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Գումար"
                                    placeholder="Գումար"
                                    name="description"
                                    value={serviceData.minPrice}/>
                    </div>
                </div>

                <div className="add-service">
                    <LinkButton title={"Ավելացնել"}/>
                </div>

            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditService)