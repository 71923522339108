// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/promo-code-add.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {changeStateField} from "../../utils/helperFunctions";
import {AddPromoCode} from "../../redux/actions";
import {validateFields} from "./utils/actionFunctions";

class AddEditProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsData: {
                quantity: "",
                amount: "",
                sum: "",
                fuel: null,
            },
            loading: false,
            languageTab: props.mainLanguage,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = this.getInputValues.bind(this);
        this.validateFields = validateFields.bind(this);
        this.addPromoCode = this.addPromoCode.bind(this);

    }

    getInputValues({name, value}) {
        const { fieldsData} = this.state;
        if ((name === 'quantity') && value && (value < 1 || value > 10000 || !Number(value))) {
            return;
        }
        if((name === 'amount'  && value)){
            if (fieldsData.fuel !== null && (value < 1 || value > 1000 || !Number(value))) {
                return;
            }else if (value < 1 || value > 500000 || !Number(value)){
                return;
            }
        }

        if ((name === 'sum') && value && (value < 1 || value > 500000 || !Number(value))) {
            return;
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [name]: value,
            }
        })
    }

    async addPromoCode() {
        await this.setState({loading: true});
        const {fieldsData} = this.state;

        const errors = {
            requiredFields: ['quantity', 'amount']
        };
        const validationResult = this.validateFields(errors, fieldsData);
        if (validationResult) {
            const reqData = {};
            Object.keys(fieldsData).forEach(key => {
                fieldsData[key] && (reqData[key] = fieldsData[key]);
            });
            this.props.AddPromoCode(reqData).finally(() => {
                this.setState({loading: false});
            })
        } else {
            this.setState({loading: false});
        }
    }

    render() {
        const {fuelList, mainLanguage} = this.props;
        const {fieldsData, languageTab, loading} = this.state;
        const fuelOptions = fuelList.filter(item => !item.isHidden).map(fuel => ({
            id: fuel.id,
            name: fuel.translations.find(lg => lg.language === mainLanguage).title
        }));
        fuelOptions.unshift({
            id: null,
            name: 'Գումարային նվեր քարտ'
        });
        return <PageWrapper>
            <section className="promo-code-add">
                <PageHeader pageTitle={'Ավելացնել նվեր քարտ'}/>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              activeTab={languageTab}
                />
                <div className="promo-code-wrapper">
                    <div className="fields-wrapper">
                        <InputGroup inputType="selectCustom"
                                    label={"Տեսակ"}
                                    placeholder="Տեսակ"
                                    name="fuel"
                                    showSearch={false}
                                    value={fieldsData?.fuel}
                                    onChange={this.getInputValues}
                                    options={fuelOptions}/>
                        <div className="down-fields">
                            <InputGroup inputType="input"
                                        type="text"
                                        label="Քանակ"
                                        placeholder="Քանակ"
                                        name="quantity"
                                        regExp={/^[1-9]\d*$/}
                                        value={fieldsData?.quantity}
                                        error={this.errorsSet.has('quantity')}
                                        onChange={this.getInputValues}/>
                            <InputGroup inputType="input"
                                        type="text"
                                        label={fieldsData.fuel === null ? "Գումար" : "Լ/ԿԳ"}
                                        placeholder={fieldsData.fuel === null ? "Գումար" : "Լ/ԿԳ"}
                                        name="amount"
                                        regExp={/^[1-9]\d*$/}
                                        value={fieldsData?.amount}
                                        error={this.errorsSet.has('amount')}
                                        onChange={this.getInputValues}/>
                            {/*<InputGroup inputType="input"*/}
                            {/*            type="number"*/}
                            {/*            label="Գումար"*/}
                            {/*            placeholder="Գումար"*/}
                            {/*            name="sum"*/}
                            {/*            regExp={/^[1-9]\d*$/}*/}
                            {/*            value={fieldsData?.sum}*/}
                            {/*            error={this.errorsSet.has('sum')}*/}
                            {/*            onChange={this.getInputValues}/>*/}
                        </div>
                    </div>
                </div>
                <div className="add-promo-code">
                    <LinkButton title='Ավելացնել'
                                loading={loading}
                                cb={this.addPromoCode}/>
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    AddPromoCode
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditProduct)
