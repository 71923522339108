import {COMPOSITE_CONSTS} from "../constants";
import {request} from "../api";
import {_urlOthers, _urlRegion} from "../api";
import swal from "sweetalert";

export const GetContact = () => {
    const requestData = {
        url: `${_urlOthers}/contacts`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(async res => {
                await dispatch({
                    type: COMPOSITE_CONSTS.GET_CONTACT,
                    payload: res.data
                })
            })
    }
};

export const UpdateContact = data => {
    const requestData = {
        url: `${_urlOthers}/contacts`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: COMPOSITE_CONSTS.UPDATE_CONTACT,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Կոնտակտը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ"
                });
            })

    }
};


export const GetRegions = () => {
    const requestData = {
        url: `${_urlRegion}`,
        method: "GET",
    };
    return dispatch => {
        return request(requestData).then((res) => {
            dispatch({
                type: COMPOSITE_CONSTS.GET_REGIONS,
                payload: res?.data
            });
        })
    }
};
export const GetRegionResidences = (regionId, returnData=false) => {
    const requestData = {
        url: `${_urlRegion}/${regionId}/residences`,
        method: "GET",
    };
    return dispatch => {
        return request(requestData).then((res) => {
            !returnData && dispatch({
                type: COMPOSITE_CONSTS.GET_REGION_RESIDENCES,
                payload: res?.data
            });
            if (returnData){
                return res?.data;
            }
        })
    }
};
export const ClearRegionResidences = () => {
    return dispatch => {
        dispatch({
            type: COMPOSITE_CONSTS.CLEAR_REGION_RESIDENCES,
        });
    }
};


export const GetCoverPhoto = () => {
    const requestData = {
        url: `${_urlOthers}/cover-photo`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
    }
};


export const UpdateCoverPhoto = (data) => {
    const requestData = {
        url: `${_urlOthers}/cover-photo`,
        token: true,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
    }
};
