// Import packages
import React, {Component} from "react";

//import components
import ReactExport from "react-data-export";
import {LinkButton} from "../buttons/buttons";

//import utils
import {BALANCE_TYPES, NETWORK_STATION_TYPES} from "../../constants/constTypes";
import moment from "moment";

//import excel styles
import {headerWidth,headerFuelItemWidth, headerStyle, oddRowStyle} from "../../constants/excelConstStyles";
import {truncNumber} from "../../utils/helperFunctions";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


class DownloadNetworksExcel extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            loading: false,
        }
        this.excelButton = React.createRef();
    }

    downloadExcel = async () => {
        const {networks} = this.props;
        await this.setState({loading: true})
        try {
            await this.props.GetNetworks();
            await this.setState({data: networks})
            this.excelButton.current.click();
        } catch(err) {
        }
        this.setState({loading: false, data: []})
    }

    render() {
        const {mainLanguage, networks} = this.props;
        const {data, loading} = this.state;


        const emptyRow = [{value: ''}, {value: ''}, {value: ''}]
        const downloadedData = !!data?.length && [{
            columns: [
                {title: "Անվանում", width: headerWidth, style: headerStyle},
                {title: "Տեսակ", width: headerWidth, style: headerStyle},
                {title: "Պատասխանատու", width: headerWidth, style: headerStyle},
                {title: "Հեռախոս", width: headerWidth, style: headerStyle},
                {title: "Կարգավիճակ", width: headerWidth, style: headerStyle},
            ],
            data: [emptyRow, ...data?.map((network, index) => {
                const {translations, type, phoneNumber, isHidden} = network;
                const mainTr = translations?.find(tr => tr.language === mainLanguage);
                const bgStyle = index % 2 === 0 ? oddRowStyle : {};
                const cellStyle = {font: {sz: "11"}, ...bgStyle};

                return [
                    {value: mainTr?.title ?? '-', style: cellStyle},
                    {value: NETWORK_STATION_TYPES[type] ?? '-', style: cellStyle},
                    {value: mainTr?.supervisor ?? '-', style: cellStyle},
                    {value: phoneNumber ?? '-', style: cellStyle},
                    {value: !isHidden ? 'Ակտիվ' : 'Ոչ ակտիվ' ?? '-', style: cellStyle},
                ];
            })],
        }];

        return <div>
            {!!data?.length && <ExcelFile element={<span ref={this.excelButton} style={{visibility: 'hidden'}}/>}
                                          filename={'Networks ' + moment().format('DD-MM-YYYY')}>
                <ExcelSheet dataSet={downloadedData}
                            name={'Networks'}/>
            </ExcelFile>}
            <LinkButton title={'Excel'}
                        cb={this.downloadExcel}
                        loadingWithTitle
                        disabled={!networks?.length}
                        loading={loading}/>
        </div>
    }
}

export default DownloadNetworksExcel
