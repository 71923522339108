export const INSURANCE_CONSTS = {
    GET_INSURANCE_REQUESTS: "GET_INSURANCE_REQUESTS",
    GET_INSURANCE: "GET_INSURANCE",
    ADD_INSURANCE: "ADD_INSURANCE",
    UPDATE_INSURANCE: "UPDATE_INSURANCE",
    DELETE_INSURANCE: "DELETE_INSURANCE",
    UPDATE_INSURANCE_POSITIONS: "UPDATE_INSURANCE_POSITIONS",
    GET_NOT_SEEN_INSURANCE_COUNT: "GET_NOT_SEEN_INSURANCE_COUNT",
    SET_INSURANCE_AS_SEEN: "SET_INSURANCE_AS_SEEN",
};
