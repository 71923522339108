// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Checkbox, Icon, Switch, Row, Col, TimePicker} from "antd";
import moment from 'moment';

// Import Styles
import '../../assets/styles/containerStyles/station-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {STATION_TYPES} from "../../constants/constTypes";
import {Select} from "antd";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {validateFields} from "./utils/actionFunctions";
import {
    GetStationById,
    AddStation,
    EditStation,
    GetNetworks,
    GetFeatures,
    GetRegionResidences,
    ClearRegionResidences,
} from "../../redux/actions";
import {
    initTranslations,
    getInputValues,
    getTranslatableInputValues,
    changeStateField,
    toggleStateField,
} from "../../utils/helperFunctions";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {MediaFileCard} from "../../components/media/mediaFileCard";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";

const stationTypeOptions = Object.keys(STATION_TYPES).map(key => ({
    id: key,
    name: STATION_TYPES[key]
}));
const {Option} = Select;

class AddEditStation extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'supervisor',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                username: "",
                password: "",
                repeatPassword: "",
                longitude: 0,
                latitude: 0,
                fuels: [],
                features: [],
                phoneNumber: "",
                openingTime: "",
                closingTime: "",
                stationNetwork: undefined,
                type: undefined,
                logoPath: null,
                region: undefined,
                residence: undefined,
                address: '',
                isHidden: false,
            },
            mediaModalOpen: false,
            loading: false,
            languageTab: props.mainLanguage,
            isEditing: !!props?.id,
            passwordVisible: false,
            repeatPasswordVisible: false,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.validateFields = validateFields.bind(this);
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);

        this.getWorkingHours = this.getWorkingHours.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.getRegionId = this.getRegionId.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditStation = this.addEditStation.bind(this);
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;
        const {networks, mainLanguage,} = this.props;
        this.props.GetFeatures();
        this.props.ClearRegionResidences();
        !networks?.length && await this.props.GetNetworks();

        this.networkOptions = this.props.networks.map(network => {
            return {
                id: network.id,
                name: network?.translations?.find(tr => tr.language === mainLanguage)?.title ?? ''
            }
        });

        if (isEditing) {

            const id = this.props?.id;
            await this.props.GetStationById(id);
            const {stationById} = this.props;
            //console.log("stationById", stationById);
            const stationData = {};

            const translations = {...fieldsData.translations};
            Object.keys(fieldsData).forEach(field => {
                if (field === "region") {
                    stationData.region = stationById?.region?.id;
                    return;
                }
                if (field === "residence") {
                    stationData.residence = stationById?.residence?.id;
                    return;
                }
                if (field === "stationNetwork") {
                    stationData.stationNetwork = stationById?.stationNetwork?.id;
                    return;
                }
                if (field === "fuels") {
                    stationData.fuels = stationById?.fuels?.map(fuel => fuel.id);
                    return;
                }
                if (field === "features") {
                    stationData.features = stationById?.features?.map(feature => feature.id);
                    return;
                }
                if (field !== "translations") {
                    stationData[field] = stationById[field] ?? '';
                }
            });
            stationData.longitude = stationById?.location?.longitude || '';
            stationData.latitude = stationById?.location?.latitude || '';
            stationData.openingTime = stationById?.workingHours?.openingTime?.slice(0, 5) || '';
            stationData.closingTime = stationById?.workingHours?.closingTime?.slice(0, 5) || '';

            stationById.translations && stationById.translations.forEach(translation => {
                this.translationsFields.forEach(key => {
                    translations[translation.language] &&
                    (translations[translation.language][key] = translation[key]);
                });
            });
            stationById?.region?.id && this.props.GetRegionResidences(stationById?.region?.id);
            this.setState({
                fieldsData: {
                    ...stationData,
                    translations: translations
                },
            })
            //console.log("stationData", stationData)
        }
    }


    getMedia(mediaArray) {
        const {fieldsData, isEditing} = this.state;
        const {stationById} = this.props;
        if (isEditing) {
            (!stationById?.logoPath || (stationById?.logoPath?.id !== mediaArray[0].id)) ?
                this.updatedDataMap.set("logoPath", mediaArray[0].id) :
                this.updatedDataMap.delete("logoPath");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                logoPath: mediaArray[0]
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        const {stationById} = this.props;
        if (isEditing) {
            stationById.logoPath ?
                this.updatedDataMap.set("logoPath", null) :
                this.updatedDataMap.delete("logoPath");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                logoPath: null
            }
        })
    }

    getWorkingHours(value, name) {
        const {isEditing, fieldsData} = this.state;
        const {stationById} = this.props;
        //console.log(value);
        if (isEditing) {
            (!stationById?.workingHours?.[name] || (stationById?.workingHours?.[name] !== value)) ?
                this.updatedDataMap.set(name, value) :
                this.updatedDataMap.delete(name);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [name]: value,
            }
        });
    }

    getMultiSelectValues(name, values) {
        const {stationById} = this.props;
        const {isEditing} = this.state;
        let haveChanges = false;
        if (isEditing) {
            haveChanges = (stationById?.[name]?.length !== values.length)
                || (stationById?.[name].some(fuel => !values.some(id => id === fuel.id)));
        }
        this.getInputValues({
            name: name,
            value: values,
            haveChanges,
        })
    }

    getRegionId({value, haveChanges}) {
        const {stationById} = this.props;
        const {isEditing, fieldsData} = this.state;
        if (value) {
            this.props.GetRegionResidences(value);
        } else {
            this.props.ClearRegionResidences();
        }
        if (isEditing) {
            const initId = stationById?.region?.id;
            haveChanges ? this.updatedDataMap.set('region', value || null)
                : this.updatedDataMap.delete('region');
            stationById?.residence?.id && this.updatedDataMap.set('residence', null)

            // if (initId) {
            //     value !== initId ? this.updatedDataMap.set('region', id || null)
            //         : this.updatedDataMap.delete('region');
            //
            //     !value && this.updatedDataMap.set('residence', null)
            // } else {
            //     value ? this.updatedDataMap.set('region', id || null)
            //         : this.updatedDataMap.delete('region');
            // }
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                region: value,
                residence: undefined
            }
        })
    }

    async addEditStation() {
        await this.setState({loading: true});
        const {stationById} = this.props;
        const {fieldsData, isEditing} = this.state;
        const {translations} = fieldsData;

        const errors = {
            requiredFields: ["username", 'type', 'longitude', 'latitude', 'fuels',
                'stationNetwork'],
            translations: ["title", 'supervisor'],
        };
        // Փոփոխություն անելուց password դաշտը ոչ պարդատիր է
        if (!isEditing || fieldsData.password) {
            errors.requiredFields.push('password', 'repeatPassword');
        }
        if (fieldsData.openingTime || fieldsData.closingTime) {
            errors.requiredFields.push('openingTime', 'closingTime');
        }
        const validationResult = this.validateFields(errors, fieldsData);
        // console.log("validationResult", validationResult);
        //console.log(this.errorsSet);
        if (validationResult) {
            let TR_data = [];
            Object.keys(translations).forEach(key => {
                const reqTrItem = {
                    language: key,
                };
                const trItem = translations[key];
                Object.keys(trItem).forEach(field => {
                    trItem[field] && (reqTrItem[field] = trItem[field]);
                });
                TR_data.push(reqTrItem);
            });

            const reqData = {};

            if (isEditing) {
                this.updatedDataMap.forEach((value, key) => {
                    if (this.translationsFields.some(field => key.includes(field))) {
                        reqData.translations = TR_data;
                        return;
                    }
                    if (key === 'longitude' || key === 'latitude') {
                        reqData.location = {
                            longitude: fieldsData.longitude,
                            latitude: fieldsData.latitude
                        };
                        return;
                    }
                    if (key === 'openingTime' || key === 'closingTime') {
                        reqData.workingHours = {
                            openingTime: fieldsData.openingTime + ':00',
                            closingTime: fieldsData.closingTime + ':00'
                        };
                        return;
                    }
                    reqData[key] = value;
                });

                this.props.EditStation(stationById.id, reqData).finally(() => {
                    this.setState({loading: false});
                })
            } else {
                Object.keys(fieldsData).forEach(key => {
                    if (key === 'repeatPassword' || key === 'openingTime' || key === 'closingTime' || key === 'translations') {
                        return;
                    }
                    if (key === 'longitude' || key === 'latitude') {
                        reqData.location = {
                            longitude: fieldsData.longitude,
                            latitude: fieldsData.latitude
                        };
                        return;
                    }
                    if (key === 'logoPath') {
                        fieldsData?.logoPath && (reqData.logoPath = fieldsData?.logoPath?.id);
                        return;
                    }
                    if (key === 'features') {
                        fieldsData?.features?.length && (reqData.features = fieldsData.features);
                        return;
                    }
                    fieldsData[key] && (reqData[key] = fieldsData[key]);
                });
                if (fieldsData.openingTime || fieldsData.closingTime) {
                    reqData.workingHours = {};
                    fieldsData.openingTime && (reqData.workingHours.openingTime = fieldsData.openingTime + ':00');
                    fieldsData.closingTime && (reqData.workingHours.closingTime = fieldsData.closingTime + ':00');
                }
                reqData.translations = TR_data;
                console.log("reqDataStation", reqData);
                this.props.AddStation(reqData).finally(() => {
                    this.setState({loading: false});
                })
            }
        } else {
            this.setState({loading: false});
        }

    }


    render() {
        const {stationById, featureList, fuelList, mainLanguage, regions, regionResidences} = this.props;
        const {
            fieldsData, passwordVisible, repeatPasswordVisible, languageTab, isEditing, loading,
            mediaModalOpen
        } = this.state;
        const {translations, features,} = fieldsData;
        const languageKey = '_' + languageTab;
        const initTranslations = stationById?.translations?.find(lg => lg.language === languageTab);
        const trData = translations[languageTab] || {};

        const checkbox = <div className="right-side">
            <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
            <Switch checked={!fieldsData.isHidden}
                    onChange={(checked) => this.getInputValues({
                        name: 'isHidden',
                        value: !checked,
                        haveChanges: stationById?.isHidden !== !checked
                    })}/>
        </div>
        return <PageWrapper withActions={true}>
            <section className="station-add-edit">
                <PageHeader pageTitle={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'} Լցակայան`}
                            rightTopProps={checkbox}/>
                <div className="station-fields">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={this.errorsTabs}
                                  activeTab={languageTab}
                    />
                </div>
                <div className="station-fields">
                    <div className="fields-section-one">
                        <div className="input-form">
                            <div className="upload-btn">
                                <InputGroup inputType={"wrapper"}>
                                    <LinkButton className={"bg-white"}
                                                title={"Ընտրել Նկար"}
                                                cb={this.toggleMediaModal}/>
                                </InputGroup>
                            </div>
                        </div>
                    </div>
                    <div className="fields-section-one">
                        <div className="media-wrapper">
                            {fieldsData.logoPath && <MediaFileCard item={fieldsData.logoPath}
                                                                   customDelete={this.deleteMedia}
                            />}
                        </div>
                    </div>
                    <div className="fields-section-one">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Անվանում<span
                                            className="field-required">*</span></>}
                                        placeholder="Անվանում"
                                        name="title"
                                        maxLength={256}
                                        value={trData.title}
                                        initValue={initTranslations?.title}
                                        error={this.errorsSet.has('title' + languageKey)}
                                        onChange={this.getTranslatableInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="selectCustom"
                                        label={<>Ցանց<span
                                            className="field-required">*</span></>}
                                        placeholder="Ցանց"
                                        name="stationNetwork"
                                        showSearch={false}
                                        value={fieldsData?.stationNetwork}
                                        initValue={stationById?.stationNetwork?.id}
                                        error={this.errorsSet.has('stationNetwork')}
                                        onChange={this.getInputValues}
                                        options={this.networkOptions}/>
                        </div>
                        <div className="input-form">

                            <InputGroup inputType="selectCustom"
                                        label={<>Լցակայանի տիպ<span
                                            className="field-required">*</span></>}
                                        placeholder="Լցակայանի տիպ"
                                        name="type"
                                        showSearch={false}
                                        value={fieldsData?.type}
                                        initValue={stationById?.type}
                                        error={this.errorsSet.has('type')}
                                        onChange={this.getInputValues}
                                        options={stationTypeOptions}/>
                        </div>
                    </div>
                    <div className="fields-section-two">
                    </div>
                    <div className="fields-section-three">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>Պատասխանատու<span
                                            className="field-required">*</span></>}
                                        placeholder="Պատասխանատու"
                                        name="supervisor"
                                        maxLength={256}
                                        value={trData.supervisor}
                                        initValue={initTranslations?.supervisor}
                                        error={this.errorsSet.has('supervisor' + languageKey)}
                                        onChange={this.getTranslatableInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="number"
                                        label={<>Լայնություն<span
                                            className="field-required">*</span></>}
                                        placeholder="Լայնություն"
                                        maxValue={90}
                                        minValue={-90}
                                        name="latitude"
                                        value={fieldsData?.latitude}
                                        initValue={stationById?.location?.latitude}
                                        error={this.errorsSet.has('latitude')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="number"
                                        label={<>Երկայնություն<span
                                            className="field-required">*</span></>}
                                        placeholder="Երկայնություն"
                                        maxValue={180}
                                        minValue={-180}
                                        name="longitude"
                                        value={fieldsData?.longitude}
                                        initValue={stationById?.location?.longitude}
                                        error={this.errorsSet.has('longitude')}
                                        onChange={this.getInputValues}/>
                        </div>
                    </div>
                    <div className="fields-section-four">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="email"
                                        label={<>Մուտքանուն<span
                                            className="field-required">*</span></>}
                                        placeholder="Մուտքանուն"
                                        name="username"
                                        maxLength={50}
                                        value={fieldsData.username}
                                        initValue={stationById?.username}
                                        error={this.errorsSet.has('username')}
                                        onChange={this.getInputValues}>
                            </InputGroup>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type={`${passwordVisible ? "text" : "password"}`}
                                        label={<>Գաղտնաբառ<span
                                            className="field-required">*</span></>}
                                        placeholder="Գաղտնաբառ"
                                        name="password"
                                        maxLength={20}
                                        autocomplete={'new-password'}
                                        value={fieldsData.password}
                                        initValue={stationById?.password}
                                        error={this.errorsSet.has('password')}

                                        onChange={this.getInputValues}>
                            <span className={'visibility-icon'}
                                  onClick={toggleStateField.bind(this, "passwordVisible")}>
                                <Icon type={`${passwordVisible ? "eye" : "eye-invisible"}`}/>
                            </span>
                            </InputGroup>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type={`${repeatPasswordVisible ? "text" : "password"}`}
                                        label={<>Կրկնել Գաղտնաբառը<span
                                            className="field-required">*</span></>}
                                        placeholder="Կրկնել Գաղտնաբառը"
                                        name="repeatPassword"
                                        maxLength={20}
                                        autocomplete={'new-password'}
                                        value={fieldsData.repeatPassword}
                                        error={this.errorsSet.has('repeatPassword')}
                                        onChange={this.getInputValues}>
                            <span className={'visibility-icon'}
                                  onClick={toggleStateField.bind(this, "repeatPasswordVisible")}>
                                <Icon type={`${repeatPasswordVisible ? "eye" : "eye-invisible"}`}/>
                            </span>
                            </InputGroup>
                        </div>
                    </div>
                    <div className="fields-section-fuel">
                        <div className="input-form">
                            <label>Վառելիք<span
                                className="field-required">*</span></label>
                            <Select
                                mode="multiple"
                                style={{width: '100%'}}
                                showSearch={false}
                                value={fieldsData.fuels}
                                placeholder="Ընտրեք Վառելիք"
                                className={`${this.errorsSet.has('fuels') ? "invalid" : ""}`}
                                onChange={this.getMultiSelectValues.bind(this, 'fuels')}>
                                {fuelList && fuelList.map(fuel => {
                                    return <Option key={fuel.id} value={fuel.id}>
                                        {fuel?.translations?.find(tr => tr.language === mainLanguage)?.title}
                                    </Option>
                                })}
                            </Select>
                        </div>
                    </div>
                    <div className="fields-section-six">
                        <div className="input-form">
                            <InputGroup inputType="selectCustom"
                                        label={" Գործ. հասցե"}
                                        placeholder="Մարզ/Քաղաք"
                                        name="region"
                                        showSearch={false}
                                        value={fieldsData?.region}
                                        initValue={stationById?.region?.id}
                                        withClear
                                        error={this.errorsSet.has('region')}
                                        onChange={this.getRegionId}
                                        options={regions}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="selectCustom"
                                        label={<>&nbsp;</>}
                                        placeholder="Քաղաք/Համայնք"
                                        name="residence"
                                        showSearch={false}
                                        value={fieldsData?.residence}
                                        initValue={stationById?.residence?.id}
                                        withClear
                                        error={this.errorsSet.has('residence')}
                                        onChange={this.getInputValues}
                                        options={regionResidences}/>
                        </div>
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label={<>&nbsp;</>}
                                        placeholder="Հասցե"
                                        name="address"
                                        maxLength={50}
                                        value={fieldsData?.address}
                                        initValue={stationById?.address}
                                        error={this.errorsSet.has('address')}
                                        onChange={this.getInputValues}/>
                        </div>
                    </div>
                    <div className="fields-section-seven">
                        <div className="input-form">
                            <InputGroup inputType="input"
                                        type="text"
                                        label="Հեռախոս"
                                        placeholder="Հեռախոս"
                                        name="phoneNumber"
                                        regExp={/^[+\d]\d*$/}
                                        maxLength={20}
                                        value={fieldsData.phoneNumber}
                                        initValue={stationById?.phoneNumber}
                                        error={this.errorsSet.has('phoneNumber')}
                                        onChange={this.getInputValues}/>
                        </div>
                        <div className="input-form">
                            <div
                                className={`input-group ${this.errorsSet.has('openingTime') ? 'error' : ''}`}>
                                <label>Աշխատանքային ժամեր</label>
                                <TimePicker placeholder="Սկիզբ"
                                            format={"HH:mm"}
                                            style={{width: "100%"}}
                                            value={fieldsData?.openingTime ? moment(fieldsData?.openingTime, 'HH:mm') : undefined}
                                            onChange={(value) => this.getWorkingHours(value ? value.format("HH:mm") : '', "openingTime")}/>
                            </div>
                        </div>
                        <div className="input-form">
                            <div
                                className={`input-group ${this.errorsSet.has('closingTime') ? 'error' : ''}`}>
                                <label>&nbsp;</label>
                                <TimePicker placeholder="Ավարտ"
                                            format={"HH:mm"}
                                            style={{width: "100%"}}
                                            value={fieldsData?.closingTime ? moment(fieldsData?.closingTime, 'HH:mm') : undefined}
                                            onChange={(value) => this.getWorkingHours(value ? moment(value).format("HH:mm") : '', "closingTime")}/>
                            </div>

                        </div>
                    </div>
                    <div className="services-wrapper">
                        <h3>Ծառայություններ</h3>
                        <div className="service-checkboxes">
                            <Checkbox.Group
                                onChange={this.getMultiSelectValues.bind(this, 'features')}
                                value={features}>
                                <Row>
                                    {featureList && !!featureList.length && featureList.map((feature) => {
                                        return <Col span={8} key={feature?.id}>
                                            <Checkbox checked={feature?.id} value={feature?.id}>
                                                {feature?.translations?.find(lg => lg.language === mainLanguage)?.title}
                                            </Checkbox>
                                        </Col>
                                    })}
                                </Row>
                            </Checkbox.Group>
                        </div>
                    </div>
                    <div className="flex-wrapper-right">
                        <LinkButton title={`${isEditing ? 'Փոփոխել' : 'Ավելացնել'}`}
                                    loading={loading}
                                    disabled={!this.updatedDataMap.size && isEditing}
                                    cb={this.addEditStation}/>
                    </div>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetStationById,
    AddStation,
    EditStation,
    GetNetworks,
    GetFeatures,
    GetRegionResidences,
    ClearRegionResidences,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditStation)
