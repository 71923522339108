// import React from "react";
import {_urlNotifications, request} from "../api";
import {history} from "../../configs/history";
import {store} from "../store";


//import utils
// import {notificationSound} from "../../utils/notificationSound";
import {ACTION_TYPES, NOTIFICATION_TYPES} from "../../socket/constants";
import {UTIL_CONSTS} from "../constants";
import {GetNonOpenedNotificationsCount, SetAllNotificationsAsOpened} from "../../socket/emitters";
import {checkBrowserTabNotVisible} from "../../utils/checkBrowserTabNotVisible";


export const GetNotifications = ({
                                     offset = 0,
                                     limit = 20,
                                     reset = true,
                                 } = {}) => {

    const requestData = {
        url: `${_urlNotifications}?offset=${offset}&limit=${limit}`,
        method: "GET",
        acceptLanguage: true,
        token: true
    };

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                // console.log(data, 'data');
                data && dispatch({
                    type: NOTIFICATION_TYPES.GET_NOTIFICATION_LIST,
                    payload: {
                        data,
                        reset,
                        hasMore: data?.length === limit
                    }
                });
                return data;
            })
            .catch(err => {
            }).finally(() => {
                dispatch({type: UTIL_CONSTS.END_LOADING});
            })
    };
};

// export const ToggleNotification = updatedData => {
//     const requestData = {
//         url: `${_urlUsers}/fcm/notifications`,
//         method: "PUT",
//         token: true,
//         data: updatedData
//     };
//
//     return dispatch => {
//         return request(requestData)
//     }
// };


export const newNotification = (notification) => {
    let path = history?.location?.pathname;
    let inNotificationPage = path && path.startsWith('/');
    store.dispatch({
        type: ACTION_TYPES.NEW_NOTIFICATION,
        payload: notification
    });

        GetNonOpenedNotificationsCount();
    // if (!inNotificationPage || checkBrowserTabNotVisible()) {
    //     // const sound = notificationSound();
    //     // !sound.playing && sound.play(true);
    // } else {
    //     SetAllNotificationsAsOpened();
    // }
};


