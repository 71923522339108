// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";


// Import Styles
import '../../assets/styles/cards/service-card.scss';
import {MaterialIcon} from "../utils/Icon";
import {LinkButton} from "../buttons/buttons";


class ServiceCard extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        const {data} = this.props;
        return <div className={"service-card"}>
            <img className="service-img" src={require("../../assets/images/temp-images/"+data?.serviceImg)} alt=""/>
            <div>
                <div className="service-title">{data?.serviceName}</div>
                <div className="edit-button">
                    <LinkButton title={<MaterialIcon icon="edit"/>}
                                link={"services/edit/"+ data.id}
                                className="bg-orange"/>
                </div>
            </div>
        </div>

    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCard)