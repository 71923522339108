import {ORDER_CONSTS} from "../constants";

export const initialState = {
    ordersList: {
        itemsList: [],
    },
    orderById: null,
    notSeenOrdersCount: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ORDER_CONSTS.GET_ORDERS:
            return {
                ...state,
                ordersList: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.ordersList.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore
                },
            };
        case ORDER_CONSTS.CLEAR_ORDERS:
            return {
                ...state,
                orderById: {...initialState.orderById},
            };
            case ORDER_CONSTS.GET_ORDER_BY_ID:
            return {
                ...state,
                orderById: action.payload,
            };
        case ORDER_CONSTS.UPDATE_ORDER_STATUS:
            return {
                ...state,
                orderById: {
                    ...state.orderById,
                    status: action.payload
                }
            };
        case ORDER_CONSTS.DELETE_ORDER_PRODUCT_BY_ID:
            const products = state.orderById.products &&
                state.orderById.products.filter(product => product.productId !== action.payload);
            return {
                ...state,
                orderById: {
                    ...state.orderById,
                    products,
                }
            };
        case ORDER_CONSTS.GET_NOT_SEEN_ORDERS_COUNT:
            return {
                ...state,
                notSeenOrdersCount: action.payload?.count ?? 0
            };
        case ORDER_CONSTS.SET_ORDER_AS_SEEN:
            return {
                ...state,
                ordersList: {
                    itemsList: state.ordersList.itemsList.map(order => order.id === action.payload ? {
                        ...order,
                        seen: true
                    } : order),
                },
                notSeenOrdersCount: state.notSeenOrdersCount - 1
            };
        default:
            return state;
    }
}