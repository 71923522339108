import {request, _urlStory} from "../api";
import {STORY_CONSTS} from "../constants";
import swal from "sweetalert";
import {history} from "../../configs/history";

export const GetStory = () => {
    const requestData = {
        url: _urlStory,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: STORY_CONSTS.GET_STORY,
                    payload: data
                });
            })
    }
};

export const AddStory = (data) => {
    const requestData = {
        url: _urlStory,
        token: true,
        method: "POST",
        customHeaders: {
            "Content-Type": "multipart/form-data",
        },
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                swal({
                    title: "Հաջողվեց!",
                    text: "Գովազդը հաջողությամբ ստեղծվեց",
                    icon: "success",
                    button: "Լավ",
                });
                history.push('/stories')
            }).catch((error) => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ-որ բան այն չէ",
                    icon: "warning",
                    button: "Լավ",
                })
            })
    }
};

export const UpdateStory = (id, data) => {
    const requestData = {
        url: `${_urlStory}/${id}`,
        method: "PATCH",
        token: true,
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                swal({
                    title: "Հաջողվեց!",
                    text: "Գովազդը հաջողությամբ փոփոխվեց",
                    icon: "success",
                    button: "Լավ",
                });
                history.push('/stories')
            }).catch((error) => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ-որ բան այն չէ",
                    icon: "warning",
                    button: "Լավ",
                })
            })
    }
};

export const DeleteStory = (id) => {
    const requestData = {
        url: `${_urlStory}/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: STORY_CONSTS.DELETE_STORY,
                    payload: id
                });
                swal({
                    title: "Հաջողվեց!",
                    text: "Գովազդը հաջողությամբ ջնջվեց",
                    icon: "success",
                    button: "Լավ",
                });
            })
    }
};

export const UpdateStoryPositions = items => {
    const requestData = {
        url: _urlStory,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: STORY_CONSTS.UPDATE_STORY_POSITIONS
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Գովազդը հաջողությամբ թարմացվեց!",
                    icon: "success",
                    button: "Լավ"
                })
            })
            .catch(() => {
                swal({
                    title: "Զգուշացում!",
                    text: "Ինչ-որ բան այն չէ",
                    icon: "warning",
                    button: "Լավ",
                })
            })
    }
};
