export const StaticTextFields = {
    emptyViews: {
        pageName: 'Empty Views',
        list: [
            {
                label: "Empty view - not found page",
                key: "not_found_empty_view"
            },
        ]
    },
    header: {
        pageName: 'Header',
        list: [
            {
                label: "Header - our fuels",
                key: "nav_our_fuels"
            },
            {
                label: "Header - connect service center",
                key: "service_center"
            },
            {
                label: "Header - main menu item",
                key: "menu_item_main"
            },
            {
                label: "Header - services menu item",
                key: "menu_item_services"
            },
            {
                label: "Header - fuels menu item",
                key: "menu_item_fuels"
            },
            {
                label: "Header - apps menu item",
                key: "menu_item_apps"
            },
            {
                label: "Header - contacts menu item",
                key: "menu_item_contacts"
            },
            {
                label: "Header - partners menu item",
                key: "menu_item_partners"
            },
        ]
    },
    mobileMenu: {
        pageName: 'Mobile Menu',
        list: [
            {
                label: "Mobile Menu - language",
                key: "mobile_menu_language"
            },
        ]
    },
    main: {
        pageName: 'Main',
        list: [
            {
                label: "Main - see video button",
                key: "main_see_video"
            },
            {
                label: "Main - slider 1 item title",
                key: "slider_1_item_title"
            },
            // {
            //     label: "Main - slider 1 item description",
            //     key: "slider_1_item_description"
            // },
        ]
    },
    sliderDescription: {
        pageName: 'Slider description',
        list: [
            {
                label: "Main - slider 1 item description",
                key: "slider_1_item_description"
            },
        ]
    },
    services: {
        pageName: 'Services',
        list: [
            {
                label: "Services - title",
                key: "services_title"
            },
            {
                label: "Services - description",
                key: "services_description"
            },
            {
                label: "Services - 1 item title",
                key: "service_1_item_title"
            },
            {
                label: "Services - 1 item description",
                key: "service_1_item_description"
            },
            {
                label: "Services - 2 item title",
                key: "service_2_item_title"
            },
            {
                label: "Services - 2 item description",
                key: "service_2_item_description"
            },
            {
                label: "Services - 3 item title",
                key: "service_3_item_title"
            },
            {
                label: "Services - 3 item description",
                key: "service_3_item_description"
            },
            {
                label: "Services - 4 item title",
                key: "service_4_item_title"
            },
            {
                label: "Services - 4 item description",
                key: "service_4_item_description"
            },
        ]
    },
    fuels: {
        pageName: 'Fuels',
        list: [
            {
                label: "Fuels - title",
                key: "fuels_title"
            },
            {
                label: "Fuels - first banner title",
                key: "fuels_first_banner_title"
            },
            {
                label: "Fuels - first banner 1 item",
                key: "fuels_first_banner_1_item"
            },
            {
                label: "Fuels - first banner 2 item",
                key: "fuels_first_banner_2_item"
            },
            {
                label: "Fuels - first banner 3 item",
                key: "fuels_first_banner_3_item"
            },
            {
                label: "Fuels - first banner 4 item",
                key: "fuels_first_banner_4_item"
            },
            {
                label: "Fuels - second banner title",
                key: "fuels_second_banner_title"
            },
            {
                label: "Fuels - second banner 1 item",
                key: "fuels_second_banner_1_item"
            },
            {
                label: "Fuels - second banner 2 item",
                key: "fuels_second_banner_2_item"
            },
            {
                label: "Fuels - second banner 3 item",
                key: "fuels_second_banner_3_item"
            },
            {
                label: "Fuels - second banner 4 item",
                key: "fuels_second_banner_4_item"
            },
            {
                label: "Fuels - list 1 item title",
                key: "fuels_list_1_item_title"
            },
            {
                label: "Fuels - list 1 item description",
                key: "fuels_list_1_item_description"
            },
            {
                label: "Fuels - list 2 item title",
                key: "fuels_list_2_item_title"
            },
            {
                label: "Fuels - list 2 item description",
                key: "fuels_list_2_item_description"
            },
            {
                label: "Fuels - list 3 item title",
                key: "fuels_list_3_item_title"
            },
            {
                label: "Fuels - list 3 item description",
                key: "fuels_list_3_item_description"
            },
            {
                label: "Fuels - list 4 item title",
                key: "fuels_list_4_item_title"
            },
            {
                label: "Fuels - list 4 item description",
                key: "fuels_list_4_item_description"
            },
            {
                label: "Fuels - list 5 item title",
                key: "fuels_list_5_item_title"
            },
            {
                label: "Fuels - list 5 item description",
                key: "fuels_list_5_item_description"
            },
            {
                label: "Fuels - list 6 item title",
                key: "fuels_list_6_item_title"
            },
            {
                label: "Fuels - list 6 item description",
                key: "fuels_list_6_item_description"
            },
        ]
    },
    apps: {
        pageName: 'Apps',
        list: [
            {
                label: "Apps - title",
                key: "apps_title"
            },
            {
                label: "Apps - info title",
                key: "apps_info_title"
            },
            {
                label: "Apps - info description title",
                key: "apps_info_description"
            },
        ]
    },
    contacts: {
        pageName: 'Contacts',
        list: [
            {
                label: "Contacts - title",
                key: "contacts_title"
            },
            {
                label: "Contacts - placeholder firstName",
                key: "contacts_page_placeholder_firstName"
            },
            {
                label: "Contacts - placeholder company name",
                key: "contacts_page_placeholder_company_name"
            },
            {
                label: "Contacts - placeholder tax code name",
                key: "contacts_page_placeholder_tax_code_name"
            },
            {
                label: "Contacts - placeholder email",
                key: "contacts_page_placeholder_email"
            },
            {
                label: "Contacts - placeholder phoneNumber",
                key: "contacts_page_placeholder_phoneNumber"
            },
            {
                label: "Contacts - placeholder message",
                key: "contacts_page_placeholder_message"
            },
            {
                label: "Contacts - button send message",
                key: "contacts_page_btn_send_message"
            },
            {
                label: "Contacts - cards 1 item",
                key: "contacts_cards_1_item"
            },
            {
                label: "Contacts - cards 2 item",
                key: "contacts_cards_2_item"
            },
            {
                label: "Contacts - cards 3 item",
                key: "contacts_cards_3_item"
            },
            {
                label: "Contacts - cards 4 item",
                key: "contacts_cards_4_item"
            },
            {
                label: "Contacts - cards 5 item",
                key: "contacts_cards_5_item"
            }
        ]
    },
    partners: {
        pageName: 'Partners',
        list: [
            {
                label: "Partners - title",
                key: "partners_title"
            },
        ]
    },
    addressModal: {
        pageName: 'Address Modal',
        list: [
            {
                label: "Address modal - title",
                key: "map_modal_title"
            },
            {
                label: "Address modal - label residence street",
                key: "map_modal_label_residence_street"
            },
            {
                label: "Address modal - label building",
                key: "map_modal_label_building"
            },
            {
                label: "Address modal - label building entrance",
                key: "map_modal_label_building_entrance"
            },
            {
                label: "Address modal - label building floor",
                key: "map_modal_label_building_floor"
            },
            {
                label: "Address modal - label building house",
                key: "map_modal_label_building_house"
            },
            {
                label: "Address modal - cancel button",
                key: "map_modal_btn_cancel"
            },
            {
                label: "Address modal - save button",
                key: "map_modal_btn_save"
            },
        ]
    },
    footer: {
        pageName: 'Footer',
        list: [
            {
                label: "Footer - social title",
                key: "footer_social_title"
            },
            {
                label: "Footer - copyright text",
                key: "footer_section_copyright_text"
            },
            {
                label: "Footer - project developed by text",
                key: "footer_section_developed_by_text"
            },
        ]
    },
}