// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
// import ISO6391 from 'iso-639-1';

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {UpdateLanguage} from "../../redux/actions";

// Import styles
import "../../assets/styles/containerStyles/add-language.scss";

// Import components
import {LinkButton} from "../../components/buttons/buttons";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {Switch} from "antd";

import {AlertDialog} from "../../components/uiElements/alertDialog";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

class EditLanguage extends Component {
    constructor() {
        super();

        this.state = {
            languageData: {
                isHidden: true,
                name: "",
                code: "",
            },
            errorName:false,
            alertDialogOpen: false,
            alertMode: "",
        };

        this.currentLg = null;
        this.updatedDataMap = new Map();
        this.openAlertModal = this.openAlertModal.bind(this);
        this.closeAlertModal = this.closeAlertModal.bind(this);
        this.getInputValues = this.getInputValues.bind(this);
        this.editLanguage = this.editLanguage.bind(this);
    }

    componentDidMount() {
        const {languages, id} = this.props;
        const lgData = languages && id && languages.find(lg => lg.id === id);

        this.currentLg = lgData ? {...lgData} : {};

        lgData && this.setState({
            languageData: {
                code: lgData.code,
                name: lgData.name,
                isHidden: lgData.isHidden,
            },
        });

    }

    openAlertModal(alertMode) {
        const stateData = {
            alertDialogOpen: true,
            alertMode
        };
        this.setState(stateData);
    }

    closeAlertModal() {
        this.setState({
            alertDialogOpen: false,
            alertMode: '',
        });
    }

    getInputValues({name, value, haveChanges}) {
        haveChanges
            ? this.updatedDataMap.set(name, value)
            : this.updatedDataMap.delete(name);

        this.setState({
            languageData: {
                ...this.state.languageData,
                [name]: value
            }
        })
    }


    editLanguage() {
        const {languageData} = this.state;
        const {name} = languageData;
        const id = this.props.id;

        if (!name) {
            this.setState({errorName: true});
           // this.openAlertModal('warning');
            return;
        }
        this.props.UpdateLanguage(languageData, id).catch((error) => {

        });

    }


    render() {
        const {requestLoading} = this.props;
        const {alertDialogOpen, alertMode, languageData, errorName} = this.state;

        const checkbox = !this.currentLg?.isMain ? <div className="right-side">
            <label>{!languageData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
            <Switch checked={!languageData.isHidden} name="isHidden"
                    onChange={(checked) => this.getInputValues({
                        name: "isHidden",
                        value: !checked,
                        haveChanges: this.currentLg?.isHidden !== !checked
                    })}/>
        </div> : '';
        return <PageWrapper withActions={true}>
            <PageHeader pageTitle={'Փոփոխել Լեզուն'}
                        rightTopProps={checkbox}
                        renderingCustomLeftPart={`Կոդ - ${languageData.code}`}/>
            <section className="language-edit">
                <InputGroup value={languageData.name}
                            initValue={this.currentLg?.name}
                            label={"Անուն*"}
                            inputType={"input"}
                            type={'text'}
                            name={"name"}
                            maxLength={20}
                            error={errorName}
                            onChange={this.getInputValues}/>

                <div className="right-side">
                    <LinkButton cb={this.editLanguage}
                                disabled={!this.updatedDataMap.size}
                                loading={requestLoading}
                                title="Փոփոխել"/>
                </div>
            </section>
            <AlertDialog title="Զգուշացում!"
                         message="Դուք ունեք դատարկ դաշտեր, խնդրում ենք լրացնել դրանք նախքան հաստատելը."
                         buttons={["Փակել"]}
                         isOpen={alertDialogOpen && alertMode === "warning"}
                         closeModal={this.closeAlertModal}/>
            <AlertDialog title="Զգուշացում!"
                         message="Այդպիսի լեզու արդեն գոյություն ունի"
                         buttons={["Փակել"]}
                         isOpen={alertDialogOpen && alertMode === "warning_1"}
                         closeModal={this.closeAlertModal}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    UpdateLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLanguage);
