// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon} from "antd";

// Import Styles
import '../../assets/styles/containerStyles/station-details.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import DetailsHeader from "../../components/cards/DetailsHeader";
import ItemsList from "../../components/ItemsList";
import QRModal from '../../components/uiElements/QRModal';
import {MaterialIcon} from "../../components/utils/Icon";
import {LinkButton} from "../../components/buttons/buttons";
// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetStationById, GetHistoryByStationId, ClearByIdHistory} from "../../redux/actions";
import {stationsHistoryHeaderInfo} from "../history/utils/constants";
import {parseStationHistoryData} from "../history/utils/actionFunctionsForDetails";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {toggleStateField} from "../../utils/helperFunctions";


class StationDetails extends Component {
    constructor() {
        super();
        this.state = {
            activeTab: "workers",
            previewModalOpen: false,
        };
        this.togglePreview = toggleStateField.bind(this, 'previewModalOpen')
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    async componentDidMount() {
        const id = this.props?.id;
        this.props.GetHistoryByStationId({id});
        await this.props.GetStationById(id);
    }

    componentWillUnmount() {
        this.props.ClearByIdHistory();
    }

    loadMoreItems() {
        const {historyById, id} = this.props;
        historyById?.hasMore && this.props.GetHistoryByStationId({
            id,
            reset: false,
            offset: historyById?.itemsList?.length,
            startDate: historyById?.startDate,
            endDate: historyById?.endDate
        });
    };


    render() {
        const {stationById, mainLanguage, historyById, requestLoading} = this.props;
        const historyInfo = parseStationHistoryData(historyById, mainLanguage);

        const mainTr = stationById?.translations?.find(tr => tr.language === mainLanguage);
        const networkTr = stationById?.stationNetwork?.title;
        const stationDataHeader = {
            id: stationById?.id,
            name: mainTr?.title,
            address: `${stationById?.region?.name ?? ''}, ${stationById?.residence?.name ?? ''}, ${stationById?.address ?? ''}`,
            isHidden: !stationById?.isHidden,
            status: true
        };

        return <PageWrapper>
            <section className="station-details">
                <DetailsHeader data={stationDataHeader}
                               withEditAction={<LinkButton
                                   link={`/station/edit/${stationById?.id}`}
                                   title={<MaterialIcon icon="edit"/>}
                                   className="bg-orange"/>}
                />
                <div className={'qr-wrapper'} ref={this.wrap}>
                    <LinkButton title={'Տեսնել QR կոդը'} cb={this.togglePreview}/>
                </div>
                <div className="separator"/>
                <div className="station-info-wrapper">
                    <table className="station-info">
                        <tbody>
                        {networkTr &&
                        <tr>
                            <td className="text-bold">Ցանց։</td>
                            <td className="text-grey">{networkTr}</td>
                        </tr>
                        }
                        {mainTr?.supervisor &&
                        <tr>
                            <td className="text-bold">Պատասխանատու։</td>
                            <td className="text-grey">{mainTr?.supervisor}</td>
                        </tr>
                        }
                        {stationById?.phoneNumber &&
                        <tr>
                            <td className="text-bold">Հեռախոս։</td>
                            <td className="text-grey">{stationById?.phoneNumber}</td>
                        </tr>
                        }
                        {stationById?.username &&
                        <tr>
                            <td className="text-bold">Մուտքանուն։</td>
                            <td className="text-grey">{stationById?.username}</td>
                        </tr>
                        }
                        {stationById?.location &&
                        <tr>
                            <td className="text-bold">Տեղադրություն։</td>
                            <td className="text-grey">
                                {stationById?.location?.latitude && "Lat: " + stationById.location.latitude}
                                {stationById?.location?.longitude && " / Lng: " + stationById.location.longitude}</td>
                        </tr>
                        }
                        </tbody>
                    </table>
                    {stationById?.features && !!stationById?.features.length &&
                    <div className="station-features">
                        <h3 className="info-title">Ծառայություններ</h3>
                        {stationById?.features.map((feature) => {
                            const trFeature = feature.translations?.find(tr => tr.language === mainLanguage)?.title
                            return <div className="station-feature" key={feature.id}>
                                <Icon type="check"/>
                                {trFeature}
                            </div>
                        })}
                    </div>}
                </div>
                <div className="separator"/>
                <h3 className="info-title">Վաճառվող վառելիք</h3>
                <div className="station-fuels-wrapper">
                    {stationById?.fuels && !!stationById?.fuels.length && stationById?.fuels.map((fuel) => {
                        const trFuel = fuel.translations?.find(tr => tr.language === mainLanguage)?.title
                        return <div className="station-fuel" key={fuel.id}>
                            <img src={generateImageMediaUrl(fuel?.iconPath?.path)} alt=""/>
                            <span className="fuel-title">{trFuel}</span>
                        </div>
                    })}
                </div>
                <div className="station-history">
                    <h1 className="history-title">Պատմություն</h1>
                    <div className="short-separator"/>
                    <ItemsList itemListHeaderInfo={stationsHistoryHeaderInfo}
                               itemListInfo={historyInfo}
                               loading={requestLoading}
                               infiniteScroll={true}
                               hasMore={historyById.hasMore}
                               useWindow={true}
                               loadMoreItems={this.loadMoreItems}/>
                </div>
            </section>
            <QRModal visible={this.state.previewModalOpen}
                     stationId={stationById?.id}
                     stationTitle={mainTr?.title}
                     closeModal={this.togglePreview}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetStationById,
    GetHistoryByStationId,
    ClearByIdHistory
};

export default connect(mapStateToProps, mapDispatchToProps)(StationDetails)
