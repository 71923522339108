import {request, _urlMedia, _urlMediaImages} from "../api";
import {MEDIA_CONSTS, LIMIT_GET_MEDIA, UTIL_CONSTS} from "../constants";
import swal from "sweetalert";

export const UploadFile = (file, pathList) => {
    return dispatch => {
        const requestData = {
            url: _urlMedia,
            token: true,
            method: "POST",
            data: file,
            customHeaders: {
                "Accept": "application/json",
                "Content-Type": "multipart/form-data",
            }
        };
        return request(requestData).then(res => {
            dispatch({
                type: MEDIA_CONSTS.UPLOAD_FILE,
                payload: {
                    data: res.data,
                    pathList
                }
            });
            dispatch({
                type: UTIL_CONSTS.END_LOADING
            });
        })
    }
};


export const GetGallery = (offset = 0, limit = LIMIT_GET_MEDIA, reset = true, text) => {
    return dispatch => {
        let url = `${_urlMedia}`;
        if (text) {
            url += `?name=${text}`;
        }
        const requestData = {
            url: url,
            token: true,
            method: "GET",
        };
        return request(requestData)
            .then(res => {
                res && res.data && dispatch({
                    type: MEDIA_CONSTS.GET_MEDIA,
                    payload: {
                        data: res.data,
                        hasMore: res.data.length === limit,
                        reset,
                        text,
                    },
                })
            })

    }
};

export const UpdateFile = (id, data, type) => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });

        const requestData = {
            url: `${_urlMedia}/${type === 'FOLDER' ? 'folder/' : ''}${id}`,
            token: true,
            method: "PATCH",
            data,
        };
        return request(requestData)
            .then(res => {
                dispatch({
                    type: MEDIA_CONSTS.UPDATE_MEDIA,
                    payload: res.data
                });
                swal({
                    title: "Հաջողվեց",
                    text: "Ֆայլը հաջողությամբ փոփոխվեց!",
                    icon: "success",
                    button: "Լավ"
                })
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                });
            })
    }
};

export const DeleteGalleryImage = (id, pathList) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}/${id}`,
            token: true,
            method: "DELETE",
        };
        return request(requestData)
            .then(() => {
                dispatch({
                    type: MEDIA_CONSTS.DELETE_MEDIA_BY_ID,
                    payload: {id, pathList}
                })
            })
    }
};

export const AddFolder = (data, pathList) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}/folder`,
            token: true,
            method: "POST",
            data,
        };
        return request(requestData).then(res => {
            dispatch({
                type: MEDIA_CONSTS.ADD_FOLDER,
                payload: {
                    data: res.data,
                    pathList
                }
            });
            dispatch({
                type: UTIL_CONSTS.END_LOADING
            });
            swal({
                title: "Հաջողվեց",
                text: "Թղթապանակը հաջողությամբ ստեղծվեց!",
                icon: "success",
                button: "Լավ"
            })
        }).finally(() => {
            dispatch({
                type: UTIL_CONSTS.END_LOADING
            });
        })
    }
};

export const DeleteFolder = (id, pathList) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}/folder/${id}`,
            token: true,
            method: "DELETE",
        };
        return request(requestData).then(res => {
            dispatch({
                type: MEDIA_CONSTS.DELETE_FOLDER,
                payload: {id, pathList}
            });
        })
    }
};

export const AddProductImage = (data) => {
    const requestData = {
        url: `${_urlMediaImages}/product-images`,
        token: true,
        method: "POST",
        data,
        customHeaders: {
            "Content-Type": "multipart/form-data",
        }
    };
    return request(requestData).then(res => {
        return res?.data;
    }).catch(() => {});
};

export const ChangeProductMainImage = (productId, mediaId) => {
    const requestData = {
        url: `${_urlMediaImages}/product-images/${productId}/make-main/${mediaId}`,
        token: true,
        method: "PUT",
    };
    return request(requestData).catch(() => {
    });
};

export const DeleteProductImage = (id, deletedImageIds) => {
    const requestData = {
        url: `${_urlMediaImages}/product-images/${id}?mediaIds=${deletedImageIds.join(',')}`,
        token: true,
        method: "DELETE",
    };
    return request(requestData).catch(() => {
    });
};

export const AddStoryImage = (data) => {
    const requestData = {
        url: `${_urlMediaImages}/services-ads-images`,
        token: true,
        method: "POST",
        data,
        customHeaders: {
            "Content-Type": "multipart/form-data",
        }
    };
    return request(requestData).then(res => {
        return res?.data;
    }).catch(() => {});
};

export const ChangeStoryMainImage = (storyId, mediaId) => {
    const requestData = {
        url: `${_urlMediaImages}/services-ads-images/${storyId}/make-main/${mediaId}`,
        token: true,
        method: "PUT",
    };
    return request(requestData).catch(() => {
    });
};

export const DeleteStoryImage = (id, deletedImageIds) => {
    const requestData = {
        url: `${_urlMediaImages}/services-ads-images/${id}?mediaIds=${deletedImageIds.join(',')}`,
        token: true,
        method: "DELETE",
    };
    return request(requestData).catch(() => {
    });
};
